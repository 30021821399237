import { ReactElement } from 'react';
import { zIndexes } from '../../../styling/zIndexes';
import { RaptorTheme, mainColors } from '../../../styling/theme';
import { SectionsAndPages } from '../../documentation/SectionsAndPages.component';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  sidebarLeftRoot: {
    width: '18.75rem',
    zIndex: zIndexes.sideBar,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0.94rem',
    boxShadow: theme.shadows[1],
    height: 'calc(100vh)',
    padding: '0.62rem',
    color: mainColors.mainBlue,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.31rem',
    width: '100%',
  },
  sectionHeading: {
    all: 'unset',
    fontSize: '1.25rem',
    fontWeight: 600,
    padding: '0.31rem 0.62rem',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  sectionItem: {
    all: 'unset',
    fontSize: '1.00rem',
    fontWeight: 400,
    marginLeft: '1.25rem',
    padding: '0.31rem 0.62rem',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  active: {
    backgroundColor: theme.palette.grey[100],
  },
  divider: {
    width: '100%',
    borderTop: `1px solid ${mainColors.mainBlue}`,
  },
  sideBarTitle: {
    fontSize: '1.88rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    padding: '0 0.62rem',
  },
}));

function DocumentationMenu(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.sidebarLeftRoot}>
      <div className={classes.sideBarTitle}>Documentation</div>
      <div className={classes.divider}></div>
      {SectionsAndPages.map((section) => (
        <div className={classes.section}>
          <NavLink
            to={`/documentation/${section.route}`}
            className={({ isActive }) =>
              isActive
                ? `${classes.sectionHeading} ${classes.active}`
                : classes.sectionHeading
            }
          >
            {section.name}
          </NavLink>
          {section.pages.map((page) => (
            <NavLink
              to={`/documentation/${section.route}/${page.route}`}
              className={({ isActive }) =>
                isActive
                  ? `${classes.sectionItem} ${classes.active}`
                  : classes.sectionItem
              }
            >
              {page.name}
            </NavLink>
          ))}
        </div>
      ))}
    </div>
  );
}

export default DocumentationMenu;
