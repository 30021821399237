import { FC, useEffect, useState } from 'react';
import client from '../../../../../utilities/requestClient';
import useRouteParams from '../../../../../hooks/useRouteParams';
import FactSheetsEditorColumn from './EditorTiles/FactSheetsEditorColumn.component';
import PdfViewer from './Pdfviewer/PdfViewer.component';
import { useNavigate } from 'react-router-dom';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import { mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { updateSection } from '../../../../../redux/pages/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  editorPageParent: {
    // width: '100vw',
    display: 'flex',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    gap: '3.12rem',
    justifyContent: 'center',
    height: '100%',
  },
  editorContainer: {
    maxWidth: '50.00rem',
    width: '100%',
    padding: '0 0.62rem',
    overflowY: 'auto',
    // overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.31rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '0.62rem',
    },
  },
  viewerContainer: {
    // position: 'sticky',
    // top: 0
    // overflow: 'hidden',
    width: '100%',
  },
}));

const FactSheetsEditor: FC = () => {
  // Need to add this here for the moment until the routing is improved
  const navigate = useNavigate();

  const requestClient = client();
  const [factSheetsData, setFactSheetsData] = useState<any[] | null>(null);
  // Will be used to dertmine if any of the content has been changed and if so then it can be saved.
  const [haveChangesBeenMade, setHaveChangesBeenMade] =
    useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const { fundId } = useRouteParams('fundId');
  const { fundCode } = useRouteParams('fundCode');
  const { fundName } = useRouteParams('fundName');
  const getDelegeatedActsData = async () => {
    requestClient
      .get(`/get_raidr_data/fact_sheet/English/${fundId}`)
      .then((response) => {
        setFactSheetsData(response.data);
        setIsPageLoading(false);
      });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  const classes = useStyles();

  return (
    <GeneralComponentErrorShield
      dataObjects={[
        {
          data: factSheetsData || [],
          isFetching: isPageLoading,
          error: null,
        },
      ]}
    >
      <div className={classes.editorPageParent}>
        <div className={classes.editorContainer}>
          <BackToOverviewBtn
            backToOverview={() => {
              navigate(`fact-sheets-overview`);
            }}
          />
          <FactSheetsEditorColumn
            factSheetsData={factSheetsData || []}
            setFactSheetsData={setFactSheetsData}
            setHaveChangesBeenMade={setHaveChangesBeenMade}
          />
        </div>
        <div className={classes.viewerContainer}>
          <PdfViewer
            factSheetsData={factSheetsData}
            haveChangesBeenMade={haveChangesBeenMade}
            setHaveChangesBeenMade={setHaveChangesBeenMade}
            fundId={fundCode}
            fundName={fundName}
          />
        </div>
      </div>
    </GeneralComponentErrorShield>
  );
};

export default FactSheetsEditor;
