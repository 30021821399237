import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { Tooltip, Typography } from '@mui/material';

interface ProcessingStatusBoxProps {
  statusColor: string;
  text: string;
  dataTimestamp: string;
  processingTimestamp: string;
  reportTimestamp: string;
}

const useProcessingBoxStyles = makeStyles<RaptorTheme>((theme) => ({
  tooltip: {
    maxWidth: 'none',
  },
}));

// const StatusBox = ({ status, text }: { status: Status; text: string, dataTimestamp: string, processingTimestamp: string, reporttimestamp: string }) => (
//     <div
//       style={{
//         padding: '0.50rem',
//         borderRadius: 8,
//         border: `1px solid ${mapProcessingStatusToColor(status)}`,
//         backgroundColor: mapProcessingStatusToColor(status),
//         // maxWidth: '25.00rem'
//       }}
//     >
//       <Typography
//         variant="h3"
//         style={{
//           transform: 'translateY(1px)',
//           color: 'white',
//           fontSize: '1.12rem',
//         }}
//       >
//         {text}
//       </Typography>
//     </div>
//   );

function ProcessingStatusBox(props: ProcessingStatusBoxProps): ReactElement {
  const {
    statusColor,
    text,
    dataTimestamp,
    processingTimestamp,
    reportTimestamp,
  } = props;
  const classes = useProcessingBoxStyles();

  return text !== '' ? (
    <Tooltip
      placement="top"
      disableHoverListener={dataTimestamp === '' && processingTimestamp === ''}
      classes={{ tooltip: classes.tooltip }}
      title={
        <div
          style={{
            // minWidth: '3312.50rem',
            width: '18.75rem',
          }}
        >
          {dataTimestamp !== '' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" style={{ color: 'white' }}>
                {'Data Recived at:'}
              </Typography>
              <Typography variant="h3" style={{ color: 'white' }}>
                {dataTimestamp}
              </Typography>
            </div>
          ) : null}
          {processingTimestamp !== '' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" style={{ color: 'white' }}>
                {'Processing Began at:'}
              </Typography>
              <Typography variant="h3" style={{ color: 'white' }}>
                {processingTimestamp}
              </Typography>
            </div>
          ) : null}
          {reportTimestamp !== '' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" style={{ color: 'white' }}>
                {'Report Delivered at:'}
              </Typography>
              <Typography variant="h3" style={{ color: 'white' }}>
                {reportTimestamp}
              </Typography>
            </div>
          ) : null}
        </div>
      }
    >
      <div
        style={{
          padding: '0.50rem',
          borderRadius: 8,
          border: `1px solid ${statusColor}}`,
          backgroundColor: statusColor,
          cursor: 'pointer',
          // maxWidth: '25.00rem'
        }}
      >
        <Typography
          variant="h3"
          style={{
            transform: 'translateY(1px)',
            color: 'white',
            fontSize: '1.12rem',
          }}
        >
          {text}
        </Typography>
      </div>
    </Tooltip>
  ) : (
    <div
      style={{
        padding: '0.50rem',
        borderRadius: 8,
        border: `1px solid ${mainColors.lightGrey}}`,
        backgroundColor: mainColors.lightGrey,
        // opacity: 0.3,
        cursor: 'pointer',
        // maxWidth: '25.00rem'
      }}
    >
      <Typography
        variant="h3"
        style={{
          transform: 'translateY(1px)',
          color: 'black',
          fontSize: '1.12rem',
        }}
      >
        {'Not Expected'}
      </Typography>
    </div>
  );
}

export default ProcessingStatusBox;
