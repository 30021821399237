import React, { useEffect } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';
import TableContainer from './subComponents/TableContainer.component';
import requestClient from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { greys, mainColors } from '../../../../styling/theme';

const useStyles = makeStyles(() => ({
  lookbackDaysContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '0.62rem',
    padding: '0.62rem 0.94rem',
    margin: '0.62rem',
    background: 'white',
    borderRadius: '0.25rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.62rem',
  },
  lookbackDaysTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  lookbackDaysInput: {
    all: 'unset',
    padding: '0.31rem',
    width: '2.50rem',
    height: '1.25rem',
    fontSize: '0.88rem',
    borderRadius: '0.12rem',
    border: `1px solid ${greys.grey200}`,
  },
  lookbackDaysButton: {
    all: 'unset',
    height: '1.25rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontSize: '0.88rem',
    fontWeight: 500,
    background: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: mainColors.mainBlue_lighter,
    },
  },
  lookbackDaysActive: {
    fontSize: '0.88rem',
    color: mainColors.mainBlue,
    fontWeight: 500,
  },
}));

export type TableRow = {
  current_date: string;
  current_value: number | string;
  fund_name: string;
  internal_fund_name: string;
  previous_date: string;
  previous_value: number | string;
};

export type TableType = 'percentage' | 'pass_fail';

export type Table = {
  key: string;
  title: string;
  type: TableType;
  data: TableRow[];
};

const handleValueChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: React.Dispatch<React.SetStateAction<number | ''>>,
) => {
  // only allow numbers to be entered
  // if number is greater than 180, set to 180
  const value = e.target.value;
  const number = Number(value);
  if (value === '') {
    setValue('');
  } else if (!isNaN(number) && number <= 180) {
    setValue(number);
  } else if (!isNaN(number) && number > 180) {
    setValue(180);
  }
};

const StatusChange: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const classes = useStyles();

  const client = requestClient();

  const sectionDetails = useSelector(createSectionByIdSelector(props.section));

  const [lookbackDays, setLookbackDays] = React.useState<number | ''>(1);
  const [activeLookbackDays, setActiveLookbackDays] = React.useState<number>(1);

  const [requestStatus, setRequestStatus] = React.useState<
    'idle' | 'loading' | 'error' | 'success'
  >('idle');
  const [statusChangeData, setStatusChangeData] = React.useState<Table[]>([]);

  const getData = () => {
    setRequestStatus('loading');
    const schemas = sectionDetails?.fundTypes.length
      ? `?schemas=${sectionDetails?.fundTypes}`
      : '';
    const days = lookbackDays
      ? schemas
        ? `&days_back=${lookbackDays}`
        : `?days_back=${lookbackDays}`
      : '';
    client
      .get(`decline_and_fall` + schemas + days)
      .then((response) => {
        setRequestStatus('success');
        setStatusChangeData(response.data);
        setTimeout(() => {
          setRequestStatus('idle');
        }, 2000);
      })
      .catch(() => {
        setRequestStatus('error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={classes.lookbackDaysContainer}>
        <form
          className={classes.formContainer}
          onSubmit={(e) => {
            e.preventDefault();
            setActiveLookbackDays(lookbackDays as number);
            getData();
          }}
        >
          <div className={classes.lookbackDaysTitle}>Lookback Days:</div>
          <input
            type="text"
            className={classes.lookbackDaysInput}
            value={lookbackDays}
            onChange={(e) => handleValueChange(e, setLookbackDays)}
          />
          <button className={classes.lookbackDaysButton}>Calculate</button>
        </form>
        <div className={classes.lookbackDaysActive}>
          {`Active lookback days: ${activeLookbackDays}`}
        </div>
      </div>
      {requestStatus === 'loading' ? (
        <DisplayAreaCenteredWrapper>
          <RaptorLoading
            messages={[
              `Loading data for ${lookbackDays} ${
                lookbackDays && lookbackDays <= 1 ? 'day' : 'days'
              } previously...`,
            ]}
          />
        </DisplayAreaCenteredWrapper>
      ) : (
        <TableContainer data={statusChangeData} section={props.section} />
      )}
    </>
  );
};

export default StatusChange;
