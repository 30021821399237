import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import { greys } from '../../../../../styling/theme';
import { Link } from 'react-router-dom';
import { updateActiveDate } from '../../../../../redux/pages/actions';
import PageLinkButton from '../../../../buttons/PageLinkButton';
import GeneralReportDatePicker from '../../../../general/GeneralReportDatePicker';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import { Button, Typography } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

export interface EsgOverviewTableData {
  name: string;
  fundId: string;
  // articleLevel?: string;
  // articleStatus?: string;
  // businessInvolvementScore?: number;
  // zeroEmissions: number;
  // esg: number;
  positionsBreakdown: any;
  index: number;
  nav: string | number;
  currency: number;
  positionDate: string;
  package?: string;
  columnOneValue: string;
  columnOneStatus: string;
  columnOnePageLink: string;
  columnTwoValue: string;
  columnTwoStatus: string;
  columnTwoPageLink: string;
  columnThreeValue: string;
  columnThreeStatus: string;
  columnThreePageLink: string;
  columnFourValue: string;
  columnFourStatus: string;
  columnFourPageLink: string;
  esgReports: string[];
}
export interface EsgOverviewTableProps {
  data: DataObject;
  esgReportsList: any;
}

const dummyEsgFundScores = {
  gross: {
    e: 78.16,
    esg: 73.78,
    g: 75.53,
    s: 67.65,
  },
  net: {
    e: 78.16,
    esg: 73.78,
    g: 75.53,
    s: 67.65,
  },
};

export function buildEsgExposureData(
  data: any,
  esgReportsList: any,
): EsgOverviewTableData[] {
  // Check data
  if (!data.overview_page_data.length) return [];
  const esgReports = esgReportsList ? esgReportsList.data[0] : null;

  // get the overview data.
  const overviewData = data.overview_page_data;

  // Create list for returning
  const overviewTableData: EsgOverviewTableData[] = [];

  overviewData.forEach((value: any, index: number) => {
    const columnsData = value.overview_columns_data || null;
    overviewTableData.push({
      name: value.fund_name,
      fundId: value.fund_id,
      positionsBreakdown:
        (value.e < 0 && value.s < 0 && value.g < 0 && value.esg < 0) ||
        value.positions,
      index: index + 1,
      nav: addCommasToNumbersAndRound(value.nav),
      currency: value.currency,
      positionDate: value.position_date,
      package:
        'esg_subscription_package' in value
          ? value.esg_subscription_package
          : undefined,
      columnOneValue: columnsData ? columnsData[0].value.toString() : 'NA',
      columnOneStatus: columnsData ? columnsData[0].status : 'NA',
      columnOnePageLink: columnsData ? columnsData[0].url : '',
      columnTwoValue: columnsData ? columnsData[1].value.toString() : 'NA',
      columnTwoStatus: columnsData ? columnsData[1].status : 'NA',
      columnTwoPageLink: columnsData ? columnsData[1].url : '',
      columnThreeValue: columnsData ? columnsData[2].value.toString() : 'NA',
      columnThreeStatus: columnsData ? columnsData[2].status : 'NA',
      columnThreePageLink: columnsData ? columnsData[2].url : '',
      columnFourValue: columnsData ? columnsData[3].value.toString() : 'NA',
      columnFourStatus: columnsData ? columnsData[3].status : 'NA',
      columnFourPageLink: columnsData ? columnsData[3].url : '',
      esgReports: esgReports ? esgReports[value.fund_id] : [],
    });
  });
  return overviewTableData;
}

export const EsgArticleStatusBox = ({
  article,
  articleStatus,
  fundId,
  newDate,
}: {
  article: string | undefined;
  articleStatus: Status;
  fundId: string;
  newDate?: string;
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (newDate) {
      dispatch(updateActiveDate(newDate));
    }
  };

  return article ? (
    <Link
      to={`/esg/article-scanner${fundId ? `?fundId=${fundId}` : ``}`}
      style={{ textDecoration: 'none' }}
    >
      <div
        style={{
          padding: '0.50rem',
          borderRadius: 8,
          border: `1px solid ${mapStatusToColor(specifyStatus(articleStatus))}`,
        }}
      >
        <Typography
          variant="h3"
          style={{
            transform: 'translateY(1px)',
            color: mapStatusToColor(articleStatus),
            fontSize: '1.12rem',
          }}
        >
          {article}
        </Typography>
      </div>
    </Link>
  ) : (
    <Button
      onClick={handleClick}
      style={{
        backgroundColor: greys.grey400,
        color: greys.grey600,
        width: '100%',
        maxWidth: '12.50rem',
      }}
      disabled={true}
    >
      NA
    </Button>
  );
};

export const EsgPurePackageButton = () => (
  <Button
    style={{
      backgroundColor: greys.grey400,
      color: greys.grey600,
      width: '100%',
      maxWidth: '12.50rem',
    }}
    disabled={true}
  >
    NA
  </Button>
);

function getDatesFromEsgReportNames(reportNames: string[]): Date[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: Date[] = [];
  reportNames.forEach((reportName: string) => {
    // Get the last 14 characters from the report name.
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(new Date(formattedDate));
  });
  const sortedDates = dates.sort();
  return sortedDates;
}

function getDatesFromEsgReportNamesAsStrings(reportNames: string[]): string[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: string[] = [];
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(formattedDate);
  });
  return dates;
}

function findEsgReportnameFromDate(
  date: Date | null,
  reportNames: string[],
): string | null {
  if (!date) return null;
  // Format the date as a string
  const dateString = formatDateForCheckingState(date);
  let foundReportName = '';
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    if (dateString === formattedDate) {
      foundReportName = reportName;
      return;
    }
  });
  return foundReportName;
}

function buildEsgOverviewColumns(
  clientName: string,
  columnTitles: string[],
): CustomColumn<EsgOverviewTableData>[] {
  const detailColumns: CustomColumn<EsgOverviewTableData>[] = [
    {
      title: '#',
      field: 'index',
      width: '5%',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    {
      title: 'Fund Id',
      field: 'fundId',
      width: '8%',
      render: (rowData) =>
        clientName == 'mersenne' ? `Fund${rowData.index}` : rowData.fundId,
    },
    {
      title: 'Fund Name',
      field: 'name',
      width: '18%',
    },
    { title: 'Position Date', field: 'positionDate' },
    {
      title: 'ESG Reports',
      field: 'esgReports',
      sorting: false,
      // render: reportsSelect,
      render: (rowData: EsgOverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.esgReports}
          reportUrl="ucits_historical_report"
          fundId={rowData.fundId}
          customGetDatesFromReportNamesFunction={getDatesFromEsgReportNames}
          customGetDatesFromReportNamesAsStringsFunction={
            getDatesFromEsgReportNamesAsStrings
          }
          customFindReportnameFromDate={findEsgReportnameFromDate}
        />
      ),
      // width: '18%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Net Asset Value',
      field: 'nav',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: columnTitles[0],
      field: 'esg',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: EsgOverviewTableData) => (
        <PageLinkButton
          page={`${rowData.columnOnePageLink}?fundId=${rowData.fundId}`}
          section="esg"
          text={rowData.columnOneValue}
          status={specifyStatus(rowData.columnOneStatus)}
          disabled={rowData.columnOnePageLink === ''}
        />
      ),
    },
    {
      title: columnTitles[1],
      field: 'articleLevel',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: EsgOverviewTableData) =>
        rowData.package && rowData.package === 'esg_pure_package' ? (
          <EsgPurePackageButton />
        ) : (
          <PageLinkButton
            page={`${rowData.columnTwoPageLink}?fundId=${rowData.fundId}`}
            section="esg"
            text={rowData.columnTwoValue}
            status={specifyStatus(rowData.columnTwoStatus)}
            disabled={rowData.columnTwoPageLink === ''}
          />
        ),
    },
    {
      title: columnTitles[2],
      field: 'businessInvolvementScore',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: EsgOverviewTableData) =>
        rowData.package && rowData.package === 'esg_pure_package' ? (
          <EsgPurePackageButton />
        ) : (
          <PageLinkButton
            page={`${rowData.columnThreePageLink}?fundId=${rowData.fundId}`}
            section="esg"
            text={rowData.columnThreeValue}
            status={specifyStatus(rowData.columnThreeStatus)}
            disabled={rowData.columnThreePageLink === ''}
          />
        ),
    },
    {
      title: columnTitles[3],
      field: 'zeroEmissions',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: EsgOverviewTableData) =>
        rowData.package && rowData.package === 'esg_pure_package' ? (
          <EsgPurePackageButton />
        ) : (
          <PageLinkButton
            page={`${rowData.columnFourPageLink}?fundId=${rowData.fundId}`}
            section="esg"
            text={rowData.columnFourValue}
            status={specifyStatus(rowData.columnFourStatus)}
            disabled={rowData.columnFourPageLink === ''}
          />
        ),
    },
  ];
  return detailColumns;
}

function allOtherSorting(data: any) {
  const dataSorted = data;
  dataSorted.overview_page_data.sort((a: any, b: any) => {
    return a.fund_id.localeCompare(b.fund_id, 'en', { numeric: true });
  });

  return dataSorted;
}

const DEFAULT_COLUMN_NAMES = [
  'ESG',
  'Article Level',
  'Business Involvement Score',
  'Zero Emission / Paris Aligned',
];

const EsgOverviewTable: FC<EsgOverviewTableProps> = ({
  data,
  esgReportsList,
}) => {
  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const sorted = allOtherSorting(data.data[0]);
  const tableData = buildEsgExposureData(sorted, esgReportsList);
  const columnTitles =
    'overview_columns' in sorted
      ? sorted.overview_columns
      : DEFAULT_COLUMN_NAMES;
  const detailColumns = buildEsgOverviewColumns(clientName, columnTitles);

  return (
    <GridItem xs={12} card>
      <CustomTable<EsgOverviewTableData>
        columns={detailColumns}
        showToolbar
        data={tableData}
        title={'ESG Overview'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default EsgOverviewTable;
