import React from 'react';
import GeneralTable from './GeneralTable.component';
import makeStyles from '@mui/styles/makeStyles';
import { Table } from '../StatusChange.page';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0.62rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.62rem',
    justifyContent: 'flex-start',
  },
}));

interface Props {
  data: Table[];
  section: string;
}

const TableContainer: React.FC<Props> = ({ section, data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((table: Table) => (
        <GeneralTable section={section} table={table} />
      ))}
    </div>
  );
};

export default TableContainer;
