import React, { CSSProperties, ReactElement } from 'react';
import {
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { RaptorTheme } from '../../styling/theme';
import GeneralLoadingCover from '../general/GeneralLoadingCover';
import { flexify } from '../../utilities/cssMixins';
import usePngFromRecharts from '../../hooks/usePngFromRecharts';
import FileSaver from 'file-saver';
import ExportButton from '../feedback/ExportButton';
import ChartDownloadButton from '../buttons/ChartDownloadButton';
import { Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  center: {
    margin: '0 auto',
    fontSize: '0.88rem',
  },
});

interface Props<T> {
  xAxes: XAxisProps[];
  yAxes: YAxisProps[];
  data: any;
  components: {
    ComponentType: any;
    props: { [key: string]: any };
  }[];
  height: number | string;
  download?: boolean;
  width: number | string;
  loading: boolean;
  export?: boolean;
  title: string;
  legendFormatter?: any;
  legendWrapper?: CSSProperties;
  tooltipFormatter?: any;
  margin: { top: number; bottom: number; left: number; right: number };
}
function GenericComposedChart<T>(props: Props<T>): ReactElement {
  const classes = useStyles();

  const { ref, handleDownload } = usePngFromRecharts();

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, props.title + '.png');
    }
  };
  const theme = useTheme();
  return (
    <GeneralLoadingCover
      loading={props.loading}
      style={{
        height: props.height,
        width: props.width,
        padding: '3.12rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0.25rem 1.25rem',
        }}
      >
        <div id="chart-title">
          <Typography
            variant={'h2'}
            style={{ color: theme.palette.primary.main }}
          >
            {props.title}
          </Typography>
        </div>

        <div id="export_area" style={{ display: 'flex', alignItems: 'center' }}>
          {props.export && (
            <ExportButton
              exportData={props.data}
              fields={
                props.data && props.data[0] ? Object.keys(props.data[0]) : []
              }
            />
          )}
          {props.download && <ChartDownloadButton handler={saveImage} />}
        </div>
      </div>
      {props.data.length ? (
        <ResponsiveContainer
          width={props.width}
          height={props.height}
          className={classes.center}
        >
          <ComposedChart data={props.data} margin={props.margin} ref={ref}>
            {props.xAxes.map((axisProps: XAxisProps, index: number) => (
              <XAxis {...axisProps} key={`${axisProps.dataKey}`} />
            ))}
            {props.yAxes.map((axisProps: YAxisProps, index: number) => (
              <YAxis {...axisProps} key={`${axisProps.dataKey}`} />
            ))}
            <Tooltip formatter={props.tooltipFormatter} />
            <Legend
              formatter={props.legendFormatter}
              wrapperStyle={
                props.legendWrapper ? props.legendWrapper : { bottom: 0 }
              }
            />
            {props.components.map((component: any, index: number) => (
              <component.ComponentType
                {...component.props}
                key={`${component.props.dataKey}`}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            height: '100%',
            minHeight: '25.00rem',
            width: '100%',
            ...flexify(),
          }}
        >
          <Typography variant="h3">No data available</Typography>
        </div>
      )}
    </GeneralLoadingCover>
  );
}
GenericComposedChart.defaultProps = {
  legendFormatter: (value: any) => value,
  tooltipFormatter: (value: any) => value,
};

export default GenericComposedChart;
