import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import { mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap, Tooltip } from '@mui/material';

interface AssetAllocationPerformanceTableProps {
  data: DataObject;
}

interface AssetAllocationPerformanceTableData {
  sector_name: string;
  sector_returns: number;
  aggregate_gross_exposure: number;
  aggregate_pct_gross_exposure: number;
  benchmark_weights: number;
  difference: number;
  over_or_under_weight: string;
  risk_weighted_benchmark_returns: number;
  fund_weighted_sector_returns: number;
  benchmark_weighted_sector_returns: number;
  asset_allocation_pct: number;
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    cursor: 'pointer',
    width: 'fit-content',
    margin: '0 auto',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    // '&:hover': {
    //   backgroundColor: mainColors.hoverOverWhite,
    // },
  },
}));

const buildColumns = (
  classes: ClassNameMap<string>,
  sortHeader: (header: keyof AssetAllocationPerformanceTableData) => void,
): CustomColumn<AssetAllocationPerformanceTableData>[] => [
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('sector_name')}
      >
        <Tooltip title="">
          <div>Sector Name</div>
        </Tooltip>
      </div>
    ),
    field: 'sector_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '15%',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('aggregate_pct_gross_exposure')}
      >
        <Tooltip title="">
          <div>Fund Weights %</div>
        </Tooltip>
      </div>
    ),
    field: 'aggregate_pct_gross_exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      percentageToNdecialPlaces(rowData.aggregate_pct_gross_exposure / 100, 2),
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('benchmark_weights')}
      >
        <Tooltip title="">
          <div>Benchmark Weights %</div>
        </Tooltip>
      </div>
    ),
    field: 'benchmark_weights',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      percentageToNdecialPlaces(rowData.benchmark_weights / 100, 2),
  },

  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('difference')}
      >
        <Tooltip title="">
          <div>Difference in Weights</div>
        </Tooltip>
      </div>
    ),
    field: 'difference',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(rowData.difference / 100, 2)
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('sector_returns')}
      >
        <Tooltip title="">
          <div>Benchmark Sector Returns %</div>
        </Tooltip>
      </div>
    ),
    field: 'sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(rowData.sector_returns / 100, 2)
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('fund_weighted_sector_returns')}
      >
        <Tooltip title="">
          <div>Fund Weighted Benchmark Sector Returns %</div>
        </Tooltip>
      </div>
    ),
    field: 'fund_weighted_sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(
            rowData.fund_weighted_sector_returns / 100,
            2,
          )
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('benchmark_weighted_sector_returns')}
      >
        <Tooltip title="">
          <div>Benchmark Weighted Benchmark Sector Returns</div>
        </Tooltip>
      </div>
    ),
    field: 'benchmark_weighted_sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      rowData.sector_name !== 'Total'
        ? percentageToNdecialPlaces(
            rowData.benchmark_weighted_sector_returns / 100,
            2,
          )
        : '',
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('asset_allocation_pct')}
      >
        <Tooltip title="">
          <div>Asset Allocation Returns %</div>
        </Tooltip>
      </div>
    ),
    field: 'asset_allocation_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: AssetAllocationPerformanceTableData) =>
      percentageToNdecialPlaces(rowData.asset_allocation_pct / 100, 2),
  },
  {
    title: (
      <div
        className={classes.tableHeader}
        onClick={() => sortHeader('over_or_under_weight')}
      >
        <Tooltip title="">
          <div>Over/Under Weight</div>
        </Tooltip>
      </div>
    ),
    field: 'over_or_under_weight',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const buildTableData = (
  inputData: any,
): AssetAllocationPerformanceTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: any[] = [];
    // Sum over the Asset Allocation Column, Fund weights and Benchmark weights columns to create a total row
    const totalRow: any = {
      sector_name: 'Total',
      sector_returns: 0,
      aggregate_gross_exposure: 0,
      aggregate_pct_gross_exposure: 0,
      benchmark_weights: 0,
      difference: 0,
      over_or_under_weight: '',
      risk_weighted_benchmark_returns: 0,
      fund_weighted_sector_returns: 0,
      benchmark_weighted_sector_returns: 0,
      asset_allocation_pct: 0,
    };
    returnData.push(totalRow);
    inputData.data[0].sector_weights_comparision.map((sector: any) => {
      returnData.push({
        sector_name: sector.sector_name,
        sector_returns: sector.sector_returns,
        aggregate_gross_exposure: sector.aggregate_gross_exposure,
        aggregate_pct_gross_exposure: sector.aggregate_pct_gross_exposure,
        benchmark_weights: sector.benchmark_weights,
        difference: sector.difference,
        over_or_under_weight: sector.over_or_under_weight,
        risk_weighted_benchmark_returns: sector.risk_weighted_benchmark_returns,
        fund_weighted_sector_returns: sector.fund_weighted_sector_returns,
        benchmark_weighted_sector_returns:
          sector.benchmark_weighted_sector_returns,
        asset_allocation_pct: sector.asset_allocation_pct,
      });
    });
    returnData.forEach((row: any) => {
      // totalRow.sector_returns += row.sector_returns;
      // totalRow.aggregate_gross_exposure += row.aggregate_gross_exposure;
      totalRow.aggregate_pct_gross_exposure += row.aggregate_pct_gross_exposure;
      totalRow.benchmark_weights += row.benchmark_weights;
      // totalRow.difference += row.difference;
      // totalRow.risk_weighted_benchmark_returns += row.risk_weighted_benchmark_returns;
      // totalRow.fund_weighted_sector_returns += row.fund_weighted_sector_returns;
      // totalRow.benchmark_weighted_sector_returns += row.benchmark_weighted_sector_returns;
      totalRow.asset_allocation_pct += row.asset_allocation_pct;
    });
    return returnData;
  }
};

const AssetAllocationPerformanceTable: React.FC<
  AssetAllocationPerformanceTableProps
> = ({ data }) => {
  const classes = useStyles();

  const originalTableData = buildTableData(data);
  const [tableData, setTableData] =
    React.useState<AssetAllocationPerformanceTableData[]>(originalTableData);
  const [currentSortedColumn, setCurrentSortedColumn] = React.useState<
    string | null
  >(null);
  const [currentSortOrder, setCurrentSortOrder] = React.useState<
    'asc' | 'desc' | null
  >(null);

  const sortTable = (
    column: keyof AssetAllocationPerformanceTableData,
    order: 'asc' | 'desc',
  ) => {
    // sort originalTableData by column and order (using localecompare if string), leaving the first column in place
    const [totalRow, ...rest] = originalTableData;
    let sortedRest: AssetAllocationPerformanceTableData[] = [];
    if (typeof rest[0][column] === 'number') {
      sortedRest = rest.sort((a: any, b: any) => {
        if (order === 'asc') {
          return a[column] - b[column];
        } else {
          return b[column] - a[column];
        }
      });
    } else if (typeof rest[0][column] === 'string') {
      sortedRest = rest.sort((a: any, b: any) => {
        if (order === 'asc') {
          return a[column].localeCompare(b[column], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        } else {
          return b[column].localeCompare(a[column], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }
      });
    }
    setTableData([totalRow].concat(sortedRest));
  };

  const sortHeader = (header: keyof AssetAllocationPerformanceTableData) => {
    if (currentSortedColumn === null || currentSortOrder === null) {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('asc');
      sortTable(header, 'asc');
    } else if (currentSortedColumn === header && currentSortOrder === 'asc') {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('desc');
      sortTable(header, 'desc');
    } else if (currentSortedColumn === header && currentSortOrder === 'desc') {
      setCurrentSortedColumn(null);
      setCurrentSortOrder(null);
      setTableData(originalTableData);
    } else {
      setCurrentSortedColumn(header);
      setCurrentSortOrder('asc');
      sortTable(header, 'asc');
    }
  };

  return (
    <GridItem xs={12} card>
      <CustomTable<AssetAllocationPerformanceTableData>
        columns={buildColumns(classes, sortHeader)}
        showToolbar
        data={tableData}
        title={'Sector Comparison'}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: true,
          exportButton: true,
          rowStyle: (rowData: AssetAllocationPerformanceTableData) => ({
            backgroundColor:
              rowData.sector_name === 'Total'
                ? hexToRGBA(mainColors.jonquil, 0.8)
                : undefined,
            borderTop:
              rowData.sector_name === 'Total' ? '1px solid black' : undefined,
          }),
        }}
      />
    </GridItem>
  );
};

export default AssetAllocationPerformanceTable;
