import React, { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import client from '../../../../../utilities/requestClient';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleControlBarShowing } from '../../../../../redux/ui/controlbar/actions';
import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import RaptorSelectObject from '../../../../selects/RaptorSelectObject';

interface SaveConfirmationDialogBoxProps {
  dialogIsShowing: boolean;
  setDialogIsShowing: (value: boolean) => void;
  fundId: string;
  shareClass: string;
  documentLanguage: string;
  filteredOverviewData: KIDOverviewData[];
  setFilteredOverviewData: (value: KIDOverviewData[]) => void;
  fundName: string;
  backToOverviewLink: string;
  hasEditBtn?: boolean;
  isPriips: boolean;
}

const useStyles = makeStyles(() => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectContainer: {
    width: '75.00rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewConfirmationDialogBox: FC<SaveConfirmationDialogBoxProps> = ({
  dialogIsShowing,
  setDialogIsShowing,
  fundId,
  shareClass,
  documentLanguage,
  filteredOverviewData,
  setFilteredOverviewData,
  backToOverviewLink,
  hasEditBtn,
  isPriips,
}) => {
  const classes = useStyles();
  // INdicates that a review has been completed
  const [reviewComplete, setReviewComplete] = React.useState<boolean>(false);
  // USed to store the next document that is to be reviewed.
  const [nextDocument, setNextDocument] = React.useState<any>('');
  const requestClient = client();

  // Function for marking the current document as reviewed
  const markDocumentReviewed = () => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object.
    formData.append('fund_id', fundId);
    formData.append('share_class', shareClass);
    formData.append('document_language', documentLanguage);
    requestClient
      .post('kids_generator_mark_kid_reviewed', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (
          'Status' in response.data &&
          response.data['Status'] === 'success'
        ) {
          setReviewComplete(true);

          const filteredOverviewDataCopy = filteredOverviewData.filter(
            (doc) => doc.fund_id_string !== fundId,
          );

          setFilteredOverviewData(filteredOverviewDataCopy);
        }
      });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Function for returning to the overview page
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    navigate(backToOverviewLink);
  };

  // Function for hadling the close of the dialog box
  const handleClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      setDialogIsShowing(false);
    }
  };

  // Function for reviewing the next document
  function reviewNextDocument() {
    setDialogIsShowing(false);
    dispatch(toggleControlBarShowing(false));

    // Reroute to the review page for the next document
    navigate(
      isPriips
        ? `/raidr/priips-kids-review?fundId=${nextDocument.fund_id_string}&isin=${
            nextDocument.share_class
          }&documentLanguage=${nextDocument.document_language}&currentVersion=${
            nextDocument.version
          }&publishedVersion=${nextDocument.version.split('.')[0] + '.0.0'}`
        : `/raidr/ucits-kiids-review?fundId=${nextDocument.fund_id_string}&isin=${
            nextDocument.share_class
          }&documentLanguage=${nextDocument.document_language}&currentVersion=${
            nextDocument.version
          }&publishedVersion=${nextDocument.version.split('.')[0] + '.0.0'}`,
    );
  }

  return (
    <Dialog
      open={dialogIsShowing}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth={true}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      {!reviewComplete ? (
        <>
          <DialogTitle id="review-header-text" textAlign="center">
            Confirm Review?
          </DialogTitle>
          <DialogContentText id="review-subheader-text" textAlign="center">
            Update the version number of the document to indicate that a review
            has been completed.
          </DialogContentText>
          <DialogActions>
            {hasEditBtn && (
              <Button
                onClick={() =>
                  navigate(
                    isPriips
                      ? `/raidr/priips-kids-editor?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}`
                      : `/raidr/ucits-kiids-editor?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}`,
                  )
                }
              >
                Go To Edit Page
              </Button>
            )}
            <Button onClick={() => setDialogIsShowing(false)}>Cancel</Button>
            <Button onClick={markDocumentReviewed}>Confirm</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="review-complete-header-text" textAlign="center">
            Review Complete
          </DialogTitle>
          {filteredOverviewData.length > 0 ? (
            <>
              <DialogContentText id="review-select-text" textAlign="center">
                Would you like to review another Document? Select the Document
                from the dropdown below
              </DialogContentText>
              <DialogContent style={{ overflowY: 'visible' }}>
                <div className={classes.selectContainer}>
                  <RaptorSelectObject
                    options={filteredOverviewData}
                    onSelect={setNextDocument}
                    defaultOption={filteredOverviewData[0]}
                    getDisplayValue={(item) =>
                      `${item?.fund_name} - ${item?.share_class}`
                    }
                    maxWidth="50.00rem"
                  />
                </div>
              </DialogContent>
            </>
          ) : (
            <DialogContentText id="review-select-text" textAlign="center">
              There Are no more documents to review
            </DialogContentText>
          )}
          <DialogActions>
            <Button onClick={backToOverview}>Return To Overview</Button>
            {filteredOverviewData.length > 0 ? (
              <Button
                onClick={reviewNextDocument}
                disabled={nextDocument === ''}
              >
                Review Next Document
              </Button>
            ) : null}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ReviewConfirmationDialogBox;
