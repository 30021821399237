import React, { ReactElement } from 'react';
import { greys, mainColors } from '../../styling/theme';
import { Status } from '../../types/redux/data/dataTypes';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Theme, Tooltip, Typography } from '@mui/material';

interface StyleProps {
  status: keyof typeof mainColors;
  statusDarker: keyof typeof mainColors;
  color: string | null;
  neutralColor?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props: StyleProps) =>
      props.disabled
        ? greys.grey700
        : props.neutralColor
          ? mainColors.mainBlue_lighter
          : mainColors[props.status],
    color: (props: StyleProps) => (props.color ? props.color : 'white'),
    width: '100%',
    maxWidth: '12.50rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: (props: StyleProps) =>
        props.disabled
          ? greys.grey700
          : props.neutralColor
            ? mainColors.mainBlue
            : mainColors[props.statusDarker],
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

export interface PageLinkButtonProps {
  status: Status;
  fundName?: string;
  page: string;
  section: string;
  text?: string;
  textColor?: string | null;
  setTitleBarSelect?: {
    selectedValue: string;
  };
  additionalHandler?: () => void;
  tooltipText?: string;
  neutralColor?: boolean;
  disabled?: boolean;
}

function PageLinkButton({
  status,
  section,
  page,
  ...props
}: React.PropsWithChildren<PageLinkButtonProps>): ReactElement {
  const handleClick = () => {
    //TODO manage what clicking on this button does
    if (props.additionalHandler) props.additionalHandler();
  };
  const classes = useStyles({
    status,
    statusDarker: `${status}_darker`,
    color: props.textColor || null,
    neutralColor: props.neutralColor,
    disabled: props.disabled || status === Status.NA,
  } as StyleProps);

  return (
    <Link
      to={`/${section}/${page}${
        props.fundName ? `?fundId=${props.fundName}` : ``
      }`}
      className={classes.link}
    >
      {props.tooltipText ? (
        <Tooltip
          title={
            <Typography variant="h4" style={{ color: 'white' }}>
              {props.tooltipText}
            </Typography>
          }
          placement="top"
        >
          <Button
            disabled={props.disabled}
            onClick={handleClick}
            className={classes.PageLinkButtonRoot}
          >
            {props.text || status}
          </Button>
        </Tooltip>
      ) : (
        <Button
          disabled={props.disabled}
          onClick={handleClick}
          className={classes.PageLinkButtonRoot}
        >
          {props.text || status}
        </Button>
      )}
    </Link>
  );
}

export default PageLinkButton;
