import React, { FC, useEffect, useState } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { mainColors } from '../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  roundNumberToNDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { ColumnIdToHeaderMapType } from '../../../feedback/ExportButton';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';

export interface VarChangesTableProps {
  data: any;
  comparisonDate: string;
  referenceDate: string;
}

interface VarChangesTableData {
  asset: string;
  position_var_data_comparison: number | string;
  position_var_data_reference: number | string;
  position_var_data_percentage_change: number | string;
  position_cvar_data_comparison: number | string;
  position_cvar_data_reference: number | string;
  position_cvar_data_percentage_change: number | string;
  position_dvar_data_comparison: number | string;
  position_dvar_data_reference: number | string;
  position_dvar_data_percentage_change: number | string;
}

interface SortColumnButtonProps {
  column: keyof VarChangesTableData;
  activeColumn: keyof VarChangesTableData;
  currentSortingType: SortingType;
  content: string;
}

const useStyles = makeStyles(() => ({
  sortHeader: {
    cursor: 'pointer',
    margin: 'auto',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.12rem',
    userSelect: 'none',
    minWidth: '6.88rem',
    '&:hover': {
      backgroundColor: mainColors.veryFaintBlue,
    },
    '&:active': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
  sortHeaderActive: {
    backgroundColor: mainColors.hoverOverVeryFaintBlue,
  },
}));

const buildColumns = (
  SortColumnButton: FC<SortColumnButtonProps>,
  currentSortedColumn: keyof VarChangesTableData,
  currentSortingType: SortingType,
): CustomColumn<VarChangesTableData>[] => {
  return [
    {
      title: '',
      field: 'asset',
      width: '20%',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'asset'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={rowData.asset}
            />
          );
        } else {
          return rowData.asset;
        }
      },
    },
    {
      title: '|',
      field: 'var',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: '',
      field: 'position_var_data_comparison',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_var_data_comparison'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_var_data_comparison)}
            />
          );
        } else if (rowData.position_var_data_comparison === undefined) {
          return rowData.position_var_data_comparison;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_var_data_comparison,
            2,
          )} %`;
        }
      },
    },
    {
      title: 'VAR',
      field: 'position_var_data_reference',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_var_data_reference'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_var_data_reference)}
            />
          );
        } else if (rowData.position_var_data_reference === undefined) {
          return rowData.position_var_data_reference;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_var_data_reference,
            2,
          )} %`;
        }
      },
    },
    {
      title: '',
      field: 'position_var_data_percentage_change',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else if (
          typeof rowData.position_var_data_percentage_change === 'number' &&
          rowData.position_var_data_percentage_change > 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Pass,
          };
        } else if (
          typeof rowData.position_var_data_percentage_change === 'number' &&
          rowData.position_var_data_percentage_change < 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Fail,
          };
        } else {
          return {
            textAlign: 'center',
            fontWeight: 600,
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_var_data_percentage_change'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_var_data_percentage_change)}
            />
          );
        } else if (rowData.position_var_data_percentage_change === undefined) {
          return rowData.position_var_data_percentage_change;
        } else if (
          typeof rowData.position_var_data_percentage_change === 'number' &&
          rowData.position_var_data_percentage_change > 0
        ) {
          return `+ ${percentageToNdecialPlaces(
            rowData.position_var_data_percentage_change,
            2,
          )}`;
        } else if (
          typeof rowData.position_var_data_percentage_change === 'number' &&
          rowData.position_var_data_percentage_change < 0
        ) {
          return `- ${percentageToNdecialPlaces(
            Math.abs(rowData.position_var_data_percentage_change),
            2,
          )}`;
        } else {
          return '--';
        }
      },
    },
    {
      title: '|',
      field: 'cvar',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: '',
      field: 'position_cvar_data_comparison',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_cvar_data_comparison'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_cvar_data_comparison)}
            />
          );
        } else if (rowData.position_cvar_data_comparison === undefined) {
          return rowData.position_cvar_data_comparison;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_cvar_data_comparison,
            2,
          )} %`;
        }
      },
    },
    {
      title: 'C-VAR',
      field: 'position_cvar_data_reference',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_cvar_data_reference'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_cvar_data_reference)}
            />
          );
        } else if (rowData.position_cvar_data_reference === undefined) {
          return rowData.position_cvar_data_reference;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_cvar_data_reference,
            2,
          )} %`;
        }
      },
    },
    {
      title: '',
      field: 'position_cvar_data_percentage_change',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else if (
          typeof rowData.position_cvar_data_percentage_change === 'number' &&
          rowData.position_cvar_data_percentage_change > 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Pass,
          };
        } else if (
          typeof rowData.position_cvar_data_percentage_change === 'number' &&
          rowData.position_cvar_data_percentage_change < 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Fail,
          };
        } else {
          return {
            textAlign: 'center',
            fontWeight: 600,
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_cvar_data_percentage_change'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_cvar_data_percentage_change)}
            />
          );
        } else if (rowData.position_cvar_data_percentage_change === undefined) {
          return rowData.position_cvar_data_percentage_change;
        } else if (
          typeof rowData.position_cvar_data_percentage_change === 'number' &&
          rowData.position_cvar_data_percentage_change > 0
        ) {
          return `+ ${percentageToNdecialPlaces(
            rowData.position_cvar_data_percentage_change,
            2,
          )}`;
        } else if (
          typeof rowData.position_cvar_data_percentage_change === 'number' &&
          rowData.position_cvar_data_percentage_change < 0
        ) {
          return `- ${percentageToNdecialPlaces(
            Math.abs(rowData.position_cvar_data_percentage_change),
            2,
          )}`;
        } else {
          return '--';
        }
      },
    },
    {
      title: '|',
      field: 'dvar',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: '',
      field: 'position_dvar_data_comparison',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_dvar_data_comparison'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_dvar_data_comparison)}
            />
          );
        } else if (
          rowData.asset === 'Asset' ||
          rowData.position_dvar_data_comparison === undefined
        ) {
          return rowData.position_dvar_data_comparison;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_dvar_data_comparison,
            2,
          )} %`;
        }
      },
    },
    {
      title: 'D-VAR',
      field: 'position_dvar_data_reference',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_dvar_data_reference'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_dvar_data_reference)}
            />
          );
        } else if (rowData.position_dvar_data_reference === undefined) {
          return rowData.position_dvar_data_reference;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_dvar_data_reference,
            2,
          )} %`;
        }
      },
    },
    {
      title: '',
      field: 'position_dvar_data_percentage_change',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else if (
          typeof rowData.position_dvar_data_percentage_change === 'number' &&
          rowData.position_dvar_data_percentage_change > 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Pass,
          };
        } else if (
          typeof rowData.position_dvar_data_percentage_change === 'number' &&
          rowData.position_dvar_data_percentage_change < 0
        ) {
          return {
            textAlign: 'center',
            fontWeight: 600,
            color: mainColors.Fail,
          };
        } else {
          return {
            textAlign: 'center',
            fontWeight: 600,
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset') {
          return (
            <SortColumnButton
              column={'position_dvar_data_percentage_change'}
              activeColumn={currentSortedColumn}
              currentSortingType={currentSortingType}
              content={String(rowData.position_dvar_data_percentage_change)}
            />
          );
        } else if (rowData.position_dvar_data_percentage_change === undefined) {
          return rowData.position_dvar_data_percentage_change;
        } else if (
          typeof rowData.position_dvar_data_percentage_change === 'number' &&
          rowData.position_dvar_data_percentage_change > 0
        ) {
          return `+ ${percentageToNdecialPlaces(
            rowData.position_dvar_data_percentage_change,
            2,
          )}`;
        } else if (
          typeof rowData.position_dvar_data_percentage_change === 'number' &&
          rowData.position_dvar_data_percentage_change < 0
        ) {
          return `- ${percentageToNdecialPlaces(
            Math.abs(rowData.position_dvar_data_percentage_change),
            2,
          )}`;
        } else {
          return '--';
        }
      },
    },
  ];
};

const buildVarData = (
  data: any,
  comparisonDate: string,
  referenceDate: string,
  currentSortedColumn: keyof VarChangesTableData,
  currentSortingType: SortingType,
) => {
  if (!data.data || !data.data.length) {
    return [];
  } else {
    const outputData: VarChangesTableData[] = [];
    Object.keys(data.data[0]).map((assetName: string) => {
      const asset = data.data[0][assetName];
      if (assetName == 'Aggregate') {
        outputData.push({
          asset: assetName,
          position_var_data_comparison: asset.position_cvar_data_comparison,
          position_var_data_reference: asset.position_cvar_data_reference,
          position_var_data_percentage_change:
            asset.position_var_data_percentage_change,
          position_cvar_data_comparison: asset.position_cvar_data_comparison,
          position_cvar_data_reference: asset.position_cvar_data_reference,
          position_cvar_data_percentage_change:
            asset.position_cvar_data_percentage_change,
          position_dvar_data_comparison: asset.position_dvar_data_comparison,
          position_dvar_data_reference: asset.position_dvar_data_reference,
          position_dvar_data_percentage_change:
            asset.position_dvar_data_percentage_change,
        });
      } else {
        outputData.push({
          asset: assetName,
          position_var_data_comparison: asset.position_var_data_comparison,
          position_var_data_reference: asset.position_var_data_reference,
          position_var_data_percentage_change:
            asset.position_var_data_percentage_change,
          position_cvar_data_comparison: asset.position_cvar_data_comparison,
          position_cvar_data_reference: asset.position_cvar_data_reference,
          position_cvar_data_percentage_change:
            asset.position_cvar_data_percentage_change,
          position_dvar_data_comparison: asset.position_dvar_data_comparison,
          position_dvar_data_reference: asset.position_dvar_data_reference,
          position_dvar_data_percentage_change:
            asset.position_dvar_data_percentage_change,
        });
      }
    });
    // by default, keep aggregate row on top and sort the rest by reference data VaR
    outputData.sort((a: VarChangesTableData, b: VarChangesTableData) => {
      const A_SortedColumn =
        currentSortingType === 'DESCENDING'
          ? a[currentSortedColumn]
          : b[currentSortedColumn];
      const B_SortedColumn =
        currentSortingType === 'DESCENDING'
          ? b[currentSortedColumn]
          : a[currentSortedColumn];
      if (a.asset === 'Aggregate') {
        return -1;
      } else if (b.asset === 'Aggregate') {
        return 1;
      } else if (
        typeof A_SortedColumn === 'number' &&
        typeof B_SortedColumn === 'number'
      ) {
        return B_SortedColumn - A_SortedColumn;
      } else if (
        typeof A_SortedColumn === 'string' &&
        typeof B_SortedColumn === 'string'
      ) {
        return A_SortedColumn.localeCompare(B_SortedColumn, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      } else if (
        typeof A_SortedColumn === 'number' &&
        typeof B_SortedColumn === 'string'
      ) {
        return 1;
      } else if (
        typeof A_SortedColumn === 'string' &&
        typeof B_SortedColumn === 'number'
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    outputData.unshift({
      asset: 'Asset',
      position_var_data_comparison: comparisonDate,
      position_var_data_reference: referenceDate,
      position_var_data_percentage_change: '% Change',
      position_cvar_data_comparison: comparisonDate,
      position_cvar_data_reference: referenceDate,
      position_cvar_data_percentage_change: '% Change',
      position_dvar_data_comparison: comparisonDate,
      position_dvar_data_reference: referenceDate,
      position_dvar_data_percentage_change: '% Change',
    });
    return outputData;
  }
};

const buildExportData = (data: any) => {
  const outputData: VarChangesTableData[] = [];
  data.forEach((row: VarChangesTableData) => {
    if (row.asset !== 'Asset') {
      outputData.push(row);
    }
  });
  return outputData;
};

const buildColumnFieldsMapColumns = (
  comparisonDate: string,
  referenceDate: string,
): ColumnIdToHeaderMapType => {
  return [
    {
      label: 'Asset',
      key: 'asset',
    },
    {
      label: `VAR - ${comparisonDate}`,
      key: 'position_var_data_comparison',
    },
    {
      label: `VAR - ${referenceDate}`,
      key: 'position_var_data_reference',
    },
    {
      label: 'VAR - Change',
      key: 'position_var_data_percentage_change',
    },
    {
      label: `C-VAR - ${comparisonDate}`,
      key: 'position_cvar_data_comparison',
    },
    {
      label: `C-VAR - ${referenceDate}`,
      key: 'position_cvar_data_reference',
    },
    {
      label: 'C-VAR - Change',
      key: 'position_cvar_data_percentage_change',
    },
    {
      label: `D-VAR - ${comparisonDate}`,
      key: 'position_dvar_data_comparison',
    },
    {
      label: `D-VAR - ${referenceDate}`,
      key: 'position_dvar_data_reference',
    },
    {
      label: 'D-VAR - Change',
      key: 'position_dvar_data_percentage_change',
    },
  ];
};

const sortTypes = ['DESCENDING', 'ASCENDING'] as const;
type SortingType = (typeof sortTypes)[number];

const VarChangesTable: FC<VarChangesTableProps> = (props) => {
  const classes = useStyles();

  const [currentSortedColumn, setCurrentSortedColumn] = useState<
    keyof VarChangesTableData
  >('position_var_data_reference');
  const [currentSortingType, setCurrentSortingType] =
    useState<SortingType>('DESCENDING');
  const [builtVarData, setBuiltVarData] = useState<VarChangesTableData[]>(
    buildVarData(
      props.data,
      props.comparisonDate,
      props.referenceDate,
      currentSortedColumn,
      currentSortingType,
    ),
  );

  const toggleSortState = (column: keyof VarChangesTableData) => {
    if (column === currentSortedColumn) {
      if (currentSortingType === 'DESCENDING') {
        setCurrentSortingType('ASCENDING');
      } else {
        setCurrentSortingType('DESCENDING');
      }
    } else {
      setCurrentSortedColumn(column);
      setCurrentSortingType('DESCENDING');
    }
  };

  const SortColumnButton: FC<SortColumnButtonProps> = ({
    column,
    activeColumn,
    currentSortingType,
    content,
  }) => {
    const tooltipMessage = () => {
      if (column !== activeColumn) {
        return 'click to sort descending';
      } else if (currentSortingType === 'DESCENDING') {
        return 'click to sort ascending';
      } else {
        return 'click to sort descending';
      }
    };

    return (
      <Tooltip title={tooltipMessage()} placement={'bottom'}>
        <div
          className={
            column === activeColumn
              ? clsx(classes.sortHeader, classes.sortHeaderActive)
              : classes.sortHeader
          }
          onClick={() => toggleSortState(column)}
        >
          {content}
        </div>
      </Tooltip>
    );
  };

  const columns = buildColumns(
    SortColumnButton,
    currentSortedColumn,
    currentSortingType,
  );

  useEffect(() => {
    setBuiltVarData(
      buildVarData(
        props.data,
        props.comparisonDate,
        props.referenceDate,
        currentSortedColumn,
        currentSortingType,
      ),
    );
  }, [currentSortedColumn, currentSortingType]);

  const exportData = buildExportData(builtVarData);

  const columnFieldsMap = buildColumnFieldsMapColumns(
    props.comparisonDate,
    props.referenceDate,
  );

  return (
    <GridItem xs={12} card>
      <CustomTable<VarChangesTableData>
        columns={columns}
        showToolbar
        data={builtVarData}
        title={'VaR Changes'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
          draggable: false,
          rowStyle: (rowData: VarChangesTableData) => ({
            backgroundColor:
              rowData.asset === 'Aggregate' ? mainColors.lightGrey : 'white',
          }),
        }}
        fieldsMap={columnFieldsMap}
        exportData={exportData}
        exportFileName={`risk_comparison_var_(${props.comparisonDate})-(${props.referenceDate})`}
      />
    </GridItem>
  );
};

export default VarChangesTable;
