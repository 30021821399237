import {
  FloatingFocusManager,
  FloatingList,
  autoUpdate,
  flip,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { greys, mainColors } from '../../styling/theme';
import { Theme } from '@mui/material';

interface StyleProps {
  backgroundColor?: string;
  textColor?: string;
  hoverColor?: string;
  maxWidth?: string;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '1.88rem',
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '18.75rem'),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    overflow: 'ellipsis',
    color: (props) => (props.textColor ? props.textColor : mainColors.mainBlue),
    // color: 'red',
    borderRadius: '0.12rem',
    fontSize: '1.00rem',
    fontWeight: 600,
    letterSpacing: '0.06rem',
    border: `1px solid ${mainColors.mainBlue}`,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: (props) =>
        props.hoverColor
          ? props.hoverColor
          : mainColors.controlButtonBlue_lighter,
    },
    padding: '0.50rem 0.62rem',
    backgroundColor: (props) =>
      props.backgroundColor ? props.backgroundColor : greys.grey100,
    '&:hover, &:focus': {
      backgroundColor: (props) =>
        props.backgroundColor ? props.backgroundColor : greys.grey200,
    },
  },
  listbox: {
    display: 'flex',
    minWidth: '18.75rem',
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '18.75rem'),
    flexDirection: 'column',
    color: mainColors.mainBlue,
    border: `2px solid ${mainColors.mainBlue}`,
    maxHeight: '12.50rem',
    overflowY: 'scroll',
    zIndex: 100,
    '&::-webkit-scrollbar': {
      width: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue_lighter,
      borderRadius: '0.62rem',
    },
  },
  item: {
    fontSize: '0.94rem',
    backgroundColor: 'white',
    padding: '0.53rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
}));

interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null) => void;
}

const SelectContext = React.createContext<SelectContextValue>(
  {} as SelectContextValue,
);

interface SelectProps {
  options: string[];
  defaultOption?: string;
  onSelect: (value: string) => void;
  backgroundColor?: string;
  textColor?: string;
  hoverColor?: string;
  maxWidth?: string;
  children: any;
}

const Select: React.FC<SelectProps> = ({
  options,
  defaultOption,
  onSelect,
  children,
  backgroundColor,
  textColor,
  hoverColor,
  maxWidth,
}) => {
  const classes = useStyles({
    backgroundColor,
    textColor,
    hoverColor,
    maxWidth,
  } as StyleProps);

  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(
    defaultOption ? options.indexOf(defaultOption) : null,
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(
    defaultOption ? options.indexOf(defaultOption) : null,
  );
  const [selectedLabel, setSelectedLabel] = React.useState<string | null>(
    defaultOption ?? null,
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const elementsRef = React.useRef<Array<HTMLElement | null>>([]);
  const labelsRef = React.useRef<Array<string | null>>([]);

  const handleSelect = React.useCallback((index: number | null) => {
    setSelectedIndex(index);
    setIsOpen(false);
    if (index !== null) {
      setSelectedLabel(labelsRef.current[index]);
      onSelect(labelsRef.current[index] as string);
    }
  }, []);

  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index);
    } else {
      handleSelect(index);
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNav, typeahead, click, dismiss, role],
  );

  const selectContext = React.useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  return (
    <>
      <div
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
        className={classes.container}
      >
        {selectedLabel ?? 'Select...'}
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={classes.listbox}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
};

interface OptionProps {
  label: string;
}

const Option: React.FC<OptionProps> = ({ label }) => {
  const classes = useStyles({});

  const { activeIndex, selectedIndex, getItemProps, handleSelect } =
    React.useContext(SelectContext);

  const { ref, index } = useListItem({ label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <div
      ref={ref}
      className={classes.item}
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      style={{
        background: isActive ? mainColors.hoverOverVeryFaintBlue : '',
        fontWeight: isSelected ? 'bold' : '',
      }}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
    >
      {label}
    </div>
  );
};

interface RaptorSelectProps {
  options: string[];
  defaultOption?: string;
  handleChoice: (choice: string) => void;
  textColor?: string;
  backgroundColor?: string;
  hoverColor?: string;
  maxWidth?: string;
}

const RaptorSelect: React.FC<RaptorSelectProps> = ({
  options,
  defaultOption,
  handleChoice,
  textColor,
  backgroundColor,
  hoverColor,
  maxWidth,
}) => {
  return (
    <Select
      options={options}
      defaultOption={defaultOption}
      onSelect={handleChoice}
      backgroundColor={backgroundColor}
      textColor={textColor}
      hoverColor={hoverColor}
      maxWidth={maxWidth}
    >
      {options.map((option, index) => (
        <Option key={index} label={option} />
      ))}
    </Select>
  );
};

export default RaptorSelect;
