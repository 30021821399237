import { Status } from '../../types/redux/data/dataTypes';
import React from 'react';
import { mapStatusToColor } from '../../utilities/colorUtilities';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';

interface RaptorStatusBoxProps {
  status: Status;
  message?: string;
  tooltipValue?: string;
  key?: string;
}

// This component has height of 100% and width of 100%.
// It is meant to be used as a child of a container which is used to fix it's width.
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    color: 'white',
    borderRadius: '0.25rem',
    padding: '0.50rem 0.62rem',
    fontSize: '1.00rem',
    fontWeight: 500,
    letterSpacing: '0.06rem',
  },
  tooltip: {
    fontSize: '1.00rem',
    padding: '0.62rem 1.25rem',
    borderRadius: '0.25rem',
  },
});

const RaptorStatusBox: React.FC<RaptorStatusBoxProps> = ({
  status,
  message,
  tooltipValue,
  key,
}) => {
  const classes = useStyles();

  if (!status) status = Status.NA;

  return (
    <Tooltip
      key={key}
      title={tooltipValue ? tooltipValue : ''}
      classes={{ tooltip: classes.tooltip }}
    >
      <div
        className={classes.container}
        style={{ backgroundColor: mapStatusToColor(status) }}
      >
        {message || status.toUpperCase()}
      </div>
    </Tooltip>
  );
};

export default RaptorStatusBox;
