import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { greys, mainColors } from '../../../../styling/theme';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { useSelector } from 'react-redux';
import { openingAnimationIsActiveSelector } from '../../../../redux/pages/selectors';
import { Tooltip, Typography } from '@mui/material';

interface IndividualPieProps {
  animBegin: number;
  dataTitle: string;
  data: any;
  pieRoute: string | null;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    transition: 'all .3s',
    height: 'fit-content',
    padding: '0.62rem',
    '&:hover': {
      backgroundColor: greys.grey200,
    },
    borderRadius: 16,
  },
  title: {
    fontSize: '1.25rem',
    width: '100%',
    fontWeight: 500,
    bottom: 20,
    color: mainColors.mainBlue,
  },
  colorPoint: {
    height: 8,
    width: 8,
    borderRadius: '50%',

    marginRight: '0.62rem',
    '&.pass': {
      background: 'rgba(19,187,57,1)',
    },
    '&.alert': {
      background: 'rgba(255,115,21,1)',
    },
    '&.fail': {
      background: 'rgba(236,14,14,1)',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  displayPie: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  label: {
    fontSize: '1.25rem',
    color: mainColors.mainBlue,
    fontWeight: 500,
  },
}));

const IndividualPie: FC<IndividualPieProps> = ({
  animBegin,
  dataTitle,
  data,
  pieRoute,
}) => {
  const navigate = useNavigate();

  const handlePieClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (pieRoute) navigate(pieRoute);
  };

  const newData = data.slice(1).map((datum: any) => {
    return { name: datum[0], value: datum[1] };
  });

  const total = newData.reduce((vals: any, currentVal: any) => {
    return vals + currentVal.value;
  }, 0);

  const passValue = newData.filter((item: any) => item.name === 'Pass')[0]
    .value;

  const pc_good = passValue / total;

  const classes = useStyles();

  const animationsActive = useSelector(openingAnimationIsActiveSelector);

  return (
    <Tooltip title={`Access ${dataTitle} Data`}>
      <div className={classes.root} onClick={handlePieClick}>
        <ResponsiveContainer width={160} height={160}>
          <PieChart>
            <RechartsTooltip
              itemStyle={{ fontSize: '1.00rem', color: mainColors.mainBlue }}
              formatter={(value: any, name: any, props: any) =>
                value + ' funds'
              }
            />
            <Pie
              data={newData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={'80%'}
              innerRadius={'50%'}
              fill="#8884d8"
              animationBegin={animBegin}
              isAnimationActive={animationsActive}
              animationDuration={1000}
              cursor={'pointer'}
            >
              {newData.map((entry: any, index: any) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={mapStatusToColor(entry.name)}
                  />
                );
              })}
              <Label width={30} position="center" className={classes.label}>
                {Math.round(pc_good * 100) + '%'}
              </Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <Typography variant="h2" align="center" className={classes.title}>
          {dataTitle}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default IndividualPie;
