import { AxiosInstance } from '../../../../../utilities/requestClient';

export const getFactSheetOverview = async () => {
  return (await AxiosInstance.get(`factsheet-overview-data`)).data;
};

export const getFactSheetDemoFile = async () => {
  return (
    await AxiosInstance.get(
      `risksystem_pdf_generator/fact-sheets/get-demo-fact-sheets`,
      {
        responseType: 'blob',
      },
    )
  ).data;
};
