import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { Cell } from 'recharts';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import { Status } from '../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import formatDate from '../../../../utilities/dateFormatters';
import {
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import PercentValueToggleButton from '../../../buttons/PercentValueToggleButton.component';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import { buildPrincipleAdverseImpactTableData } from './PrincipleAdverseImpactTable.component';

interface ESGOverviewPanelProps {
  data: any;
  fundName: string | null;
}

interface OverviewTimeSeriesData {
  name: string;
  value: number;
}

const useOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  chartContainer: {
    width: '80%',
  },
  scoreContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  basicInfoContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  fundInfoLine: {
    display: 'flex',
  },
  individualChart: {
    marginBottom: '0.62rem',
    height: '12.50rem',
    width: '12.50rem',
    justifyContent: 'center',
  },
}));

function buildOverviewTimeseriesData(data: any): OverviewTimeSeriesData[] {
  if (!data.data.length) return [];
  if (!('nav' in data.data[0])) return [];
  if (!('exposure' in data.data[0])) return [];
  if (!('gross_exposure' in data.data[0])) return [];
  if (!('portfolio_scores' in data.data[0])) return [];
  // In Future we will actually receive the data.
  // But for now we just mock it up.
  const today = new Date();
  const startDate = new Date();
  startDate.setFullYear(today.getFullYear() - 1);
  const currentScore = data.data[0]['portfolio_scores']['gross']['esg'];
  let updatingScore = currentScore;
  const timeseriesData: OverviewTimeSeriesData[] = [];
  while (startDate <= today) {
    updatingScore += Math.random() * 2 - 1;
    timeseriesData.push({
      name: formatDate(startDate) || '',
      value: updatingScore,
    });
    startDate.setDate(startDate.getDate() + 1);
  }
  // Now append the current score.
  // timeseriesData.push({
  //     name: formatDate(today) || '',
  //     value: currentScore
  // })

  return timeseriesData;
}

const PrincipleAdverseImpactOverviewPanel: FC<ESGOverviewPanelProps> = (
  props,
) => {
  const { data, fundName } = props;
  const classes = useOverviewPanelStyles();
  const timSeriesData = buildOverviewTimeseriesData(data);
  const barChartData = buildPrincipleAdverseImpactTableData(data);
  // Will be used to toggle between dollar values and percent of NAV.
  const [percentValueOption, setPercentValueOption] =
    useState<string>('percent');
  // Set the x axis props
  const xAxis = [
    {
      dataKey: 'ruleNumber',
      label: { value: 'Rule Number', dy: 10 },
      // tickFormatter: (val: string) =>
      //     val.length > 10 ? val.substr(0, 10) + '...' : val,
    },
  ];
  const bars = [
    {
      dataKey:
        percentValueOption === 'percent'
          ? 'concerningExposure'
          : 'concerningExposureValue',
      key:
        percentValueOption === 'percent'
          ? 'concerningExposure'
          : 'concerningExposureValue',
      fill: mainColors.mainBlue,
      children: barChartData.map((value, index) => (
        <Cell
          key={`cell-${index}`}
          fill={mapStatusToColor(value.qualitativeIndicatorScan as Status)}
        />
      )),
    },
  ];
  return (
    <GridItem xs={12} card>
      <div className={classes.componentsContainer}>
        {/* <div className={classes.basicInfoContainer}>
                    <Typography variant="h2" align="left" >Fund Information</Typography>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >Fund Name: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{fundName}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >NAV: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{addCommasToNumbersAndRound(data.data[0]['nav'])}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >Exposure: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{percentageToTwoDecimalPlaces(data.data[0]['exposure'])}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >Environmental Score: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{roundNumberToTwoDecimalPlaces(data.data[0]['portfolio_scores']['gross']['e'])}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >Social Score: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{roundNumberToTwoDecimalPlaces(data.data[0]['portfolio_scores']['gross']['s'])}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >Corporate Governance Score: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{roundNumberToTwoDecimalPlaces(data.data[0]['portfolio_scores']['gross']['g'])}</Typography>
                    </div>
                    <div className={classes.fundInfoLine}>
                        <Typography variant="h3" >ESG Score: </Typography>
                        <Typography variant="h3" style={{ color: greys.grey600, marginLeft: '0.62rem' }} >{roundNumberToTwoDecimalPlaces(data.data[0]['portfolio_scores']['gross']['esg'])}</Typography>
                    </div>
                </div> */}

        <div
        // className={classes.chartContainer}
        >
          <div
            style={{
              float: 'left',
              marginLeft: '1.25rem',
              marginTop: '1.25rem',
              textAlign: 'center',
            }}
          >
            <PercentValueToggleButton
              percentValueOption={percentValueOption}
              updatePercentValueOption={setPercentValueOption}
            />
          </div>
          <GenericBarChart
            xAxes={xAxis}
            height={500}
            width={'100%'}
            bars={bars}
            data={barChartData}
            title={'Exposure By Rule'}
            loading={false}
            legend={false}
            yAxisLabel={{
              angle: -90,
              position: 'left',
              value: 'Exposure',
              style: {
                textAnchor: 'middle',
                // fill: theme.palette.grey[500],
              },
            }}
            yAxisFormatter={(value: string) =>
              percentValueOption === 'percent'
                ? percentageToTwoDecimalPlaces(value)
                : formatMillions(value)
            }
            tooltipFormatter={(value: string, name: string, element: any) => {
              return [
                percentValueOption === 'percent'
                  ? percentageToTwoDecimalPlaces(value)
                  : addCommasToNumbersAndRound(value),
                // name === 'concerningExposure' ? 'In Scope Exposure %' : 'In Scope Exposure Value'
                element.payload.name,
              ];
            }}
            legendWrapper={{ left: 0 }}
            legendLayout="horizontal"
            legendPositionHorizontal="center"
            legendPositionVertical="top"
            // legendFormatter={legendFormatter}
            // tooltipFormatter={tooltipFormatter}
          />
        </div>
      </div>
    </GridItem>
  );
};

export default PrincipleAdverseImpactOverviewPanel;
