import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, ReactElement } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Button } from '@mui/material';
import { specifyStatus } from '../../../../utilities/generalMappings';

interface SummaryTableProps {
  data: any;
  issuer: string;
  setIssuer: Dispatch<string>;
}

interface ISummaryTableData {
  positionDate: string;
  issuerName: string;
  percentage: number;
  limit: number;
  status: string;
  aggregateValue: number;
  percentageLimit: number;
  latestIssueSize: number;
}

interface SummaryTableButtonProps {
  value: Status;
  onClickHandle: () => void;
}

function getStatusColors(value: Status) {
  switch (value) {
    case Status.Pass:
      return { main: mainColors.Pass, hover: mainColors.Pass_darker };
    case Status.Alert:
      return { main: mainColors.Alert, hover: mainColors.Alert_darker };
    case Status.Fail:
      return { main: mainColors.Fail, hover: mainColors.Fail_darker };
    default:
      return { main: greys.grey400, hover: greys.grey400 };
  }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props: StyleProps) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '12.50rem',
    '&:hover': {
      backgroundColor: (props: StyleProps) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '12.50rem',
  },
}));

export function SummaryTableButton({
  value,
  onClickHandle,
}: React.PropsWithChildren<SummaryTableButtonProps>): ReactElement {
  const color = getStatusColors(value);
  const classes = useStyles({
    mainColor: color.main,
    mainColorHover: color.hover,
  });
  return (
    <Button onClick={onClickHandle} className={classes.PageLinkButtonRoot}>
      {value}
    </Button>
  );
}

function buildSummaryTableData(data: any): ISummaryTableData[] {
  if (!data.data.length) return [];
  const summaryData = data.data[0].manco[0];
  const tableData: ISummaryTableData[] = [];

  summaryData.issuers.forEach((issuer: any) => {
    tableData.push({
      positionDate: summaryData['Position Date'],
      issuerName: issuer.Issuer,
      percentage: issuer.Percentage_value,
      limit: issuer.limit,
      status: issuer.status,
      aggregateValue: issuer.aggregate_value,
      percentageLimit: issuer.Percentage_limit,
      latestIssueSize: issuer.latest_issue_size,
    });
  });

  return tableData;
}

function buildSummaryTableColumns(
  setIssuer: Dispatch<string>,
): CustomColumn<ISummaryTableData>[] {
  return [
    {
      title: 'Issuer Name',
      field: 'issuerName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Latest Issue Size',
      field: 'latestIssueSize',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.latestIssueSize),
    },
    {
      title: 'Aggregate Value',
      field: 'aggregateValue',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.aggregateValue),
    },
    {
      title: 'Limit',
      field: 'limit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.limit),
    },

    {
      title: 'Percentage',
      field: 'percentage',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.percentage),
    },
    {
      title: 'Percentage Limit',
      field: 'percentageLimit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.percentageLimit),
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (
        <SummaryTableButton
          value={specifyStatus(rowData.status)}
          onClickHandle={() => setIssuer(rowData.issuerName)}
        />
      ),
    },
  ];
}

function getMancoName(data: any): string {
  if (!data.data.length) return 'Aggregated';
  if (!('manco' in data.data[0])) return 'Aggregated';
  return data.data[0].manco[0].Name;
}

function SummaryTable({
  data,
  issuer,
  setIssuer,
}: SummaryTableProps): ReactElement | null {
  const tableData = buildSummaryTableData(data);
  const tableColumns = buildSummaryTableColumns(setIssuer);
  const mancoName = getMancoName(data);

  return (
    <GridItem xs={12} card>
      <CustomTable<ISummaryTableData>
        title={`${mancoName} Coverage Ratio`}
        showToolbar={true}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: (rowData: any) => ({
            backgroundColor:
              rowData.issuerName === issuer
                ? mainColors.mainBlue_lightAlt
                : undefined,
          }),
        }}
        data={tableData}
        columns={tableColumns}
      />
    </GridItem>
  );
}

export default SummaryTable;
