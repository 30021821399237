import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
} from '@mui/material';
import { ButtonHTMLAttributes, ReactNode, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import EditIcon from '@mui/icons-material/Edit';

import { Header } from '@tanstack/react-table';
import { mainColors, RaptorTheme } from '../../../../styling/theme';
import clsx from 'clsx';
import { grey } from '@mui/material/colors';

interface FilterButtonProps<T> extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  header: Header<T, unknown>;
  isEditable?: boolean;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  mainPopper: {
    border: `1px solid #e1e4e8`,
    boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '0.62rem',
    zIndex: theme.zIndex.modal,
    color: mainColors.mainBlue,
    backgroundColor: 'white',
    minWidth: '18.75rem',
    maxWidth: '25.00rem',
  },
  mainPopperHeader: {
    borderBottom: '1px solid #eaecef',
    padding: '0.94rem 1.88rem',
    fontWeight: 600,
    fontSize: '0.94rem',
  },

  filterButton: {
    borderRadius: 5,
    padding: '0.31rem',
    cursor: 'pointer',
    '&:hover svg': {
      color: mainColors.mainBlue,
    },
  },

  isFiltered: {
    background: mainColors.mainBlue,
    color: 'white',
  },

  tooltip: {
    fontSize: '0.88rem',
  },
}));

const FilterButton = <T,>({
  children,
  header,
  isEditable,
}: FilterButtonProps<T>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const id = open ? 'filter-popper' : undefined;

  const isFiltered = header.column.getIsFiltered();

  return (
    <>
      <Box
        display="flex"
        margin="auto"
        maxWidth={100}
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Tooltip
          title={open ? '' : 'Filter'}
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={clsx(classes.filterButton, {
              [classes.isFiltered]: isFiltered,
            })}
          >
            <TuneIcon style={{ fontSize: '1.12rem' }} />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={open ? '' : 'Sort'}
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            className={clsx(classes.filterButton)}
            onClick={header.column.getToggleSortingHandler()}
          >
            {header.column.getIsSorted() === 'asc' ? (
              <ArrowDropUp style={{ fontSize: '1.12rem' }} />
            ) : header.column.getIsSorted() === 'desc' ? (
              <ArrowDropDown style={{ fontSize: '1.12rem' }} />
            ) : (
              <SortIcon style={{ fontSize: '1.12rem' }} />
            )}
          </IconButton>
        </Tooltip>

        {isEditable && (
          <Tooltip
            title={open ? '' : 'Editable'}
            classes={{ tooltip: classes.tooltip }}
          >
            <EditIcon style={{ fontSize: '1.12rem', color: grey[500] }} />
          </Tooltip>
        )}
      </Box>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.mainPopper}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div>
            <Box className={classes.mainPopperHeader}>
              Choose Column Filters
            </Box>
            {children}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default FilterButton;
