import React, { FC } from 'react';
import { greys, mainColors } from '../../../../styling/theme';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';

interface EmissionsCostsChartProps {
  data: any;
}

const EmissionsCostsChart: FC<EmissionsCostsChartProps> = (props) => {
  const { data } = props;

  const legendFormatter = (value: string) => {
    switch (value) {
      case 'scope1':
        return 'Scope 1 Emissions';
      case 'scope2':
        return 'Scope 2 Emissions';
      case 'scope3':
        return 'Scope 3 Emissions';
      case 'totalC02':
        return 'Total C02';
      case 'airPol':
        return 'Air Pollutants (Sox / Nox / etc)';
      default:
        return value;
    }
  };
  const tooltipFormatter = (value: string) => {
    switch (value) {
      case 'scope1':
        return 'Scope 1 Emissions';
      case 'scope2':
        return 'Scope 2 Emissions';
      case 'scope3':
        return 'Scope 3 Emissions';
      case 'totalC02':
        return 'Total C02';
      default:
        return '';
    }
  };
  return (
    <>
      <GridItem xs={12} card>
        <GenericBarChart
          title={'Emissions Costs (% NAV)'}
          id={'esg_stress_chart_emissinos_costs'}
          loading={data.isFetching}
          bars={[
            {
              dataKey: 'scope1',
              fill: mainColors.mainBlue,
              key: 'scope1',
              // yAxisId: 'left'
            },
            {
              dataKey: 'scope2',
              fill: mainColors.fourthPurple,
              key: 'scope2',
              // yAxisId: 'left'
            },
            {
              dataKey: 'scope3',
              fill: mainColors.Alert,
              key: 'scope3',
              // yAxisId: 'left'
            },
            {
              dataKey: 'totalC02',
              fill: mainColors.Fail,
              key: 'totalC02',
              // yAxisId: 'left'
            },
          ]}
          loadingHeight={400}
          height={400}
          margin={{ top: 0, bottom: 24, left: 24, right: 24 }}
          width={'100%'}
          legend
          legendFormatter={legendFormatter}
          yAxisLabel={{
            position: 'left',
            angle: -90,
            offset: 0,
            fontSize: '0.88rem',
            fill: greys.grey700,
            value: 'P/L',
            style: {
              textAnchor: 'middle',
            },
          }}
          yAxisFormatter={(val) => percentageToTwoDecimalPlacesNoHundred(val)}
          tooltipFormatter={(val: any, name: string) => [
            percentageToTwoDecimalPlacesNoHundred(val as number),
            tooltipFormatter(name),
          ]}
          xAxes={[
            {
              label: {
                value: 'Score Filter Level',
                offset: 10,
                style: {
                  fill: greys.grey700,
                  fontSize: '0.88rem',
                },
                position: 'bottom',
              },
              dataKey: 'name',
            },
          ]}
          data={data}
          exportButton={false}
        />
      </GridItem>
      <GridItem xs={12} card>
        <GenericBarChart
          title={'SOX / NOX Emissions Costs (% NAV)'}
          id={'esg_stress_chart_emissinos_sox_costs'}
          loading={data.isFetching}
          bars={[
            {
              dataKey: 'airPol',
              fill: mainColors.tertiaryGreen,
              key: 'airPol',
              // yAxisId: 'left'
            },
          ]}
          loadingHeight={400}
          height={400}
          margin={{ top: 0, bottom: 24, left: 24, right: 24 }}
          width={'100%'}
          legend
          legendFormatter={legendFormatter}
          yAxisLabel={{
            position: 'left',
            angle: -90,
            offset: 0,
            fontSize: '0.88rem',
            fill: greys.grey700,
            value: 'P/L',
            style: {
              textAnchor: 'middle',
            },
          }}
          yAxisFormatter={(val) => percentageToTwoDecimalPlacesNoHundred(val)}
          tooltipFormatter={(val: any, name: string) => [
            percentageToTwoDecimalPlacesNoHundred(val as number),
            'Air Pollutants (Sox / Nox / etc)',
          ]}
          xAxes={[
            {
              label: {
                value: 'Score Filter Level',
                offset: 10,
                style: {
                  fill: greys.grey700,
                  fontSize: '0.88rem',
                },
                position: 'bottom',
              },
              dataKey: 'airPolName',
            },
          ]}
          data={data}
          exportButton={false}
        />
      </GridItem>
    </>
  );
};

export default EmissionsCostsChart;
