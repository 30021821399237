import React from 'react';
import { useFormContext, UseFormRegister } from 'react-hook-form';
import { useEditableCellStyles } from '../styles/extras.styles';
import clsx from 'clsx';
import { ValidationObject } from '../types/editable.types';

interface NumberEditInput extends React.InputHTMLAttributes<HTMLInputElement> {
  validation?: ValidationObject;
  props: {
    updated: boolean;
    editOptions: {
      key_name: string;
    };
  };
  errors: Record<string, any>;
  register: UseFormRegister<any>;
  responseError?: boolean;
  setResponseError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitEdits: () => void;
  handleCancelEdits: () => void;
  testFn?: (val: any) => void;
  showChange: boolean;
}

export const NumberEditInput: React.FC<NumberEditInput> = ({
  validation,
  props,
  errors,
  register,
  responseError,
  setResponseError,
  testFn,
  showChange,
  ...rest
}) => {
  const classes = useEditableCellStyles();
  const { trigger, getValues } = useFormContext();

  // Determine the step value based on the minimum distance in the values array
  const stepValue = validation ? calculateMinStep(validation.values) : 0.1;

  const hasError =
    (errors && errors[props.editOptions.key_name]) || responseError;

  const minVal = validation?.min ?? Number.MIN_SAFE_INTEGER;
  const maxVal = validation?.max ?? Number.MAX_SAFE_INTEGER;

  return (
    <input
      className={clsx(classes.editableCell, {
        [classes.error]: hasError,
        [classes.updated]: !hasError && props.updated,
        [classes.changed]: !hasError && !props.updated && showChange, // Only applies if "updated" is false
      })}
      type="number"
      max={maxVal}
      min={minVal}
      onKeyUp={async (e) => {
        if (e.key === 'Enter') {
          const hookformVal = getValues(props.editOptions.key_name);

          if (!hookformVal) {
            console.error('Validation failed, preventing submission');
            return;
          }

          const isValid = await trigger(props.editOptions.key_name);

          if (!isValid) {
            console.error('Validation failed, preventing submission');
            return;
          }

          rest.handleSubmitEdits(); // Submit if valid
        }

        if (e.key === 'Escape') {
          rest.handleCancelEdits();
        }
      }}
      step={stepValue}
      {...register(props.editOptions.key_name, {
        // required: 'This field is required',
        // valueAsNumber: true,
        max: {
          value: maxVal,
          message: `Value must be between ${validation?.min} and ${validation?.max}`,
        },
        min: {
          value: minVal,
          message: `Value must be between ${validation?.min} and ${validation?.max}`,
        },

        validate: (value) => {
          if (validation) {
            if (validation.values.length > 0) {
              return (
                validation.values.includes(+value as never) ||
                `Value must be one of ${validation.values.join(', ')}`
              );
            }
            if (isNaN(value) || value === null) {
              // Not solid logic as we should allow nulls in some cases
              return 'Invalid number'; // Prevent non-numeric values
            }
          }
          return true;
        },

        onChange: (e) => {
          trigger(props.editOptions.key_name);
          const hookformVal = getValues(props.editOptions.key_name);
          if (testFn) {
            testFn(hookformVal);
          }
          setResponseError(false);
        },

        onBlur: async (e) => {
          const hookformVal = getValues(props.editOptions.key_name);

          trigger(props.editOptions.key_name);

          if (testFn) {
            testFn(hookformVal);
          }
        },
      })}
    />
  );
};

const calculateMinStep = (values: number[] | string[]): number => {
  if (values.length < 2) return 0.01; // Default step if not enough values to compare
  let minStep = Number.MAX_VALUE;
  for (let i = 1; i < values.length; i++) {
    const step = +values[i] - +values[i - 1];
    if (step < minStep) {
      minStep = step;
    }
  }
  return minStep;
};
