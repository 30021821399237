import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { toTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { LawSecondLevelData } from './SecondLevelTable';
import { Button } from '@mui/material';
import { ArrowRight, TripOrigin } from '@mui/icons-material';

export const rule8ThirdLevelDetail = [
  (rowData: LawSecondLevelData) => ({
    render: (rowData: LawSecondLevelData) => {
      return (
        <Rule8ThirdLevelTable
          data={rowData.position_g_data.filter((asset: any) => {
            return asset.g_name === rowData.issuer;
          })}
          issuer={rowData.issuer}
          activePositionDate={rowData.positionDate}
          ruleNumber={rowData.ruleNumber}
        />
      );
    },
    disabled:
      rowData.headerRow ||
      !rowData.position_g_data.filter((asset: any) => {
        return asset.g_name === rowData.issuer;
      }).length,
    icon: rowData.headerRow ? TripOrigin : ArrowRight,
  }),
];

interface Props {
  data: LawSecondLevelData;
  issuer: string;
  activePositionDate: string;
  ruleNumber: number;
}

export interface rule8ThirdLevelData {
  accrued_interest_base: number;
  accrued_interest_local: number;
  base_currency: string;
  country_code: string;
  expiry_date: string;
  exposure: number;
  fx_rate: number;
  g_key: string;
  g_name: string;
  gm_id: number;
  gm_name_long: string;
  instrument_id: number;
  instrument_type_id: number;
  isin: string;
  market_price: number;
  used_price: number;
  maturity_date: string;
  mr_position: number;
  risk_factor: number;
  strike: string;
}

const rule8TableColumns: CustomColumn<rule8ThirdLevelData>[] = [
  {
    title: '#',
    field: 'number',
    width: 1,
  },
  {
    title: 'Asset Name',
    field: 'gm_name_long',
    width: '20%',
  },
  {
    title: 'ISIN',
    field: 'isin',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Maturity Date',
    field: 'maturity_date',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Risk Factor',
    field: 'risk_factor',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'FX Rate',
    field: 'fx_rate',
    render: (rowData: rule8ThirdLevelData) =>
      rowData.fx_rate !== undefined
        ? toTwoDecimalPlaces(rowData.fx_rate)
        : 'N/A',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Accrued Interest (Local Currency)',
    field: 'accrued_interest_local',
    render: (rowData: rule8ThirdLevelData) =>
      rowData.accrued_interest_local !== undefined
        ? toTwoDecimalPlaces(rowData.accrued_interest_local)
        : 'N/A',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Accrued Interest (Base Currency)',
    field: 'accrued_interest_base',
    render: (rowData: rule8ThirdLevelData) =>
      rowData.accrued_interest_base !== undefined
        ? toTwoDecimalPlaces(rowData.accrued_interest_base)
        : 'N/A',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Used Price',
    field: 'used_price',
    render: (rowData: rule8ThirdLevelData) =>
      rowData.used_price !== undefined
        ? toTwoDecimalPlaces(rowData.used_price)
        : 'N/A',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position',
    field: 'mr_position',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rowData: rule8ThirdLevelData) =>
      toTwoDecimalPlaces(rowData.exposure),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

// lazily setting data to any, because what is returned from the server could either be
//
export function buildAssetData(data: any): rule8ThirdLevelData[] {
  try {
    return data.map((asset: any, index: number) => {
      return {
        number: index + 1,
        ...asset,
      };
    });
  } catch (err) {
    throw new Error('Problem creating error from data: ' + err);
  }
}

const useStyles = makeStyles<RaptorTheme, { alteredColor?: boolean }>(
  (theme) => ({
    minusButton: {
      color: (props) =>
        props.alteredColor ? mainColors.mainGold : theme.palette.grey[800],
      backgroundColor: 'white',
      minWidth: 0,
      padding: '4px 8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.main,
      },
    },
  }),
);

export const PreviousPositionDateButton = ({
  currentPositionDate,
  activePositionDate,
  handler,
  ...props
}: {
  currentPositionDate: string;
  activePositionDate: string;
  handler: () => void;
  key: string;
  alteredColor?: boolean;
}) => {
  const classes = useStyles({
    alteredColor: props.alteredColor,
  });
  const isToday = currentPositionDate === activePositionDate;
  return (
    <Button onClick={handler} className={classes.minusButton}>
      {isToday
        ? 'Compare with previous position date'
        : `View today's position date (${activePositionDate})`}
    </Button>
  );
};

function Rule8ThirdLevelTable({
  data,
  issuer,
  activePositionDate,
  ruleNumber,
}: Props): ReactElement {
  const tableData = buildAssetData(data);

  return (
    <CustomTable<rule8ThirdLevelData>
      showToolbar={true}
      columns={rule8TableColumns}
      options={{
        paging: false,
        showTitle: true,
        exportButton: true,
        search: true,
        toolbar: true,
      }}
      data={tableData}
      title="Underlying Assets"
      csvFields={tableData.length > 0 ? Object.keys(tableData[0]) : []}
    />
  );
}

export default Rule8ThirdLevelTable;
