import React, { ReactElement, useState } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import FillCell from '../../../../tables/FillCell';
import { greys, mainColors } from '../../../../../styling/theme';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import DisplayAreaCenteredWrapper from '../../../../layout/utilities/displayAreaWrapper';
import makeStyles from '@mui/styles/makeStyles';
import LiquidationTimeSecondLevelTableWrapper from './LiquidationTimeSecondLevelTableWrapper.component';
import NoDataMessage from '../../../../feedback/NoDataMessage.component';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import clsx from 'clsx';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

interface Props {
  bauData: DataObject;
  stressedData: DataObject;
  superStressedData: DataObject;
}

export interface FundData {
  fundName: string;
  fundId: string;
  date: string;
  position: number;
  grossExposure: number;
  holdingPc: number;
}

export interface LiquidityData {
  assetName: string;
  isin: string;
  position: number;
  grossExposure: number;
  zeroToOne: number;
  twoToSeven: number;
  eightToThirty: number;
  thirtyOneToNinety: number;
  ninetyOneToOneEighty: number;
  OneEightOneToThreeSixFour: number;
  moreThanAYear: number;
  funds: FundData[];
}

export interface OverviewLiquidityData {
  stressLevel: string;
  zeroToOne: number;
  twoToSeven: number;
  eightToThirty: number;
  thirtyOneToNinety: number;
  ninetyOneToOneEighty: number;
  OneEightOneToThreeSixFour: number;
  moreThanAYear: number;
}

export const AssetColumns: CustomColumn<LiquidityData>[] = [
  {
    title: 'Asset Name',
    field: 'assetName',
    cellStyle: {
      padding: 0,
      paddingLeft: '0.62rem',
      width: '20%',
    },
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '0.62rem',
      // display: 'none',
      width: '7%',
    },
  },
  {
    title: 'Position',
    field: 'position',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '0.62rem',
      // display: 'none',
      width: '5%',
    },
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    headerStyle: { textAlign: 'center' },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '0.62rem',
      width: '5%',
    },
  },
  {
    title: '0 - 1',
    field: 'zeroToOne',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '2 - 7',
    field: 'twoToSeven',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '8 - 30',
    field: 'eightToThirty',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '31 - 90',
    field: 'thirtyOneToNinety',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '91 - 180',
    field: 'ninetyOneToOneEighty',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '180 - 364',
    field: 'OneEightOneToThreeSixFour',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
  {
    title: '> 365',
    field: 'moreThanAYear',
    cellStyle: {
      padding: 0,
      width: '9%',
    },
  },
];

const mapStressLevelColor = (stresslevel: string): string => {
  if (stresslevel === 'BAU') {
    return mainColors.mainBlue;
  } else if (stresslevel === 'Stressed') {
    return mainColors.Fail;
  } else if (stresslevel === 'Super-Stressed') {
    return mainColors.Fail_even_darker;
  } else {
    return mainColors.mainBlue;
  }
};

interface StressLevelCellProps {
  cellColor: string;
  value: string;
}

const StressLevelCell: React.FC<StressLevelCellProps> = (props) => {
  return <div>{props.value}</div>;
};

export const OverviewColumns: CustomColumn<OverviewLiquidityData>[] = [
  {
    title: 'Stress Level',
    field: 'stressLevel',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '0.62rem',
      width: '10%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '10%',
    },
    pdfRenderType: 'CustomBackgroundColor',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <StressLevelCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.stressLevel}
        />
      );
    },
  },
  {
    title: '0 - 1',
    field: 'zeroToOne',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.zeroToOne}
        />
      );
    },
  },
  {
    title: '2 - 7',
    field: 'twoToSeven',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.twoToSeven}
        />
      );
    },
  },
  {
    title: '8 - 30',
    field: 'eightToThirty',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.eightToThirty}
        />
      );
    },
  },
  {
    title: '31 - 90',
    field: 'thirtyOneToNinety',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.thirtyOneToNinety}
        />
      );
    },
  },
  {
    title: '91 - 180',
    field: 'ninetyOneToOneEighty',
    width: '9%',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.ninetyOneToOneEighty}
        />
      );
    },
  },
  {
    title: '180 - 364',
    field: 'OneEightOneToThreeSixFour',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.OneEightOneToThreeSixFour}
        />
      );
    },
  },
  {
    title: '> 365',
    field: 'moreThanAYear',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      width: '9%',
    },
    headerStyle: {
      textAlign: 'center',
      width: '9%',
    },
    pdfRenderType: 'FillBox',
    render: (rowData: OverviewLiquidityData) => {
      return (
        <FillCell
          cellColor={mapStressLevelColor(rowData.stressLevel)}
          value={rowData.moreThanAYear}
        />
      );
    },
  },
];

export const mapStressToColor = (stressLevel: string) => {
  switch (stressLevel) {
    case 'BAU':
      return mainColors.mainBlue;
    case 'Stressed':
      return mainColors.Fail;
    case 'Super-Stressed':
      return mainColors.Fail_even_darker;
    default:
      return mainColors.mainBlue;
  }
};

export const createColumn: <T extends LiquidityData>(
  col: CustomColumn<T>,
  stressLevel: string,
) => CustomColumn<T> = (col, stressLevel) => {
  const isFillBoxColumn =
    col.field !== 'assetName' &&
    col.field !== 'grossExposure' &&
    col.field !== 'position' &&
    col.field !== 'isin';
  return {
    ...col,
    render: (rowData) =>
      isFillBoxColumn ? (
        <FillCell
          cellColor={mapStressToColor(stressLevel)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value={rowData[col.field as keyof LiquidityData] as number}
        />
      ) : col.field !== 'assetName' && col.field !== 'isin' ? (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addCommasToNumbersAndRound(
          rowData[col.field as keyof LiquidityData] as number,
        )
      ) : (
        rowData[col.field]
      ),
    pdfRenderType: isFillBoxColumn ? 'FillBox' : undefined,
    renderMethod: isFillBoxColumn
      ? {
          methodName: 'statusBox',
          params: ['itemNumber', 'stressLevel', col.field as string],
        }
      : undefined,
    headerStyle: {
      textAlign: col.field === 'fundName' ? 'left' : 'center',
    },
  };
};

export const createColumns: (
  stressLevel: string,
) => CustomColumn<LiquidityData>[] = (stressLevel: string) => {
  return AssetColumns.map((col: CustomColumn<LiquidityData>) =>
    createColumn(col, stressLevel),
  );
};

export function buildAssetTableData(inputData: any) {
  if (!inputData.data.length) return [];
  if (!Object.keys(inputData.data[0]).length) return [];

  const data = inputData.data[0];
  const returnedData: LiquidityData[] = [];
  Object.keys(data).forEach((asset) => {
    if (asset !== 'aggregate_manco_level_liquidity') {
      returnedData.push({
        assetName: asset,
        isin: data[asset].asset_isin,
        position: data[asset].manco_level_position_size,
        grossExposure: Math.round(data[asset].manco_gross_exposure),
        zeroToOne: data[asset].asset_liquidation_time[0] * 100,
        twoToSeven: data[asset].asset_liquidation_time[1] * 100,
        eightToThirty: data[asset].asset_liquidation_time[2] * 100,
        thirtyOneToNinety: data[asset].asset_liquidation_time[3] * 100,
        ninetyOneToOneEighty: data[asset].asset_liquidation_time[4] * 100,
        OneEightOneToThreeSixFour: data[asset].asset_liquidation_time[5] * 100,
        moreThanAYear: data[asset].asset_liquidation_time[6] * 100,
        funds: data[asset].sub_funds.map((fund: (string | number)[]) => ({
          fundId: fund[0],
          fundName: fund[1],
          date: fund[2],
          position: fund[3],
          grossExposure: fund[4],
          holdingPc: fund[5],
        })),
      });
    }
  });

  return returnedData;
}

export function buildOverviewTableData(
  bauData: any,
  stressedData: any,
  superStressedData: any,
) {
  const returnedData: OverviewLiquidityData[] = [];

  if (!bauData.data.length) return returnedData;
  if (!Object.keys(bauData.data[0]).length) return returnedData;
  const bau = bauData.data[0];
  returnedData.push({
    stressLevel: 'BAU',
    zeroToOne: bau['aggregate_manco_level_liquidity'][0],
    twoToSeven: bau['aggregate_manco_level_liquidity'][1],
    eightToThirty: bau['aggregate_manco_level_liquidity'][2],
    thirtyOneToNinety: bau['aggregate_manco_level_liquidity'][3],
    ninetyOneToOneEighty: bau['aggregate_manco_level_liquidity'][4],
    OneEightOneToThreeSixFour: bau['aggregate_manco_level_liquidity'][5],
    moreThanAYear: bau['aggregate_manco_level_liquidity'][6],
  });

  if (!stressedData.data.length) return returnedData;
  if (!Object.keys(stressedData.data[0]).length) return returnedData;
  const stressed = stressedData.data[0];
  returnedData.push({
    stressLevel: 'Stressed',
    zeroToOne: stressed['aggregate_manco_level_liquidity'][0],
    twoToSeven: stressed['aggregate_manco_level_liquidity'][1],
    eightToThirty: stressed['aggregate_manco_level_liquidity'][2],
    thirtyOneToNinety: stressed['aggregate_manco_level_liquidity'][3],
    ninetyOneToOneEighty: stressed['aggregate_manco_level_liquidity'][4],
    OneEightOneToThreeSixFour: stressed['aggregate_manco_level_liquidity'][5],
    moreThanAYear: stressed['aggregate_manco_level_liquidity'][6],
  });

  if (!superStressedData.data.length) return returnedData;
  if (!Object.keys(superStressedData.data[0]).length) return returnedData;
  const superStressed = superStressedData.data[0];
  returnedData.push({
    stressLevel: 'Super-Stressed',
    zeroToOne: superStressed['aggregate_manco_level_liquidity'][0],
    twoToSeven: superStressed['aggregate_manco_level_liquidity'][1],
    eightToThirty: superStressed['aggregate_manco_level_liquidity'][2],
    thirtyOneToNinety: superStressed['aggregate_manco_level_liquidity'][3],
    ninetyOneToOneEighty: superStressed['aggregate_manco_level_liquidity'][4],
    OneEightOneToThreeSixFour:
      superStressed['aggregate_manco_level_liquidity'][5],
    moreThanAYear: superStressed['aggregate_manco_level_liquidity'][6],
  });

  return returnedData;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    padding: '1.25rem 0.62rem 0.62rem 0.62rem',
  },
  button: {
    transition: 'width .2s',
    borderRadius: '0.50rem',
    padding: '0.5 1.88rem',
    margin: '0 0.31rem',
    fontSize: 'clamp(0.62rem, 0.9vw, 0.94rem)',
    height: '1.88rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    border: 'none',
    filter: `drop-shadow(0.06rem 0.06rem 0.06rem ${greys.grey400})`,
  },
  bauButton: {
    backgroundColor: hexToRGBA(mainColors.mainBlue, 0.4),
    '&:hover': {
      backgroundColor: mainColors.mainBlue,
    },
  },
  stressedButton: {
    backgroundColor: hexToRGBA(mainColors.Fail, 0.4),
    '&:hover': {
      backgroundColor: mainColors.Fail,
    },
  },
  superStressedButton: {
    backgroundColor: hexToRGBA(mainColors.Fail_even_darker, 0.4),
    '&:hover': {
      backgroundColor: mainColors.Fail_even_darker,
    },
  },
  bauButtonActive: {
    backgroundColor: mainColors.mainBlue,
  },
  stressedButtonActive: {
    backgroundColor: mainColors.Fail,
  },
  superStressedButtonActive: {
    backgroundColor: mainColors.Fail_even_darker,
  },
}));

type StressTypes = 'BAU' | 'Stressed' | 'Super-Stressed';

function LiquidationTimeTable({
  bauData,
  stressedData,
  superStressedData,
}: Props): ReactElement | null {
  const classes = useStyles();

  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const overviewData = buildOverviewTableData(
    bauData,
    stressedData,
    superStressedData,
  );
  const bauTableData = buildAssetTableData(bauData);

  const [shownStressLevel, setShownStressLevel] = useState<StressTypes>('BAU');

  const getData = (stressLevel: StressTypes) => {
    switch (stressLevel) {
      case 'BAU':
        return buildAssetTableData(bauData);
      case 'Stressed':
        return buildAssetTableData(stressedData);
      case 'Super-Stressed':
        return buildAssetTableData(superStressedData);
    }
  };

  const getColumns = (stressLevel: StressTypes) => {
    switch (stressLevel) {
      case 'BAU':
        return createColumns('BAU');
      case 'Stressed':
        return createColumns('Stressed');
      case 'Super-Stressed':
        return createColumns('Super-Stressed');
    }
  };

  const handleGetButtonStyle = (button: string) => {
    if (button === 'BAU' && button === shownStressLevel) {
      return clsx(classes.button, classes.bauButton, classes.bauButtonActive);
    } else if (button === 'BAU') {
      return clsx(classes.button, classes.bauButton);
    } else if (button === 'Stressed' && button === shownStressLevel) {
      return clsx(
        classes.button,
        classes.stressedButton,
        classes.stressedButtonActive,
      );
    } else if (button === 'Stressed') {
      return clsx(classes.button, classes.stressedButton);
    } else if (button === 'Super-Stressed' && button === shownStressLevel) {
      return clsx(
        classes.button,
        classes.superStressedButton,
        classes.superStressedButtonActive,
      );
    } else if (button === 'Super-Stressed') {
      return clsx(classes.button, classes.superStressedButton);
    }
  };

  return overviewData.length ? (
    <>
      <GridItem xs={12} card>
        <CustomTable<OverviewLiquidityData>
          title={`Total ${
            clientName === 'gemini' ? 'Platform' : 'Manco'
          } Liquidation Time (Days)`}
          showToolbar={true}
          options={{
            exportButton: true,
            paging: false,
          }}
          data={overviewData}
          columns={OverviewColumns}
          imageToPdf={true}
        />
      </GridItem>
      <GridItem xs={12} card>
        <div className={classes.buttonsContainer}>
          <Button
            onClick={() => setShownStressLevel('BAU')}
            className={handleGetButtonStyle('BAU')}
          >
            BAU
          </Button>
          <Button
            onClick={() => setShownStressLevel('Stressed')}
            className={handleGetButtonStyle('Stressed')}
          >
            Stressed
          </Button>
          <Button
            onClick={() => setShownStressLevel('Super-Stressed')}
            className={handleGetButtonStyle('Super-Stressed')}
          >
            Super-Stressed
          </Button>
        </div>
        <CustomTable<LiquidityData>
          title={`Asset Liquidation Time (Days) - ${shownStressLevel}`}
          showToolbar={true}
          options={{
            exportButton: true,
            paging: bauTableData.length > 50,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200, 500],
            search: true,
            emptyRowsWhenPaging: false,
          }}
          data={getData(shownStressLevel)}
          detailPanel={LiquidationTimeSecondLevelTableWrapper()}
          columns={getColumns(shownStressLevel)}
        />
      </GridItem>
    </>
  ) : (
    <DisplayAreaCenteredWrapper>
      <NoDataMessage message={'Error Parsing Data'} />
    </DisplayAreaCenteredWrapper>
  );
}

export default LiquidationTimeTable;
