import { FunctionComponent } from 'react';
import { useEditorTileStyles } from '../../styles/KidsEditor.styles';

interface ErrorEditorTileProps {
  message: string;
}

const ErrorEditorTile: FunctionComponent<ErrorEditorTileProps> = ({
  message,
}) => {
  const classes = useEditorTileStyles();
  return (
    <div className={classes.outerContainer}>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <div
            style={{
              color: 'red',
              fontSize: '0.94rem',
              textAlign: 'center',
            }}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorEditorTile;
