import GeneralCard from '../../../general/GeneralCard';
import GenericLineChart from '../../../charts/GenericLineChart';
import { YieldDataType, YieldDataProps } from '../YieldPage';
import { makeStyles } from '@mui/styles';

const lines = [
  { dataKey: 'par', color: '#8884d8', strokeWidth: 2 },
  { dataKey: 'spot', color: '#82ca9d', strokeWidth: 2 },
  { dataKey: 'forward', color: '#D20103', strokeWidth: 2 },
];

function getMinMaxYieldData(data: YieldDataType[]): {
  max: number;
  min: number;
} {
  if (data.length === 0) {
    return { max: NaN, min: NaN }; // or any other value that makes sense in your context
  }

  const allValues = data.flatMap((item) => [item.par, item.spot, item.forward]);

  const minOfValues = Math.min(...allValues) * 0.95; // Taking 90% of the minimum value
  const maxOfValues = Math.max(...allValues) * 1.05;

  const values = {
    max: maxOfValues,
    min: minOfValues,
  };

  return values;
}

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '1.25rem',
    height: '80vh', // really this should be calc of the titlebar etc
  },
}));
const YieldCurve: React.FC<YieldDataProps> = ({ yieldData }) => {
  const classes = useStyles();

  const error = yieldData.length === 0 ? 'No data available' : null;

  const removeTableData = (data: any[]) => {
    return data.map(({ tableData, ...rest }) => rest);
  };

  return (
    <GeneralCard className={classes.container}>
      <GenericLineChart
        id="yield-curve-analysis"
        data={removeTableData(yieldData)}
        lines={lines}
        title="Yield Curve"
        pdfTitle="yield-curve"
        width={'100%'}
        height={'90%'}
        showLegend={true}
        showTooltip={true}
        xAxisLabel={{
          value: 'Maturity',
          position: 'top',
          fontWeight: 'bold',
        }}
        download
        export
        allowPdfExport
        pdfConfig={{ chartWidth: 0.95, pdfIdentifier: 'yield-curve' }}
        xAxisType="number"
        xAxisDataKey="matur"
        yAxisDomain={[
          getMinMaxYieldData(yieldData).min,
          getMinMaxYieldData(yieldData).max,
        ]}
        showXTick={true}
        xAxisTickFormatter={(tick: any) => tick.toFixed(0)}
        xAxisTicks={[1, 2, 3, 5, 7, 10, 15, 20, 30]}
        yInterval={'preserveEnd'}
        showYTicks={true}
        showDot={{ strokeWidth: 4 }}
        backgroundColor="white" // sets the background color for the chart
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        error={error}
      />
    </GeneralCard>
  );
};

export default YieldCurve;
