import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../styling/theme';
import { Input } from '@mui/material';
import { SearchSharp } from '@mui/icons-material';

const useNewSearchStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[100],
    width: '7.50rem',
    transition: 'width .2s',
    borderRadius: '0.50rem',
    paddingLeft: '0.62rem',
    '& svg': {
      pointerEvents: 'none',
      fontSize: '1.25rem',
      color: theme.palette.grey[800],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  input: {
    padding: '0.62rem',
    paddingLeft: '0.31rem',
  },
  focused: {
    color: theme.palette.primary.main,
    width: '8.75rem',
    '& svg': {
      color: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.grey[100],
  },
}));

interface Props {
  searchHandler: (input: string) => void;
}

function TableSearch({
  searchHandler,
}: React.PropsWithChildren<Props>): ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchHandler(event.target.value);
  };
  const newSearchStyles = useNewSearchStyles();

  const newSearchField = (
    <Input
      placeholder="Search..."
      disableUnderline
      classes={newSearchStyles}
      startAdornment={<SearchSharp />}
      onChange={handleChange}
    />
  );
  return newSearchField;
}

export default TableSearch;
