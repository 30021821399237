import { AxiosInstance } from '../../../utilities/requestClient';
import { IESGPortfolioSummary } from './portfolioSummary/types/esg-portfolio.types';

export const getEsgPortfolioSummary = async (
  fundId: string,
  positionDate: string,
) => {
  return (
    await AxiosInstance.get<IESGPortfolioSummary>(
      `get_fund_esg_data/${fundId}/${positionDate}`,
    )
  ).data;
};
