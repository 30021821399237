import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import React from 'react';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import FillCell from '../../../../tables/FillCell';

export interface BasicLiquidityData {
  zeroToOne: number;
  twoToSeven: number;
  eightToThirty: number;
  thirtyOneToNinety: number;
  ninetyOneToOneEighty: number;
  OneEightOneToThreeSixFour: number;
  moreThanAYear: number;
}

export const csvAggregateLiquidation = [
  'fundName',
  'grossExposure',
  'zeroToOne',
  'twoToSeven',
  'eightToThirty',
  'thirtyOneToNinety',
  'ninetyOneToOneEighty',
  'OneEightOneToThreeSixFour',
  'moreThanAYear',
];

export interface AggregateLiquidityData extends BasicLiquidityData {
  fundName: string;
  [key: string]: any;
  grossExposure: string | number;
  itemNumber?: number;
}

export const agTableColumns: CustomColumn<AggregateLiquidityData>[] = [
  {
    title: 'Fund Name',
    field: 'fundName',
    width: '15%',
    cellStyle: {
      padding: 0,
      paddingLeft: '0.62rem',
    },
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: {
      padding: 0,
      // textAlign: 'right',
      textAlign: 'center',
      // paddingRight: '1.25rem',
    },
    headerStyle: { textAlign: 'left' },
  },
  {
    title: '0 - 1',
    field: 'zeroToOne',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '2 - 7',
    field: 'twoToSeven',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '8 - 30',
    field: 'eightToThirty',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '31 - 90',
    field: 'thirtyOneToNinety',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '91 - 180',
    field: 'ninetyOneToOneEighty',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '180 - 364',
    field: 'OneEightOneToThreeSixFour',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '> 365',
    field: 'moreThanAYear',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
];

export const agTableColumnsPDF: CustomColumn<AggregateLiquidityData>[] = [
  {
    title: 'Fund Name',
    field: 'fundName',
    width: '15%',
    cellStyle: {
      padding: 0,
      paddingLeft: '0.62rem',
    },
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
      paddingRight: '1.25rem',
    },
  },
  {
    title: '0 - 1',
    field: 'zeroToOne',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '2 - 7',
    field: 'twoToSeven',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '8 - 30',
    field: 'eightToThirty',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '31 - 90',
    field: 'thirtyOneToNinety',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '91 - 180',
    field: 'ninetyOneToOneEighty',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '180 - 364',
    field: 'OneEightOneToThreeSixFour',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '> 365',
    field: 'moreThanAYear',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
];

export const stressLevels = ['standard', 'stressed', 'superStressed']; // TS3.4 syntax
export type StressLevel = (typeof stressLevels)[number]; // 'a'|'b'|'c';
export const mapStressToColor = (stressLevel: string) => {
  switch (stressLevel) {
    case 'standard':
      return mainColors.mainBlue;
    case 'stressed':
      return mainColors.Alert;
    case 'superStressed':
      return mainColors.secondaryRed;
    default:
      return mainColors.mainBlue;
  }
};
export const createColumn: <T extends AggregateLiquidityData>(
  col: CustomColumn<T>,
  stressLevel: string,
) => CustomColumn<T> = (col, stressLevel) => ({
  ...col,
  render: (rowData) =>
    col.field !== 'fundName' &&
    col.field !== 'grossExposure' &&
    col.field !== 'position' ? (
      <FillCell
        delayMultiplier={rowData.itemNumber}
        cellColor={mapStressToColor(stressLevel)}
        value={rowData[col.field!]}
      />
    ) : col.field !== 'fundName' ? (
      addCommasToNumbersAndRound(rowData[col.field] as number)
    ) : (
      rowData[col.field]
    ),
  pdfRenderType: 'FillBox',
  headerStyle: {
    textAlign: col.field === 'fundName' ? 'left' : 'center',
  },
});

export const createAgTableColumns: (
  stressLevel: StressLevel,
) => CustomColumn<AggregateLiquidityData>[] = (stressLevel: StressLevel) => {
  return agTableColumns.map((col: CustomColumn<AggregateLiquidityData>) =>
    createColumn(col, stressLevel),
  );
};
export const createAgTableColumnsPDF: (
  stressLevel: StressLevel,
) => CustomColumn<AggregateLiquidityData>[] = (stressLevel: StressLevel) => {
  return agTableColumnsPDF.map((col: CustomColumn<AggregateLiquidityData>) =>
    createColumn(col, stressLevel),
  );
};

export function buildMainData(data: any) {
  const finalData = data.fund_data.map((dataItem: any, index: number) => {
    const { external_name, gross_exposure } = dataItem;
    const [
      zero_one,
      two_seven,
      eight_thirty,
      thirtyone,
      ninetyone,
      oneeightone,
      gt365,
    ] = dataItem.liquidation_data;
    return {
      // itemNumber is for setting a delay on the fill box
      itemNumber: index,
      fundName: external_name,
      grossExposure: Math.round(gross_exposure),
      zeroToOne: zero_one,
      // zero_one_val: toTwoDecimalPlaces(zero_one),
      twoToSeven: two_seven,
      // two_seven_val: toTwoDecimalPlaces(two_seven),
      eightToThirty: eight_thirty,
      // eight_thirty_val: toTwoDecimalPlaces(eight_thirty),
      thirtyOneToNinety: thirtyone,
      // thirtyone_val: toTwoDecimalPlaces(thirtyone),
      ninetyOneToOneEighty: ninetyone,
      // ninetyone_val: toTwoDecimalPlaces(ninetyone),
      OneEightOneToThreeSixFour: oneeightone,
      // oneeightone_val: toTwoDecimalPlaces(oneeightone),
      moreThanAYear: gt365,
      // gt365_val: toTwoDecimalPlaces(gt365)
    };
  });

  return finalData;
}
