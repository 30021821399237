import React, { FC } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import CustomTable from '../../../../../tables/CustomTable';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../../utilities/numberFormatters';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../../styling/theme';

export interface SecondLevelTableData {
  assetName: string;
  assetClass: string;
  currency: number;
  size: number;
  totalPl: number;
  totalPlPc: number;
  assetPl: number;
  assetPlPc: number;
  currencyPl: number;
  currencyPlPc: number;
}

export interface SecondLevelTableProps {
  title: string;
  data: any;
}

const secondLevelColumns: CustomColumn<SecondLevelTableData>[] = [
  {
    title: 'Asset Name',
    field: 'assetName',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Class',
    field: 'assetClass',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Asset Type',
    field: 'assetType',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Size',
    field: 'size',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency PL',
    field: 'currencyPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.currencyPl),
  },
  {
    title: 'Currency PL %',
    field: 'currencyPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      percentageToNdecialPlaces(rowData.currencyPlPc / 100, 2),
  },
  {
    title: 'Asset PL',
    field: 'assetPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.assetPl),
  },
  {
    title: 'Asset PL %',
    field: 'assetPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      percentageToNdecialPlaces(rowData.assetPlPc / 100, 2),
  },
  {
    title: 'Total PL',
    field: 'totalPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      addCommasToNumbersAndRound(rowData.totalPl),
  },
  {
    title: 'Total PL %',
    field: 'totalPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SecondLevelTableData) =>
      percentageToNdecialPlaces(rowData.totalPlPc / 100, 2),
  },
];

const useStyles = makeStyles(() => ({
  secondLevelContainer: {
    margin: '0.94rem',
    border: `1px solid ${mainColors.lightGrey}`,
    borderRadius: '0.31rem',
  },
}));

const SecondLevelTable: FC<SecondLevelTableProps> = (props) => {
  const { title, data } = props;

  const classes = useStyles();

  return (
    <div className={classes.secondLevelContainer}>
      <CustomTable<SecondLevelTableData>
        columns={secondLevelColumns}
        showToolbar
        data={data}
        title={title}
        options={{
          paging: false,
          showTitle: false,
          exportButton: true,
          search: false,
          toolbar: true,
        }}
      />
    </div>
  );
};

export default SecondLevelTable;
