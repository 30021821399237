import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../styling/theme';
import GeneralCard from '../../../general/GeneralCard';
import { useMarketNews } from '../services/queries';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { NewsArticle } from '../services/api.routes';
import { formatDistanceToNow } from 'date-fns';
import NoDataMessage from '../../../feedback/NoDataMessage.component';

const useStyles = makeStyles({
  container: {
    maxHeight: '22.50em',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.31rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '0.62rem',
    },
    padding: '0.31em 0.62em 1.25em',
  },
  header: {
    color: mainColors.pumpkin,
    fontSize: '1.56em',
  },
  list: {
    padding: '0',
    margin: '0',
  },
  item: {
    margin: '0.62em 0',
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '0.62rem',
  },
  time: {
    color: greys.grey400,
    fontSize: '0.75em',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: '6.25rem',
  },
  text: {
    fontSize: '0.94em',
    fontWeight: 'medium',
    flexGrow: 1,
    color: 'black',
    textDecoration: 'none',
    transition: 'color 0.2s',
    '&:hover': {
      color: mainColors.mainBlue,
    },
  },
});

export interface NewsProps {
  selectedNews: string;
}

function orderByMostRecent(data: NewsArticle[] | undefined) {
  if (!data) return [];
  return data.sort((a, b) => b.providerPublishTime - a.providerPublishTime);
}
function timeAgoWithDateFns(timestamp: number) {
  return formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true });
}

const NewsComponent: React.FC<NewsProps> = ({ selectedNews }) => {
  const classes = useStyles();

  const { data, isPending, error } = useMarketNews(selectedNews);

  if (error) {
    return (
      <GeneralCard
        cardStyle={{
          height: '23.75rem',
          maxWidth: '43.75em',
          minWidth: '18.75em',
          padding: '0.31em',
        }}
      >
        <NoDataMessage message={'Error Retrieving Data'} />
      </GeneralCard>
    );
  }

  const orderedData = orderByMostRecent(data);

  return (
    <GeneralCard
      cardStyle={{
        height: '23.75rem',
        maxWidth: '43.75em',
        minWidth: '18.75em',
        padding: '0.31em',
      }}
    >
      {isPending ? (
        <RaptorLoading
          centerWrap
          messages={['Loading News Data', 'This may take a few seconds...']}
        />
      ) : orderedData.length === 0 ? (
        <h1 className={classes.header}>No News Available for {selectedNews}</h1>
      ) : (
        <div className={classes.container}>
          <h2 className={classes.header}>Latest News</h2>
          <ul className={classes.list}>
            {orderedData.map((item, index) => (
              <li key={index} className={classes.item}>
                <span className={classes.time}>
                  {timeAgoWithDateFns(item.providerPublishTime)}
                </span>
                <a
                  className={classes.text}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </GeneralCard>
  );
};

export default NewsComponent;
