import { useQuery } from '@tanstack/react-query';
import { getEsgPortfolioSummary } from '../../esg.routes';

export function useESGPortfolioData(
  fundId: string | null,
  positionDate: string | undefined,
) {
  return useQuery({
    queryKey: ['esg-portfolio-data', fundId, positionDate],
    queryFn: () => getEsgPortfolioSummary(fundId!, positionDate!),
    enabled: !!fundId && !!positionDate,
  });
}
