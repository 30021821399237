import { FunctionComponent } from 'react';
import { mainColors } from '../../styling/theme';
import RaptorLoading from '../feedback/RaptorLoading';
import GridItem from '../layout/GridComponents/GridItem';

interface TableLoaderProps {
  title: string;
}

const TableLoader: FunctionComponent<TableLoaderProps> = ({ title }) => {
  return (
    <GridItem xs={12} card>
      <header style={{ color: mainColors.mainBlue }}>
        <h2
          style={{ padding: '2.50rem', fontSize: '1.12rem', textAlign: 'center' }}
        >
          {title}
        </h2>
      </header>
      <div
        style={{ height: '80vh', display: 'flex', justifyContent: 'center' }}
      >
        <RaptorLoading />
      </div>
    </GridItem>
  );
};

export default TableLoader;
