import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';

export interface EquityTableData {
  name: string;
  sector: string;
  yield: number;
  currency: string;
  countryCode: string;
  forex: number;
  price: number;
  position: number;
  outstandingSecurities: number;
  exposure: number;
  riskFactor: number;
  cashYield: number;
}

export interface EquityServerData {
  base_currency: string;
  equity_data: {
    [key: string]: any;
  }[];
  equity_sector_data: any[];
  gics_comparable_data: string[][];
  nav: number;
  portfolio_exposure: number;
  position_dates: string[];
  sector_name: string;
}

export function generateEquityTableDataColumns(): CustomColumn<EquityTableData>[] {
  return [
    {
      title: 'Name',
      field: 'name',
      width: '12.50rem',
    },
    {
      title: 'ISIN',
      field: 'isin',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sector',
      field: 'sector',
      width: '18.75rem',
    },

    {
      title: 'Yield',
      field: 'yield',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.yield),
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Country Code',
      field: 'countryCode',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '10%',
    },
    {
      title: 'Forex',
      field: 'forex',
      render: (rowData) => toTwoDecimalPlaces(rowData.forex),
    },
    {
      title: 'Price',
      field: 'price',
      width: '10%',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Risk Factor',
      field: 'riskFactor',
      width: '10%',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Position',
      field: 'position',
    },
    {
      title: 'Outstanding Securities',
      field: 'outstandingSecurities',
      width: '12.50rem',
      render: (rowData) =>
        addCommasToNumbersAndRound(rowData.outstandingSecurities),
      cellStyle: {
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) => {
        return percentageToTwoDecimalPlacesNoHundred(rowData.exposure);
      },
    },
    {
      title: 'Cash Yield',
      field: 'cashYield',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.cashYield),
    },
  ];
}

export function generateEquityTableData(inputData: EquityServerData[]) {
  if (!inputData.length) return [];

  const data = inputData[0];

  const returnData = data.equity_data.map((equityEl) => {
    return {
      name: equityEl.gm_name,
      sector: equityEl.sector_name,
      yield: equityEl.yield,
      isin: equityEl.isin,
      riskFactor: equityEl.risk_factor,
      currency: equityEl.base_currency,
      countryCode: equityEl.country_code,
      forex: equityEl.forex_factor,
      price: equityEl.client_price,
      position: equityEl.mr_position,
      outstandingSecurities: equityEl.outstanding_securities,
      exposure: (equityEl.position_exposure * 100) / inputData[0].nav,
      cashYield: equityEl.yield,
    };
  });

  return returnData;
}
