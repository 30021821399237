import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { hexToRGBA } from '../../../../utilities/colorUtilities';

// styling for the main positions table component
export const ultraTableStyles = makeStyles<RaptorTheme>((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.88rem 1.88rem 0.62rem 1.88rem',
  },
  colSelection: {
    maxHeight: '12.50rem',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  header: {
    ...theme.typography.h1,
    fontSize: 'clamp(1.25rem, 1vw, 1.88rem)',
  },
  toolbarOptions: {
    flexGrow: 1,
    display: 'flex',
    gap: '0.62rem',
    '@media only screen and (max-width: 1850px)': {
      flexDirection: 'column',
      marginBottom: '0.62rem',
    },
  },
  mainOptions: {
    display: 'flex',
    alignItems: 'flex-center',
    justifyContent: 'flex-end',
    gap: '0.31rem',
    '@media only screen and (max-width: 1850px)': {
      justifyContent: 'flex-end',
    },
    marginBlock: '0.62rem',
  },
  tableContainer: {
    overflow: 'auto',
    position: 'relative', //needed for sticky header
    height: 'calc(100vh - 20.62rem)', // 20.62rem to account for the topbar and titlebar
    minHeight: '25.00rem', // to ensure at least 10 rows rendered
    marginBottom: '1.25rem',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.38rem',
      height: '0.38rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
      marginTop: '4.38rem', // gives the illusion of the scrollbar being underneath the sticky header
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '0.62rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  table: {
    borderCollapse: 'collapse',
  },
  tableHead: {
    position: 'sticky',
  },
  tableBody: {
    paddingBottom: '1.25rem',
    height: '80vh',
    overflow: 'auto',
    display: 'block',
    '&::-webkit-scrollbar': {
      width: '0.38rem',
      height: '0.38rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '0.62rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  row: {
    display: 'flex',
    borderTop: `1px solid ${mainColors.lightGrey}`,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  aggregateRow: {
    backgroundColor: mainColors.lightGrey,
    '&:hover': {
      backgroundColor: '#D2D2D2',
    },
  },
  rowExpanded: {
    borderRight: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderTop: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderLeft: `2px solid ${mainColors.hoverOverMainBlue}`,
    marginTop: '-3px',
  },
  headerRow: {
    display: 'flex',
    borderBottom: '2px solid grey',
  },
  cell: {
    ...theme.typography.h3,
    fontWeight: 500,
    overflow: 'clip',
    wordBreak: 'break-all',
    textAlign: 'center',
    fontSize: '0.88rem',
    width: '60px',
    minWidth: '3.12rem',
    minHeight: '2.50rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    borderLeft: `1px solid ${mainColors.lightGrey}`,
    borderBottom: `1px solid ${mainColors.lightGrey}`,
  },
  cellWithWordBreak: {
    wordBreak: 'break-word',
  },
  expander: {
    margin: '0 0.62rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanderIcon: {
    transform: 'scale(0.9)',
  },
  expanderHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${mainColors.lightGrey}`,
    width: '60px',
  },
  columnHead: {
    width: '100%',
    minWidth: '4.38rem',
    blockSize: 'fit-content',
  },
  columnHeadTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '4.06rem',
  },
  sortIcon: {
    cursor: 'pointer',
  },
  dragHandle: {
    fontWeight: 600,
    border: 'none',
    background: 'none',
    cursor: 'grab',
    padding: '0.62rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'clamp(0.75rem, 0.85vw, 1.00rem)',
    color: mainColors.mainBlue,
    position: 'relative',
  },
  columnHeadTooltip: {
    backgroundColor: mainColors.mainBlue,
    fontSize: '0.88rem',
  },
  numberInputs: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.62rem',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '0 0.50rem',
    height: '1.56rem',
    backgroundColor: theme.palette.grey[100],
    fontSize: '0.75rem',
    width: '35%',
  },
  inputRoot: {
    width: '100%',
  },
  picker: {
    width: '80%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    height: '1.56rem',
  },
  pickerContent: {
    fontSize: '0.75rem',
  },
  pickerTextField: {
    color: theme.palette.primary.main,
    fontSize: '0.62rem',
    paddingLeft: '0.50rem',
  },
  switchContainer: {
    marginRight: '1.88rem',
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: mainColors.mainBlue,
      '&:hover': {
        backgroundColor: alpha(
          mainColors.mainBlue_lighter,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
  },

  search: {
    height: '38px',
  },

  resetButton: {
    transition: 'width .2s',
    borderRadius: '0.50rem',
    paddingInline: '0.62rem',
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    fontSize: '0.75rem',
  },

  assetClassButtonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.62rem 0.12rem',
    '@media only screen and (max-width: 1850px)': {
      justifyContent: 'flex-end',
    },
  },
  assetClassButton: {
    transition: 'width .2s',
    borderRadius: '0.50rem',
    padding: '0.5 1.88rem',
    margin: '0 0.31rem',
    noWrap: 'true',
    fontSize: 'clamp(0.62rem, 0.9vw, 0.94rem)',
    backgroundColor: greys.grey400,
    height: '1.88rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    filter: `drop-shadow(0.06rem 0.06rem 0.06rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  activeAssetClassButton: {
    backgroundColor: mainColors.mainBlue,
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '1.88rem',
    marginTop: '20vh',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  paginationControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0.62rem 1.25rem 0 1.25rem',
  },
  paginationButtons: {
    display: 'flex',
  },
  pageToggleButton: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    border: 'none',
    color: 'white',
    padding: '0.31rem 0.62rem',
    margin: '0 0.31rem',
    cursor: 'pointer',
    borderRadius: '0.31rem',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: mainColors.mainBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  pageToggleButtonInactive: {
    pointerEvents: 'none',
    backgroundColor: mainColors.inactiveGrey,
  },
  pageCounts: {
    fontSize: '0.75rem',
    fontWeight: 600,
    margin: '0.94rem 0.62rem 0 0.62rem',
  },
  pageInput: {
    width: '3.12rem',
  },
  itemsPerPageSelect: {
    width: '3.12rem',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const compactUltraStyles = makeStyles<RaptorTheme>((theme) => ({
  tableContainer: {
    overflow: 'auto',
    position: 'relative',
    minHeight: '25.00rem',
    marginBottom: '1.25rem',
    height: 'calc(100vh - 13.12rem)',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.38rem',
      height: '0.38rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
      marginTop: '4.38rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '0.62rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },

  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      borderBottom: `1px solid ${mainColors.mainBlue}`,
    }
  },

  title: {
    color: mainColors.mainBlue,
    fontSize: 'clamp(1.25rem, 1vw, 1.88rem)',
    fontWeight: 600,
  },

  headerCell: {
    fontWeight: 600,
    fontSize: 'clamp(0.75rem, 0.85vw, 1.00rem)',
    color: mainColors.mainBlue,
    textAlign: 'center',
    padding: '1.25rem',
  },

  tableCell: {
    textAlign: 'center',
    padding: '0.88rem',
    borderBottom: `1px solid ${greys.grey300}`,
    fontSize: '0.88rem',
    fontWeight: 500,
  },
}));
