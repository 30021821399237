import { useQuery } from '@tanstack/react-query';
import {
  getEditablePositionData,
  getPositionUpdateConfigData,
} from './api.routes';
import { AuthorisedFundConfigType } from '../PositionsUpdatePortal.types';

export function usePositionPortalConfigs() {
  return useQuery({
    queryKey: ['position-update-portal-configs'],
    queryFn: getPositionUpdateConfigData,
    retry: false,
  });
}

// Auth config not correctly typed -> unsure how cast as any
export function useEditablePositionsData(
  fundName: string,
  authConfig?: AuthorisedFundConfigType,
) {
  return useQuery({
    queryKey: ['editable-positions-data', fundName],
    queryFn: () => getEditablePositionData(fundName),
    enabled: !!fundName && !!authConfig,
  });
}
