import React from 'react';
import useFundNotes from '../../../../hooks/useFundNotes';
import { Note } from '../../../../sharedInterfaces/notes';
import { mainColors } from '../../../../styling/aifTheme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import formatRule from '../../../../utilities/formatRule';
import RaptorLoading from '../../../feedback/RaptorLoading';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import NotesTable from '../NotesPage/NotesTable/NotesTable.component';
import { Typography, useTheme } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { NoteData } from '../../sharedComponents/notes/Notes';

export interface FundFailTableProps {
  fundName: string;
  fundId: string;
  failsData: any;
  startDate: string;
  endDate: string;
  subject: 'ucits_prospectus-restrictions' | 'ucits_ucits-law';
}

export interface FundFailTableData {
  rule: string;
  ruleDescription: string;
  startFailDate: string;
  endFailDate: string;
  headerRow?: boolean;
}

const fundFailTableColumns: CustomColumn<FundFailTableData>[] = [
  {
    field: 'rule',
    title: 'Rule',
    width: '6.25rem',
    cellStyle: (data, rowData) => ({
      fontWeight: rowData.headerRow ? 700 : 400,
      color: rowData.headerRow ? mainColors.mainBlue : 'inherit',
      textAlign: 'center',
    }),
    render: (rowData) => (!rowData.headerRow ? '' : formatRule(rowData.rule)),
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'ruleDescription',
    headerStyle: {
      textAlign: 'center',
    },
    title: 'Rule Description',
    render: (rowData) => (!rowData.headerRow ? '' : rowData.ruleDescription),
    cellStyle: (data, rowData) => ({
      fontWeight: rowData.headerRow ? 700 : 400,
      color: rowData.headerRow ? mainColors.mainBlue : 'inherit',
      textAlign: 'center',
    }),
  },
  {
    width: '12.50rem',
    field: 'startFailDate',
    title: 'Start Fail Date',
  },
  {
    width: '12.50rem',
    field: 'endFailDate',
    title: 'End Fail Date',
  },
];

const generateFailsDataByFund = (failingFund: any) => {
  if (!failingFund) return [];
  const keysFromFundObject = Object.keys(failingFund);
  const updatedWithNewRows: FundFailTableData[] = [];
  for (const key of keysFromFundObject) {
    updatedWithNewRows.push({
      headerRow: true,
      rule: failingFund[key].rule,
      ruleDescription: failingFund[key].rule_description,
      startFailDate: '',
      endFailDate: '',
    });
    if (key !== 'external_fund_name' && key !== 'internal_fund_name') {
      // if we're dealing with one of the object keys that starts with "rule..."
      const dates = [...failingFund[key].date_data];
      // remove header row
      dates.shift();
      // go through each of the dates and create a row
      for (const date of dates) {
        updatedWithNewRows.push({
          rule: failingFund[key].rule,
          ruleDescription: failingFund[key].rule_description,
          startFailDate: date[0],
          endFailDate: date[1],
        });
      }
    }
  }
  return updatedWithNewRows
    .filter((el: any) => el.rule !== undefined)
    .sort((a: any, b: any) => {
      const aNumber = parseInt(a.rule.slice(5));
      const bNumber = parseInt(b.rule.slice(5));
      if (aNumber === bNumber) {
        if (!a.headerRow && !b.headerRow) {
          return a.startFailDate > b.startFailDate ? 1 : -1;
        }
        return a.headerRow ? -1 : 1;
      }
      return aNumber < bNumber ? -1 : 1;
    });
};

export const NotesDropDown = ({
  rule,
  startDate,
  endDate,
  ruleDescription,
  fundId,
  subject,
}: {
  rule: string;
  startDate: string;
  ruleDescription: string;
  endDate: string;
  fundId: string;
  subject: 'ucits_ucits-law' | 'ucits_prospectus-restrictions';
}) => {
  const { notes, loading } = useFundNotes({
    fundId,
    subject,
    topic: rule,
    positionDate: startDate,
    shouldCall: true,
    startDate,
    endDate,
    // otherUrlProvided: `/get_fund_notes/${fundId}/${startDate}/${endDate}/ucits_ucits-law/${rule}`,
  });
  const theme = useTheme();

  const notesData: NoteData[] =
    notes.map((note: Note) => ({
      date: note.fund_note_timestamp,
      noteValue: note.fund_note_text,
      positionDate: note.fund_note_position_date,
      noteType: note.fund_note_text.includes('TABLE_NOTE:') ? 'table' : 'basic',
    })) || [];
  return notes ? (
    loading ? (
      <RaptorLoading setWidth="2.50rem" />
    ) : Object.keys(notes).length > 0 ? (
      <NotesTable
        // if we reverse, the earliest notes come at the top
        tableData={notesData.reverse()}
        // this is where we have to put in the correct rule title
        title={`${formatRule(rule)}: ${ruleDescription}`}
        showToolbar
      />
    ) : (
      <div style={{ padding: '0.50rem', display: 'flex' }}>
        <Typography
          variant="h5"
          style={{ fontWeight: 500, color: theme.palette.grey[600] }}
        >
          No notes saved for {formatRule(rule)} between {startDate} and{' '}
          {endDate}
        </Typography>
      </div>
    )
  ) : null;
};

export const FundFailTable: React.FC<FundFailTableProps> = ({
  fundName,
  failsData,
  startDate,
  endDate,
  fundId,
  subject,
}) => {
  const dataForTable = generateFailsDataByFund(failsData);
  return (
    <GridItem xs={12} card>
      <CustomTable<FundFailTableData>
        emptyDataSourceMessage="No Fails Found for Specified Period."
        options={{ paging: false, exportButton: true }}
        data={dataForTable}
        columns={fundFailTableColumns}
        showToolbar
        detailPanel={[
          (rowData: FundFailTableData) => {
            const icon = () => (!rowData.headerRow ? <UnfoldMore /> : <></>);
            const render = () => (
              <NotesDropDown
                subject={subject}
                rule={rowData.rule}
                ruleDescription={rowData.ruleDescription}
                startDate={rowData.startFailDate}
                endDate={rowData.endFailDate}
                fundId={fundId}
              />
            );
            return {
              render,
              disabled: rowData.headerRow,
              icon,
            };
          },
        ]}
        title={`${fundName} - UCITS Fails - ${startDate} to ${endDate}`}
      />
    </GridItem>
  );
};
