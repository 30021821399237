import React, { useEffect } from 'react';
import { greys, mainColors } from '../../styling/theme';
import { CircularProgress, ClassNameMap, Tooltip } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { OpenInNew } from '@mui/icons-material';

export type RaptorFileDownloadButtonStatus =
  | 'idle'
  | 'loading'
  | 'success'
  | 'error';

interface RaptorFileDownloadButtonProps {
  message?: string;
  status?: RaptorFileDownloadButtonStatus;
  tooltipValue?: string;
  key?: string;
  openDownloadInNewTab?: boolean;
}

// This component has height of 100% and width of 100%.
// It is meant to be used as a child of a container which is used to fix it's width.
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    gap: '0.62rem',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    color: 'white',
    borderRadius: '0.25rem',
    padding: '0.50rem 0.62rem',
    fontSize: '1.00rem',
    fontWeight: 500,
    letterSpacing: '0.06rem',
    backgroundColor: mainColors.controlButtonBlue,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.controlButtonBlue_lighter,
    },
  },
  tooltip: {
    fontSize: '1.00rem',
    padding: '0.62rem 1.25rem',
    borderRadius: '0.25rem',
  },
  loadingIcon: {
    marginRight: '0.62rem',
    color: 'white',
    fontWeight: 600,
  },
  idle: {
    backgroundColor: mainColors.controlButtonBlue,
  },
  loading: {
    backgroundColor: greys.grey400,
    cursor: 'default',
    '&:hover': {
      backgroundColor: greys.grey400,
    },
  },
  success: {
    backgroundColor: mainColors.Pass,
    cursor: 'default',
    '&:hover': {
      backgroundColor: mainColors.Pass,
    },
  },
  error: {
    backgroundColor: mainColors.Fail,
    cursor: 'default',
    '&:hover': {
      backgroundColor: mainColors.Fail,
    },
  },
});

const statusToMessage = (
  status: RaptorFileDownloadButtonStatus | undefined,
) => {
  switch (status) {
    case 'idle':
      return 'Generate Report';
    case 'loading':
      return 'Generating...';
    case 'success':
      return 'Success';
    case 'error':
      return 'Error';
    default:
      return 'Generate Report';
  }
};

const statusToStyle = (
  classes: ClassNameMap<string>,
  status: RaptorFileDownloadButtonStatus | undefined,
) => {
  switch (status) {
    case 'idle':
      return classes.idle;
    case 'loading':
      return classes.loading;
    case 'success':
      return classes.success;
    case 'error':
      return classes.error;
    default:
      return classes.idle;
  }
};

const RaptorFileDownloadButton: React.FC<RaptorFileDownloadButtonProps> = ({
  message,
  status,
  tooltipValue,
  key,
  openDownloadInNewTab,
}) => {
  const classes = useStyles();

  const [userMessage, setUserMessage] = React.useState<string>(
    message ?? statusToMessage(status),
  );

  useEffect(() => {
    setUserMessage(message ?? statusToMessage(status));
  }, [message, status]);

  return (
    <Tooltip
      key={key}
      title={tooltipValue ? tooltipValue : ''}
      classes={{ tooltip: classes.tooltip }}
    >
      <div className={clsx(classes.container, statusToStyle(classes, status))}>
        {openDownloadInNewTab === true ? <OpenInNew /> : null}
        {status === 'loading' ? (
          <CircularProgress
            size={20}
            thickness={7}
            className={classes.loadingIcon}
          />
        ) : null}
        <div>{userMessage}</div>
      </div>
    </Tooltip>
  );
};

export default RaptorFileDownloadButton;
