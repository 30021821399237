import React, { ReactElement } from 'react';
import { mapStatusToColor } from '../../utilities/colorUtilities';
import { Status } from '../../types/redux/data/dataTypes';
import { mainColors } from '../../styling/theme';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  rowDisabled?: boolean;
  customMessage?: string;
  tooltipMessage?: string;
}

function NoTimeSeries({
  rowDisabled,
  customMessage,
  tooltipMessage,
}: Props): ReactElement {
  return tooltipMessage && !rowDisabled ? (
    <Tooltip
      title={
        <Typography variant="h3" style={{ color: 'white' }}>
          {tooltipMessage}
        </Typography>
      }
      placement="top"
      style={{ fontSize: '6.25rem' }}
    >
      <Typography
        variant="h4"
        style={{
          color: rowDisabled
            ? mapStatusToColor(Status.NA)
            : mainColors.mainBlue,
          fontSize: 14,
        }}
      >
        {customMessage ? customMessage : 'No time series data available'}
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      variant="h4"
      style={{
        color: rowDisabled ? mapStatusToColor(Status.NA) : mainColors.mainBlue,
        fontSize: 14,
      }}
    >
      {customMessage ? customMessage : 'No time series data available'}
    </Typography>
  );
}

export default NoTimeSeries;
