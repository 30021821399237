import React, { FC } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import PeExposureSecondLevelTableWrapper from './PeExposureSecondLevelTableWrapper.component';

interface PeExposureTableProps {
  data: any;
  fundId: string;
}

export interface IPeExposureTableData {
  asset: string;
  isin: string;
  assetClass: string;
  assetType: string;
  currency: string;
  fxRate?: number;
  localExposure?: number;
  baseExposure?: number;
  grossExposure: number;
  grossExposurePercentage: number;
  commitment: number;
  commitmentPercentage: number;
  underlyingAssets: any;
  countryIndex: string;
  strategyIndex: string;
  sectorIndex: string;
  countryIndexTooltip: string;
  strategyIndexTooltip: string;
  sectorIndexTooltip: string;
  isAggregateRow: boolean;
  gmid: string;
  ebit: number;
  ebitGrowth: number;
  ebitVolatility: number;
  wacc: number;
  maturityDate: string;
}

export const peExposureTableColumns: CustomColumn<IPeExposureTableData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Isin',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Asset Class',
    field: 'assetClass',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Asset Type',
    field: 'assetType',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Fx Rate',
    field: 'fxRate',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      rowData.fxRate
        ? roundNumberToNDecimalPlaces(rowData.fxRate, 4)
        : rowData.fxRate,
  },
  {
    title: 'Local Exposure',
    field: 'localExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      rowData.localExposure
        ? addCommasToNumbersAndRound(rowData.localExposure)
        : rowData.localExposure,
  },
  {
    title: 'Base Exposure',
    field: 'baseExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      rowData.baseExposure
        ? addCommasToNumbersAndRound(rowData.baseExposure)
        : rowData.baseExposure,
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      rowData.grossExposure
        ? addCommasToNumbersAndRound(rowData.grossExposure)
        : rowData.grossExposure,
  },
  {
    title: 'Gross Exposure%',
    field: 'grossExposurePercentage',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      percentageToTwoDecimalPlaces(rowData.grossExposurePercentage),
  },
  {
    title: 'Commitment',
    field: 'commitment',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      addCommasToNumbersAndRound(rowData.commitment),
  },
  {
    title: 'commitment%',
    field: 'commitmentPercentage',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData: IPeExposureTableData) =>
      percentageToTwoDecimalPlaces(rowData.commitmentPercentage),
  },
];

function buildPeExposureTableData(data: any): IPeExposureTableData[] {
  if (!data.data.length) return [];
  const exposureData = data.data[0].exposure_data;
  const nav = data.data[0].nav;
  const tableData: IPeExposureTableData[] = [];
  interface exposureType {
    [key: string]: IPeExposureTableData[];
  }

  // Get all of the asset classes in the data.
  const assetClasses: exposureType = {};
  for (const key of Object.keys(exposureData)) {
    if (!(exposureData[key]['asset class'] in assetClasses)) {
      // The first element in each asset class array will be the aggregation
      assetClasses[exposureData[key]['asset class']] = [
        {
          asset: `${exposureData[key]['asset class']} (Aggregation)`,
          isin: '',
          assetClass: exposureData[key]['asset class'],
          assetType: 'Aggregation',
          currency: '',
          fxRate: undefined,
          localExposure: undefined,
          baseExposure: undefined,
          grossExposure: 0,
          grossExposurePercentage: 0,
          commitment: 0,
          commitmentPercentage: 0,
          underlyingAssets: [],
          countryIndex: '',
          strategyIndex: '',
          sectorIndex: '',
          countryIndexTooltip: '',
          strategyIndexTooltip: '',
          sectorIndexTooltip: '',
          isAggregateRow: true,
          gmid: '',
          ebit: 0,
          ebitGrowth: 0,
          ebitVolatility: 0,
          wacc: 0,
          maturityDate: '',
        },
      ];
    }

    // Now append the asset to the corresponding list for that asset type.
    assetClasses[exposureData[key]['asset class']].push({
      asset: exposureData[key].name,
      isin: exposureData[key].isin,
      assetClass: exposureData[key]['asset class'],
      assetType: exposureData[key].asset_type,
      currency: exposureData[key].currency,
      fxRate: exposureData[key].fx,
      localExposure: exposureData[key].lc_exposure,
      baseExposure: exposureData[key].bc_exposure,
      grossExposure: exposureData[key].gross_exposure,
      grossExposurePercentage: exposureData[key].gross_exposure / nav,
      commitment: exposureData[key].commitment,
      commitmentPercentage: exposureData[key].commitment / nav,
      underlyingAssets: exposureData[key].underlying_assets,
      countryIndex: exposureData[key].Location,
      strategyIndex: exposureData[key].Strategy,
      sectorIndex: exposureData[key].Industry,
      countryIndexTooltip: exposureData[key].Location_tooltip,
      strategyIndexTooltip: exposureData[key].Strategy_tooltip,
      sectorIndexTooltip: exposureData[key].Industry_tooltip,
      isAggregateRow: false,
      gmid: exposureData[key].gm_id,
      ebit: exposureData[key]['EBIT'],
      ebitGrowth: exposureData[key]['EBIT Growth'],
      ebitVolatility: exposureData[key]['EBIT Volatility'],
      wacc: exposureData[key]['WACC'],
      maturityDate: exposureData[key]['Maturity Date'],
    });

    // Finally increment values in the Aggregate row.
    assetClasses[exposureData[key]['asset class']][0].grossExposure =
      assetClasses[exposureData[key]['asset class']][0].grossExposure +
      exposureData[key].gross_exposure;
    assetClasses[exposureData[key]['asset class']][0].grossExposurePercentage =
      assetClasses[exposureData[key]['asset class']][0]
        .grossExposurePercentage +
      exposureData[key].gross_exposure / nav;
    assetClasses[exposureData[key]['asset class']][0].commitment =
      assetClasses[exposureData[key]['asset class']][0].commitment +
      exposureData[key].commitment;
    assetClasses[exposureData[key]['asset class']][0].commitmentPercentage =
      assetClasses[exposureData[key]['asset class']][0].commitmentPercentage +
      exposureData[key].commitment / nav;
  }
  // Combine all the asset classes into one list.
  for (const key of Object.keys(assetClasses)) {
    tableData.push(...assetClasses[key]);
  }

  // for (const key of Object.keys(exposureData)) {
  //     tableData.push({
  //         asset: exposureData[key].name,
  //         isin: exposureData[key].isin,
  //         assetClass: exposureData[key]['asset class'],
  //         assetType: exposureData[key].asset_type,
  //         currency: exposureData[key].currency,
  //         fxRate: exposureData[key].fx,
  //         localExposure: exposureData[key].lc_exposure,
  //         baseExposure: exposureData[key].bc_exposure,
  //         grossExposure: exposureData[key].gross_exposure,
  //         grossExposurePercentage: exposureData[key].gross_exposure / nav,
  //         commitment: exposureData[key].commitment,
  //         commitmentPercentage: exposureData[key].commitment / nav,
  //         underlyingAssets: exposureData[key].underlying_assets,
  //         countryIndex: 'A Country',
  //         strategyIndex: 'A Strategy',
  //         sectorIndex: 'An Index',
  //         countryIndexTooltip: exposureData[key].Location_tooltip,
  //         strategyIndexTooltip: exposureData[key].Strategy_tooltip,
  //         sectorIndexTooltip: exposureData[key].Industry_tooltip

  //     })
  // }

  return tableData;
}

const PeExposureTable: FC<PeExposureTableProps> = (props) => {
  const { data, fundId } = props;
  const tableData = buildPeExposureTableData(data);
  return (
    <GridItem xs={12} card>
      <CustomTable<IPeExposureTableData>
        //   selectedPositionDate={data[0].selected_position_date}
        showToolbar={true}
        id={`${fundId}_exposure_main`}
        title={'Exposure Data'}
        //   csvFields={exposureMainTableCsvFields}
        loading={props.data.isFetching}
        data={tableData}
        exportFileName={`exposure-${fundId}}`}
        detailPanel={PeExposureSecondLevelTableWrapper()}
        options={{
          paging: false,
          search: true,
          rowStyle: (rowData: IPeExposureTableData) => ({
            backgroundColor: rowData.isAggregateRow
              ? mainColors.mainBlue_lightAlt
              : 'white',
          }),
          exportButton: true,
          exportAllData: true,
          // grouping: true,
          emptyRowsWhenPaging: false,
          // maxBodyHeight: 'calc(100vh - 12.38rem)',
        }}
        columns={peExposureTableColumns}
        toolbarComponents={{
          toolbarTitle: 'Exposure Data',
        }}
      />
    </GridItem>
  );
};

export default PeExposureTable;
