import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import { Dispatch, FC, useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useWindowSize } from 'react-use';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import client from '../../../../../../utilities/requestClient';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import { Button } from '@mui/material';

interface PdfViewerProps {
  delegatedActsData: any[] | null;
  haveChangesBeenMade: boolean;
  setHaveChangesBeenMade: Dispatch<any>;
  fundId: string | null;
  fundName: string | null;
}

const useStyles = makeStyles<RaptorTheme>(() => ({
  parentContainer: {
    minHeight: '12.50rem',
    display: 'flex',
    margin: '1.25rem',
    gap: '1.25rem',
  },
  buttonsContainer: {
    gap: '0.62rem',
    justifyContent: 'center',
  },
  button: {
    height: '1.88rem',
    marginTop: '0.31rem',
    marginRight: '0.62rem',
    width: '7.50rem',
    backgroundColor: mainColors.controlButtonBlue,
    textColor: 'white',
  },
  navigationButton: {
    height: '1.88rem',
    marginRight: '0.62rem',
    width: '3.75rem',
    backgroundColor: mainColors.controlButtonBlue,
    textColor: 'white',
  },
  viewer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const PdfViewer: FC<PdfViewerProps> = ({
  delegatedActsData,
  haveChangesBeenMade,
  setHaveChangesBeenMade,
  fundId,
  fundName,
}) => {
  const classes = useStyles();
  const requestClient = client();

  const [pdfDoc, setPdfDoc] = useState<any>(null);

  const [pdfDocLoading, setPdfDocLoading] = useState(false);
  const { height } = useWindowSize();

  const refreshPdf = () => {
    // Check if the required fields have been filled in.
    if (!delegatedActsData || !delegatedActsData.length) {
      return;
    }
    const content: any[] = [];
    delegatedActsData.forEach((element: any) => {
      content.push(JSON.parse(element.content));
    });
    const delegatedActsJson = JSON.stringify(content);

    const requestBody = {
      contents: delegatedActsJson,
      fund_id: fundId || '',
      fund_name: fundName || '',
      document_date: '2022-12-31',
    };

    setPdfDocLoading(true);

    requestClient
      .post(
        'risksystem_pdf_generator/generate_delegated_acts_report',
        requestBody,
        {
          headers: {
            accept: 'application/pdf',
          },
          responseType: 'blob',
        },
      )
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'delegated_acts_report.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    refreshPdf();
  }, [delegatedActsData]);

  const handleFileDownload = () => {
    if (pdfDoc) {
      fileDownload(pdfDoc || '', 'pai.pdf');
    }
  };

  const { showAsyncSnackbar } = useSnackbar();

  async function handleSaveChanges() {
    // Check if the required fields have been filled in.
    if (!delegatedActsData || !delegatedActsData.length) {
      return;
    }

    const raidrJson = JSON.stringify(delegatedActsData);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('raidr_data', raidrJson);

    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Saving Data.',
      successMessage: 'Data Saved.',
      failureMessage: 'An Error occured while saving.',
      promiseToResolve: requestClient.post('write_raidr_data', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }),
    });

    // Update the save button to be disabled
    setHaveChangesBeenMade(false);
    // Refresh the PDF
    // refreshPdf();
  }

  return (
    <GridItem xs={12} card>
      <div className={classes.parentContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => refreshPdf()}
            endIcon={<CachedIcon style={{ color: 'white' }} />}
          >
            <div style={{ color: 'white' }}>Refresh</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleSaveChanges()}
            endIcon={<SaveIcon style={{ color: 'white' }} />}
            disabled={!haveChangesBeenMade}
          >
            <div style={{ color: 'white' }}>Save</div>
          </Button>
        </div>
        <div className={classes.viewerContainer}>
          <div className={classes.viewer}>
            <div
              style={{
                border: pdfDocLoading ? '1px solid' : undefined,
                borderColor: pdfDocLoading ? mainColors.mainBlue : undefined,
                width: height * 0.66,
                height: height * 0.89,
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <iframe
                src=""
                width={pdfDocLoading ? '0%' : '100%'}
                height="100%"
              ></iframe>
              {pdfDocLoading ? (
                <RaptorLoading
                  centerWrap
                  messages={['Generating Document...']}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default PdfViewer;
