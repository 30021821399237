import React, { ReactElement } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  SubFundSummaryBasicInfoData,
  SubFundSummaryCounterpartyTable,
  SubFundSummaryRiskComplianceRulesTable,
  SubFundSummaryStressTestTable,
  generateBasicInfo,
  generateCounterpartyInfo,
  generateData,
  generateRiskComplianceRulesInfo,
  generateStressTestInfo,
  stressTestColumns,
  subFundSummaryBasicInfoColumns,
  subFundSummaryCounterpartyColumns,
  subFundSummaryRiskComplianceColumns,
} from './SubFundSummary.data';

function SubFundSummary({
  fundId,
  fundName,
}: FundInfoComponentProps): ReactElement {
  const summaryData = useFetchData({
    keyName: `${fundId}_sub_fund_summary`,
    url: `sub_fund_setup/${fundId}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[summaryData]}>
      <GridItem xs={12} card>
        <CustomTable<SubFundSummaryBasicInfoData>
          data={generateData(summaryData, generateBasicInfo)}
          columns={subFundSummaryBasicInfoColumns}
          options={{
            paging: false,
          }}
          toolbarComponents={{
            titleStyle: {
              fontSize: '1.12rem',
              fontWeight: 500,
              transform: 'translate(-0.31rem, -0.31rem)',
            },
          }}
          showToolbar
          title={fundName}
        />
      </GridItem>
      {/* Risk Compliance */}
      {summaryData && summaryData.data[0]?.risk_compliance ? (
        <GridItem xs={3} card>
          <CustomTable<SubFundSummaryRiskComplianceRulesTable>
            data={generateData(summaryData, generateRiskComplianceRulesInfo)}
            columns={subFundSummaryRiskComplianceColumns}
            options={{
              paging: false,
            }}
            multipleTablePdfParameters={{
              specifiedWidth: 3,
              tablesInRow: 5,
            }}
            toolbarComponents={{
              titleStyle: {
                fontSize: '1.12rem',
                fontWeight: 500,
                transform: 'translate(-0.31rem, -0.31rem)',
              },
            }}
            showToolbar
            title="Prospectus Restrictions Rules"
          />
        </GridItem>
      ) : null}
      {[
        'Risk Sector Stress Tests',
        'Relative Stress Tests',
        'Historical Stress Tests',
      ].map((el) => {
        return (
          <GridItem xs={2} card key={el}>
            <CustomTable<SubFundSummaryStressTestTable>
              multipleTablePdfParameters={{
                specifiedWidth: 2,
                tablesInRow: 5,
              }}
              key={el}
              options={{
                paging: false,
              }}
              columns={stressTestColumns}
              data={generateData(
                summaryData,
                generateStressTestInfo,
                el.includes('Risk')
                  ? 'risk_sector'
                  : el.includes('Relative')
                    ? 'relative'
                    : 'historical',
              )}
              title={el}
              toolbarComponents={{
                titleStyle: {
                  fontSize: '1.12rem',
                  fontWeight: 500,
                  transform: 'translate(-0.31rem, -0.31rem)',
                },
              }}
              showToolbar
            />
          </GridItem>
        );
      })}
      {/* Counterparty Data */}
      {summaryData && summaryData.data[0]?.counterparty_data ? (
        <GridItem xs={3} card>
          <CustomTable<SubFundSummaryCounterpartyTable>
            options={{
              paging: false,
            }}
            multipleTablePdfParameters={{
              specifiedWidth: 3,
              tablesInRow: 5,
            }}
            columns={subFundSummaryCounterpartyColumns}
            data={generateData(summaryData, generateCounterpartyInfo)}
            title={'Counterparty Data'}
            showToolbar
            toolbarComponents={{
              titleStyle: {
                fontSize: '1.12rem',
                fontWeight: 500,
                transform: 'translate(-0.31rem, -0.31rem)',
              },
            }}
          />
        </GridItem>
      ) : null}
    </GeneralComponentErrorShield>
  );
}

export default SubFundSummary;
