import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';

export interface AssetCountryCodeGrossExposureProps {
  dataForRender: DataObject;
  fundId: string;
  fundName: string;
}
export interface ICountryCodeExposure {
  countryCode: string;
  riskRank: string;
  grossExposure: string;
}
export function buildCountryCodeExposure(data: any): ICountryCodeExposure[] {
  // Loop through each country and create an array of all data
  const finalData = [];
  let i = 0;
  for (i = 0; i < data.country_code_exposure.gross.length; i++) {
    const countryCode = data.country_code_exposure.gross[i][0];
    const grossExposure = data.country_code_exposure.gross[i][1];
    const riskRank =
      'risk_rank' in data.country_code_exposure
        ? data.country_code_exposure.risk_rank[countryCode]
        : '';
    finalData.push({
      countryCode: countryCode,
      grossExposure:
        grossExposure === 0
          ? '0%'
          : percentageToTwoDecimalPlaces(grossExposure),
      riskRank: riskRank,
    });
  }

  return finalData.filter(
    (item: any) => !(item.grossExposure === '0%' || item.countryCode === ''),
  );
}

export const useAssetCountryCodeExposureColumns =
  (): CustomColumn<ICountryCodeExposure>[] => {
    const generalStyle = {
      fontSize: '1.00rem',
      padding: '0.62rem',
    };
    return [
      {
        title: 'Country Code',
        width: '33%',
        field: 'countryCode',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Gross Exposure',
        width: '33%',
        field: 'grossExposure',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Risk Rank',
        field: 'riskRank',
        width: '33%',
        cellStyle: { ...generalStyle, textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
      },
    ];
  };

const AssetCountryCodeExposure: FC<AssetCountryCodeGrossExposureProps> = (
  props,
) => {
  const { fundId, fundName } = props;
  const { data } = props.dataForRender;
  const columns = useAssetCountryCodeExposureColumns();
  const renderedData = !data.length ? [] : buildCountryCodeExposure(data[0]);
  ////   const renderedData = buildAssetCountryCodeExposure(data);
  //const classes = useAssetCountryCodeExposureStyles();
  return (
    <CustomTable<ICountryCodeExposure>
      selectedPositionDate={data[0].selected_position_date}
      multipleTablePdfParameters={{
        specifiedWidth: 4,
        tablesInRow: 3,
      }}
      id={`${fundId}_asset_country_code_exposure`}
      title="Asset Country Code Exposure"
      showToolbar={true}
      loading={props.dataForRender.isFetching}
      csvFields={['countryCode', 'grossExposure', 'riskRank']}
      toolbarComponents={{
        toolbarTitle: 'Asset Country Code Exposure',
      }}
      columns={columns}
      data={renderedData}
      options={{
        paging: false,
        search: false,
        exportButton: true,
      }}
    />
  );
};

export default AssetCountryCodeExposure;
