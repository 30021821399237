import makeStyles from '@mui/styles/makeStyles';
import { scaleLinear } from 'd3-scale';
import { FC, ReactElement, useState } from 'react';
import { useWindowSize } from 'react-use';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { flexify } from '../../../../utilities/cssMixins';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PercentValueToggleButton from '../../../buttons/PercentValueToggleButton.component';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';

interface PeHeatMapTableProps {
  data: any;
  selectedPosition: string | null;
  fundId: string;
}

interface PeHeatMapTableData {
  ebitGrowthStress: number;
  stress05: number;
  stress10: number;
  stress15: number;
  stress20: number;
  stress25: number;
  stress30: number;
  stressMinus05: number;
  stressMinus10: number;
  stress0: number;
  nav: number;
  percentValueIndicator: string;
}

// const stressValues = ['+10%', '+5%', '0%', '-5%', '-10%', '-15%', '-20%', '-25%', '-30%']
const stressValues = [-0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3];
const stressKeys = [
  'stressMinus10',
  'stressMinus05',
  'stress0',
  'stress05',
  'stress10',
  'stress15',
  'stress20',
  'stress25',
  'stress30',
];

function buildPeHeatMapData(
  data: any,
  selectedPosition: string | null,
  percentValueOption: string,
  nav: number,
): PeHeatMapTableData[] {
  if (!selectedPosition) return [];
  if (!data.data.length) return [];
  if (!(selectedPosition in data.data[0])) return [];
  const heatMapData = data.data[0][selectedPosition];
  const tableData: PeHeatMapTableData[] = [];
  interface rowType {
    [key: string]: number | string;
  }

  // for (let i = 0; i < stressValues.length; i++) {
  for (let i = stressValues.length - 1; i >= 0; i--) {
    const rowData: rowType = {
      ebitGrowthStress: stressValues[i],
      stress05: 0,
      stress10: 0,
      stress15: 0,
      stress20: 0,
      stress25: 0,
      stress30: 0,
      stressMinus05: 0,
      stressMinus10: 0,
      stress0: 0,
      nav,
      percentValueIndicator: percentValueOption,
    };
    const currentStressValues = heatMapData.heatmap[i];
    for (let j = 0; j < currentStressValues.length; j++) {
      rowData[stressKeys[j]] =
        percentValueOption === 'percent'
          ? currentStressValues[j]['p/l']
          : currentStressValues[j]['p/l'] * nav;
    }
    tableData.push(rowData as unknown as PeHeatMapTableData);
  }

  return tableData;
}

interface PEHeatMapCellProps {
  value: number;
  minValue: number;
  maxValue: number;
  formatValueFunction?: (arg0: number) => number | string;
  ebit: number;
  growth: number;
  fundId: string;
  nav: number;
  percentValueOption: string;
}

function getGradinetColor(
  value: number,
  minValue: number,
  maxValue: number,
  nav: number,
  percentValueIndicator: string,
) {
  const range = maxValue - minValue;
  const formattedMaxValue =
    percentValueIndicator === 'percent' ? maxValue : maxValue / nav;
  const formattedMinValue =
    percentValueIndicator === 'percent' ? minValue : minValue / nav;
  const formattedValue =
    percentValueIndicator === 'percent' ? value : value / nav;
  const gradientFirstStep = scaleLinear()
    .domain([Math.min(formattedMinValue, -1), -0.5])
    .range([
      mainColors.Fail_darker as unknown as number,
      mainColors.Fail as unknown as number,
    ]);
  const gradientSecondStep = scaleLinear()
    .domain([-0.5, Math.min(formattedMaxValue, -0.2)])
    .range([
      mainColors.Fail as unknown as number,
      mainColors.Alert_darker as unknown as number,
    ]);
  const gradientThirdStep = scaleLinear()
    .domain([-0.2, Math.min(0, formattedMaxValue)])
    .range([
      mainColors.Alert_darker as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const gradientFourthStep = scaleLinear()
    .domain([0, Math.min(formattedMaxValue, 0.1)])
    .range([
      mainColors.Alert as unknown as number,
      mainColors.Pass_darker as unknown as number,
    ]);
  const gradientFifthStep = scaleLinear()
    .domain([0.1, Math.max(1, formattedMaxValue)])
    .range([
      mainColors.Pass_darker as unknown as number,
      mainColors.Pass as unknown as number,
    ]);

  if (value <= -0.5) {
    return gradientFirstStep(formattedValue);
  } else if (value <= -0.2) {
    return gradientSecondStep(formattedValue);
  } else if (value <= 0) {
    return gradientThirdStep(formattedValue);
  } else if (value <= 0.1) {
    return gradientFourthStep(formattedValue);
  } else {
    return gradientFifthStep(formattedValue);
  }

  // const gradientSecondHalf = scaleLinear()
  //     .domain([minValue + (range * 0.5), maxValue])
  //     .range([
  //         (mainColors.Alert as unknown) as number,
  //         (mainColors.Pass as unknown) as number,
  //     ]);
  // if (value <= minValue + (range * 0.5)) {
  //     return gradientFirstHalf(value);
  // } else {
  //     return gradientSecondHalf(value);
  // }
  // return gradient(value);
  // const range = maxValue - minValue;
  // const failGradient = scaleLinear()
  //     .domain([minValue, minValue + (range * 0.25)])
  //     .range([
  //         (mainColors.Fail_darker as unknown) as number,
  //         (mainColors.Fail as unknown) as number,
  //     ]);
  // const alertGradient = scaleLinear()
  //     .domain([minValue + (range * 0.25), minValue + (range * 0.5)])
  //     .range([
  //         (mainColors.Alert_darker as unknown) as number,
  //         (mainColors.Alert as unknown) as number,
  //     ]);
  // const passGradient = scaleLinear()
  //     .domain([minValue + (range * 0.5), maxValue])
  //     .range([
  //         (mainColors.Pass_darker as unknown) as number,
  //         (mainColors.Pass as unknown) as number,
  //     ]);
  // if (value <= minValue + (range * 0.25)) {
  //     return failGradient(value);
  // } else if (value <= minValue + (range * 0.5)) {
  //     return alertGradient(value);
  // } else {
  //     return passGradient(value);
  // }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    height: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    // maxWidth: '12.50rem',
    '&:hover': {
      backgroundColor: (props) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '12.50rem',
  },
}));

function PEHeatMapCell(props: PEHeatMapCellProps): ReactElement {
  const {
    value,
    minValue,
    maxValue,
    formatValueFunction,
    fundId,
    ebit,
    growth,
    nav,
    percentValueOption,
  } = props;

  const backgroundColor =
    percentValueOption === 'percent'
      ? getGradinetColor(value, minValue, maxValue, nav, percentValueOption)
      : getGradinetColor(
          value / nav,
          minValue / nav,
          maxValue / nav,
          nav,
          'percent',
        );

  return (
    //   <Wrapper {...props}>
    <Link
      to={`/pe/discount-cashflow?fundId=${fundId}&ebit=${ebit}&growth=${growth}`}
      style={{ textDecoration: 'none' }}
    >
      <Tooltip
        title={
          <Typography variant="h3" style={{ color: 'white' }}>
            Click To View Scenario on Discount Cashflow Page.
          </Typography>
        }
        placement="top"
      >
        <div
          style={{
            height: '5.00rem',
            // backgroundColor: getGradinetColor(value, minValue, maxValue, nav, percentValueOption) as unknown as string,
            backgroundColor: backgroundColor as unknown as string,
            borderColor: 'white',
            borderStyle: 'solid',
            borderWidth: '0.5px',
            ...flexify('center', 'center'),
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ color: 'white', fontSize: 16, fontWeight: 600 }}
          >
            {formatValueFunction
              ? formatValueFunction(value)
              : addCommasToNumbersAndRound(value)}
          </Typography>
        </div>
      </Tooltip>
    </Link>
    //   </Wrapper>
  );
}

function getPeHeatMapColumns(
  minValue: number,
  maxValue: number,
  percentValueOption: string,
  fundId: string,
): CustomColumn<PeHeatMapTableData>[] {
  return [
    {
      // title: 'Ebit Growth / EBIT',
      title: (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '12.50rem' }}></div>
            <div style={{}}>
              EBIT <ArrowForwardIcon sx={{ color: mainColors.mainBlue }} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '4.38rem' }}></div>
            <div style={{}}>
              EBIT Growth{' '}
              <ArrowDownwardIcon sx={{ color: mainColors.mainBlue }} />
            </div>
            <div></div>
          </div>
        </div>
      ),
      field: 'ebitGrowthStress',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.mainBlue,
        fontWeight: 700,
      },
      render: (rowData: PeHeatMapTableData) =>
        rowData.ebitGrowthStress > 0
          ? `+${percentageToNdecialPlaces(rowData.ebitGrowthStress, 0)}`
          : percentageToNdecialPlaces(rowData.ebitGrowthStress, 0),
      // headerStyle: {
      //     textAlign: 'center',
      // },
    },
    {
      title: '+30%',
      field: 'stress30',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress30}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '+25%',
      field: 'stress25',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress25}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '+20%',
      field: 'stress20',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress20}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '+15%',
      field: 'stress15',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress15}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },

    {
      title: '+10%',
      field: 'stress10',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress10}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '+5%',
      field: 'stress05',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stress05}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '0%',
      field: 'stress0',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stressMinus10}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-5%',
      field: 'stressMinus05',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stressMinus05}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '-10%',
      field: 'stressMinus10',
      render: (rowData: PeHeatMapTableData) => (
        <PEHeatMapCell
          value={rowData.stressMinus10}
          minValue={minValue}
          maxValue={maxValue}
          formatValueFunction={
            percentValueOption === 'percent'
              ? percentageToTwoDecimalPlaces
              : undefined
          }
          ebit={-0.1}
          growth={rowData.ebitGrowthStress}
          fundId={fundId}
          nav={rowData.nav}
          percentValueOption={rowData.percentValueIndicator}
        />
      ),
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];
}

interface minMaxValues {
  minValue: number;
  maxValue: number;
}

function getMinMaxPL(
  data: any,
  selectedPosition: string | null,
  percentValueOption: string,
  nav: number,
): minMaxValues {
  if (!selectedPosition) return { minValue: 0, maxValue: 0 };
  if (!data.data.length) return { minValue: 0, maxValue: 0 };
  if (!(selectedPosition in data.data[0])) return { minValue: 0, maxValue: 0 };
  const heatMapData = data.data[0][selectedPosition].heatmap;
  let minValue =
    percentValueOption === 'percent'
      ? heatMapData[0][0]['p/l'] / nav
      : heatMapData[0][0]['p/l'];
  let maxValue =
    percentValueOption === 'percent'
      ? heatMapData[0][0]['p/l'] / nav
      : heatMapData[0][0]['p/l'];

  for (let i = 0; i < heatMapData[0].length; i++) {
    const currentStressValues = heatMapData[i];
    for (let j = 0; j < currentStressValues.length; j++) {
      if (
        minValue >
        (percentValueOption === 'percent'
          ? currentStressValues[j]['p/l']
          : currentStressValues[j]['p/l'] * nav)
      ) {
        minValue =
          percentValueOption === 'percent'
            ? currentStressValues[j]['p/l']
            : currentStressValues[j]['p/l'] * nav;
      }
      if (
        maxValue <
        (percentValueOption === 'percent'
          ? currentStressValues[j]['p/l']
          : currentStressValues[j]['p/l'] * nav)
      ) {
        maxValue =
          percentValueOption === 'percent'
            ? currentStressValues[j]['p/l']
            : currentStressValues[j]['p/l'] * nav;
      }
    }
  }
  return { minValue, maxValue };
}

function getDescription(data: any, selectedPosition: string | null) {
  if (!selectedPosition) return null;
  if (!data.data.length) return null;
  if (!(selectedPosition in data.data[0])) null;
  return data.data[0][selectedPosition].description;
}

const PeHeatMapTable: FC<PeHeatMapTableProps> = (props) => {
  const { data, selectedPosition, fundId } = props;

  // Will be used to toggle between dollar values and percent of NAV.
  const [percentValueOption, setPercentValueOption] =
    useState<string>('percent');
  // Hardcoding in teh NAV for now, in future will be taken from the route.
  const nav = 10000000;
  const tableData = buildPeHeatMapData(
    data,
    selectedPosition,
    percentValueOption,
    nav,
  );
  const description = getDescription(data, selectedPosition);
  const { minValue, maxValue } = getMinMaxPL(
    data,
    selectedPosition,
    percentValueOption,
    nav,
  );
  const PeHeatMapColumns = getPeHeatMapColumns(
    minValue,
    maxValue,
    percentValueOption,
    fundId,
  );

  return (
    <>
      {description ? (
        <GridItem xs={12} card style={{ padding: 8 }}>
          <Typography variant="h3" style={{ padding: 20 }}>
            Description: {description}
          </Typography>
        </GridItem>
      ) : null}
      <GridItem xs={12} card style={{ padding: 8 }}>
        <div
          style={{
            float: 'left',
            marginLeft: '1.25rem',
            marginTop: '1.25rem',
            textAlign: 'center',
          }}
        >
          <PercentValueToggleButton
            percentValueOption={percentValueOption}
            updatePercentValueOption={setPercentValueOption}
          />
        </div>
        <CustomTable<PeHeatMapTableData>
          pdfTitle="PE Heat Map"
          columns={PeHeatMapColumns}
          id={'pe_heat_map'}
          showToolbar
          data={tableData}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            rowStyle: {
              marginLeft: 0,
            },
            sorting: false,
            draggable: false,
            toolbar: true,
          }}
        />
      </GridItem>
    </>
  );
};

export default PeHeatMapTable;
