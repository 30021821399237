import { FunctionComponent, useState } from 'react';
import { EditableInputProps } from './EditableCell';
import { ClassNameMap } from '@mui/styles';
import { GeneralFunctionType } from '../types/functions.types';
import { Switch, Typography } from '@mui/material';

interface SwitchComponentProps extends EditableInputProps {
  classes: ClassNameMap;
  fn?: GeneralFunctionType;
  columnID: string;
}

const SwitchComponent: FunctionComponent<SwitchComponentProps> = (props) => {
  const { classes } = props;
  const [isChecked, setIsChecked] = useState(props.checked); // Not ideally linked to the database

  const etcStatus = isChecked ? 'no' : 'yes';

  return (
    <div className={classes.switch}>
      <Typography>No</Typography>
      <Switch
        checked={isChecked}
        onChange={() => {
          setIsChecked((prev) => !prev);
          props.setValue(etcStatus);
        }}
      />
      <Typography>Yes</Typography>
    </div>
  );
};

export default SwitchComponent;
