import { KidsData } from '../editor/KidsEditor';
import { KidsDataPdfVersion } from '../types/KidsData.types';

export function convertToPdfVersion(data: KidsData): KidsDataPdfVersion {
  let templateContent = null;
  if (data.templateId && data.templateType) {
    templateContent = {
      id: data.templateId,
      type: data.templateType,
      text: data.content,
    };
    templateContent = '{' + JSON.stringify(templateContent) + '}';
  }

  return {
    format_options: data.formatOptions,
    manco_id: data.mancoId,
    tag: data.tag,
    content: templateContent ? templateContent : data.content,
    version: data.version,
    edited_by_name: data.editedByName,
    edit_timestamp: data.editTimestamp,
    edited_by: data.editedBy,
    fund_name: data.fundName,
    comment_id: data.commentId,
    field_id: data.fieldId,
    published_by: data.publishedBy,
    kiid_id: data.kiidId,
    kiid_index: data.kiidIndex,
    share_class_name: data.shareClassName,
    share_class: data.shareClass,
    fund_id_string: data.fundIdString,
    has_unresolved_comment: data.hasUnresolvedComment,
    document_language: data.documentLanguage,
    published_by_name: data.publishedByName,
    fund_id: data.fundId,
    is_published: data.isPublished,
  };
}
