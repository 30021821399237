import { BasicLiquidityData } from '../../fundAssetLiquidationTime/table';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import FillCell from '../../../../tables/FillCell';
import React from 'react';

export type LiquidityScenarioTypes =
  | 'Business as Usual'
  | 'Stressed'
  | 'Super stressed';
const scenarios = [
  'Business as Usual',
  'Stressed',
  'Super stressed',
  'Credit Crisis Sept08',
  'EU Sov Debt Crisis Jul11',
];
export interface MarketBasedPortfolioLiquidationTime
  extends BasicLiquidityData {
  scenario: LiquidityScenarioTypes;
}

export const mapScenarioToColor = (scenario: LiquidityScenarioTypes) => {
  switch (scenario) {
    case 'Business as Usual':
      return mainColors.mainBlue;
    case 'Stressed':
      return mainColors.Alert;
    case 'Super stressed':
      return mainColors.secondaryRed;
    default:
      return mainColors.mainBlue;
  }
};

export const marketBasedColumns: CustomColumn<MarketBasedPortfolioLiquidationTime>[] =
  [
    {
      title: 'Scenario',
      field: 'scenario',
      cellStyle: {
        padding: 0,
        paddingLeft: '0.62rem',
        paddingRight: '0.62rem',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '0 - 1',
      field: 'zeroToOne',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.zeroToOne}
        />
      ),
      pdfRenderType: 'FillBox',
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '2 - 7',
      pdfRenderType: 'FillBox',
      field: 'twoToSeven',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.twoToSeven}
        />
      ),

      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },

      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '8 - 30',
      pdfRenderType: 'FillBox',
      field: 'eightToThirty',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.eightToThirty}
        />
      ),
      cellStyle: {
        padding: 0,

        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '31 - 90',
      pdfRenderType: 'FillBox',
      field: 'thirtyOneToNinety',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.thirtyOneToNinety}
        />
      ),
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '91 - 180',
      pdfRenderType: 'FillBox',
      field: 'ninetyOneToOneEighty',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.ninetyOneToOneEighty}
        />
      ),
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '181 - 364',
      pdfRenderType: 'FillBox',
      field: 'OneEightOneToThreeSixFour',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.OneEightOneToThreeSixFour}
        />
      ),
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '> 365',
      pdfRenderType: 'FillBox',
      field: 'moreThanAYear',
      render: (rowData) => (
        <FillCell
          cellColor={mapScenarioToColor(rowData.scenario)}
          value={rowData.moreThanAYear}
        />
      ),
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      sorting: false,
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

export function buildMarketBasedTable(data: any[]) {
  if (!data.length) return [];
  try {
    const fundData = data[0].liquidation_risk_report.fund_data;
    return fundData.map((scenario: any, index: number) => {
      const { external_name, liquidation_data } = scenario;
      const [
        zero_one,
        two_seven,
        eight_thirty,
        thirtyone,
        ninetyone,
        oneeightone,
        gt365,
      ] = liquidation_data;

      return {
        scenario: scenarios[index],
        zeroToOne: zero_one,
        twoToSeven: two_seven,
        eightToThirty: eight_thirty,
        thirtyOneToNinety: thirtyone,
        ninetyOneToOneEighty: ninetyone,
        OneEightOneToThreeSixFour: oneeightone,
        moreThanAYear: gt365,
      };
    });
  } catch (err) {
    console.error('There was a problem building the market table: ', err);
    return [];
  }
}
