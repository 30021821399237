import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import ESGIconRenderer from '../../../../images/esgIcons/EsgIconRenderer';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import {
  formatESGWithUnit,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface KPIOverviewProps {
  data: any;
  currentPage: string | null;
  rulesData: any;
}

interface KPIPanelProps {
  data: any;
  kpiKey: string;
  currentPage: string | null;
  rulesData: any;
}

const useKPIOverviewStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  kpiPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.25rem',
    borderColor: 'black',
  },
  kpiMetricScore: {
    display: 'flex-column',
    padding: '1.88rem',
    justifyContent: 'center',
    width: '25%',
  },
  tablesContainer: {
    display: 'flex-column',
    justifyContent: 'center',
    width: '75%',
  },
  metricTable: {
    // width: '100%',
    padding: 8,
    borderStyle: 'solid',
    borderRadius: 25,
    borderColor: greys.grey200,
    marginBottom: '0.62rem',
  },
  qualitativeTable: {
    // width: '100%',
    padding: 8,
    borderStyle: 'solid',
    borderRadius: 25,
    borderColor: greys.grey200,
  },
}));

function mapPageToKey(currentPage: string | null) {
  switch (currentPage) {
    case 'environmental-esg':
      return 'environment';
    case 'social-esg':
      return 'social';
    case 'corporate-governance-esg':
      return 'governance';
    default:
      return 'esg';
  }
}

function buildKPIPanels(
  data: any,
  currentPage: string | null,
  rulesData: any,
): ReactElement {
  if (!currentPage) return <></>;
  if (!data.data.length) return <></>;
  if (!('raptor_esg_page' in data.data[0])) return <></>;
  if (!(mapPageToKey(currentPage) in data.data[0].raptor_esg_page))
    return <></>;
  if (!rulesData.data.length) return <></>;
  const currentPageData =
    data.data[0].raptor_esg_page[mapPageToKey(currentPage)];

  const panels = Object.keys(currentPageData).map((key: any) => {
    return (
      <KPIPanel
        data={currentPageData[key]}
        kpiKey={key}
        currentPage={currentPage}
        rulesData={rulesData}
        key={key}
      />
    );
  });

  return <>{panels}</>;
}

const StatusBox = ({ status }: { status: Status }) => (
  <div
    style={{
      padding: '0.50rem',
      borderRadius: 8,
      border: `1px solid ${mapStatusToColor(status)}`,
      backgroundColor: mapStatusToColor(status),
      // maxWidth: '25.00rem'
      textAlign: 'center',
    }}
  >
    <Typography
      variant="h3"
      style={{
        transform: 'translateY(1px)',
        color: 'white',
        fontSize: '1.12rem',
      }}
    >
      {status}
    </Typography>
  </div>
);

interface IKPITableData {
  ruleCode: string;
  // name: string;
  description: string;
  value: number | string;
  limit: number | string;
  status: string;
}

function buildTableColumns(
  formatValue: boolean,
): CustomColumn<IKPITableData>[] {
  const KPIOverviewColumns: CustomColumn<IKPITableData>[] = [
    {
      title: 'Name',
      field: 'ruleCode',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      width: '8%',
    },
    {
      title: 'Description',
      field: 'description',
      width: '60%',
    },
    {
      title: 'Value',
      field: 'value',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      width: '12%',
    },
    {
      title: 'Limit',
      field: 'limit',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      width: '12%',
    },
    {
      title: 'Status',
      field: 'status',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      render: (rowData: IKPITableData) => (
        <StatusBox status={rowData.status as Status} />
      ),
      width: '8%',
    },
  ];
  return KPIOverviewColumns;
}

function getRuleLimit(ruleId: any) {
  const rule = {
    rule_id: null,
    limit: 0,
    bound: null,
  };
  // temporarily commented out so all are null
  // rulesLimits.forEach((element: any) => {
  //     if (element.rule_id === ruleId) {
  //         rule = element
  //     }
  // })
  return rule;
}

function buildTableData(
  data: any,
  rulesData: any,
  metricOrQualitative: 'metric' | 'qualitative',
): IKPITableData[] {
  if (!rulesData.data.length) return [];
  const dataKey =
    metricOrQualitative === 'metric'
      ? 'metric_breakdown'
      : 'qualitative_breakdown';
  const optionData = data[dataKey];
  const rulesLookup = rulesData.data[0];
  const tableData: IKPITableData[] = [];

  optionData.forEach((value: any) => {
    if (value[0] in rulesLookup && 'description' in rulesLookup[value[0]]) {
      const currentRuleLimit = getRuleLimit(value[0]);
      let status = 'N/A';

      // Allow for case where value is a number and not a string
      const convertedValue =
        typeof value[1] === 'string'
          ? parseFloat(value[1].replace('%', ''))
          : value[1];

      if (
        currentRuleLimit.limit &&
        currentRuleLimit.limit > 0 &&
        currentRuleLimit.bound === 'lower'
      ) {
        if (currentRuleLimit.limit < convertedValue) status = 'Pass';
        else if (currentRuleLimit.limit * 0.8 < convertedValue)
          status = 'Alert';
        else status = 'Fail';
      } else if (
        currentRuleLimit.limit &&
        currentRuleLimit.limit > 0 &&
        currentRuleLimit.bound === 'upper'
      ) {
        if (currentRuleLimit.limit > convertedValue) status = 'Pass';
        else if (currentRuleLimit.limit * 1.2 > convertedValue)
          status = 'Alert';
        else status = 'Fail';
      }

      if (metricOrQualitative === 'qualitative') {
        tableData.push({
          ruleCode: value[0],
          description: rulesLookup[value[0]].description,
          value: formatESGWithUnit(value[1], rulesLookup[value[0]].unit),
          limit: currentRuleLimit.limit
            ? `${roundNumberToTwoDecimalPlaces(currentRuleLimit.limit)} %`
            : '-- %',
          status: status,
        });
      } else {
        tableData.push({
          ruleCode: value[0],
          description: rulesLookup[value[0]].description,
          value: formatESGWithUnit(value[1], rulesLookup[value[0]].unit),
          limit: currentRuleLimit.limit
            ? `${roundNumberToTwoDecimalPlaces(currentRuleLimit.limit)}`
            : '--',
          status: status,
        });
      }
    }
  });

  return tableData;
}

const KPIPanel: FC<KPIPanelProps> = ({
  data,
  kpiKey,
  currentPage,
  rulesData,
}) => {
  const classes = useKPIOverviewStyles();
  const metricTableData = buildTableData(data, rulesData, 'metric');
  const qualitativeTableData = buildTableData(data, rulesData, 'qualitative');
  const metricColumns = buildTableColumns(true);
  const qualitativeColumns = buildTableColumns(false);

  return (
    <GridItem xs={12} card>
      <div className={classes.kpiPanel}>
        <div className={classes.kpiMetricScore}>
          <ESGIconRenderer iconKey={kpiKey} />
          <Typography
            variant="h3"
            style={{
              marginTop: '2.50rem',
              color: greys.grey400,
              fontSize: '1.56rem',
            }}
          >
            {kpiKey} Score
          </Typography>
          <Typography
            variant="h3"
            style={{
              marginTop: '0.31rem',
              color: mainColors.mainBlue,
              fontSize: '1.88rem',
            }}
          >
            {roundNumberToTwoDecimalPlaces(data.metric)}
          </Typography>
          <Typography
            variant="h3"
            style={{
              marginTop: '0.62rem',
              color: greys.grey400,
              fontSize: '1.56rem',
            }}
          >
            Qualitative Indicator Score
          </Typography>
          <Typography
            variant="h3"
            style={{
              marginTop: '0.31rem',
              color: mainColors.mainBlue,
              fontSize: '1.88rem',
            }}
          >
            {roundNumberToTwoDecimalPlaces(data.qualitative)} / 100.00
          </Typography>
        </div>
        <div className={classes.tablesContainer}>
          <div className={classes.metricTable}>
            <CustomTable<IKPITableData>
              columns={metricColumns}
              showToolbar
              data={metricTableData}
              title={'Metric Indicators'}
              options={{
                paging: false,
                search: false,
                exportButton: false,
              }}
            />
          </div>
          <div className={classes.qualitativeTable}>
            <CustomTable<IKPITableData>
              columns={qualitativeColumns}
              showToolbar
              data={qualitativeTableData}
              title={'Qualitative Indicators'}
              options={{
                paging: false,
                search: false,
                exportButton: false,
              }}
            />
          </div>
        </div>
      </div>
    </GridItem>
  );
};

const KPIOverview: FC<KPIOverviewProps> = ({
  data,
  currentPage,
  rulesData,
}) => {
  const KpiPanels = buildKPIPanels(data, currentPage, rulesData);

  return (
    <>
      <GridItem xs={12} card>
        <Typography variant="h2" style={{ padding: '1.25rem' }}>
          KPI Overview
        </Typography>
      </GridItem>
      {KpiPanels}
    </>
  );
};

export default KPIOverview;
