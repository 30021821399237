import React, { FC } from 'react';
import { EditorTileProps } from '../../kids/editor/editorTiles/KidsEditorColumn.component';
import KidsEditorGridItem from '../../kids/layout/KidsEditorGridItem.component';
import { greys, mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import UtilityButtons from '../buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  sriStatusContainer: {
    borderColor: 'black',
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    marginLeft: '0.62rem',
    fontSize: '0.94rem',
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '6.25rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '0.62rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
  riskWorkdingContainerLeft: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    textAlign: 'left',
    fontWeight: 500,
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
  },
  riskWorkdingContainerRight: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    textAlign: 'right',
    fontWeight: 500,
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
  },
  statusBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '0.62rem',
  },
  sriStatusBoxSelected: {
    backgroundColor: mainColors.controlButtonBlue,
    color: 'white',
    fontWeight: 700,
    boxSizing: 'border-box',
    padding: '0.62rem',
    width: '100%',
    textAlign: 'center',
    height: '6.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: mainColors.controlButtonBlue,
    fontSize: '1.88rem',
  },
  sriStatusBox: {
    backgroundColor: 'white',
    color: mainColors.mainBlue,
    fontWeight: 600,
    boxSizing: 'border-box',
    padding: '0.62rem',
    width: '100%',
    textAlign: 'center',
    height: '6.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'black',
  },
}));

const SRIChartEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  moveField,
  deleteField,
}) => {
  const classes = useStyles();
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the element
  const fieldId = priipsKidsElement.fieldId;
  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.editorContainer}>
        <div className={classes.sriStatusContainer}>
          <SriStatusBoxTable sri={parseInt(priipsKidsElement.content)} />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
          disableDelete
          disableDeleteMessage="You cannot delete the SRI chart."
        />
      </div>
    </KidsEditorGridItem>
  );
};

const SriStatusBoxTable: React.FC<{ sri: number }> = ({ sri }) => {
  const classes = useStyles();
  const iterator = [...Array(7).keys()].map((x) => x + 1);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className={classes.riskWorkdingContainerLeft}>
          <span>Lower Risk</span>
          <br />
          <span>Typically Lower Rewards</span>
        </div>
        <div className={classes.riskWorkdingContainerRight}>
          <span>Higher Risk</span>
          <br />
          <span>Typically Higher Rewards</span>
        </div>
      </div>
      <div className={classes.statusBoxContainer}>
        {iterator.map((i) => {
          return (
            <div
              key={i}
              className={
                sri === i ? classes.sriStatusBoxSelected : classes.sriStatusBox
              }
            >
              <span>{i}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SRIChartEditorTile;
