import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, ReactElement, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { RaptorTheme } from '../../../../styling/theme';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { BASE_URL } from '../../../../utilities/requestClient';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import FileCard from '../Library/FileCard';
import { Button, Grid, Typography } from '@mui/material';

interface FileSelectorsProps {
  filesData: DataObject;
  selectedFolder: string;
}

function getOptions(data: DataObject): string[] {
  if (!data) return [];
  if (!data.data) return [];
  if (!data.data.length) return [];
  return data.data[0];
}

function FileSelectors(props: FileSelectorsProps): ReactElement {
  const { filesData, selectedFolder } = props;
  const fileOptions = getOptions(filesData);

  return fileOptions.length > 0 ? (
    <>
      <Grid
        container
        spacing={2}
        style={{ padding: 8, width: '33%', height: '100%' }}
      >
        {fileOptions.map((el) => (
          <FileCard
            predefinedUrl={`${BASE_URL}raptor_library/${selectedFolder}/${el}`}
            key={el}
            title={el}
            prefix={''}
          />
        ))}
      </Grid>
    </>
  ) : (
    <></>
  );
}

interface FolderSelectorsProps {
  foldersData: DataObject;
  setSelectedFolder: Dispatch<any>;
  selectedFolder: string;
}

const useDisplayOptionsButtonsStyles = makeStyles<RaptorTheme>((theme) => ({
  container: {
    lineHeight: 25,
  },
  contained: {
    height: '1.88rem',
    background: 'white',
    borderRadius: 8,
  },
  label: {
    flexDirection: 'column',

    alignItems: 'start',
  },
  buttonText: {
    colorPrimary: theme.palette.common.white,
  },
}));

function FolderSelectors(props: FolderSelectorsProps): ReactElement {
  const { foldersData, setSelectedFolder, selectedFolder } = props;
  const folderOptions = getOptions(foldersData);

  return (
    <GridItem
      xs={12}
      card
      key="folder-selectors"
      item
      cardStyle={{
        padding: 8,
        width: '100%',
        minHeight: '12.50rem',
      }}
    >
      <Typography variant="h2" style={{ marginLeft: '0.62rem' }}>
        Select a Library Section Below.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          marginTop: '0.62rem',
          marginBottom: '0.62rem',
          marginLeft: '0.62rem',
          maxWidth: '18.75rem',
        }}
      >
        {folderOptions.map((el) => (
          <Button
            variant="contained"
            onClick={() => setSelectedFolder(el)}
            color={el === selectedFolder ? 'secondary' : 'primary'}
            key={el}
            style={{
              marginBottom: '0.62rem',
            }}
          >
            <Typography
              variant="h3"
              color="primary"
              style={{
                color: 'white',
              }}
            >
              {el}
            </Typography>
          </Button>
        ))}
      </div>
    </GridItem>
  );
}

function RaptorLibrary(): ReactElement {
  const [selectedFolder, setSelectedFolder] = useState('');
  const foldersData = useFetchData({
    url: 'raptor_library',
    keyName: 'raptor_library_folders',
    makeFetch: true,
  });

  const filesData = useFetchData({
    url: `raptor_library/${selectedFolder}`,
    keyName: `raptor_library_folder_${selectedFolder}`,
    makeFetch: selectedFolder !== '',
  });

  return (
    <GeneralComponentErrorShield dataObjects={[foldersData]}>
      <Grid container spacing={2} style={{ padding: 8, width: '33%' }}>
        <FolderSelectors
          foldersData={foldersData}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
        />
      </Grid>
      <FileSelectors filesData={filesData} selectedFolder={selectedFolder} />
    </GeneralComponentErrorShield>
  );
}

export default RaptorLibrary;
