import React, { FormEvent, useState } from 'react';
import requestClient from '../../../../utilities/requestClient';
import GridItem from '../../../layout/GridComponents/GridItem';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../styling/theme';
import { formatESGWithUnit } from '../../../../utilities/numberFormatters';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.31rem',
    width: '100%',
    padding: '0.62rem 0.94rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  description: {
    fontSize: '0.75rem',
    fontWeight: 400,
    margin: '0.31rem 0 0 0',
    color: mainColors.mainBlue,
  },
  inputFormContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '0.62rem',
  },
  input: {
    width: '18.75rem',
    height: '1.88rem',
    padding: '0.31rem 0.62rem',
    border: `1px solid ${mainColors.mainBlue}`,
    fontSize: '0.88rem',
    fontWeight: 400,
    margin: '0.62rem 0',
  },
  button_valid: {
    all: 'unset',
    width: 'fit-content',
    height: '1.88rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  button_invalid: {
    all: 'unset',
    width: 'fit-content',
    height: '1.88rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: greys.grey200,
    color: 'white',
    userSelect: 'none',
  },
  noResults: {
    fontSize: '0.75rem',
    fontWeight: 500,
    margin: '0.31rem 0 0 0',
    color: mainColors.mainBlue,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '0.62rem',
  },
  tableHeadCell: {
    color: mainColors.mainBlue,
    fontWeight: 600,
    fontSize: '0.88rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
  },
  responseMessage: {
    fontSize: '0.75rem',
    fontWeight: 500,
  },
}));

interface PreTradeCheckerProps {
  fundId: string;
  positionDate: string | undefined;
}

interface Result {
  isin: string;
  name: string;
  indicators: {
    indicator: string;
    value: number;
    unit: string;
  }[];
}

const getAuthMessageColor = (authStatus: 'loading' | 'success' | 'error') => {
  switch (authStatus) {
    case 'loading':
      return greys.grey600;
    case 'success':
      return mainColors.Pass;
    case 'error':
      return mainColors.Fail;
  }
};

const PreTradeChecker: React.FC<PreTradeCheckerProps> = ({
  fundId,
  positionDate,
}) => {
  const classes = useStyles();
  const client = requestClient();

  const [isin, setIsin] = useState('');
  const [results, setResults] = useState<Result[]>([]);

  const [responseStatus, setResponseStatus] = useState<
    'loading' | 'success' | 'error'
  >('loading');
  const [responseMessage, setResponseMessage] = useState<string>('');

  const prependResults = (newResult: Result) => {
    setResults((prevResults) => [newResult, ...prevResults]);
  };

  const isValidIsin = (isin: string): boolean => {
    return isin.length === 12;
  };

  const handleCheck = (event: FormEvent<HTMLFormElement>, ISIN: string) => {
    event.preventDefault();
    setResponseStatus('loading');
    setResponseMessage('Loading...');
    client
      .get(`/pre_trade_checker/${fundId}/${positionDate}/${ISIN}`)
      .then((response) => {
        prependResults({ isin: ISIN, ...response.data });
        setResponseStatus('success');
        setResponseMessage('Success!');
        setTimeout(() => {
          setResponseStatus('loading');
          setResponseMessage('');
        }, 2000);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setResponseStatus('error');
          setResponseMessage('ISIN not found.');
        } else {
          setResponseStatus('error');
          setResponseMessage(
            'An unknown error occured. Please contact RiskSystem support.',
          );
        }
      });
  };

  return (
    <GridItem xs={12} card className={classes.root}>
      <div className={classes.title}>Pre-Trade Checker</div>
      <div className={classes.description}>
        Insert a valid ISIN and click to run. The result will be added to the
        table below.
      </div>
      <form
        className={classes.inputFormContainer}
        onSubmit={(event: FormEvent<HTMLFormElement>) =>
          handleCheck(event, isin)
        }
      >
        <input
          type="text"
          placeholder="ISIN"
          value={isin}
          onChange={(e) => setIsin(e.target.value)}
          className={classes.input}
        />
        {isValidIsin(isin) ? (
          <button type="submit" className={classes.button_valid}>
            Check
          </button>
        ) : (
          <div className={classes.button_invalid}>Check</div>
        )}
        <div
          className={classes.responseMessage}
          style={{ color: getAuthMessageColor(responseStatus) }}
        >
          {responseMessage}
        </div>
      </form>
      {results.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>ISIN</TableCell>
                <TableCell className={classes.tableHeadCell}>Name</TableCell>
                {results.length > 0 &&
                  results[0].indicators.map((indicator) => (
                    <TableCell
                      key={indicator.indicator}
                      className={classes.tableHeadCell}
                    >
                      {indicator.indicator}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.isin}</TableCell>
                  <TableCell>{result.name}</TableCell>
                  {result.indicators.map((indicator, index) => (
                    <TableCell key={index}>
                      {formatESGWithUnit(indicator.value, indicator.unit)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.noResults}>No results to show.</div>
      )}
    </GridItem>
  );
};

export default PreTradeChecker;
