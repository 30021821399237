import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { mainColors, RaptorTheme } from '../../../../styling/theme';
import { makeStyles } from '@mui/styles';

export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px lightgrey inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px lightgrey inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
    background: isPinned ? 'white' : undefined,
  };
};

export const useEditableCellStyles = makeStyles<RaptorTheme>((theme) => ({
  container: {
    psoition: 'relative',
    display: 'flex',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  editableCell: {
    height: '100%',
    width: '100%',
    padding: '0.625rem',
    textAlign: 'center',
    border: 'none',
    background: 'var(--pastle-blue-lighter)',
    '&:hover': {
      outline: '1.25px solid blue',
      background: 'var(--pastle-blue-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '1.25px solid blue',
      background: 'var(--pastle-blue-light)',
      cursor: 'pointer',
    },
  },
  updated: {
    background: 'var(--pastle-green)',
    outline: '1.25px solid var(--pastle-green-darker)',
    '&:hover': {
      outline: '1.25px solid green',
      background: 'var(--pastle-green-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '1.25px solid green',
      background: 'var(--pastle-green-light)',
      cursor: 'pointer',
    },
  },
  changed: {
    background: 'var(--pastle-orange)',
    outline: '1.25px solid var(--pastle-orange-darker)',
    '&:hover': {
      outline: '1.25px solid orange',
      background: 'var(--pastle-orange-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '1.25px solid orange',
      background: 'var(--pastle-orange-light)',
      cursor: 'pointer',
    },
  },
  error: {
    background: 'var(--pastle-red)',
    outline: '1.25px solid var(--pastle-red-darker)',
    '&:hover': {
      outline: '1.25px solid red',
      background: 'var(--pastle-red-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '1.25px solid red',
      background: 'var(--pastle-red-light)',
      cursor: 'pointer',
    },
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionBtnContainer: {
    position: 'absolute',
    gap: '1.25px',
    display: 'flex',
    left: 0,
    top: 0,
  },
  actionBtn: {
    backgroundColor: 'white',
    outline: '0.625px solid black',
    border: 'none',
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.156rem',
    borderRadius: '1.25px',
    '& svg': {
      fontSize: '1.2375rem',
      transition: 'filter 0.3s ease',
    },
  },
  confirmBtn: {
    '&:hover': {
      backgroundColor: '#d4edda',
      '& svg': {
        filter: 'brightness(1.2)',
      },
    },
  },
  cancelBtn: {
    '&:hover': {
      backgroundColor: '#f8d7da',
      '& svg': {
        filter: 'brightness(1.2)',
      },
    },
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  tooltip: {
    backgroundColor: 'var(--pastle-red-darker)',
  },
  mainPopper: {
    border: `0.625px solid #e1e4e8`,
    boxShadow: '0 5px 15px rgba(149, 157, 165, 0.2)',
    borderRadius: '0.625rem',
    zIndex: theme.zIndex.modal,
    color: mainColors.mainBlue,
    backgroundColor: 'white',
    minWidth: '18.75rem',
    maxWidth: '25rem',
  },
  mainPopperHeader: {
    borderBottom: '0.625px solid #eaecef',
    padding: '0.9375rem 1.875rem',
    fontWeight: 600,
    fontSize: '0.9375rem',
  },
  filterButton: {
    borderRadius: 3.125,
    padding: '0.3125rem',
    cursor: 'pointer',
    '&:hover svg': {
      color: mainColors.mainBlue,
    },
  },
  isFiltered: {
    background: mainColors.mainBlue,
    color: 'white',
  },
  inputContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));
