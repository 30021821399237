import React, { FC, FormEvent, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { ClassNameMap } from '@mui/styles';
import {
  UserAuthPreferencesData,
  getAuthMessageColor,
} from '../SecuritySettings.component';
import TokenService from '../../../../../services/token-service';
import FastAPIInstance from '../../../../../services/risksystem3-public-api';

const useStyles = makeStyles(() => ({
  settingsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '0.62rem',
    borderRadius: '0.25rem',
  },
  settingsTitle: {
    fontSize: '1.25rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
    marginBottom: '0.62rem',
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.62rem',
  },
  settingsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
  },
  settingsItemTitle: {
    fontSize: '0.94rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  settingsItemDescription: {
    fontSize: '0.75rem',
    color: mainColors.mainBlue,
    fontWeight: 400,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
  },
  settingsItemValueContainer: {
    display: 'flex',
    gap: '0.62rem',
    color: mainColors.mainBlue,
    fontWeight: 600,
    alignItems: 'center',
    fontSize: '1.00rem',
  },
  settingsItemValue: {
    all: 'unset',
    fontSize: '1.00rem',
    fontWeight: 400,
    padding: '0.31rem 0.62rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '6.25rem',
  },
  submitChangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '1.88rem',
  },
  submitEditButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  submitEditButton_inactive: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: greys.grey300,
    color: 'white',
    userSelect: 'none',
  },
}));

// this is where the data will be passed in from the parent component
// be sure to also update the "No settings to display" message to include any changes to these props
interface Props {
  account_invitation_link_valid_period_minutes:
    | UserAuthPreferencesData
    | undefined;
  forget_passwd_invitation_link_valid_period_minutes:
    | UserAuthPreferencesData
    | undefined;
  reloadSettings: () => void;
}

const InvitationSettings: FC<Props> = ({
  account_invitation_link_valid_period_minutes,
  forget_passwd_invitation_link_valid_period_minutes,
  reloadSettings,
}) => {
  const classes: ClassNameMap<string> = useStyles();

  const [accountInvitationPeriod, setAccountInvitationPeriod] =
    React.useState<number | null>(
      account_invitation_link_valid_period_minutes
        ? (account_invitation_link_valid_period_minutes.attribute_value as unknown as number)
        : 60,
    );
  const [forgetPasswordInvitationPeriod, setForgotPasswordInvitationPeriod] =
    React.useState<number | null>(
      forget_passwd_invitation_link_valid_period_minutes
        ? (forget_passwd_invitation_link_valid_period_minutes.attribute_value as unknown as number)
        : 10,
    );

  useEffect(() => {
    if (
      account_invitation_link_valid_period_minutes &&
      (account_invitation_link_valid_period_minutes.attribute_value as unknown as number) !==
        accountInvitationPeriod
    ) {
      setAccountInvitationPeriod(
        account_invitation_link_valid_period_minutes.attribute_value as unknown as number,
      );
    }
    if (
      forget_passwd_invitation_link_valid_period_minutes &&
      (forget_passwd_invitation_link_valid_period_minutes.attribute_value as unknown as number) !==
        forgetPasswordInvitationPeriod
    ) {
      setForgotPasswordInvitationPeriod(
        forget_passwd_invitation_link_valid_period_minutes.attribute_value as unknown as number,
      );
    }
  }, [
    account_invitation_link_valid_period_minutes,
    forget_passwd_invitation_link_valid_period_minutes,
  ]);

  const [
    accountInvitationPeriodRequestStatus,
    setAccountInvitationPeriodRequestStatus,
  ] = React.useState<string>('idle');
  const [
    accountInvitationPeriodRequestMessage,
    setAccountInvitationPeriodRequestMessage,
  ] = React.useState<string>('');

  const [
    forgetPasswordInvitationPeriodRequestStatus,
    setForgotPasswordInvitationPeriodRequestStatus,
  ] = React.useState<string>('idle');
  const [
    forgetPasswordInvitationPeriodRequestMessage,
    setForgotPasswordInvitationPeriodRequestMessage,
  ] = React.useState<string>('');

  const handleChangeAccountInvitationPeriod = (
    e: FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    const validatedPeriod = accountInvitationPeriod === null || accountInvitationPeriod < 1 ? 1 : accountInvitationPeriod;
    setAccountInvitationPeriod(validatedPeriod);
    
    setAccountInvitationPeriodRequestStatus('loading');
    setAccountInvitationPeriodRequestMessage(
      'Updating account invitation period...',
    );
    FastAPIInstance
      .put(
        '/v1/settings/auth_pref_attrs/',
        {
          client_id: TokenService.getUser().user_client_id,
          attribute_name: 'account_invitation_link_valid_period_minutes',
          attributes: {
            attribute_value: validatedPeriod,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setAccountInvitationPeriodRequestStatus('success');
          setAccountInvitationPeriodRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setAccountInvitationPeriodRequestStatus('fail');
          setAccountInvitationPeriodRequestMessage(response.data.detail);
        }
      })
      .catch((error) => {
        setAccountInvitationPeriodRequestStatus('fail');
        setAccountInvitationPeriodRequestMessage(
          error.response?.data?.detail || 'An error occurred while updating the invitation period'
        );
      })
      .finally(() => {
        setTimeout(() => {
          setAccountInvitationPeriodRequestStatus('idle');
          setAccountInvitationPeriodRequestMessage('');
        }, 5000);
      });
    setTimeout(() => {
      setAccountInvitationPeriodRequestStatus('success');
      setAccountInvitationPeriodRequestMessage('Updated successfully.');
    }, 1000);
  };

  const handleChangeForgotPasswordInvitationPeriod = (
    e: FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    const validatedPeriod = forgetPasswordInvitationPeriod === null || forgetPasswordInvitationPeriod < 1 ? 1 : forgetPasswordInvitationPeriod;
    setForgotPasswordInvitationPeriod(validatedPeriod);
    
    setForgotPasswordInvitationPeriodRequestStatus('loading');
    setForgotPasswordInvitationPeriodRequestMessage(
      'Updating forgot password period...',
    );
    FastAPIInstance
      .put(
        '/v1/settings/auth_pref_attrs/',
        {
          client_id: TokenService.getUser().user_client_id,
          attribute_name: 'forget_passwd_invitation_link_valid_period_minutes',
          attributes: {
            attribute_value: validatedPeriod,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setForgotPasswordInvitationPeriodRequestStatus('success');
          setForgotPasswordInvitationPeriodRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setForgotPasswordInvitationPeriodRequestStatus('fail');
          setForgotPasswordInvitationPeriodRequestMessage(response.data.detail);
        }
      })
      .catch((error) => {
        setForgotPasswordInvitationPeriodRequestStatus('fail');
        setForgotPasswordInvitationPeriodRequestMessage(
          error.response?.data?.detail || 'An error occurred while updating the invitation period'
        );
      })
      .finally(() => {
        setTimeout(() => {
          setForgotPasswordInvitationPeriodRequestStatus('idle');
          setForgotPasswordInvitationPeriodRequestMessage('');
        }, 5000);
      });
  };

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.settingsTitle}>Invitation Settings</div>
      {!account_invitation_link_valid_period_minutes &&
      !forget_passwd_invitation_link_valid_period_minutes ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemDescription}>
              No settings to display
            </div>
          </div>
        </div>
      ) : null}
      {account_invitation_link_valid_period_minutes ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Account Invitation Expiry Duration
            </div>
            <div className={classes.settingsItemDescription}>
              The time in minutes that an invitation will be valid for. After
              this time, the invitation will expire and the user will need to be
              re-invited. We recommend 60 minutes.
            </div>
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeAccountInvitationPeriod(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="number"
                  min={1}
                  max={1440}
                  step={1}
                  value={accountInvitationPeriod ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    setAccountInvitationPeriod(value === '' ? null : Number(value));
                  }}
                  className={classes.settingsItemValue}
                />
                Minutes
              </div>
              <div className={classes.submitChangeContainer}>
                {(account_invitation_link_valid_period_minutes.attribute_value as unknown as number) !==
                accountInvitationPeriod ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {accountInvitationPeriodRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(
                        accountInvitationPeriodRequestStatus,
                      ),
                    }}
                  >
                    <h3>{accountInvitationPeriodRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {forget_passwd_invitation_link_valid_period_minutes ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Forgot Password Invitation Expiry Duration
            </div>
            <div className={classes.settingsItemDescription}>
              The time in minutes that a password reset invitation will be valid
              for. After this time, the invitation will expire. We recommend 10
              minutes.
            </div>
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeForgotPasswordInvitationPeriod(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="number"
                  min={1}
                  max={1440}
                  step={1}
                  value={forgetPasswordInvitationPeriod ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    setForgotPasswordInvitationPeriod(value === '' ? null : Number(value));
                  }}
                  className={classes.settingsItemValue}
                />
                Minutes
              </div>
              <div className={classes.submitChangeContainer}>
                {(forget_passwd_invitation_link_valid_period_minutes.attribute_value as unknown as number) !==
                forgetPasswordInvitationPeriod ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {forgetPasswordInvitationPeriodRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(
                        forgetPasswordInvitationPeriodRequestStatus,
                      ),
                    }}
                  >
                    <h3>{forgetPasswordInvitationPeriodRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvitationSettings;
