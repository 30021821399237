import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { FC } from 'react';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { BASE_URL } from '../../../../../utilities/requestClient';
import clsx from 'clsx';

const useDownloadFilesStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    fontSize: '0.75rem',
    padding: '0.19rem 0.62rem',
    gap: '0.31rem',
    color: 'white',
    backgroundColor: mainColors.mainBlue,
    borderRadius: '0.12rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    fontWeight: 600,
    textTransform: 'none',
  },
  icon: {
    fontSize: '1.25rem',
  },
}));

interface DownloadFilesButtonProps {
  fundId?: string;
  fundName?: string;
  mancoId: string;
  buttonText?: string;
  tooltipMessage: string;
  documentLanguage: string;
  className?: string;
  sx?: any;
}

const DownloadFileButton: FC<DownloadFilesButtonProps> = (props) => {
  const classes = useDownloadFilesStyles();
  const {
    fundId,
    fundName,
    mancoId,
    buttonText,
    tooltipMessage,
    documentLanguage,
    className,
  } = props;

  // Define a function for downloading files
  function downloadFile() {
    // Create a form object that will be used for posting the request
    const mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST';
    const params = ['manco_id', 'document_language']; // Add document_language to the params
    // On form submit send the request to the correct endpoint
    mapForm.action =
      BASE_URL +
      `risksystem_pdf_generator/get_all_kid_files/${
        fundName ? fundName + '_documents.zip' : 'all_documents.zip'
      }`;
    // add the fund_id
    const mancoIdInput = document.createElement('input');
    mancoIdInput.type = 'text';
    mancoIdInput.name = 'manco_id';
    mancoIdInput.value = mancoId;
    // Add to the form
    mapForm.appendChild(mancoIdInput);

    // If fund_id is provided then add to the params
    if (fundId) {
      params.push('fund_id');
      const fundIdInput = document.createElement('input');
      fundIdInput.type = 'text';
      fundIdInput.name = 'fund_id';
      fundIdInput.value = fundId;
      // Add to the form
      mapForm.appendChild(fundIdInput);
    }

    // add the document_language
    const documentLanguageInput = document.createElement('input');
    documentLanguageInput.type = 'text';
    documentLanguageInput.name = 'document_language';
    documentLanguageInput.value = documentLanguage;
    // Add to the form
    mapForm.appendChild(documentLanguageInput);

    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify(params);
    // Add to the form
    mapForm.appendChild(paramsInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title={tooltipMessage}
      placement={'top'}
      arrow
      enterNextDelay={500}
      enterDelay={500}
    >
      <Button
        variant="contained"
        className={clsx(classes.root, className)}
        disableElevation={true}
        onClick={downloadFile}
        sx={props.sx}
      >
        {buttonText || ''}
        <DownloadForOfflineIcon className={classes.icon} />
      </Button>
    </Tooltip>
  );
};

export default DownloadFileButton;
