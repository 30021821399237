import { CSSProperties } from 'react';
import { mainColors } from '../../../../../../../styling/theme';
import { hexToRGBA } from '../../../../../../../utilities/colorUtilities';
import { CustomColumn } from '../../../../../../../types/components/tables/tableTypes';

export interface ExceedanceSecondaryData {
  name: string;
  start: number;
  end: number;
  change: number;
  returnPc: number;
}

export type ExceedanceSecondaryServerHeaderLine = [
  'Name',
  'Start',
  'End',
  'Change',
  'Return %',
  'Min',
  'Max',
];

export type ExceedanceSecondaryServerDataLines = [
  string,
  number,
  number,
  number,
  number,
  number,
  number,
  string,
];

export type ExceedanceSecondaryServerData =
  | ExceedanceSecondaryServerHeaderLine
  | ExceedanceSecondaryServerDataLines;

const centerStyles: {
  headerStyle: CSSProperties;
  cellStyle: CSSProperties;
} = {
  headerStyle: {
    textAlign: 'center',
    color: mainColors.mainBlue,
    backgroundColor: hexToRGBA(mainColors.mainBlue, 0.1),
    padding: '0.50rem',
  },
  cellStyle: {
    textAlign: 'center',
  },
};
export function generateExceedanceSecondaryColumns(): CustomColumn<ExceedanceSecondaryData>[] {
  return [
    {
      title: 'Name',
      field: 'name',
      headerStyle: {
        color: mainColors.mainBlue,
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.1),
        padding: '0.50rem',
        textAlign: 'center',
      },
    },
    {
      title: 'Start',
      field: 'start',
      ...centerStyles,
    },
    {
      title: 'End',
      field: 'end',
      ...centerStyles,
    },
    {
      title: 'Change',
      field: 'change',
      ...centerStyles,
    },
    {
      title: 'Return %',
      field: 'returnPc',
      ...centerStyles,
    },
  ];
}

export function generateExceedanceSecondaryData(
  data: ExceedanceSecondaryServerData[],
) {
  if (!data || !data.length) return [];
  const dataForUse = [...data];

  dataForUse.shift();

  try {
    return dataForUse.map((el: ExceedanceSecondaryServerData) => {
      const [name, start, end, change, returnPc] =
        el as ExceedanceSecondaryServerDataLines;
      return {
        name,
        start,
        end,
        change,
        returnPc,
      };
    });
  } catch (error) {
    return [];
  }
}
