import React from 'react';
import { greys, mainColors } from '../../../styling/theme';
import RaptorLogo from '../../../images/RaptorLogo';
import NavContainer from './NavContainer.component';
import LogoRender from '../sidebar/LogoRender/LogoRender.component';
import UserOptionsMenu from './UserOptionsMenu.component';
import { useSelector } from 'react-redux';
import {
  clientNameSelector,
  configNameSelector,
} from '../../../redux/auth/selectors';
import { useLocation, useNavigate } from 'react-router';
import { zIndexes } from '../../../styling/zIndexes';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBackIos } from '@mui/icons-material';
import { openingRouteSelector } from '../../../redux/pages/selectors';

const useStyles = makeStyles({
  topBarRoot: {
    height: '3.75rem',
    width: '100vw',
    padding: '0.62rem 1.25rem 0.62rem 0.31rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${greys.grey100}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: zIndexes.topBar,
  },
  raptorLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1.25rem 0.31rem 0.62rem',
    cursor: 'pointer',
  },

  userItems: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: '0 0.31rem',
    gap: '0.62rem',
    paddingLeft: '0.62rem',
  },

  topBarContents: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0.62rem',
  },

  topBarContentsBackButton: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '1.25rem',
    },
    '&:hover': {
      backgroundColor: greys.grey100,
    },
  },
});

const TopBar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const clientName = useSelector(clientNameSelector);
  const configName = useSelector(configNameSelector);
  const openingRoute = useSelector(openingRouteSelector)!;

  const { pathname } = useLocation();
  const [_, section] = pathname.split('/');

  const redirectDashboard = () => {
    openingRoute ? navigate(`/${openingRoute}`) : navigate(`/dashboard`);
  };

  return (
    <>
      <div className={classes.topBarRoot}>
        <div className={classes.raptorLogo} onClick={redirectDashboard}>
          <RaptorLogo />
        </div>
        {section === 'documentation' ? (
          <div className={classes.topBarContents}>
            <div
              className={classes.topBarContentsBackButton}
              onClick={redirectDashboard}
            >
              <ArrowBackIos /> Dashboard
            </div>
          </div>
        ) : (
          <NavContainer />
        )}
        <div className={classes.userItems}>
          <LogoRender
            clientName={(clientName as string) || 'mersenne'}
            configName={(configName as string) || 'mersenne_funds'}
          />
          <UserOptionsMenu />
        </div>
      </div>
    </>
  );
};

export default React.memo(TopBar);
