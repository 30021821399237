import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import React, { Dispatch, FC } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

interface UtilityButtonsProps {
  moveField: (index: number, direction: 'up' | 'down') => void;
  deleteField: (fieldId: string) => void;
  fieldId: string;
  index: number;
  isTemplateField?: boolean;
  disableDelete?: boolean;
  disableDeleteMessage?: string;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    paddingLeft: '0.62rem',
    // gap: '1.25rem',
    marginTop: '1.88rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  confirmDeleteContainer: {
    backgroundColor: 'white',
    // border: '1px solid black',
    // borderRadius: '6px',
    width: '12.50rem',
  },
  confirmDeleteText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  confirmationButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  dialogTitle: {
    fontColor: mainColors.Fail,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmDeleteDialogBoxProps {
  deleteField: (fieldId: string) => void;
  setDeleteButtonIsPressed: Dispatch<boolean>;
  fieldId: string;
  deleteButtonIsPressed: boolean;
}

const ConfirmDeleteDialogBox: FC<ConfirmDeleteDialogBoxProps> = (props) => {
  const {
    deleteField,
    fieldId,
    setDeleteButtonIsPressed,
    deleteButtonIsPressed,
  } = props;
  return (
    <Dialog
      open={deleteButtonIsPressed}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setDeleteButtonIsPressed(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{'Delete Field Permanently?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to delete this field?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteButtonIsPressed(false)}>Cancel</Button>
        <Button
          onClick={() => {
            setDeleteButtonIsPressed(false);
            deleteField(fieldId);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UtilityButtons: FC<UtilityButtonsProps> = (props) => {
  const {
    moveField,
    fieldId,
    index,
    deleteField,
    isTemplateField,
    disableDelete,
    disableDeleteMessage,
  } = props;

  const [deleteButtonIsPressed, setDeleteButtonIsPressed] =
    React.useState<boolean>(false);

  function handleDeleteButtonPress() {
    setDeleteButtonIsPressed(true);
  }

  const classes = useStyles();
  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        arrow
        title="Move Field Up"
      >
        <ArrowCircleUpIcon
          sx={{
            fontSize: '1.75rem',
            color: mainColors.mainBlue,
            borderRadius: 1,
            padding: '0.12rem',
            cursor: 'pointer',
            '&:hover': {
              color: mainColors.mainBlue_lighter,
            },
          }}
          onClick={() => moveField(index, 'up')}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        arrow
        title={
          isTemplateField
            ? 'Deletion is currently not possible for template fields'
            : disableDeleteMessage
              ? disableDeleteMessage
              : 'Delete Field'
        }
      >
        <div
          style={{
            marginBottom: '-0.31rem',
          }}
        >
          {deleteButtonIsPressed && (
            <ConfirmDeleteDialogBox
              deleteField={deleteField}
              fieldId={fieldId}
              setDeleteButtonIsPressed={setDeleteButtonIsPressed}
              deleteButtonIsPressed={deleteButtonIsPressed}
            />
          )}
          <DeleteForeverIcon
            sx={{
              fontSize: '1.75rem',
              color:
                isTemplateField || disableDelete
                  ? greys.grey600
                  : mainColors.Fail,
              borderRadius: 1,
              padding: '0.12rem',
              cursor: 'pointer',
              '&:hover': {
                color:
                  isTemplateField || disableDelete
                    ? greys.grey600
                    : mainColors.Fail_darker,
              },
            }}
            onClick={
              isTemplateField || disableDelete
                ? undefined
                : handleDeleteButtonPress
            }
          />
        </div>
      </Tooltip>
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Move Field Down"
      >
        <ArrowCircleDownIcon
          sx={{
            fontSize: '1.75rem',
            color: mainColors.mainBlue,
            borderRadius: 1,
            padding: '0.12rem',
            cursor: 'pointer',
            '&:hover': {
              color: mainColors.mainBlue_lighter,
            },
          }}
          onClick={() => moveField(index, 'down')}
        />
      </Tooltip>
    </div>
  );
};

export default UtilityButtons;
