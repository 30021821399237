import React, { FunctionComponent, useMemo, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import MancoStaticExposureTable from './subComponents/MancoStaticExposureTable.component';
import MancoAssetGrossExposure from './subComponents/MancoAssetGrossExposure.component';
import MancoExposureBarChart from './subComponents/MancoExposureBarChart.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import MancoCountryExposureBarChart from './subComponents/MancoCountryExposureBarChart.component';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import UltraTable, {
  TableData,
  UltraTablePresetConfig,
} from '../../../tables/ultraTable/UltraTable';
import { ALL_EXPOSURE_COLUMNS } from '../../../tables/ultraTable/data/presetColumnData';
import { ultraTableStyles } from '../../../tables/ultraTable/styles/ultratable.styles';
import {
  buildColumns,
  buildData,
} from '../../../tables/ultraTable/utils/buildUltraTableData';
import {
  ALL_EXPOSURE_PRESETS,
  GeneralPreset,
} from '../../../tables/ultraTable/data/ultrapresetColumnData';
import UnderlyingPositionTable from '../../sharedComponents/UnderlyingPositionTable';
import { Position } from '../../../tables/ultraTable/types/position.types';
import { UltraTablePreset } from '../../../tables/ultraTable/types/presets.types';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { UltraTableWrapperProps } from '../../sharedComponents/ExposureFund';
import { EditableFields } from '../../../tables/ultraTable/types/editable.types';

const MancoExposure: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const classes = ultraTableStyles();

  const mancoExposureData = useFetchData({
    url: `manco_gem/${positionDate}`,
    keyName: `manco_exposure_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[mancoExposureData]}
      customLoadingMessages={[
        'Loading Exposure Data...',
        'This May Take Few Moments',
      ]}
      customErrorMessage="No positions available for this date"
    >
      <GridItem
        xs={5}
        style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      >
        <MancoAssetGrossExposure data={mancoExposureData} />
        <MancoStaticExposureTable dataForRender={mancoExposureData} />
      </GridItem>
      <MancoExposureBarChart data={mancoExposureData} />
      <MancoCountryExposureBarChart data={mancoExposureData} />

      <HedgePositionsTableWrapper
        originalData={mancoExposureData}
        fundId={'manco'}
        fundName={'manco'}
        classes={classes}
      />
    </GeneralComponentErrorShield>
  );
};

export default MancoExposure;

//This is only here to deal with the render more hooks issue due to the buildDataFn having a context selector hook
const HedgePositionsTableWrapper: FunctionComponent<UltraTableWrapperProps> = ({
  originalData,
  fundId,
  fundName,
  classes,
}) => {
  const [tableAggregate, setTableAggregate] = useState(true);

  const data = buildData(originalData.data[0], tableAggregate);
  const columns = buildColumns(tableAggregate, ALL_EXPOSURE_COLUMNS, classes);

  const tableData: TableData<EditablePosition> = {
    columns,
    data,
  };

  // TODO: Refactor This
  const totalAssetClasses = useMemo(() => {
    const assetClasses: string[] = [];
    tableData.data.forEach((item) => {
      if (item?.asset_class && !assetClasses.includes(item.asset_class)) {
        assetClasses.push(item.asset_class);
      }
    });
    return assetClasses;
  }, [tableData]);

  const presets: UltraTablePreset[] = [];
  totalAssetClasses.forEach((asset) => {
    const preset = ALL_EXPOSURE_PRESETS.find((preset) => preset.name === asset);
    if (preset) {
      presets.push(preset);
    }
  });

  const ultraTablePresets: UltraTablePresetConfig = {
    default: GeneralPreset,
    allPresets: presets,
    display: 'buttons',
  };

  const positionDate = originalData.data[0].selected_position_date;

  return (
    <UltraTable<EditablePosition>
      title="Exposure Data"
      tableData={tableData}
      getRowCanExpand={() => true}
      pdfConfig={{
        pdfIdentifier: `${fundId}_exposure_main`,
        pdfTitle: `Exposure Data - ${fundName}`,
        pdfExportGroupName: 'exposure_page',
        pdfExportGroupOrder: 5,
        pdfFileName: `exposure-${fundId}-${
          positionDate || formatDateForCheckingState(new Date())
        }`,
      }}
      actionBtns={[
        <FormControlLabel
          control={
            <Switch
              checked={tableAggregate}
              onChange={() => setTableAggregate((prev) => !prev)}
              className={classes.switch}
            />
          }
          label={'Aggregate Table'}
          className={classes.switchContainer}
        />,
      ]}
      columnSelector={{ select: true, group: true }} // hacky solution
      positionDate={positionDate}
      nestedComponent={UnderlyingPositionTable}
      presets={ultraTablePresets}
      mancoHack={true}
    />
  );
};

export interface EditablePosition extends Position, EditableFields {}
