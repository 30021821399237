import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { forwardRef } from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { cn } from '../../utilities/cn';
import { Tooltip } from '@mui/material';
import { ultraTableStyles } from '../tables/ultraTable/styles/ultratable.styles';

interface GenericDropdownProps<T> {
  className?: string;
  label?: string;
  dropdownButton?: boolean;
  placeholder?: string;
  data: T[];
  getDisplayValue: (item: T) => string;
  //   getDisplayIcon?: (item: T) => any;
  selected: T[] | T;
  setSelected: (value: T[] | T) => void;
  tooltip?: string;
}

const GenericDropdown = forwardRef<HTMLDivElement, GenericDropdownProps<any>>(
  <T,>(props: GenericDropdownProps<T>, ref: React.Ref<HTMLDivElement>) => {
    const classes = ultraTableStyles();

    return (
      <div ref={ref} className="font-sans">
        <Menu>
          <Tooltip
            classes={{ tooltip: classes.columnHeadTooltip }}
            title={props.tooltip || ''}
            placement="top"
            enterDelay={500}
          >
            <MenuButton
              className={cn(
                props.className,
                'inline-flex items-center gap-2 rounded-md bg-mainblue px-3 py-1.5 font-sans text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white',
              )}
            >
              {props.label || 'Options'}
              <ExpandCircleDownIcon className="size-4 fill-white/60" />
            </MenuButton>
          </Tooltip>

          <MenuItems
            transition
            anchor="bottom end"
            className="z-50 origin-top-right rounded-xl border border-black/5 bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            {props.data.map((item) => {
              return (
                <MenuItem>
                  <button
                    onClick={() => props.setSelected(item)}
                    className={`group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-blue-50 ${props.selected == item && 'bg-blue-100'}`}
                  >
                    <div className="text-sm/6">
                      {props.getDisplayValue(item)}
                    </div>
                  </button>
                </MenuItem>
              );
            })}
          </MenuItems>
        </Menu>
      </div>
    );
  },
);

export default GenericDropdown;
