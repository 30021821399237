import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC } from 'react';
import FilterForTemplateFieldsSelect from '../../components/buttons/FilterForTemplateFieldsSelect.comonent';
import RefreshFiguresButton from '../../components/buttons/RefreshFiguresButton.component';
import { Typography } from '@mui/material';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { KidsData } from '../editor/KidsEditor';

interface ControlBarOptionsProps {
  fundName: string;
  fundId: string;
  shareClass: string;
  documentLanguage: string | null;
  setTemplateFilter: Dispatch<string>;
  setFiguresUpdated: Dispatch<boolean>;
  setPriipsKidsContent: Dispatch<React.SetStateAction<KidsData[]>>;
  figuresUpdated: boolean;
}

const useStyles = makeStyles(() => ({
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '2.50rem',
    // width: '100%',
    // paddingLeft: '0.62rem',
    // gap: '0.62rem',
  },
  fundNameContainer: {
    width: '300%',
    overflow: 'hidden',
    maxHeight: '1.88rem',
    marginRight: '0.62rem',
  },
  additionalButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '0.62rem',
    // marginLeft: '6.25rem',
  },
}));

const ControlBarOptions: FC<ControlBarOptionsProps> = (props) => {
  const { isPriips } = useRaidrKids();
  const classes = useStyles();
  return (
    <div className={classes.optionsContainer}>
      <div className={classes.fundNameContainer}>
        <Typography variant="h6">{props.fundName}</Typography>
      </div>
      <div className={classes.additionalButtonsContainer}>
        {isPriips && (
          <RefreshFiguresButton
            fundId={props.fundId}
            shareClass={props.shareClass}
            documentLanguage={props.documentLanguage}
            setPriipsKidsContent={props.setPriipsKidsContent}
          />
        )}
        <FilterForTemplateFieldsSelect
          setTemplateFilter={props.setTemplateFilter}
        />
      </div>
    </div>
  );
};

export default ControlBarOptions;
