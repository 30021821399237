import { CSSProperties } from 'react';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';

export interface IRiskComparisonTableExposureChanges {
  number: number | string;
  name: string;
  class: string;
  type: string;
  currency: string;
  localCurrencyExposure_pc_ref: number | string;
  baseCurrencyExposure_pc_ref: number | string;
  grossCurrencyExposure_pc_ref: number | string;
  localCurrencyExposure_pc_comp: number | string;
  baseCurrencyExposure_pc_comp: number | string;
  grossCurrencyExposure_pc_comp: number | string;
  localCurrencyExposure_pc_diff: number | string;
  baseCurrencyExposure_pc_diff: number | string;
  grossCurrencyExposure_pc_diff: number | string;
}

const handleDateSorting =
  (
    referenceDate: string,
    columnRef: keyof IRiskComparisonTableExposureChanges,
  ) =>
  (
    a: IRiskComparisonTableExposureChanges,
    b: IRiskComparisonTableExposureChanges,
  ) => {
    if (
      a.baseCurrencyExposure_pc_ref === referenceDate ||
      b.baseCurrencyExposure_pc_ref === referenceDate
    ) {
      return 1;
    } else {
      return a[columnRef] > b[columnRef] ? -1 : 1;
    }
  };

export const csvExposureChanges = [
  'number',
  'name',
  'class',
  'type',
  'currency',
  'localCurrencyExposure_pc_ref',
  'localCurrencyExposure_pc_diff',
  'localCurrencyExposure_pc_comp',
  'baseCurrencyExposure_pc_diff',
  'baseCurrencyExposure_pc_comp',
  'baseCurrencyExposure_pc_ref',
  'grossCurrencyExposure_pc_diff',
  'grossCurrencyExposure_pc_comp',
  'grossCurrencyExposure_pc_ref',
];

const cellBorder = '1px solid white';

export const refCellStyle: CSSProperties = {
  backgroundColor: hexToRGBA(mainColors.mainBlue, 0.3),
  borderRight: cellBorder,
  textAlign: 'center',
};

export const compCellStyle: CSSProperties = {
  backgroundColor: hexToRGBA(mainColors.secondaryRed, 0.3),
  borderRight: cellBorder,
  textAlign: 'center',
};
export const diffCellStyle: CSSProperties = {
  backgroundColor: hexToRGBA(mainColors.tertiaryGreen, 0.3),
  borderRight: cellBorder,
  textAlign: 'center',
};

const alignCenter: { headerStyle: CSSProperties; cellStyle: CSSProperties } = {
  headerStyle: {
    textAlign: 'center',
  },
  cellStyle: {
    textAlign: 'center',
  },
};

export const useExposureChangesColumns = (
  referenceDate: string,
  comparisonDate: string,
): CustomColumn<IRiskComparisonTableExposureChanges>[] =>
  [
    {
      title: '#',
      field: 'number',
      width: 3,
      cellStyle: {
        padding: '0.62rem',
      },
    },
    {
      title: 'Name',
      field: 'name',
      width: '20%',
    },
    {
      title: 'Class',
      field: 'class',
      ...alignCenter,
    },
    {
      title: 'Type',
      field: 'type',
      ...alignCenter,
    },
    {
      title: 'Currency',
      field: 'currency',
      ...alignCenter,
    },
    {
      title: 'Local Currency Exposure %',
      field: 'localCurrencyExposure_pc_ref',
      cellStyle: {
        ...refCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Base Currency Exposure %',
      field: 'baseCurrencyExposure_pc_ref',
      cellStyle: (
        data: IRiskComparisonTableExposureChanges[],
        rowData: IRiskComparisonTableExposureChanges,
      ) => {
        return rowData.baseCurrencyExposure_pc_ref === referenceDate
          ? {
              ...refCellStyle,
              fontSize: '1.00rem',
              textAlign: 'center',
            }
          : { ...refCellStyle };
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Gross Currency Exposure %',
      field: 'grossCurrencyExposure_pc_ref',
      cellStyle: {
        ...refCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Local Currency Exposure %',
      field: 'localCurrencyExposure_pc_comp',
      cellStyle: {
        ...compCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Base Currency Exposure %',
      field: 'baseCurrencyExposure_pc_comp',
      cellStyle: (
        data: IRiskComparisonTableExposureChanges[],
        rowData: IRiskComparisonTableExposureChanges,
      ) => {
        return rowData.baseCurrencyExposure_pc_comp === comparisonDate
          ? {
              ...compCellStyle,
              fontSize: '1.00rem',
              textAlign: 'center',
            }
          : { ...compCellStyle };
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Gross Currency Exposure %',
      field: 'grossCurrencyExposure_pc_comp',
      cellStyle: {
        ...compCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Local Currency Exposure %',
      field: 'localCurrencyExposure_pc_diff',
      cellStyle: {
        ...diffCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Base Currency Exposure %',
      field: 'baseCurrencyExposure_pc_diff',
      cellStyle: (
        data: IRiskComparisonTableExposureChanges[],
        rowData: IRiskComparisonTableExposureChanges,
      ) => {
        return rowData.baseCurrencyExposure_pc_diff === 'Difference'
          ? {
              ...diffCellStyle,
              fontSize: '1.00rem',
              textAlign: 'center',
            }
          : { ...diffCellStyle };
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Gross Currency Exposure %',
      field: 'grossCurrencyExposure_pc_diff',
      cellStyle: {
        ...diffCellStyle,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ].map((col: any) => {
    return {
      ...col,
      customSort: handleDateSorting(
        referenceDate,
        col.field as keyof IRiskComparisonTableExposureChanges,
      ),
    };
  });
