import React from 'react';
import { greys, mainColors } from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import MancoPieChart from '../MancoPieChart/mancoPieChart.component';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    paddingTop: '1.25rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: '0 0.62rem',
    width: '9.38rem',
    '&:hover': {
      backgroundColor: greys.grey100,
    },
  },
  pie: {
    pointer: 'cursor',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  percentageText: {
    fontSize: '1.12rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '1.25rem',
    textAlign: 'center',
    width: '100%',
    padding: '0.12rem 0.31rem',
    fontWeight: 500,
    bottom: 20,
    color: mainColors.mainBlue,
    backgroundColor: 'transparent',
    borderRadius: '0.25rem',
  },
}));

interface IndividualPieProps {
  dataTitle: string;
  data: any;
  isSelectedKey: boolean;
  handlePieClick: () => void;
}

export interface StyleProps {
  width: number;
  pc_good: number;
  isSelectedKey: boolean;
}

const IndividualPie: React.FC<IndividualPieProps> = ({
  dataTitle,
  data,
  isSelectedKey,
  handlePieClick,
}) => {
  const classes = useStyles();

  const pieData = data.map((datum: any) => {
    return { name: datum[0], value: datum[1] };
  });

  const passValue = pieData.filter((item: any) => item.name === 'Pass')[0]
    .value;
  const failValue = pieData.filter((item: any) => item.name === 'Fail')[0]
    .value;
  const pc_good = passValue / (failValue + passValue);

  return (
    <Tooltip title={`Filter ${dataTitle}`}>
      <div className={classes.root} onClick={handlePieClick}>
        <div className={classes.pie}>
          <MancoPieChart data={pieData} animBegin={0} />
          <div className={classes.percentageText}>
            {Math.round(pc_good * 100) + '%'}
          </div>
        </div>
        <div
          className={classes.title}
          style={{
            backgroundColor: isSelectedKey
              ? mainColors.mainBlue_slightlyDarker
              : 'transparent',
            color: isSelectedKey ? 'white' : mainColors.mainBlue,
          }}
        >
          {dataTitle}
        </div>
      </div>
    </Tooltip>
  );
};

export default React.memo(IndividualPie);
