import React from 'react';
import CounterpartyTable from './counterpartyTable/counterpartyTable.component';
import {
  buildDerivativeExposureAvailableLiquidityRatio,
  buildDerivativeVarData,
  buildLiquidationDistanceData,
  buildPositionVarData,
  counterpartyAvailableLiquidityColumns,
  counterpartyLiquidationColumns,
  counterpartyVarExposureColumns,
} from './tableDisplay.data';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
interface CounterpartyTableDisplayProps {
  data: DataObject;
}
const CounterpartyTableDisplay = (props: CounterpartyTableDisplayProps) => {
  const dataForUse = props.data.data[0];
  const counterpartyNames = dataForUse.counterparty_risk.cpName;
  const counterpartyIds = dataForUse.counterparty_risk.cpId;

  const EXPOSURE_EXPORT_COLS = [
    'counterparty',
    'equity',
    'commodity',
    'fixedIncome',
    'cash',
    'alternative',
    'total',
  ];

  return (
    <>
      <CounterpartyTable
        xl={7}
        data={buildPositionVarData(
          counterpartyIds,
          counterpartyNames,
          dataForUse.cp_position_var_data,
        )}
        columns={counterpartyVarExposureColumns}
        exportColumns={EXPOSURE_EXPORT_COLS}
        title="Position VaR Exposure"
      />
      <CounterpartyTable
        xl={5}
        data={buildLiquidationDistanceData(
          counterpartyIds,
          counterpartyNames,
          dataForUse.cp_position_var_data?.liquid,
        )}
        columns={counterpartyLiquidationColumns}
        title="Liquidation Distance"
        exportColumns={['counterparty', 'assetValue', 'liquidationDistance']}
      />
      <CounterpartyTable
        xl={7}
        data={buildDerivativeVarData(
          counterpartyIds,
          counterpartyNames,
          dataForUse.cp_derivative_var_data,
        )}
        exportColumns={EXPOSURE_EXPORT_COLS}
        columns={counterpartyVarExposureColumns}
        title="Derivative VaR Exposure"
      />
      <CounterpartyTable
        xl={5}
        exportColumns={[
          'counterparty',
          'liquidity',
          'var',
          'cpRatio',
          'fundRatio',
        ]}
        data={buildDerivativeExposureAvailableLiquidityRatio(
          dataForUse.cp_derivative_var_data,
          counterpartyNames,
          counterpartyIds,
        )}
        columns={counterpartyAvailableLiquidityColumns}
        title="Derivative Exposure Available Liquidity Ratio"
      />
    </>
  );
};

export default CounterpartyTableDisplay;
