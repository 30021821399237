import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../styling/theme';
import StressTestBreakdownTimeSeries from './StressTestBreakdownLineChart.component';
import useFetchData from '../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';

interface DetailProps {
  dataKey: string;
  fundId: string;
  positionDate?: string;
}

export const useOverviewDetailStyles = makeStyles((theme: RaptorTheme) => ({
  chartContainerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  map: {
    textAlign: 'left',
    margin: '0.94rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chart: {
    // NOTE this could be better - DRY
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '0.94rem',
    padding: 8,
  },
  outerDiv: {
    textAlign: 'center',
    // height: "25.00rem",
    width: '80%',
    maxWidth: '37.50rem',
    height: '80%',
  },
  container: {
    position: 'relative',
    // height: "31.25rem",
    padding: '1.25rem 0',
    paddingBottom: 0,
  },
  mapTitle: {
    marginTop: 0,
    marginBottom: '1.88rem',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  lineChart: {
    transform: 'translateX(1.56rem)',
    '& g:first-child': {
      backgroundColor: 'red',
    },
  },
  colorChartBox: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: 10,
    left: 10,
    backgroundColor: 'red',
  },
}));

const Detail: React.FC<DetailProps> = ({ dataKey, fundId, positionDate }) => {
  const breakdownData = useFetchData({
    url: `get_private_equity_stress_test_breakdown/${fundId}/${positionDate}/${dataKey}`,
    keyName: `stress_test_breakdown_data_${fundId}_${dataKey}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[breakdownData]}
      customLoadingMessages={['Loading Historical Timeseries Data...']}
      customErrorMessage={'No Data Found'}
    >
      <StressTestBreakdownTimeSeries data={breakdownData} dataKey={dataKey} />
    </GeneralComponentErrorShield>
  );
};

export default Detail;
