import React, { Fragment, ReactElement, useState } from 'react';
import {
  ColumnDef,
  FilterFn,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ByRuleData,
  ByRuleUnderlyingPositionsData,
} from './ByRuleTable.component';
import {
  ifNumberAddCommasAndRound,
  roundToNDecimalPlacesReturnNumber,
} from '../../../../../../utilities/numberFormatters';
import { customSortingFn } from '../../../../../tables/ultraTable/utils/ultratable.utils';

// second level positions table props
interface PositionsSubTableProps {
  row: Row<ByRuleData>;
}

// column definitions for second level positions
const columns: ColumnDef<ByRuleUnderlyingPositionsData>[] = [
  {
    accessorKey: 'issuer',
    header: () => 'Issuer',
  },
  {
    accessorKey: 'isin',
    header: () => 'ISIN',
  },
  {
    accessorKey: 'sector_name',
    header: () => 'Sector Name',
  },
  {
    accessorKey: 'asset_class',
    header: () => 'Asset Class',
  },
  {
    accessorKey: 'exposure',
    header: () => 'Exposure',
    cell: (props) =>
      ifNumberAddCommasAndRound(
        roundToNDecimalPlacesReturnNumber(props.getValue() as number, 0),
      ),
  },
  {
    accessorKey: 'gross_exposure',
    header: () => 'Gross Exposure',
    cell: (props) =>
      ifNumberAddCommasAndRound(
        roundToNDecimalPlacesReturnNumber(props.getValue() as number, 0),
      ),
  },
  {
    accessorKey: 'gross_exposure_pc',
    header: () => 'Gross Exposure %',
    cell: (props) =>
      roundToNDecimalPlacesReturnNumber(props.getValue() as number, 3),
  },
  {
    accessorKey: 'value',
    header: () => 'Value',
  },
];

// styles for second level positions table
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  border: {
    borderRight: `2px solid ${mainColors.mainBlue}`,
    borderBottom: `2px solid ${mainColors.mainBlue}`,
    borderLeft: `2px solid ${mainColors.mainBlue}`,
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    padding: '0.31rem',
  },
  table: {
    tableLayout: 'fixed',
    paddingBottom: '1.25rem',
    borderCollapse: 'collapse',
    minWidth: '100%',
    border: `1px solid ${mainColors.mainBlue}`,
  },
  tableHead: {
    position: 'sticky',
    borderTop: '1px solid grey',
    backgroundColor: mainColors.veryFaintBlue,
  },
  tableBody: {
    paddingBottom: '1.25rem',
  },
  row: {
    display: 'flex',
    paddingY: '0.62rem',
    borderTop: '1px solid grey',
  },
  headerRow: {
    display: 'flex',
    paddingY: '0.62rem',
  },
  cell: {
    ...theme.typography.h3,
    textAlign: 'center',
    width: '100%',
    minWidth: '3.12rem',
    minHeight: '1.88rem',
    padding: '0.62rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.62rem',
    color: 'black',
  },
  columnHead: {
    ...theme.typography.h3,
    fontWeight: 600,
    width: '100%',
    minHeight: '1.88rem',
    padding: '0.62rem 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.25rem',
    fontSize: '0.75rem',
  },
  noDataMessage: {
    color: mainColors.mainBlue,
    margin: '1.25rem 1.88rem',
  },
}));

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

// exported second levels positions table component
export default function PositionsSubTable(
  props: PositionsSubTableProps,
): ReactElement {
  const classes = useStyles();

  // crerate data state
  const [data] = useState(props.row.original.underlying_positions);

  // build simple react-table object
  const table = useReactTable<ByRuleUnderlyingPositionsData>({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    sortingFns: {
      customSortingFn: customSortingFn,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  // if items in data array render, else render message telling user no records to display
  return data.length ? (
    <div className={classes.border}>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={classes.headerRow}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} className={classes.columnHead}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={classes.tableBody}>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr className={classes.row}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={classes.cell}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <h2 className={classes.noDataMessage}>No records to display</h2>
  );
}
