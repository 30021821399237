import React, { ReactElement, useEffect, useState } from 'react';
import { zIndexes } from '../../../styling/zIndexes';
import { RaptorTheme } from '../../../styling/theme';
import PageList from './iconArea/PageList';
import { Page, Section } from '../../../types/server-data/ClientConfig';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  allPagesSelector,
  clientSectionsSelector,
} from '../../../redux/pages/selectors';
import { isLoggedInSelector } from '../../../redux/auth/selectors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  sidebarLeftRoot: {
    height: '100%',
    width: '3.75rem',
    display: 'flex',
    zIndex: zIndexes.sideBar,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    textAlign: 'center',
  },
}));

function Sidebar(): ReactElement {
  const [iconsVisible, setIconsVisible] = useState(true);
  const [showPages, setShowPages] = useState<Page[] | null>(null);
  const isAuthenticated = useSelector(isLoggedInSelector);

  const clientSections: Section[] | null = useSelector(clientSectionsSelector);
  const currentSection = useSelector(activeSectionSelector);
  const classes = useStyles({
    isLoggedIn: isAuthenticated,
  });

  const filteredSection =
    currentSection && clientSections
      ? clientSections.filter((section) => section.sectionId === currentSection)
      : [];
  const currentSectionData = filteredSection.length ? filteredSection[0] : null;
  const allPages = useSelector(allPagesSelector);

  useEffect(() => {
    setTimeout(() => {
      setIconsVisible(false);
    }, 0);
    setTimeout(() => {
      if (currentSectionData && allPages) {
        setShowPages(currentSectionData.pages.map((page) => allPages[page]));
      }
    }, 280);

    setTimeout(() => {
      setIconsVisible(true);
    }, 300);
  }, [currentSectionData]);

  return (
    <div className={classes.sidebarLeftRoot}>
      {showPages && currentSectionData && (
        <PageList
          currentPages={showPages}
          iconsVisible={iconsVisible}
          currentSection={currentSectionData}
        />
      )}
    </div>
  );
}

export default Sidebar;
