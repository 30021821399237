import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';

interface WinnersAndLosersChartProps {
  data: any;
  type: 'asset' | 'currency';
}

interface WinnersAndLosersChartData {
  asset: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.62rem 1.56rem 0.62rem 0.94rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.38rem',
    fontWeight: 400,
    margin: '0.62rem 0.94rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '0.62rem 0.94rem',
    borderRadius: '0.31rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '12.50rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.00rem',
    fontWeight: 600,
  },
  tooltipValueBelowZero: {
    color: mainColors.Fail,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>{label}</div>
          <hr />
          {payload[0].value >= 0 ? (
            <div className={classes.tooltipValue}>
              {percentageToNdecialPlaces(payload[0].value / 100, 2)}
            </div>
          ) : (
            <div
              className={clsx(
                classes.tooltipValue,
                classes.tooltipValueBelowZero,
              )}
            >
              {percentageToNdecialPlaces(payload[0].value / 100, 2)}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const CustomBar = (props: any) => {
  let fill = props.fill;

  if (props.value >= 0) {
    fill = mainColors.mainBlue;
  } else {
    fill = mainColors.Fail;
  }

  return <Rectangle {...props} fill={fill} />;
};

const buildChartData = (
  inputData: any,
  type: 'asset' | 'currency',
): WinnersAndLosersChartData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: WinnersAndLosersChartData[] = [];
    Object.keys(inputData.data[0].position_level).map((asset) => {
      returnData.push({
        asset: asset,
        value:
          inputData.data[0].position_level[asset][`aggregate_${type}_pl_pct`],
      });
    });

    returnData.sort(
      (a: WinnersAndLosersChartData, b: WinnersAndLosersChartData) =>
        b.value - a.value,
    );

    if (returnData.length <= 10) {
      return returnData;
    } else {
      const last5: WinnersAndLosersChartData[] = returnData.slice(
        -5,
        returnData.length,
      );
      const first5: WinnersAndLosersChartData[] = returnData.slice(0, 5);
      return first5.concat(last5);
    }
  }
};

const handleGetChartTitle = (type: 'asset' | 'currency') => {
  switch (type) {
    case 'currency':
      return 'Top 5 and Bottom 5 - Currency Returns';
    case 'asset':
      return 'Top 5 and Bottom 5 - Asset Returns';
    default:
      'Top 5 and Bottom 5';
  }
};

const tickFormatter = (value: string, index: number) => {
  const limit = 20; // put your maximum character
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

const WinnersAndLosersChart: React.FC<WinnersAndLosersChartProps> = ({
  data,
  type,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtChartData = buildChartData(data, type);

  const id = `${type}-top-5-and-bottom-5`;
  const title = `${type} - Top 5 and Bottom 5`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} lg={6} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{handleGetChartTitle(type)}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtChartData}
            fields={Object.keys(builtChartData[0])}
            fieldsMap={[
              { key: 'asset', label: 'Asset' },
              { key: 'value', label: 'Daily Movement %' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={400}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="asset"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={140}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickFormatter={tickFormatter}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Daily Movement %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey="value" shape={CustomBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default WinnersAndLosersChart;
