import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import {
  createSectionByIdSelector,
  createSpecificFundNameAndIdSelectorByFundType,
} from '../../../../redux/pages/selectors';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import { FundType } from '../../../../types/server-data/ClientConfig';
import {
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import GenericStatusCell from '../../../tables/GenericStatusCell';
import SecondLevelTableWrapper from './SecondLevelTableWrapper.component';

export interface StressTestsPositions {
  change_in_pnl: number;
  change_in_pnl_percentage: string;
  exposure: number;
  exposure_percentage: string;
  is_derivative: boolean;
  position_name: string;
  size: number;
}

export interface StressTestsData {
  headerRow: boolean;
  stressScenarioType: string | null;
  name: string | null;
  pL: number | null;
  pLstatus: Status | null;
  exAnteVolatility: number | null;
  exAnteVar: number | null;
  numSds: number | null;
  numVar: number | null;
  exPostVolatility: number | null;
  exPostVar: number | null;
  positions?: StressTestsPositions[];
}

export const mapStressTestNames = (name: string) => {
  switch (name) {
    case 'Sept2008':
      return 'Sept2008 - Global Financial Crisis';
    case 'Oct2008':
      return 'Oct2008 - Global Financial Crisis Escalates';
    case 'Apr2009':
      return 'Apr2009 - Global Financial Crisis Recovery';
    case 'July2011':
      return 'July2011 - Eurozone Sovereign Debt Crisis';
    case 'Aug2011':
      return 'Aug2011 - Eurozone Crisis Redux';
    case 'Mar2020':
    case 'March2020':
      return 'March2020 - Covid Crisis';
    default:
      return name;
  }
};

export const buildStressColumns = (): CustomColumn<StressTestsData>[] => {
  return [
    {
      title: 'Stress/Scenario Type',
      field: 'stressScenarioType',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: (rowData, rowDataValues) =>
        rowDataValues.headerRow
          ? {
              padding: '1.25rem',
              fontWeight: 700,
              color: mainColors.mainBlue,
              textAlign: 'center',
            }
          : {
              padding: 0,
            },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        padding: 0,
      },
      headerStyle: {},
      render: (rowData) => mapStressTestNames(rowData.name!),
    },
    {
      title: 'P/L',
      field: 'pL',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfRenderType: 'StatusWithPercentage',
      renderMethod: {
        methodName: 'percentageToTwoDecimalPlaces',
        params: ['pL'],
      },
      render: (rowData) => {
        const statusCell = rowData.headerRow ? (
          ''
        ) : (
          <GenericStatusCell
            height={'3.12rem'}
            status={rowData.pLstatus!}
            innerText={percentageToTwoDecimalPlaces(rowData.pL!)}
          />
        );
        return statusCell;
      },
    },
    {
      title: 'Ex-Ante Volatility',
      field: 'exAnteVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVolatility as number),
    },
    {
      title: 'Ex-Ante VaR',
      field: 'exAnteVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVar as number),
    },
    {
      title: '#SDs',
      field: 'numSds',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numSds as number),
    },
    {
      title: '#VaR',
      field: 'numVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numVar as number),
    },
    {
      title: 'Ex-Post Volatility',
      field: 'exPostVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVolatility as number),
    },
    {
      title: 'Ex-Post VaR',
      field: 'exPostVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVar as number),
    },
  ];
};

export const sectorNameMap: { [key: string]: string } = {
  risk_sector_stress_tests: 'Risk Sector Stress',
  historical_stress_tests: 'Historical Stress',
  relative_stress_tests: 'Relative Stress',
};

export function buildStressTestData(
  inputData: any[],
  inputRestrictions: any[],
) {
  if (!inputData || !inputRestrictions) return [];
  try {
    const mainData = inputData[0];
    const nav = inputData[0].nav;
    const restrictions = inputRestrictions[0];
    const returnArr: StressTestsData[] = [];

    [
      'risk_sector_stress_tests',
      'historical_stress_tests',
      'relative_stress_tests',
    ].forEach((testCategory) => {
      if (testCategory in mainData && mainData[testCategory].length) {
        // add header row
        returnArr.push({
          headerRow: true,
          stressScenarioType: sectorNameMap[testCategory],
          name: null,
          pL: null,
          pLstatus: null,
          exAnteVolatility: null,
          exAnteVar: null,
          numSds: null,
          numVar: null,
          exPostVolatility: null,
          exPostVar: null,
        });
        mainData[testCategory].forEach((test: any[]) => {
          const name = test[0];
          const dataValuesForOtherColumns = test[1];
          const pLvalue = dataValuesForOtherColumns[0] / nav;
          returnArr.push({
            headerRow: false,
            stressScenarioType: null,
            name,
            pL: pLvalue,
            pLstatus:
              pLvalue < 0 && Math.abs(pLvalue) >= restrictions[name]
                ? Status.Alert
                : Status.Pass, // come back to this
            exAnteVolatility: dataValuesForOtherColumns[1] / nav,
            exAnteVar: dataValuesForOtherColumns[2] / nav,
            numSds: dataValuesForOtherColumns[3],
            numVar: dataValuesForOtherColumns[4],
            exPostVolatility: dataValuesForOtherColumns[5] / nav,
            exPostVar: dataValuesForOtherColumns[6] / nav,
            positions: inputData[0].position_level_breakdown
              ? inputData[0].position_level_breakdown[name]
              : null,
          });
        });
      }
    });
    return returnArr;
  } catch (err) {
    console.error('Problem building stress data: ', err);
  }
  return [];
}
const StressScenario: FC<FundInfoComponentProps> = (props) => {
  const { fundId, positionDate } = props;
  const sectionDetailsSelector = createSectionByIdSelector(props.section);
  const sectionDetails = useSelector(sectionDetailsSelector);
  const fundDetailsSelector = createSpecificFundNameAndIdSelectorByFundType(
    sectionDetails?.fundTypes || 'UCITS',
    fundId,
    sectionDetails?.rsResources || 'RISK,LIQUIDITY',
  );
  const fundType = sectionDetails?.fundTypes || FundType.UCITS;
  const fundDetails = useSelector(fundDetailsSelector);

  const url =
    fundType === FundType.AIF
      ? positionDate
        ? `stored_stress_test_data_agg/${fundId}/${positionDate}/AIF`
        : `stored_stress_test_data_agg/${fundId}/None/AIF`
      : formatFundUrl('stored_stress_test_data_agg', fundId, positionDate);

  const stressTestData = useFetchData({
    url: url,
    keyName: makeUniqueDataKey(
      fundId,
      'stress_scenario_test_data',
      positionDate,
    ),
    makeFetch: true,
  });

  const stressTestRestrictions = useFetchData({
    url: 'stress_test_restrictions/' + fundId,
    keyName: makeUniqueDataKey(
      fundId,
      'stress_test_restrictions',
      positionDate,
    ),
    makeFetch: true,
  });

  const columns = buildStressColumns();

  const dataArrived = stressTestData && stressTestRestrictions;
  const finishedLoading =
    dataArrived &&
    !stressTestData.isFetching &&
    !stressTestRestrictions.isFetching;

  return dataArrived && fundDetails ? (
    finishedLoading ? (
      <GridItem card xs={12}>
        <CustomTable<StressTestsData>
          pdfNoClearFirstRow
          options={{
            paging: false,
            exportButton: true,
          }}
          showToolbar={true}
          data={buildStressTestData(
            stressTestData.data,
            stressTestRestrictions.data,
          )}
          toolbarComponents={{
            toolbarTitle: fundDetails.name,
          }}
          columns={columns}
          csvFields={[
            'stressScenarioType',
            'name',
            'pL',
            'pLstatus',
            'exAnteVolatility',
            'exAnteVar',
            'numSds',
            'numVar',
            'exPostVolatility',
            'exPostVar',
          ]}
          detailPanel={SecondLevelTableWrapper()}
        />
      </GridItem>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};

export default StressScenario;
