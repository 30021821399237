import React from 'react';
import CustomTable from '../../../../tables/CustomTable';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import GenericStatusCell from '../../../../tables/GenericStatusCell';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Table, TableRow, TableType } from '../StatusChange.page';
import makeStyles from '@mui/styles/makeStyles';
import LinkCell from './LinkCell';
import { Theme } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

const statusChangePageMap: { [key: string]: string } = {
  commitment: 'exposure',
  risk_compliance: 'prospectus-restrictions',
  var: 'value-at-risk',
  // all the law pages:
  // [2024-02-16] - this hardcoding is not ideal, but it's the only way to get the correct page when we have no generalised 'law' page
  // Eventually, we should have a 'law' page that takes in a 'schema' prop and renders the correct page based on that
  ucits: 'ucits-law',
  nurs: 'nurs-law',
  riaif: 'riaif-law',
  act40: 'act40-law',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '49%',
    background: 'white',
    borderRadius: '0.25rem',
  },
}));

interface Props {
  section: string;
  table: Table;
}
function buildStatusChangeColumns(
  section: string,
  key: string,
  type: TableType,
): CustomColumn<TableRow>[] {
  return [
    {
      title: 'Fund Name',
      field: 'fund_name',
      cellStyle: {
        fontSize: 12,
        width: '35%',
      },
    },
    {
      title: 'Previous Date',
      field: 'previous_date',
      cellStyle: {
        fontSize: 12,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '15%',
    },
    {
      title: 'Current Date',
      field: 'current_date',
      cellStyle: {
        fontSize: 12,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '15%',
    },
    {
      title: 'Previous Value',
      field: 'previous_value',
      width: '3.12rem',
      render: (rowData: TableRow) => {
        const link = `../${section}/${statusChangePageMap[key]}?fundId=${rowData.internal_fund_name}&positionDate=${rowData.previous_value}`;
        if (type === 'percentage') {
          return (
            <LinkCell
              cellText={percentageToTwoDecimalPlaces(
                rowData.previous_value as number,
              )}
              linkString={link}
            />
          );
        } else {
          return (
            <GenericStatusCell
              height={'3.12rem'}
              status={specifyStatus(rowData.previous_value as string)}
              linkToPage={link}
            />
          );
        }
      },
      cellStyle: {
        padding: type === 'pass_fail' ? 0 : 'auto',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      width: '3.12rem',
      title: 'Current Value',
      field: 'current_value',
      render: (rowData: TableRow) => {
        const link = `../${section}/${statusChangePageMap[key]}?fundId=${rowData.internal_fund_name}`;
        if (type === 'percentage') {
          return (
            <LinkCell
              cellText={percentageToTwoDecimalPlaces(
                rowData.current_value as number,
              )}
              linkString={link}
            />
          );
        } else {
          return (
            <GenericStatusCell
              height={'3.12rem'}
              status={specifyStatus(rowData.current_value as string)}
              linkToPage={link}
            />
          );
        }
      },
      cellStyle: {
        padding: type === 'pass_fail' ? 0 : 'auto',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];
}

const GeneralTable: React.FC<Props> = ({ section, table }) => {
  const classes = useStyles();

  const columns = buildStatusChangeColumns(section, table.key, table.type);

  return (
    <div className={classes.root}>
      <CustomTable<TableRow>
        columns={columns}
        data={table.data}
        showToolbar
        options={{
          paging: false,
          exportButton: true,
          rowStyle: {
            padding: table.type === 'pass_fail' ? 0 : 'auto',
          },
          headerStyle: {
            padding: '0.50rem',
          },
        }}
        toolbarComponents={{
          toolbarTitle: table.title,
        }}
      />
    </div>
  );
};

export default GeneralTable;
