import { ReactElement, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactTooltip from 'react-tooltip';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import json from './map.json';
import {
  RenderDataType,
  useOverviewSecondLevelStyles,
} from '../OverviewSecondLevelGraphs.component';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { Grid, Typography } from '@mui/material';

interface Props {
  val: any;
  data: any;
  title?: string;
  tooltipLabelFormatter: (val: string | number) => string;
  tooltipValueFormatter: (val: string | number) => string | number;
}

const useMapStyles = makeStyles<RaptorTheme>((theme) => ({
  mapTitle: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '1.50rem',
    textAlign: 'center',
    margin: 0,
  },
}));

export function Map(props: Props): ReactElement {
  const [content, setContent] = useState('');
  const maxVal = props.val > 0 ? props.val : 1;
  const exposureScale = scaleLinear()
    .domain([0, maxVal])
    .range([
      'white' as unknown as number,
      mainColors.mainBlue as unknown as number,
    ]);

  const { height } = useWindowSize();
  const classes = useMapStyles();
  const handleLeave = () => {
    setContent('');
  };
  const dict = props.data;

  return (
    <div style={{ display: 'grid', height: '100%', maxHeight: '80vh' }}>
      {props.title && <h2 className={classes.mapTitle}>{props.title}</h2>}
      <ComposableMap data-tip="" viewBox={'45 0 800 600'}>
        <Geographies geography={json}>
          {({ geographies, projection }) => {
            return geographies.map((geography, i) => {
              if (dict[geography.properties['Alpha-2']] === undefined) {
                dict[geography.properties['Alpha-2']] = 0;
              }

              return (
                <Geography
                  key={geography.properties.name}
                  geography={geography}
                  onMouseEnter={(e) => {
                    setContent(
                      `${props.tooltipValueFormatter(
                        dict[geography.properties['Alpha-2']],
                      )} - ${props.tooltipLabelFormatter(
                        geography.properties.name,
                      )}`,
                    );
                  }}
                  onMouseLeave={handleLeave}
                  style={{
                    default: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']],
                      ) as unknown as string,
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                    hover: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']],
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                      outline: 'none',
                    },
                    pressed: {
                      outline: 'none',
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']],
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                    },
                  }}
                />
              );
            });
          }}
        </Geographies>
      </ComposableMap>
      <ReactTooltip backgroundColor={mainColors.mainBlue} className="tooltip">
        {content}
      </ReactTooltip>
    </div>
  );
}

interface DetailMapProps {
  renderData: RenderDataType;
}

type MapDataType = {
  [index: string]: number;
};

const valueFormatter = (val: string | number) => `${val}%`;
const labelFormatter = (val: string | number) => `${val}`;

const buildMapData = (renderData: RenderDataType): MapDataType => {
  const newDict: MapDataType = {};
  renderData.country_exposure.forEach((country: any[]) => {
    newDict[country[0]] = country[1];
  });
  return newDict;
};

const getMaxVal = (renderData: RenderDataType): number => {
  let maxVal = 0;
  renderData.country_exposure.forEach((country: any) => {
    if (country[1] > maxVal) {
      maxVal = country[1];
    }
  });
  return maxVal;
};

function DetailMap(props: DetailMapProps): ReactElement {
  const classes = useOverviewSecondLevelStyles();

  return (
    <Grid
      item
      xs={props.renderData.historical_time_series.length ? 4 : 6}
      className={classes.map}
    >
      <Typography variant="h2" className={classes.mapTitle}>
        Regional Exposure (% of NAV)
      </Typography>
      <div className={classes.outerDiv}>
        <Map
          tooltipLabelFormatter={labelFormatter}
          tooltipValueFormatter={valueFormatter}
          data={buildMapData(props.renderData)}
          val={getMaxVal(props.renderData)}
        />
      </div>
    </Grid>
  );
}
export default DetailMap;
