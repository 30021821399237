import React, { FC } from 'react';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import GenericStatusCell from '../../../../tables/GenericStatusCell';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface HairCutTableProps {
  data: DataObject;
}

interface HairCutTableData {
  haircut: string;
  stressedPL: number;
  hairCutLimit: number;
  status: Status;
}

function buildHairCutTableData(data: DataObject): HairCutTableData[] {
  if (!data.data.length) return [];
  const positions = data.data[0].positions;
  if (typeof positions === 'string') return [];

  const haircut = 'Haircut Stress Test';
  const stressedPL = data.data[0].stressed_pnl;
  const hairCutLimit = data.data[0].haircut_limit;
  const status = specifyStatus(data.data[0].status);
  return [
    {
      haircut,
      stressedPL,
      hairCutLimit,
      status,
    },
  ];
}

const tableColumns: CustomColumn<HairCutTableData>[] = [
  {
    title: 'Haircut',
    field: 'haircut',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      padding: 0,
      fontWeight: 700,
      color: mainColors.mainBlue,
      textAlign: 'center',
    },
  },
  {
    title: 'Stressed P/L',
    field: 'stressedPL',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
    render: (rowData: HairCutTableData) =>
      percentageToTwoDecimalPlaces(rowData.stressedPL),
  },
  {
    title: 'Haircut Limit',
    field: 'hairCutLimit',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
    render: (rowData: HairCutTableData) =>
      percentageToTwoDecimalPlaces(rowData.hairCutLimit),
  },
  {
    title: 'Status',
    field: 'status',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
    pdfRenderType: 'PageLinkButton',
    render: (rowData: HairCutTableData) => (
      <GenericStatusCell
        height={'3.12rem'}
        status={rowData.status ? rowData.status : specifyStatus('Pass')}
      />
    ),
  },
];

const HairCutTable: FC<HairCutTableProps> = (props) => {
  const tableData = buildHairCutTableData(props.data);
  return tableData.length ? (
    <GridItem card xs={12}>
      <CustomTable<HairCutTableData>
        pdfNoClearFirstRow
        options={{
          paging: false,
          exportButton: true,
        }}
        showToolbar={true}
        data={tableData}
        columns={tableColumns}
      />
    </GridItem>
  ) : null;
};

export default HairCutTable;
