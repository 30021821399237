import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { RaptorTheme } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import MancoSummaryContainer from './components/MancoSummaryContainer/MancoSummaryContainer.component';
import FundSummaryContainer from './components/FundSummaryContainer/FundSummaryContainer.component';
import ExportItem from '../../utilities/RaptorExport/subComponents/ExportItem.component';
import { Grid, Typography } from '@mui/material';
import { getAdepaEMIRBreakdownDetailedURL } from '../../../../routes/endpoints/manco.routes';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(0.99)',
    },
  },
}));

const AdepaEMIR: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const classes = useStyles();

  const detailedEmirData = useFetchData({
    url: 'get_EMIR_breakdown_detailed',
    keyName: 'get_EMIR_breakdown_detailed',
    makeFetch: true,
  });

  const [selectedKey, setSelectedKey] = useState('');
  const updateSelectedKey = (key: string) => {
    setSelectedKey(key);
  };

  return (
    <GeneralComponentErrorShield
      dataObjects={[detailedEmirData]}
      customLoadingMessages={[
        'Loading Manco Level Data',
        'Manco Level Data May Take a Few Moments to Calculate',
        '...',
      ]}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem
          card
          key="export"
          item
          xs={12}
          cardStyle={{ padding: '0.62rem' }}
        >
          <ExportItem
            title={'EMIR Export (.xlsx)'}
            // the {option} pattern is to inject the value of the corresponding option into the downloadUrl and fileName
            downloadUrl={`get_EMIR_export`}
            fileName={`EMIR_export.xlsx`}
            openDownloadInNewTab
          />
        </GridItem>
        <GridItem
          card
          key="manco-overview"
          item
          classes={classes}
          cardStyle={{ height: '18.75rem' }}
          xs={12}
        >
          <MancoSummaryContainer
            data={detailedEmirData}
            selectedKey={selectedKey}
            setSelectedKeyFunction={updateSelectedKey}
          />
        </GridItem>
        <GridItem
          card
          key="fund-overview"
          item
          cardStyle={{ minHeight: '18.75rem' }}
          xs={12}
        >
          {selectedKey !== '' ? (
            <FundSummaryContainer
              data={detailedEmirData}
              selectedKey={selectedKey}
            />
          ) : (
            <DisplayAreaCenteredWrapper noMinHeight>
              <Typography variant="h1">
                {'Please Click on a Chart Above to Show Fund Data.'}
              </Typography>
            </DisplayAreaCenteredWrapper>
          )}
        </GridItem>
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default AdepaEMIR;
