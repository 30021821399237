import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce, isEqual } from 'lodash';
import { MinMaxStateType, MinMaxType } from './presetsData/DataTypes';
import { Button, Input, Popper } from '@mui/material';
import { Sort, Tune } from '@mui/icons-material';

type sortingTypes = 'Ascending' | 'Descending' | '';
interface PositionsTableFilterProps {
  columnID: string;
  originalMinMaxState: MinMaxStateType;
  minMaxState: MinMaxStateType;
  onMinMaxChange: (columnID: string, min: MinMaxType, max: MinMaxType) => void;
  sortState: { [key: string]: sortingTypes };
  onSortChange: () => void;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainButton: {
    fontSize: '0.62rem',
    textAlign: 'center',
    fontWeight: 500,
    color: greys.grey300,
    '&:hover,&:focus': {
      // backgroundColor: 'transparent',
      // color: mainColors.mainBlue_lighter
    },
    '& svg': {
      width: '0.94rem',
      height: '0.94rem',
      marginRight: '0.62rem',
    },
  },
  mainButtonNoFilter: {
    backgroundColor: 'transparent',
  },
  mainButtonWithFilters: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
  },
  mainPopper: {
    border: `1px solid #e1e4e8`,
    boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '0.62rem',
    width: '18.75rem',
    zIndex: theme.zIndex.modal,
    color: mainColors.mainBlue,
    backgroundColor: 'white',
  },
  mainPopperHeader: {
    borderBottom: '1px solid #eaecef',
    padding: '0.94rem',
    fontWeight: 600,
    fontSize: '0.94rem',
  },
  sortContainer: {
    borderBottom: '1px solid #eaecef',
    padding: '0.31rem 0.62rem',
    display: 'flex',
    alignItems: 'center',
  },
  sortButton: {
    padding: '0.31rem 0.62rem 0.31rem 0.31rem',
    fontSize: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-start',
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
      margin: '0.31rem',
    },
  },
  minMaxBox: {
    display: 'flex',
  },
  inputSearch: {
    width: '100%',
    padding: '0.94rem',
    '& input': {
      borderRadius: '0.31rem',
      backgroundColor: '#fff',
      marginBottom: '0.94rem',
      border: `1px solid #eaecef`,
      fontSize: '0.94rem',
      '&:focus': {
        boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
        borderColor: '#0366d6',
      },
    },
  },
}));

export default function PositionsTableFilterNumber(
  props: PositionsTableFilterProps
) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [min, setMin] = useState<MinMaxType>(
    props.minMaxState[props.columnID][0]
  );
  const [max, setMax] = useState<MinMaxType>(
    props.minMaxState[props.columnID][1]
  );

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = () => {
    props.onSortChange();
  };

  const debounceSendState = debounce(
    async (
      columnID: string,
      min: number | '' | '-',
      max: number | '' | '-'
    ) => {
      props.onMinMaxChange(columnID, min, max);
    },
    300
  );

  useEffect(() => {
    if (min === '' && max === '' && props.sortState[props.columnID] === '') {
      setActive(false);
    } else {
      setActive(true);
    }
    if (!isEqual(props.minMaxState[props.columnID], [min, max])) {
      debounceSendState(props.columnID, min, max);
    }
  }, [min, max, props.sortState[props.columnID]]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '-') {
      setMin(event.target.value);
    } else if (
      Number(event.target.value) &&
      Number(event.target.value) <= Number.MAX_SAFE_INTEGER
    ) {
      setMin(Number(event.target.value));
    } else if (!event.target.value.length) {
      setMin('');
    }
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '-') {
      setMax(event.target.value);
    } else if (
      Number(event.target.value) &&
      Number(event.target.value) <= Number.MAX_SAFE_INTEGER
    ) {
      setMax(Number(event.target.value));
    } else if (!event.target.value.length) {
      setMax('');
    }
  };

  useEffect(() => {
    if (inputFocused === false) {
      setMin(props.minMaxState[props.columnID][0]);
      setMax(props.minMaxState[props.columnID][1]);
    }
  }, [props.minMaxState[props.columnID]]);

  const handleFocus = (focused: boolean) => {
    setInputFocused(focused);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popper' : undefined;

  return (
    <React.Fragment>
      <Box className={classes.buttonBox}>
        {active ? (
          <Button
            onClick={handleClick}
            disableRipple
            aria-describedby={id}
            className={clsx(classes.mainButton, classes.mainButtonWithFilters)}
          >
            <Tune />
            <span>Filter</span>
          </Button>
        ) : (
          <Button
            onClick={handleClick}
            disableRipple
            aria-describedby={id}
            className={clsx(classes.mainButton, classes.mainButtonNoFilters)}
          >
            <Tune />
            <span>Filter</span>
          </Button>
        )}
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.mainPopper}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box className={classes.mainPopperHeader}>
              Choose Column Filters
            </Box>
            <Box className={classes.sortContainer}>
              <Button
                disableRipple
                onClick={handleSort}
                className={classes.sortButton}
              >
                <Sort />
                <span>{props.sortState[props.columnID]}</span>
              </Button>
            </Box>
            <Box className={classes.minMaxBox}>
              <Input
                value={min.toString()}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
                disableUnderline
                className={classes.inputSearch}
                placeholder="Min"
                onChange={handleMinChange}
              />
              <Input
                value={max.toString()}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
                disableUnderline
                className={classes.inputSearch}
                placeholder="Max"
                onChange={handleMaxChange}
              />
            </Box>
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}
