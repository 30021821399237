import { RouteObject } from 'react-router-dom';
import Overview from '../components/pages/sharedComponents/overview/Overview';
import PaOverview from '../components/pages/performanceAttribution/overview/PAOverview.component';
import AifmdOverviewLegacy from '../components/pages/aifmd/overviewLegacy/AifmdOverviewLegacy.component';
import LiquidityOverview from '../components/pages/liquidity/overview/Overview';
import PaOverviewAlternative from '../components/pages/performanceAttribution/overviewAlternative/PAOverviewAlternative.component';
import SrriShareClassOverview from '../components/pages/ucits/srriShareClassOverview/SrriShareClassOverview';
import EsgOverview from '../components/pages/esg/esgOverview/EsgOverview.component';
import PeOverview from '../components/pages/privateEquity/peOverview/PeOverview.component';
import CouttsMarketOverview from '../components/pages/manco/couttsMarketOverview/CouttsMarketOverview';
import MancoDashboard from '../components/pages/manco/mancoDashboard/MancoDashboard.component';
import CoverageRatio from '../components/pages/manco/coverageRatio/CoverageRatio.component';
import RaptorLibrary from '../components/pages/utilities/RaptorLibrary/RaptorLibrary.component';
import Deceased from '../components/pages/ucits/deceased/Deceased';
import NurDeceased from '../components/pages/nurs/deceased/Deceased';
import SifRaifDeceased from '../components/pages/sif-raif/deceased/Deceased.component';
import GlobalExposure from '../components/pages/ucits/globalExposure/GlobalExposure';
import StatusChange from '../components/pages/ucits/statusChange/StatusChange.page';
import AggregateLiquidation from '../components/pages/liquidity/aggLiquidation/component';
import Functionality from '../components/pages/ucits/functionality/Functionality';
import RestrictedCompanies from '../components/pages/utilities/RestrictedCompanies/RestrictedCompanies.component';
import EMIR from '../components/pages/manco/emir/emir.component';
import AdepaEMIR from '../components/pages/manco/adepaEmir/adepaEmir.component';
import RaptorExport from '../components/pages/utilities/RaptorExport/RaptorExport.component';
import RaptorExportToday from '../components/pages/utilities/RaptorExport/RaptorExportToday.component';
import KPIMonitoring from '../components/pages/utilities/KPIMonitoring/KPIMonitoring.component';
import MancoExposure from '../components/pages/manco/mancoExposure/MancoExposure.component';
import ShareClassHedgeSummary from '../components/pages/sharedComponents/shareClassHedgeSummary/ShareClassHedgeSummary.component';
import ReportDeliveryOversight from '../components/pages/manco/reportDeliveryOversight/ReportDeliveryOversight.component';
import HedgeDerivativesSelector from '../components/pages/manco/hedgeDerivativesSelector/HedgeDerivativesSelector.component';
import HybridBondsSelector from '../components/pages/manco/hybridBondsSelector/HybridBondsSelector.component';
import MancoCounterpartyExposure from '../components/pages/manco/mancoCounterpartyExposure/MancoCounterpartyExposure.component';
import PositionOversight from '../components/pages/manco/positionOversight/PositionOversight.component';
import LiquidationTime from '../components/pages/manco/liquidationTime/LiquidationTime.component';
import MancoEquity from '../components/pages/manco/asset_class/equity/MancoEquity';
import MancoFixedIncome from '../components/pages/manco/asset_class/fixed_income/FixedIncome';
import MancoCis from '../components/pages/manco/asset_class/cis/Cis';
import KidsFigures from '../components/pages/raidr/kidsFigures/KidsFigures.component';
import MarketsOverview from '../components/pages/markets/MarketsOverview';
import YieldMain from '../components/pages/yieldCurve/YieldMain';
import QuaysideShareClassHedge from '../components/pages/reportsPages/quaysideShareClassHedge/QuaysideShareClassHedge.component';
import ReportGenerator from '../components/pages/performanceAttribution/reportGenerator/ReportGenerator.component';
import FixedIncomeReportGenerator from '../components/pages/utilities/FixedIncomeReprotGenerator/FixedIncomeReportGenerator.component';
import SanctionsScanner from '../components/pages/manco/sanctionsScanner/SanctionsScanner.component';
import GeneralOverviewPage from '../components/layout/general/GeneralOverviewPage';
import { GeneralPageWrapper } from '../components/layout/general/GeneralPageWrapper';
import AifmdOverview from '../components/pages/aifmd/overview/AifmdOverview.component';
import { OverviewsRoutePaths } from './page-routes';

// These are all the geneal overview pages routes
export const OverviewsRoutes = (data: any): RouteObject[] => [
  {
    path: OverviewsRoutePaths.OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'overview'}>
        <GeneralOverviewPage Component={Overview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.PA_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'pa-overview'}>
        <GeneralOverviewPage Component={PaOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.AIFMD_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'aifmd-overview'}>
        <GeneralOverviewPage Component={AifmdOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.AIFMD_OVERVIEW_LEGACY,
    element: (
      <GeneralPageWrapper pageId={'aifmd-overview-legacy'}>
        <GeneralOverviewPage Component={AifmdOverviewLegacy} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.LIQUIDITY_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'liquidity-overview'}>
        <GeneralOverviewPage Component={LiquidityOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.PA_OVERVIEW_ALTERNATIVE,
    element: (
      <GeneralPageWrapper pageId={'pa-overview-alternative'}>
        <GeneralOverviewPage Component={PaOverviewAlternative} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.SRRI_SHARE_CLASS_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'srri-share-class-overview'}>
        <GeneralOverviewPage Component={SrriShareClassOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.ESG_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'esg-overview'}>
        <GeneralOverviewPage Component={EsgOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.PE_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'pe-overview'}>
        <GeneralOverviewPage Component={PeOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.COUTTS_MARKET_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'coutts-market-overview'}>
        <GeneralOverviewPage Component={CouttsMarketOverview} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: OverviewsRoutePaths.MANCO_DASHBOARD,
    element: (
      <GeneralPageWrapper pageId={'manco-dashboard'}>
        <GeneralOverviewPage Component={MancoDashboard} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.COVERAGE_RATIO,
    element: (
      <GeneralPageWrapper pageId={'coverage-ratio'}>
        <GeneralOverviewPage Component={CoverageRatio} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.LIBRARY,
    element: (
      <GeneralPageWrapper pageId={'library'}>
        <GeneralOverviewPage Component={RaptorLibrary} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.DECEASED,
    element: (
      <GeneralPageWrapper pageId={'deceased'}>
        <GeneralOverviewPage Component={Deceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.NURS_DECEASED,
    element: (
      <GeneralPageWrapper pageId={'nurs-deceased'}>
        <GeneralOverviewPage Component={NurDeceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.SIF_RAIF_DECEASED,
    element: (
      <GeneralPageWrapper pageId={'sif-raif-deceased'}>
        <GeneralOverviewPage Component={SifRaifDeceased} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.GLOBAL_EXPOSURE,
    element: (
      <GeneralPageWrapper pageId={'global-exposure'}>
        <GeneralOverviewPage Component={GlobalExposure} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: OverviewsRoutePaths.STATUS_CHANGE,
    element: (
      <GeneralPageWrapper pageId={'status-change'}>
        <GeneralOverviewPage Component={StatusChange} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.AGGREGATE_LIQUIDATION_TIME,
    element: (
      <GeneralPageWrapper pageId={'aggregate-liquidation-time'}>
        <GeneralOverviewPage Component={AggregateLiquidation} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.FUNCTIONALITY,
    element: (
      <GeneralPageWrapper pageId={'functionality'}>
        <GeneralOverviewPage Component={Functionality} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: OverviewsRoutePaths.RESTRICTED_COMPANIES,
    element: (
      <GeneralPageWrapper pageId={'restricted-companies'}>
        <GeneralOverviewPage Component={RestrictedCompanies} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.EMIR,
    element: (
      <GeneralPageWrapper pageId={'emir'}>
        <GeneralOverviewPage Component={EMIR} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.ADEPA_EMIR,
    element: (
      <GeneralPageWrapper pageId={'adepa-emir'}>
        <GeneralOverviewPage Component={AdepaEMIR} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: OverviewsRoutePaths.RAPTOR_EXPORT,
    element: (
      <GeneralPageWrapper pageId={'raptor-export'}>
        <GeneralOverviewPage Component={RaptorExport} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.RAPTOR_EXPORT_TODAY,
    element: (
      <GeneralPageWrapper pageId={'raptor-export-today'}>
        <GeneralOverviewPage Component={RaptorExportToday} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.KPI_MONITORING,
    element: (
      <GeneralPageWrapper pageId={'kpi-monitoring'}>
        <GeneralOverviewPage Component={KPIMonitoring} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_EXPOSURE,
    element: (
      <GeneralPageWrapper pageId={'manco-exposure'}>
        <GeneralOverviewPage Component={MancoExposure} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.SHARE_CLASS_HEDGE_SUMMARY,
    element: (
      <GeneralPageWrapper pageId={'share-class-hedge-summary'}>
        <GeneralOverviewPage Component={ShareClassHedgeSummary} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.REPORT_DELIVERY_OVERSIGHT,
    element: (
      <GeneralPageWrapper pageId={'report-delivery-oversight'}>
        <GeneralOverviewPage Component={ReportDeliveryOversight} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.HEDGE_DERIVATIVES_SELECTOR,
    element: (
      <GeneralPageWrapper pageId={'hedge-derivatives-selector'}>
        <GeneralOverviewPage Component={HedgeDerivativesSelector} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.HYBRID_BONDS_SELECTOR,
    element: (
      <GeneralPageWrapper pageId={'hybrid-bonds-selector'}>
        <GeneralOverviewPage Component={HybridBondsSelector} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_COUNTERPARTY_EXPOSURE,
    element: (
      <GeneralPageWrapper pageId={'manco-counterparty-exposure'}>
        <GeneralOverviewPage Component={MancoCounterpartyExposure} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_POSITION_OVERSIGHT,
    element: (
      <GeneralPageWrapper pageId={'manco-position-oversight'}>
        <GeneralOverviewPage Component={PositionOversight} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_LIQUIDATION_TIME,
    element: (
      <GeneralPageWrapper pageId={'manco-liquidation-time'}>
        <GeneralOverviewPage Component={LiquidationTime} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_EQUITY,
    element: (
      <GeneralPageWrapper pageId={'manco-equity'}>
        <GeneralOverviewPage Component={MancoEquity} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_FIXED_INCOME,
    element: (
      <GeneralPageWrapper pageId={'manco-fixed-income'}>
        <GeneralOverviewPage Component={MancoFixedIncome} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MANCO_CIS,
    element: (
      <GeneralPageWrapper pageId={'manco-cis'}>
        <GeneralOverviewPage Component={MancoCis} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.MARKETS_OVERVIEW,
    element: (
      <GeneralPageWrapper pageId={'markets-overview'}>
        <GeneralOverviewPage Component={MarketsOverview} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.YIELD_CURVE,
    element: (
      <GeneralPageWrapper pageId={'yield-curve'}>
        <GeneralOverviewPage Component={YieldMain} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.QUAYSIDE_SHARE_CLASS_HEDGE,
    element: (
      <GeneralPageWrapper pageId={'quayside-share-class-hedge'}>
        <GeneralOverviewPage Component={QuaysideShareClassHedge} />
      </GeneralPageWrapper>
    ),
  },

  {
    path: OverviewsRoutePaths.PA_REPORT_GENERATOR,
    element: (
      <GeneralPageWrapper pageId={'pa-report-generator'}>
        <GeneralOverviewPage Component={ReportGenerator} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.FIXED_INCOME_REPORT_GENERATOR,
    element: (
      <GeneralPageWrapper pageId={'fixed-income-report-generator'}>
        <GeneralOverviewPage Component={FixedIncomeReportGenerator} />
      </GeneralPageWrapper>
    ),
  },
  {
    path: OverviewsRoutePaths.SANCTIONS_SCANNER,
    element: (
      <GeneralPageWrapper pageId={'sanctions-scanner'}>
        <GeneralOverviewPage Component={SanctionsScanner} />
      </GeneralPageWrapper>
    ),
  },
];
