import React, { FC } from 'react';
import { adjustLabel } from '../../controlBar/components/key-select/key-select.component';
import {
  IHistoricalTableData,
  generateHistoricalTableColumns,
  generateHistoricalTableData,
} from '../data-view.data';
import CustomTable from '../../../../../../tables/CustomTable';
import { DataObject } from '../../../../../../../types/redux/data/dataTypes';
import { ValuesType } from '../../controlBar/controlBar.component';

export interface HistoricalTableProps {
  dimensions: { height: number; width: number };
  inputData: DataObject;
  height: number;
  datesAndKeys: ValuesType;
  external: boolean;
}

export function buildTitleString(keysAndDates: ValuesType) {
  const { key1, key2 } = keysAndDates;
  const addKey1 =
    key1 === 'none_selected' ? '' : adjustLabel(key1 || 'none_selected');
  const addKey2 =
    key2 === 'none_selected' ? '' : adjustLabel(key2 || 'none_selected');

  return `${addKey1}${addKey1 && addKey2 ? ' and ' : ''}${addKey2}`;
}

const HistoricalTable: FC<HistoricalTableProps> = (props) => {
  const tableColumns = generateHistoricalTableColumns(props.inputData.data);
  const tableData = generateHistoricalTableData(props.inputData.data);
  const titleString = buildTitleString(props.datesAndKeys);
  const { key1, key2 } = props.datesAndKeys;

  const csvFields = (() => {
    const fields = ['Date'];
    if (key1 && key1 !== 'none_selected') fields.push(key1);
    if (key2 && key2 !== 'none_selected') fields.push(key2);
    return fields;
  })();

  const setHeight = props.height + 5;

  return (
    <CustomTable<IHistoricalTableData>
      data={tableData}
      options={{
        paging: false,
        maxBodyHeight: setHeight,
        minBodyHeight: setHeight,
        exportButton: true,
      }}
      csvFields={csvFields}
      toolbarComponents={{
        titleStyle: {
          fontSize: titleString.length > 30 ? '1.12rem' : '1.50rem',
          fontWeight: 300,
        },
        toolbarTitle: adjustLabel(titleString),
      }}
      showToolbar={true}
      columns={tableColumns}
    />
  );
};

export default HistoricalTable;
