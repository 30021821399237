import { useFormContext } from 'react-hook-form';
import { useEditableCellStyles } from '../styles/extras.styles';
import { ValidationObject } from '../types/editable.types';
import { useState } from 'react';
import clsx from 'clsx';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import SearchSelectComponent from './SearchSelectComponent';

interface EditableDropdownProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  validation?: ValidationObject;
  props: {
    updated: boolean;
    editOptions: {
      key_name: string;
    };
    setValue: (value: any) => void;
    value: any;
  };
  responseError?: boolean;
  setResponseError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitEdits: () => void;
  handleCancelEdits: () => void;
  showChange: boolean;
}

export const EditableDropdown: React.FC<EditableDropdownProps> = ({
  validation,
  props,
  responseError,
  setResponseError,
  showChange,
}) => {
  const classes = useEditableCellStyles();
  const {
    formState: { errors },
  } = useFormContext<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const id = open ? 'filter-popper' : undefined;

  const hasError =
    (errors && errors[props.editOptions.key_name]) || responseError;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <div
      className={clsx(classes.editableCell, {
        [classes.error]: hasError,
        [classes.updated]: !hasError && props.updated,
        [classes.changed]: !hasError && !props.updated && showChange, // Only applies if "updated" is false
      })}
      onClick={handleClick}
    >
      <Box>{props.value}</Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.mainPopper}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div>
            <Box className={classes.mainPopperHeader}>Update Information</Box>

            <Box display="flex" gap={1}>
              <SearchSelectComponent
                items={validation?.values || []}
                name={props.editOptions.key_name}
                setStateValue={props.setValue}
              />
            </Box>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
