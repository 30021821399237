import { createTheme, Shadows, Theme, ThemeOptions } from '@mui/material';
import { ThemeIcons } from './themeInterfaces/icons';

interface Status {
  danger: string;
}

interface Layout {
  drawerWidth: number;
}

interface Borders {
  light: string;
  main: string;
}

interface RaptorThemeOptions extends ThemeOptions {
  status: Status;
  layout: Layout;
  icons: ThemeIcons;
  borders?: Borders;
}
export interface RaptorTheme extends Theme {
  status: Status;
  layout: Layout;
  icons: ThemeIcons;
  borders?: Borders;
}

export enum mainColors {
  background = '#E7ECEF',
  mainBlue = '#274C77',
  mainBlue_lighter = '#396fad',
  mainGold = '#DBA11C',
  mainBlue_lightAlt = '#9dadbf',
  mainBlue_slightlyDarker = '#011D5B',
  mainBlue_Darker = '#011033',
  Fail = '#ED150E',
  Fail_darker = '#d9130d',
  Fail_even_darker = '#BB110B',
  Fail_veryLight = '#FDECEC',
  Alert = '#FC7315',
  Alert_darker = '#e36f09',
  Pass = '#38BF26',
  Pass_darker = '#31a822',
  Pass_veryLight = '#EAFCEC',
  NA = '#A7A7A7',
  NA_darker = '#999999',
  secondaryRed = '#d7263d',
  tertiaryGreen = '#4d8b31',
  fourthPurple = '#462255',
  // fourthPurple = '#efdd4a',
  fifthTangerine = '#FF8E72',
  pineGreen = '#0B7A75',
  sternRed = '#904E55',
  jonquil = '#E6C229',
  pumpkin = '#F17105',
  pastelRed = '#cc6464',
  pastelRed_lighter = '#D57E7E',
  pastelGreen = '#bacc64',
  pastelGreen_lighter = '#C6D57E',
  pastelBlue = '#8ac0c0',
  pastelBlue_lighter = '#A2CDCD',
  pastelYellow = '#ffd083',
  pastelYellow_lighter = '#FFE1AF',
  controlButtonBlue = '#1875d2',
  controlButtonBlue_lighter = '#428EDA',
  lightGrey = '#E8E8E8',
  inactiveGrey = '#A8A8A8',
  hoverOverWhite = '#F6F6F6',
  hoverOverWhite_darker = '#E8E8E8',
  hoverOverMainBlue = '#426184',
  hoverOverMainBlue_lighter = '#97BDE8',
  veryFaintBlue = '#F3F9FF',
  hoverOverVeryFaintBlue = '#E7F3FF',
  tartOrange = '#F24F52',
  pastelPink = '#B01E68',
  actionRequiredYellow = '#FFBF00',
  accentOrange = '#ED8B00',
  // Colors used for highlighti g different fields types in the raidr kids editor
  textFullWidth = '#7B113A',
  textColumn = '#150E56',
  sectionHeader = '#1597BB',
  sriChart = '#C70D3A',
  performanceScenariosTable = '#ED5107',
  costsOverTimeTable = '#230338',
  compositionOfCostsTable = '#FF5722',
  pageBreak = '#FF004D',
}

export const colorsArray = [
  mainColors.mainBlue,
  mainColors.sternRed,
  mainColors.jonquil,
  mainColors.pineGreen,
  mainColors.fourthPurple,
  mainColors.pumpkin,
  mainColors.tertiaryGreen,
];

export enum StatusColors {
  Fail = mainColors.Fail,
  Alert = mainColors.Alert,
  Pass = mainColors.Pass,
}

export enum greys {
  grey100 = '#f1f1f1',
  grey200 = '#dddddd',
  grey300 = '#cecece',
  grey400 = '#bababa',
  grey500 = '#a3a3a3',
  grey600 = '#8e8e8e',
  grey700 = '#777777',
  grey800 = '#606060',
  grey900 = '#44444',
  headerGrey = '#838383',
}

function createShadows(): Shadows {
  const shadows: Shadows = Array<string>(25).fill('none') as Theme['shadows'];
  shadows[1] = '0px 3px 3px rgba(0,0,0,0.3)';
  shadows[2] = '0px 3px 6px rgba(0,0,0,0.3)';
  shadows[3] = '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
  shadows[4] = '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)';
  shadows[5] = '0px 3px 6px rgb(0 0 0 / 30%)';
  return shadows;
}

const generateCustomTheme = () => {
  const customTheme: RaptorThemeOptions = {
    borders: {
      light: '1px solid #E7ECEF',
      main: `1px solid ${mainColors.mainBlue}`,
    },
    palette: {
      primary: {
        main: mainColors.mainBlue,
      },
      secondary: {
        main: mainColors.jonquil,
      },
      background: {
        default: '#E7ECEF',
        // default: mainColors.mainBlue
      },
      grey: {
        100: '#f1f1f1',
        200: '#dddddd',
        300: '#cecece',
        400: '#bababa',
        500: '#a3a3a3',
        600: '#8e8e8e',
        700: '#777777',
        800: '#606060',
        900: '#44444',
      },
    },
    transitions: {
      easing: {
        easeOut: 'ease-out',
      },
      duration: {
        enteringScreen: 200,
        leavingScreen: 200,
      },
    },
    layout: {
      drawerWidth: 240,
    },
    status: {
      danger: 'orange',
    },
    icons: {
      size: 20,
    },
    typography: {
      fontFamily: ['Jost'].join(','),
      htmlFontSize: 16,
      h1: {
        fontWeight: 400,
        fontSize: '1.75rem',
        color: mainColors.mainBlue,
      },
      h2: {
        fontSize: '1.50rem',
        color: mainColors.mainBlue,
        fontWeight: 300,
      },
      h3: {
        color: mainColors.mainBlue,
        fontSize: '1.00rem',
      },
      h4: {
        fontSize: '1.00rem',
        textTransform: 'uppercase',
        fontWeight: 700,
        color: greys.grey400,
      },
      h5: {
        fontSize: '0.88rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '1.00rem',
        // lineHeight: 'normal',
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        color: greys.headerGrey,
        textTransform: 'uppercase',
      },
    },
    shadows: createShadows(),
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.88rem',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            display: 'none',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: mainColors.mainBlue,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 8,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: 16,
            width: 'fit-content',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: mainColors.mainBlue,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0 0px 6px rgba(0,0,0,0.1)',
          },
          list: {
            padding: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&:not(:last-child)': {
              borderBottom: `1px solid #edeef0`,
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&.MuiTableSortLabel-active': {
              color: mainColors.mainBlue_lighter,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&&&&:hover:before': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: '0 0px 6px rgba(0,0,0,0.1)',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: mainColors.mainBlue,
            color: 'white',
          },
          clickable: {
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
          },
          deleteIcon: {
            color: 'white',
            '&:hover': {
              color: '#d8d8d8',
            },
          },
        },
      },
    },
  };

  return customTheme;
};

const theme = createTheme(generateCustomTheme());

export default theme;
