import makeStyles from '@mui/styles/makeStyles';
import { FC, ReactNode } from 'react';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { greys } from '../../../../../styling/theme';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import RaptorLoading from '../../../../feedback/RaptorLoading';
import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import RaptorSelectObject from '../../../../selects/RaptorSelectObject';

interface ReviewDocumentsButtonsProps {
  filteredOverviewData: KIDOverviewData[];
  children?: ReactNode;
  isPriipsKids: boolean;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
  selectContainer: {
    width: '100%',
    minWidth: '37.50rem',
    // marginLeft: '6.25rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  arrowSelect: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltipSelect: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const ReviewDocumentsButtons: FC<ReviewDocumentsButtonsProps> = ({
  filteredOverviewData,
  children,
  isPriipsKids,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  // This function will switch the document being reviewed when the user selects a different option from the dropdown
  function onSeletionChange(choice: any) {
    const root = isPriipsKids ? 'priips-kids-review' : 'ucits-kiids-review';

    navigate(
      `${root}?fundId=${choice.fund_id_string}&isin=${
        choice.share_class
      }&documentLanguage=${choice.document_language}&currentVersion=${
        choice.version
      }&publishedVersion=${choice.version.split('.')[0] + '.0.0'}`,
    );
  }

  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        classes={{ arrow: classes.arrowSelect, tooltip: classes.tooltipSelect }}
        title="Select Document for Review"
        placement={'top'}
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <div className={classes.selectContainer}>
          {filteredOverviewData.length > 0 ? (
            <RaptorSelectObject
              options={filteredOverviewData}
              onSelect={onSeletionChange}
              defaultOption={filteredOverviewData[0]}
              getDisplayValue={(item) =>
                `${item?.fund_name} - ${item?.share_class}`
              }
              maxWidth="75.00rem"
            />
          ) : (
            <RaptorLoading setWidth={'30px'} noMargin />
          )}
        </div>
      </Tooltip>
      {children}
    </div>
  );
};

export default ReviewDocumentsButtons;
