import { FC } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import BusinessInvolvementPanel from './subComponents/BusinessInvolvementPanel.comonent';
import ExposureBySectorPanel from './subComponents/ExposureBySectorPanel.component';
import ESGOverviewPanel from './subComponents/FundScores.component';
import GeographicalExposurePanel from './subComponents/GeographicalExposurePanel.component';
import ESGPortfolioOverviewPanel from './subComponents/OverviewPanel.component';
import { Grid, Typography } from '@mui/material';
import { useESGPortfolioData } from './services/queries';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import ErrorBoundary from '../../../utilityComponents/ErrorBoundary';
import { IESGPortfolioSummary } from './types/esg-portfolio.types';
import { es } from 'date-fns/locale';

const ESGPortfolioSummaryComponent: FC<FundInfoComponentProps> = ({
  fundName,
  fundId,
  positionDate,
}) => {
  const {
    data: esgData,
    isPending,
    error,
  } = useESGPortfolioData(fundId, positionDate);

  if (isPending) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading ESG Portfolio Data',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (error) {
    // Throw the error to trigger the ErrorBoundary
    throw error;
  }

  if ('no data' in esgData) {
    return <NoDataMessage message={`No Data Availble for ${positionDate}`} />;
  }

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <PortfolioSummary
        esgData={esgData}
        fundId={fundId}
        fundName={fundName}
        positionDate={positionDate}
      />
    </Grid>
  );
};

// Wrapping with ErrorBoundary outside the component
const ESGPortfolioSummary: FC<FundInfoComponentProps> = (props) => (
  <ErrorBoundary>
    <ESGPortfolioSummaryComponent {...props} />
  </ErrorBoundary>
);

export default ESGPortfolioSummary;
interface PortfolioSummaryProps {
  esgData: IESGPortfolioSummary | undefined;
  fundId: string;
  fundName: string;
  positionDate?: string;
}

// This is brought into a sperate component so we can check for cases when tehre is no data
const PortfolioSummary: FC<PortfolioSummaryProps> = ({
  esgData,
  fundId,
  fundName,
  positionDate,
}) => {
  const check = esgData && Object.keys(esgData).length > 0;

  return check ? (
    <>
      <ESGPortfolioOverviewPanel
        data={esgData}
        fundId={fundId}
        fundName={fundName}
        positionDate={positionDate}
      />
      <ESGOverviewPanel data={esgData} fundId={fundId} />
      <BusinessInvolvementPanel data={esgData} />
      <ExposureBySectorPanel esgData={esgData} />
      <GeographicalExposurePanel esgData={esgData} />
    </>
  ) : (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h1">{'No Data Available.'}</Typography>{' '}
    </DisplayAreaCenteredWrapper>
  );
};
