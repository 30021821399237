import React from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import makeStyles from '@mui/styles/makeStyles';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import NewUser from './subComponents/NewUser.component';
import UserList from './subComponents/UserList.component';

const useStyles = makeStyles(() => ({
  pageRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.38rem',
    gap: '1rem',
  },
}));

export const roleTranslator = (role: string): string | null => {
  switch (role) {
    case 'external_administrator':
      return 'Administrator';
    case 'external_individual':
      return 'Individual';
    default:
      return null;
  }
};

export const roleTranslatorReverse = (role: string): string | null => {
  switch (role) {
    case 'Administrator':
      return 'external_administrator';
    case 'Individual':
      return 'external_individual';
    default:
      return null;
  }
};

const ManageAccounts: React.FC<SettingsComponentProps> = () => {
  const classes = useStyles();

  return (
    <GeneralComponentErrorShield
      dataObjects={[]}
      customLoadingMessages={[
        'Loading Account Data...',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <div className={classes.pageRoot}>
        <NewUser />
        <UserList />
      </div>
    </GeneralComponentErrorShield>
  );
};

export default ManageAccounts;
