import React, { ReactElement, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import useFetchData from '../../../../hooks/useFetchData';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorFileDownloadButton, {
  RaptorFileDownloadButtonStatus,
} from '../../../feedback/RaptorFileDownloadButton.component';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Button } from '@mui/material';

interface PageLinkButtonProps {
  status: number;
  text?: string;
  section?: string;
  page?: string;
  fundId?: string;
  passValue: number;
  alertValue: number;
}

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

interface ReportButtonProps {
  fundId: string;
  startDate: string;
  endDate: string;
  fundName: string;
}

function getGradinetColor(
  score: number,
  passValue: number,
  alertValue: number,
) {
  if (score >= passValue)
    return { main: mainColors.Pass, hover: mainColors.Pass_darker };
  else return { main: mainColors.mainBlue, hover: mainColors.mainBlue };
  // else if (score < passValue && score > alertValue)
  //     return { main: mainColors.mainBlue_lighter, hover: mainColors.mainBlue };
  // else return { main: mainColors.Alert, hover: mainColors.Alert_darker };
}

function getStatusColors(value: Status) {
  switch (value) {
    case Status.Pass:
      return { main: mainColors.Pass, hover: mainColors.Pass_darker };
    case Status.Alert:
    case Status.PassAlert:
      return { main: mainColors.Alert, hover: mainColors.Alert_darker };
    case Status.Fail:
      return { main: mainColors.Fail, hover: mainColors.Fail_darker };
    case Status.NA:
    default:
      return { main: greys.grey400, hover: greys.grey400 };
  }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '12.50rem',
    '&:hover': {
      backgroundColor: (props: any) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '12.50rem',
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nonLinkStatusBox: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '12.50rem',
    borderRadius: '0.25rem',
    padding: '0.50rem',
    userSelect: 'none',
  },
}));

export function PAPageLinkButton({
  status,
  section,
  page,
  fundId,
  passValue,
  alertValue,
}: React.PropsWithChildren<PageLinkButtonProps>): ReactElement {
  const handleClick = () => {
    //TODO manage what clicking on this button does
    //   if (props.additionalHandler) props.additionalHandler();
  };
  const color = getGradinetColor(status, passValue, alertValue);
  const classes = useStyles({
    mainColor: color.main,
    mainColorHover: color.hover,
  });

  return section && page ? (
    <Link to={`/${section}/${page}${fundId ? `?fundId=${fundId}` : ``}`}>
      <Button onClick={handleClick} className={classes.PageLinkButtonRoot}>
        {percentageToTwoDecimalPlacesNoHundred(status)}
      </Button>
    </Link>
  ) : (
    <div className={classes.statusContainer}>
      <div className={classes.nonLinkStatusBox}>
        {percentageToTwoDecimalPlacesNoHundred(status)}
      </div>
    </div>
  );
}

const PAReportButton: React.FC<ReportButtonProps> = ({
  fundId,
  startDate,
  endDate,
  fundName,
}) => {
  const [status, setStatus] = useState<RaptorFileDownloadButtonStatus>('idle');

  function generateReport() {
    const openReport = openInNewTab(
      `${BASE_URL}generate_performance_attribution_report/${fundId}/${startDate}/${endDate}`,
    );
    openReport();
    setStatus('idle');
  }

  return (
    <div
      style={{
        display: 'flex',
        margin: 'auto',
        maxWidth: '12.50rem',
      }}
      onClick={() => {
        if (status === 'idle') {
          setStatus('loading');
          generateReport();
        }
      }}
    >
      <RaptorFileDownloadButton status={status} />
    </div>
  );
};

export interface PaOverviewTableData {
  internalFundName: string;
  fundName: string;
  currency: string;
  assetAllocation: number;
  stockSelection: number;
  currencyPandL: number;
  assetPandL: number;
  fxPandL: number;
  totalPandL: number;
  overUnderPerformance: number;
  index: number;
}

function buildPAOverviewDetailColumns(
  startDate: string,
  endDate: string,
  clientName: string | null,
): CustomColumn<PaOverviewTableData>[] {
  const detailColumns: CustomColumn<PaOverviewTableData>[] = [
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) =>
        clientName?.includes('mersenne')
          ? `Fund${rowData.index + 1}`
          : rowData.internalFundName,
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Report',
      field: 'positionDate',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAReportButton
          fundId={rowData.internalFundName}
          startDate={startDate}
          endDate={endDate}
          fundName={rowData.fundName}
        />
      ),
    },
    {
      title: 'Total Return',
      field: 'totalPandL',
      cellStyle: { textAlign: 'center', borderLeft: '1px solid #000' },

      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.totalPandL}
          section="pa"
          page={'sub-fund-performance'}
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'Asset Return',
      field: 'assetPandL',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.assetPandL}
          section="pa"
          page={'sub-fund-performance'}
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'Currency Return',
      field: 'currencyPandL',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.currencyPandL}
          section="pa"
          page={'sub-fund-performance'}
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'FX Return',
      field: 'fxPandL',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.fxPandL}
          section="pa"
          page={'sub-fund-performance'}
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'Implied Over/Under Performance',
      field: 'overUnderPerformance',
      cellStyle: { textAlign: 'center', borderLeft: '1px solid #000' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.overUnderPerformance}
          section="pa"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'Asset Allocation',
      field: 'assetAllocation',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.assetAllocation}
          section="pa"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
          page="asset-allocation-performance"
        />
      ),
    },
    {
      title: 'Stock Selection',
      field: 'stockSelection',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.stockSelection}
          section="pa"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
          page="stock-selection-performance"
        />
      ),
    },
    {
      title: 'FX Return',
      field: 'fxPandL',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.fxPandL}
          section="pa"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
  ];
  return detailColumns;
}

function buildPaOverviewTableData(data: any): PaOverviewTableData[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const paData = data.data[0];
  const tableData: PaOverviewTableData[] = [];
  paData.forEach((fund: any, index: number) => {
    tableData.push({
      internalFundName: fund.fund_name,
      fundName: fund.external_fund_name,
      currency: fund.fund_currency,
      assetAllocation: fund.asset_allocation,
      stockSelection: fund.stock_selection,
      currencyPandL: fund.currency_profit_and_loss,
      assetPandL: fund.asset_profit_and_loss,
      fxPandL: fund.fx_profit_and_loss,
      totalPandL: fund.total_profit_and_loss,
      overUnderPerformance: fund.over_under_performance,
      index,
    });
  });

  return tableData;
}

const PaOverviewAlternative: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const overviewData = useFetchData({
    url: `performance_attribution_overview_version_two/${fromDate}/${toDate}/`,
    keyName: `pa_overview_data__${fromDate}_${toDate}`,
    makeFetch: toDate ? true : false,
  });

  const tableHeight = 'calc(100vh - 15.62rem)';
  const dataPreparedForTable = buildPaOverviewTableData(overviewData);
  const clientName = useSelector(clientNameSelector);
  const detailColumns = buildPAOverviewDetailColumns(
    fromDate,
    toDate,
    clientName,
  );

  return (
    <GeneralComponentErrorShield dataObjects={[overviewData]}>
      <GridItem card xs={12}>
        <CustomTable<PaOverviewTableData>
          showToolbar
          loadingHeight={tableHeight}
          title="PA Overview Data"
          columns={detailColumns}
          data={dataPreparedForTable}
          options={{
            paging: false,
            search: true,
            exportButton: true,
            rowStyle: {
              padding: '0.62rem',
            },
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default PaOverviewAlternative;
