import { Navigate, RouteObject } from 'react-router-dom';
import RAIDRLayout from '../components/layout/layouts/RAIDRLayout.component';
import DelegatedActsEditor from '../components/pages/raidr/delegatedActs/editor/DelegatedActsEditor.component';
import DelegatedActsOverview from '../components/pages/raidr/delegatedActs/overview/DelegatedActsOverview.component';
import FactSheetsEditor from '../components/pages/raidr/factSheets/editor/FactSheetsEditor.component';
import FactSheetsOverview from '../components/pages/raidr/factSheets/overview/FactSheetsOverview';
import KidsFigures from '../components/pages/raidr/kidsFigures/KidsFigures.component';
import CompareKIDVersions from '../components/pages/raidr/kids/compareVersions/CompareKIDVersions';
import KidsEditor from '../components/pages/raidr/kids/editor/KidsEditor';
import KidsReview from '../components/pages/raidr/kids/review/KidsReview';
import KidsView from '../components/pages/raidr/kids/view/KidsView';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import { UnconventionalRedirect } from './Router';
import KidsOverview from '../components/pages/raidr/kids/overview/KidsOverview';
import { RaidrRoutePaths } from './page-routes';

export const RaidrRoutes = (data: any): RouteObject[] => [
  {
    path: RaidrRoutePaths.ROOT,
    element: <RAIDRLayout />,
    children: [
      {
        index: true,
        path: '',
        element: <UnconventionalRedirect hardSection={'raidr'} />,
      },
      {
        path: RaidrRoutePaths.PRIIPS_KIDS_OVERVIEW,
        element: <KidsOverview />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_OVERVIEW_EDIT,
        element: <KidsOverview />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_OVERVIEW_VIEW,
        element: <KidsOverview />,
      },
      {
        path: RaidrRoutePaths.PRIIPS_KIDS_EDITOR,
        element: <KidsEditor />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_EDITOR,
        element: <KidsEditor />,
      },
      {
        path: RaidrRoutePaths.PRIIPS_KIDS_REVIEW,
        element: <KidsReview />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_REVIEW,
        element: <KidsReview />,
      },
      {
        path: RaidrRoutePaths.PRIIPS_KIDS_COMPARE_VERSIONS,
        element: <CompareKIDVersions />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_COMPARE_VERSIONS,
        element: <CompareKIDVersions />,
      },
      {
        path: RaidrRoutePaths.PRIIPS_KIDS_VIEW,
        element: <KidsView />,
      },
      {
        path: RaidrRoutePaths.UCITS_KIIDS_VIEW,
        element: <KidsView />,
      },
      {
        path: RaidrRoutePaths.FACT_SHEETS,
        element: <Navigate to={RaidrRoutePaths.FACT_SHEETS_OVERVIEW} />,
      },
      {
        path: RaidrRoutePaths.FACT_SHEETS_OVERVIEW,
        element: <FactSheetsOverview />,
      },
      {
        path: RaidrRoutePaths.FACT_SHEETS_EDITOR,
        element: <FactSheetsEditor />,
      },
      {
        path: RaidrRoutePaths.DELEGATED_ACTS_OVERVIEW,
        element: <DelegatedActsOverview />,
      },
      {
        path: RaidrRoutePaths.DELEGATED_ACTS_EDITOR,
        element: <DelegatedActsEditor />,
      },
      {
        path: RaidrRoutePaths.KIDS_FIGURES,
        element: <KidsFigures />,
      },
      {
        path: RaidrRoutePaths.NOT_FOUND,
        element: <PageNotFound />,
      },
    ],
  },
];
