import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { Typography } from '@mui/material';

interface RisksystemEsgStarsProps {
  value: number;
}

interface StarChartPropsProps {
  value: number;
  index: number;
}

const StarChart = (props: StarChartPropsProps) => {
  const { value, index } = props;

  // Calculate the height the box should be based on how much should be filled green.
  const boxWidth = value * 24;
  return (
    <svg
      version="1.1"
      id="star_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id={`cut-off-star-${index}`}>
          <rect x="0" y="0" width={boxWidth} height="200" />
        </clipPath>
      </defs>

      <path
        id={`star_${index}`}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        fill={mainColors.jonquil}
      />
      <path
        id={`star_${index}`}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        fill={greys.grey400}
      />
      <use
        clipPath={`url(#cut-off-star-${index})`}
        xlinkHref={`#star_${index}`}
      />
    </svg>
  );
};

const useStyles = makeStyles(() => ({
  individualChart: {
    // marginBottom: '0.62rem',
    height: '7.50rem',
    width: '7.50rem',
    justifyContent: 'center',
  },
  starsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: '0.62rem',
    verticalAlign: 'center',
  },
  outerDiv: {
    display: 'flex-column',
    verticalAlign: 'center',
    justifyContent: 'space-between',
    marginTop: '6.25rem',
  },
}));

const RisksystemEsgStars = (props: RisksystemEsgStarsProps) => {
  const { value } = props;
  const classes = useStyles();
  // Check how many stars we have completely filled.
  const fullStars = Math.floor(value);
  // Check how many partially filled stars we have (if any).
  const partialValue = value % 1;
  // Check how many empty stars we have (if any).
  const emptyStars = Math.floor(5 - value);
  const stars = [];
  let check = value;
  for (let i = 0; i < 5; i++) {
    // Case where we need a full star
    if (check > 1) {
      stars.push(<StarChart value={1} index={i} />);
      check -= 1;
    } else if (check > 0) {
      // Case when we need a partially filled star
      stars.push(<StarChart value={check} index={i} />);
      check -= 1;
    } else {
      // Case when we need an empty star.
      stars.push(<StarChart value={0} index={i} />);
    }
  }
  return (
    <div className={classes.outerDiv}>
      <Typography variant="h2" align="center">
        {'RiskSystem ESG Stars'}
      </Typography>
      <div className={classes.starsContainer}>
        {stars.map((star, index) => (
          <div key={`star_${index}`} className={classes.individualChart}>
            {' '}
            {star}{' '}
          </div>
        ))}
      </div>
      <Typography variant="h2" align="center">{`${value.toFixed(
        1,
      )} / 5`}</Typography>
    </div>
  );
};

export default React.memo(RisksystemEsgStars);
