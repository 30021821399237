import makeStyles from '@mui/styles/makeStyles';
import React, { FC, ReactElement } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import CorporateGovernanceChart from '../esgExposure/esgExposureComponents/CorporateGovernanceChart';
import EnvironmentalChart from '../esgExposure/esgExposureComponents/EnvironmentalChart';
import ESGChart from '../esgExposure/esgExposureComponents/ESGChart';
import SocialChart from '../esgExposure/esgExposureComponents/SocialChart';
import useFetchData from '../../../../hooks/useFetchData';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { Typography } from '@mui/material';

interface ESGOverviewPanelProps {
  data: any;
  currentPage: string | null;
  fundId: string | null;
  fundName: string | null;
  positionDate?: string;
}

const useOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
  },
  chartContainer: {
    width: '60%',
  },
  scoreContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  basicInfoContainer: {
    width: '20%',
    justifyContent: 'center',
  },
  fundInfoLine: {
    display: 'flex',
  },
  individualChart: {
    marginBottom: '0.62rem',
    height: '12.50rem',
    width: '12.50rem',
    justifyContent: 'center',
  },
}));

function mapPageToScore(currentPage: string | null) {
  switch (currentPage) {
    case 'environmental-esg':
      return 'E_Score';
    case 'social-esg':
      return 'S_Score';
    case 'corporate-governance-esg':
      return 'G_Score';
    default:
      return 'ESG_Score';
  }
}

function createChartTitle(currentPage: string | null) {
  switch (currentPage) {
    case 'environmental-esg':
      return 'Historical Environmental Score';
    case 'social-esg':
      return 'Historical Social Score';
    case 'corporate-governance-esg':
      return 'Historical Corporate Governance Score';
    default:
      return 'Historical ESG Score';
  }
}

function createScoreChart(
  currentPage: string | null,
  currentScore: number,
): ReactElement {
  switch (currentPage) {
    case 'environmental-esg':
      return (
        <div
          style={{
            height: '15.62rem',
            width: '15.62rem',
            display: 'flex-column',
            marginLeft: '2.50rem',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1.25rem', fontWeight: 100 }}
          >
            Environmental Score
          </Typography>
          <EnvironmentalChart value={currentScore} />
          <Typography variant="h2" align="center" style={{ marginTop: '1.25rem' }}>
            {roundNumberToTwoDecimalPlaces(currentScore)}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            {' '}
            / 100.00
          </Typography>
        </div>
      );
    case 'social-esg':
      return (
        <div
          style={{
            height: '15.62rem',
            width: '18.75rem',
            display: 'flex-column',
            marginLeft: '1.25rem',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1.25rem', fontWeight: 100 }}
          >
            Social Score
          </Typography>
          <SocialChart value={currentScore} />
          <Typography variant="h2" align="center" style={{ marginTop: '1.25rem' }}>
            {roundNumberToTwoDecimalPlaces(currentScore)}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            {' '}
            / 100.00
          </Typography>
        </div>
      );
    case 'corporate-governance-esg':
      return (
        <div
          style={{
            height: '15.62rem',
            width: '15.62rem',
            display: 'flex-column',
            marginLeft: '2.50rem',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1.25rem', fontWeight: 100 }}
          >
            Corporate Governance Score
          </Typography>
          <CorporateGovernanceChart value={currentScore} />
          <Typography variant="h2" align="center" style={{ marginTop: '1.25rem' }}>
            {roundNumberToTwoDecimalPlaces(currentScore)}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            {' '}
            / 100.00
          </Typography>
        </div>
      );
    default:
      return (
        <div
          style={{
            height: '15.62rem',
            width: '15.62rem',
            display: 'flex-column',
            marginLeft: '2.50rem',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1.25rem', fontWeight: 100 }}
          >
            ESG Score
          </Typography>
          <ESGChart value={currentScore} />
          <Typography variant="h2" align="center" style={{ marginTop: '1.25rem' }}>
            {roundNumberToTwoDecimalPlaces(currentScore)}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            {' '}
            / 100.00
          </Typography>
        </div>
      );
  }
}

const ESGOverviewPanel: FC<ESGOverviewPanelProps> = ({
  data,
  currentPage,
  fundId,
  fundName,
  positionDate,
}) => {
  const classes = useOverviewPanelStyles();

  const timeSeriesData = useFetchData({
    url: `/get_fund_esg_timeseries/${fundId}/${positionDate}`,
    keyName: `esg_timeseries_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const currentScore =
    data.data[0].portfolio_scores[mapPageToScore(currentPage)];
  const ScoreComponent = createScoreChart(currentPage, currentScore);

  return (
    <GridItem xs={12} card>
      <div className={classes.componentsContainer}>
        <div className={classes.basicInfoContainer}>
          <Typography variant="h2" align="left">
            Fund Information
          </Typography>
          <div className={classes.fundInfoLine}>
            <Typography variant="h3">Fund Name: </Typography>
            <Typography
              variant="h3"
              style={{ color: greys.grey600, marginLeft: '0.62rem' }}
            >
              {fundName}
            </Typography>
          </div>
          <div className={classes.fundInfoLine}>
            <Typography variant="h3">NAV: </Typography>
            <Typography
              variant="h3"
              style={{ color: greys.grey600, marginLeft: '0.62rem' }}
            >
              {addCommasToNumbersAndRound(data.data[0]['nav'])}
            </Typography>
          </div>
        </div>

        <div className={classes.chartContainer}>
          <GeneralComponentErrorShield
            dataObjects={[timeSeriesData]}
            setLoadingHeight={'25.00rem'}
            customLoadingMessages={['Loading Historical Data...']}
            customErrorMessage={'No Historical Data Found'}
          >
            <HistoricalChart data={timeSeriesData} scoreType={currentPage} />
          </GeneralComponentErrorShield>
        </div>
        <div className={classes.scoreContainer}>{ScoreComponent}</div>
      </div>
    </GridItem>
  );
};

export default ESGOverviewPanel;

interface HistoricalChartProps {
  data: DataObject;
  scoreType: string | null;
}

interface OverviewTimeSeriesData {
  date: string;
  value: number;
}

function buildOverviewTimeseriesData(
  data: any,
  selectedPage: string | null,
): OverviewTimeSeriesData[] {
  if (!data.data.length) return [];
  if (!selectedPage) return [];

  if (!(mapPageToScore(selectedPage) in data.data[0])) return [];

  return data.data[0][mapPageToScore(selectedPage)].slice().reverse();
}

function mapScoreToColor(scoreKey: string | null) {
  switch (scoreKey) {
    case 'E_Score':
      return mainColors.Pass_darker;
    case 'S_Score':
      return mainColors.pumpkin;
    case 'G_Score':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const HistoricalChart: React.FC<HistoricalChartProps> = ({
  data,
  scoreType,
}) => {
  const timeSeriesData = buildOverviewTimeseriesData(data, scoreType);

  return (
    <>
      <GenericLineChart
        title={createChartTitle(scoreType)}
        data={timeSeriesData}
        showTitle
        export={false}
        id={'esg_historical_chart'}
        xAxisDataKey="date"
        yAxisTickFormatter={(value: number) =>
          roundNumberToTwoDecimalPlaces(value)
        }
        tooltipFormatter={(value: number) =>
          roundNumberToTwoDecimalPlaces(value)
        }
        lines={[
          {
            dataKey: 'value',
            color: mapScoreToColor(mapPageToScore(scoreType)),
          },
        ]}
        width={'100%'}
        height={400}
        showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        error={'No Historical ESG Data available'}
      />
    </>
  );
};
