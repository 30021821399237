import { FC, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DifferenceIcon from '@mui/icons-material/Difference';
import { greys, mainColors } from '../../../../../styling/theme';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useRaidrKids } from '../../kids/context/RaidrKidsContext';
import { RaidrRoutePaths } from '../../../../../routes/page-routes';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
    zIndex: 10,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
    maxWidth: 'none',
    pointerEvents: 'auto',
    zIndex: 10,
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginRight: '0.75rem',
    height: '3.12rem',
  },
  runButton: {
    display: 'flex',
    jsutifyContent: 'center',
    alignItems: 'center',
  },
  selectContainer: {
    width: '100%',
    minWidth: '6.25rem',
  },
  trackedChangesContainer: {
    display: 'flex',
    borderRadius: '10px',
    padding: '5px',
    gap: '5px',
    boxSizing: 'border-box',
  },
}));

interface TrackedChangesButtonProps {
  fundId: string | null;
  shareClass: string;
  documentLanguage: string;
  versionNumbers: string[];
  initialComparisonVersion: string;
  initialTargetVersion: string;
}

const TrackedChangesButton: FC<TrackedChangesButtonProps> = ({
  fundId,
  shareClass,
  documentLanguage,
  versionNumbers,
  initialComparisonVersion,
  initialTargetVersion,
}) => {
  const classes = useStyles();
  const { isPriips } = useRaidrKids();
  const navigate = useNavigate();
  const [targetVersion, setTargetVersion] = useState(initialTargetVersion);
  const [comparisonVersion, setComparisonVersion] = useState(
    initialComparisonVersion,
  );

  const soretedVersionNumbers = versionNumbers.sort((a: string, b: string) => {
    // Split the version numbers into their parts
    const aParts = a.split('.');
    const bParts = b.split('.');
    // Compare the major version numbers (id not equal)
    if (aParts[0] !== bParts[0]) {
      return parseInt(aParts[0]) - parseInt(bParts[0]);
    } else if (aParts[1] !== bParts[1]) {
      // Compare the minor version numbers (if not equal)
      return parseInt(aParts[1]) - parseInt(bParts[1]);
    } else {
      // Compare the patch version numbers (if not equal)
      return parseInt(aParts[2]) - parseInt(bParts[2]);
    }
  });

  return (
    <div className={classes.trackedChangesContainer}>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Compariosn Version (Older)"
        placement={'top'}
        arrow
      >
        <div className={classes.selectContainer}>
          <RaptorSelect
            options={soretedVersionNumbers}
            handleChoice={setComparisonVersion}
            defaultOption={comparisonVersion}
          />
        </div>
      </Tooltip>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Target Version (Newer)"
        placement={'top'}
        arrow
      >
        <div className={classes.selectContainer}>
          <RaptorSelect
            options={soretedVersionNumbers}
            handleChoice={setTargetVersion}
            defaultOption={targetVersion}
          />
        </div>
      </Tooltip>
      <div className={classes.runButton}>
        <Tooltip
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title="Please choose two different version numbers to compare changes."
          placement="top"
          arrow
          disableHoverListener={targetVersion !== comparisonVersion}
        >
          {targetVersion !== comparisonVersion ? (
            <Link
            to={
              isPriips
                ? `${RaidrRoutePaths.PRIIPS_KIDS_COMPARE_VERSIONS}?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}&targetVersion=${targetVersion}&comparisonVersion=${comparisonVersion}`
                : `${RaidrRoutePaths.UCITS_KIIDS_COMPARE_VERSIONS}?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}&targetVersion=${targetVersion}&comparisonVersion=${comparisonVersion}`
            }

              style={{ textDecoration: 'none' }} // Prevents default underline on links
            >
              <DifferenceIcon
                sx={{
                  fontSize: '1.88rem',
                  color: 'white',
                  backgroundColor: mainColors.mainBlue,
                  borderRadius: 1,
                  padding: '0.12rem',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: mainColors.mainBlue_lighter,
                  },
                }}
              />
            </Link>
          ) : (
            <DifferenceIcon
              sx={{
                fontSize: '1.88rem',
                color: 'white',
                backgroundColor: greys.grey400,
                borderRadius: 1,
                padding: '0.12rem',
                cursor: 'not-allowed',
              }}
            />
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default TrackedChangesButton;
