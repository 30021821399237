import { Fragment, ReactElement, useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { makeStyles } from '@mui/styles';
import {
  ifNumberAddCommasAndRound,
  ifNumberRoundToNDecimalPlaces,
} from '../../../utilities/numberFormatters';
import { mainColors, RaptorTheme } from '../../../styling/theme';
import {
  customSortingFn,
  fuzzyFilter,
} from '../../tables/ultraTable/utils/ultratable.utils';

export type UnderlyingPosition = {
  name: string;
  instrument_title: string;
  client_price: number;
  delta: string;
  position_size: string;
  risk_factor: string;
  bc_exposure: number;
  fund_id: string;
  fund_name_long: string;
};

// second level positions table props
interface UltraSubTableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
}

// Function for building the table data, will return list of UnderlyingPosition rows
function buildUnderlyingExposureTableData(data: any): UnderlyingPosition[] {
  const tableData: UnderlyingPosition[] = [];

  data.position_level.forEach((asset: any) => {
    tableData.push({
      name: asset.name,
      instrument_title: asset.instrument_title || 'N/A',
      client_price: asset.client_price,
      delta: asset.delta,
      position_size: asset.position_size,
      risk_factor: asset.risk_factor,
      bc_exposure: asset.bc_exposure,
      fund_id: asset.fund_id,
      fund_name_long: asset.fund_name_long,
    });
  });
  return tableData;
}

const underlyingPositionColumns: ColumnDef<UnderlyingPosition>[] = [
  {
    accessorKey: 'name',
    header: () => 'Name',
  },
  {
    accessorKey: 'instrument_title',
    header: () => 'Instrument Title',
  },
  {
    accessorKey: 'client_price',
    header: () => 'Price',
    cell: (props) => ifNumberAddCommasAndRound(props.getValue()),
  },
  {
    accessorKey: 'delta',
    header: () => 'Delta',
    cell: (props) => ifNumberRoundToNDecimalPlaces(props.getValue(), 3),
  },
  {
    accessorKey: 'position_size',
    header: () => 'Size',
    cell: (props) => ifNumberAddCommasAndRound(props.getValue()),
  },
  {
    accessorKey: 'risk_factor',
    header: () => 'Risk Factor',
  },
  {
    accessorKey: 'bc_exposure',
    header: () => 'Base Exposure',
    cell: (props) => ifNumberAddCommasAndRound(props.getValue()),
  },
  {
    accessorKey: 'fund_id',
    header: () => 'Fund ID',
  },
  {
    accessorKey: 'fund_name_long',
    header: () => 'Fund Name',
  },
];

// styles for second level positions table
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  border: {
    borderRight: `2px solid ${mainColors.mainBlue}`,
    borderBottom: `2px solid ${mainColors.mainBlue}`,
    borderLeft: `2px solid ${mainColors.mainBlue}`,
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    padding: '0.31rem',
  },
  table: {
    tableLayout: 'fixed',
    minWidth: '100%',
    border: `1px solid ${mainColors.mainBlue}`,
  },
  tableHead: {
    position: 'sticky',
    borderTop: '1px solid grey',
    backgroundColor: mainColors.veryFaintBlue,
  },
  row: {
    display: 'flex',
    paddingY: '0.62rem',
    borderTop: '1px solid grey',
  },
  headerRow: {
    display: 'flex',
    paddingY: '0.62rem',
  },
  cell: {
    ...theme.typography.h3,
    textAlign: 'center',
    width: '100%',
    minWidth: '3.12rem',
    minHeight: '1.88rem',
    padding: '0.62rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.62rem',
    color: 'black',
  },
  columnHead: {
    ...theme.typography.h3,
    fontWeight: 600,
    width: '100%',
    minHeight: '1.88rem',
    padding: '0.62rem 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.25rem',
    fontSize: '0.75rem',
  },
  noDataMessage: {
    color: mainColors.mainBlue,
    margin: '1.25rem 1.88rem',
  },
}));

export default function UnderlyingPositionTable(props: any): ReactElement {
  const classes = useStyles();

  const data = useMemo(
    () => buildUnderlyingExposureTableData(props.data),
    [buildUnderlyingExposureTableData, props.data],
  );

  // build simple react-table object
  const table = useReactTable<UnderlyingPosition>({
    data,
    columns: underlyingPositionColumns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    sortingFns: {
      customSortingFn: customSortingFn,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  // if items in data array render, else render message telling user no records to display
  return data.length ? (
    <div className={classes.border}>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={classes.headerRow}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} className={classes.columnHead}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr className={classes.row}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={classes.cell}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <h2 className={classes.noDataMessage}>No records to display</h2>
  );
}
