import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FunctionComponent } from 'react';
import { ultraTableStyles } from '../../styles/ultratable.styles';
import { Tooltip } from '@mui/material';

interface PortalToolsProps {
  toolBarBtns?: JSX.Element[];
}

const PortalTools: FunctionComponent<PortalToolsProps> = ({ toolBarBtns }) => {
  const classes = ultraTableStyles();

  return (
    <Menu as="div">
      <Tooltip
        classes={{ tooltip: classes.columnHeadTooltip }}
        title="More tools"
        placement="top"
        enterDelay={500}
      >
        <MenuButton className="group flex h-10 w-10 items-center justify-center rounded-full bg-white p-2 transition duration-200 ease-in-out hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-mainblue/50 active:bg-gray-100">
          <MoreVertIcon className="size-5 text-gray-400 transition duration-200 ease-in-out active:text-gray-300 group-hover:text-gray-800" />
        </MenuButton>
      </Tooltip>

      {/* Here we can pass the consistant buttons for now we just render the passed ones */}
      <MenuItems
        transition
        anchor="bottom end"
        className="z-50 origin-top-right rounded-xl border border-black/5 bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {toolBarBtns?.map((child, i) => <MenuItem key={i}>{child}</MenuItem>)}
      </MenuItems>
    </Menu>
  );
};

export default PortalTools;
