import { FunctionComponent } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';

const PortalWarning: FunctionComponent = () => {
  return (
    <GridItem xs={12} card>
      <div className="p-4">
        <h1 className="text-xl font-semibold text-blue-900 mb-3">
          How to use the Position Update Portal
        </h1>
        <p className="text-blue-900 mb-3">
          This page allows you to update the positions of the fund. To update a
          position, follow these steps:
        </p>
        <ol className="list-decimal pl-8 mb-4 text-blue-900">
          <li className="mb-1">Click on the cell you would like to update</li>
          <li className="mb-1">Type in the new value</li>
          <li className="mb-1">Click the green tick to submit the changes</li>
        </ol>
        
        <div className="bg-blue-50 border-l-4 border-blue-600 p-4 rounded-r-md">
          <p className="font-semibold text-blue-800 mb-2">
            Important to note
          </p>
          <p className="text-blue-700 mb-2">
            By default, submitted changes will only affect calculations on the next date the
            fund is run.
          </p>
          <p className="text-blue-700">
            If you would like to recalculate the current date using the changes you
            have made, please click the "Request Recalculation" button, found in the
            "More Tools" dropdown menu.
          </p>
        </div>
      </div>
    </GridItem>
  );
};

export default PortalWarning;
