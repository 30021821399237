import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createSectionByIdSelector,
  createSectionFundsSelector,
} from '../../../../../../redux/pages/selectors';
import { RaptorTheme, greys } from '../../../../../../styling/theme';
import { BASE_URL } from '../../../../../../utilities/requestClient';
import GeneralSelect from '../../../../../selects/GeneralSelect';
import { FunctionalityHandlerProps } from '../../Functionality';
import FunctionalityButton from '../functionalityButton';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import RaptorDatePicker from '../../../../../selects/RaptorDatePicker.component';
import useFetchData from '../../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../../general/GeneralComponentErrorShield';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { getAllRelevantWeekdays } from '../../../../../../utilities/dateUtilities';
import { getPositionsDates } from '../../../../../../routes/endpoints/general.routes';

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  fdiContainer: {
    display: 'flex',
    position: 'relative',
    top: '0.62rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
    height: '3.12rem',
  },
  header: {
    position: 'relative',
    // top: '0.31rem',
  },
}));

function CombinedReports(props: FunctionalityHandlerProps): ReactElement {
  // Classes for fdiContainer
  const classes = useStyles();
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits'),
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];

  const [currentSelection, setCurrentSelection] = useState('none_selected');
  function updateSelection(selection: string) {
    setCurrentSelection(selection);
  }

  const [weekdays] = useState<string[]>(getAllRelevantWeekdays());
  const [selectedDate, setSelectedDate] = useState(weekdays[0]);
  const [pickerDates, setPickerDates] = useState(weekdays);
  // Create a function for generating the url and calling the report when the button is pressed
  // const requestClient = reportsClient();
  const { showAsyncSnackbar } = useSnackbar();
  // /generate_adepa_report/<fund_id>/<report_date></report_date>
  function generateReport() {
    const openReport = openInNewTab(
      `${BASE_URL}generate_adepa_report/${currentSelection}/${selectedDate}/${currentSelection}_${selectedDate.replace(
        '-',
        '_',
      )}.pdf`,
    );
    openReport();
  }
  async function handleRequest() {
    const url = `${BASE_URL}pdf_generator/${currentSelection}/${selectedDate}`;
    const func = openInNewTab(url);
    await showAsyncSnackbar({
      startMessage: 'Generating Daily Report',
      successMessage: 'Report Sent',
      failureMessage: 'Failed to generate report',
      promiseToResolve: func(),
    });
    // await requestClient.get(url);
  }
  // async function handleRequest() {

  //   // const url = `${BASE_URL}pdf_generator/${currentSelection}/${}`;
  //   // const func = openInNewTab(url);
  //   await showAsyncSnackbar({
  //     startMessage: 'Generating Daily Report',
  //     successMessage: 'Report Sent',
  //     failureMessage: 'Failed to generate report',
  //     promiseToResolve: generateReport(),
  //   });
  //   // await requestClient.get(url);
  // }

  const sectionDetails = useSelector(createSectionByIdSelector(section ?? ''));

  const dates = getPositionsDates(sectionDetails);

  useEffect(() => {
    if (!dates) return;
    if (!dates.data) return;
    if (!dates.data.length) return;
    if (!currentSelection) return;
    if (currentSelection === 'none_selected') return;
    const datesData = dates.data[0];
    // Check if the fundId is in the datesData
    if (!Object.keys(datesData).includes(currentSelection)) return;
    // If it is, return the dates
    // setSelectedDate(datesData[currentSelection][0])
    setPickerDates(datesData[currentSelection]);
  }, [currentSelection]);

  // function getDatesToUseInPicker(fundId: string) {
  //   if(!dates) return weekdays;
  //   if(!dates.data) return weekdays;
  //   if(!dates.data.length) return weekdays;
  //   if(fundId === "none_selected") return weekdays;
  //   const datesData = dates.data[0];
  //   // Check if the fundId is in the datesData
  //   if(!Object.keys(datesData).includes(fundId)) return weekdays;
  //   // If it is, return the dates
  //   return datesData[fundId];
  // }

  return (
    <GeneralComponentErrorShield dataObjects={[dates]}>
      <GridItem
        card
        xs={props.size}
        cardStyle={{ height: '100%', padding: '1.25rem' }}
      >
        <div className={classes.header}>
          <Typography
            variant="h2"
            style={{
              fontSize: '1.50rem',
              width: '100%',
              fontWeight: 500,
              textAlign: 'left',
            }}
            className={classes.header}
          >
            {props.cardTitle}
          </Typography>
        </div>
        <div className={classes.fdiContainer}>
          <GeneralSelect
            labels={fundNames}
            values={fundIds}
            selected={currentSelection}
            setSelected={updateSelection}
            placeholderValue="None Selected"
            placeholderColor={greys.grey900}
            selectTitle={'Select a fund'}
            customStyles={{
              pickerRoot: classes.pickerRoot,
            }}
          />
          {currentSelection !== 'none_selected' && (
            <>
              <RaptorDatePicker
                // key={picker.titleBarKey}
                title={'Position Date'}
                handler={(val) => {
                  setSelectedDate(dayjs(val).format('YYYY-MM-DD'));
                }}
                datesToInclude={pickerDates}
                selectedDate={dayjs(selectedDate)}
                tiedToActiveDate={false}
              />

              <FunctionalityButton
                handler={generateReport}
                title="Run PDF Report"
                disabled={!pickerDates.includes(selectedDate)}
              />
            </>
          )}
        </div>
      </GridItem>
    </GeneralComponentErrorShield>
  );
}

export default CombinedReports;
