import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { mainColors } from '../../../../../styling/theme';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { Button, Theme } from '@mui/material';

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => mainColors[props.status],
    color: (props) => (props.color ? props.color : 'white'),
    width: '100%',
    maxWidth: '12.50rem',
    '&:hover': {
      backgroundColor: (props) => mainColors[props.statusDarker],
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

export interface PageLinkButtonProps {
  status: Status;
  fundName?: string;
  shareClass?: number;
  page: string;
  section: string;
  text?: string;
  textColor?: string | null;
  setTitleBarSelect?: {
    selectedValue: string;
  };
  additionalHandler?: () => void;
}

interface StyleProps {
  status: keyof typeof mainColors;
  statusDarker: keyof typeof mainColors;
  color: string | null;
}

function PageLinkButton({
  status,
  section,
  page,
  ...props
}: React.PropsWithChildren<PageLinkButtonProps>): ReactElement {
  const handleClick = () => {
    //TODO manage what clicking on this button does
    if (props.additionalHandler) props.additionalHandler();
  };

  const classes = useStyles({
    status,
    statusDarker: `${status}_darker`,
    color: props.textColor || null,
  } as StyleProps);
  return (
    <Link
      to={`/${section}/${page}${
        props.fundName
          ? `?fundId=${props.fundName}&shareClass=${props.shareClass}`
          : ``
      }`}
      className={classes.link}
    >
      <Button onClick={handleClick} className={classes.PageLinkButtonRoot}>
        {props.text || status}
      </Button>
    </Link>
  );
}

export default PageLinkButton;
