import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { Status } from '../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import FixedRstTable from '../../liquidity/rstFixed/components/fixedRstTable.component';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import RstCell, { RstStatus } from './RstCell';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';
import { specifyStatus } from '../../../../utilities/generalMappings';

export interface AifRestrictionsUnderRedemptionsData {
  scenario: number;
  nav: number;
  market: Status;
  counterparty: Status;
  credit: Status;
  marketLiquidity: Status;
  fundingLiquidity: Status;
}

interface TimeSeriesDataUnderRedemptionsData {
  scenario: number;
  nav: number;
  valueAtRisk: number;
  correlation: number;
  ssd: number;
}

interface StatusBreakdownData {
  scenario: number;
  market: AifmdData;
  counterparty: AifmdData;
  credit: AifmdData;
  marketLiquidity: AifmdData;
  fundingLiquidity: AifmdData;
}

type AifmdData = {
  current: RstStatus;
  forecast: RstStatus;
  scenario: RstStatus;
  stress: RstStatus;
};

const statusBreakdownColumns: CustomColumn<StatusBreakdownData>[] = [
  {
    title: 'Scenario',
    field: 'scenario',
    width: 1,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Market',
    field: 'market',
    render: (rowData) => <RstCell {...rowData.market} />,
    width: '20%',
    cellStyle: {
      padding: 0,
      verticalAlign: 'initial',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Counterparty',
    field: 'counterparty',
    render: (rowData) => <RstCell {...rowData.counterparty} />,
    width: '20%',
    cellStyle: {
      padding: 0,
      verticalAlign: 'initial',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Credit',
    field: 'credit',
    render: (rowData) => <RstCell {...rowData.credit} />,
    width: '20%',
    cellStyle: {
      padding: 0,
      verticalAlign: 'initial',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Market Liquidity',
    field: 'marketLiquidity',
    render: (rowData) => <RstCell {...rowData.marketLiquidity} />,
    width: '20%',
    cellStyle: {
      padding: 0,
      verticalAlign: 'initial',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Funding Liquidity',
    field: 'fundingLiquidity',
    render: (rowData) => <RstCell {...rowData.fundingLiquidity} />,
    width: '20%',
    cellStyle: {
      padding: 0,
      verticalAlign: 'initial',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

const aifRestrictionsColumns: CustomColumn<AifRestrictionsUnderRedemptionsData>[] =
  [
    {
      title: 'Scenario',
      field: 'scenario',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'NAV',
      field: 'nav',
      render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
      cellStyle: {
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '9.38rem',
    },
    {
      title: 'Market',
      field: 'market',
      render: (rowData: AifRestrictionsUnderRedemptionsData) => (
        <RaptorStatusBox status={specifyStatus(rowData.market)} />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Counterparty',
      field: 'counterparty',
      render: (rowData: AifRestrictionsUnderRedemptionsData) => (
        <RaptorStatusBox status={specifyStatus(rowData.counterparty)} />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Credit',
      field: 'credit',
      render: (rowData: AifRestrictionsUnderRedemptionsData) => (
        <RaptorStatusBox status={specifyStatus(rowData.credit)} />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Market Liquidity',
      field: 'marketLiquidity',
      render: (rowData: AifRestrictionsUnderRedemptionsData) => (
        <RaptorStatusBox status={specifyStatus(rowData.marketLiquidity)} />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Funding Liquidity',
      field: 'fundingLiquidity',
      render: (rowData: AifRestrictionsUnderRedemptionsData) => (
        <RaptorStatusBox status={specifyStatus(rowData.fundingLiquidity)} />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

const timeSeriesDataColumns: CustomColumn<TimeSeriesDataUnderRedemptionsData>[] =
  [
    {
      title: 'Scenario',
      field: 'scenario',
      width: 1,
    },
    {
      title: 'NAV',
      field: 'nav',
      render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
      width: '9.38rem',
    },
    {
      title: 'VaR',
      field: 'valueAtRisk',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.valueAtRisk),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Correlation',
      field: 'correlation',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.correlation),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'SSD',
      field: 'ssd',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.ssd),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
enum TableId {
  AIF_RESTRICTIONS = 'aifRestrictions',
  TIME_SERIES = 'timeSeries',
  STATUS_BREAKDOWN = 'statusBreakdown',
}
const areaKeyMap = {
  Counterparty: 'counterparty',
  Credit: 'credit',
  'Funding Liquidity': 'fundingLiquidity',
  Market: 'market',
  'Market Liquidity': 'marketLiquidity',
};
const tableIdMap: { [key in TableId]: string } = {
  aifRestrictions: 'aifmd_list',
  timeSeries: 'dynamic_list',
  statusBreakdown: 'aifmd_data',
};
function buildData(
  data: any[],
  id: TableId,
):
  | AifRestrictionsUnderRedemptionsData[]
  | TimeSeriesDataUnderRedemptionsData[]
  | StatusBreakdownData[] {
  if (!data || !data.length) return [];
  try {
    const dataForUse = Array.isArray(data[0][tableIdMap[id]])
      ? [...data[0][tableIdMap[id]]]
      : { ...data[0][tableIdMap[id]] };
    // remove the header row
    if (Array.isArray(dataForUse)) {
      dataForUse.shift();
    }
    switch (id) {
      case TableId.AIF_RESTRICTIONS:
        return dataForUse.map((datum: any) => {
          const [
            scenario,
            nav,
            market,
            counterparty,
            credit,
            marketLiquidity,
            fundingLiquidity,
          ] = datum;
          return {
            scenario: scenario + 1,
            nav,
            market: specifyStatus(market),
            counterparty: specifyStatus(counterparty),
            credit: specifyStatus(credit),
            marketLiquidity: specifyStatus(marketLiquidity),
            fundingLiquidity: specifyStatus(fundingLiquidity),
          };
        });
      case TableId.TIME_SERIES:
        return dataForUse.map((datum: any) => {
          const [scenario, nav, valueAtRisk, correlation, ssd] = datum;
          return {
            scenario: scenario + 1,
            nav,
            valueAtRisk,
            correlation,
            ssd,
          };
        });
      case TableId.STATUS_BREAKDOWN:
        // first get the number of rows
        const numberOfRows = dataForUse.Counterparty.Current[0].length;
        const dataToReturn = [];
        /*
         * Then in each row we're going to need
         * market: {
         *
         * }
         * counterparty
         * credit
         * marketLiquidity and
         * fundingLiquidity
         *
         */
        for (let i = 0; i < numberOfRows; i++) {
          const finalRowObject: any = {};
          finalRowObject['scenario'] = i + 1;
          for (const area of [
            'Counterparty',
            'Credit',
            'Funding Liquidity',
            'Market',
            'Market Liquidity',
          ]) {
            const {
              Current: current,
              Forecast: forecast,
              Scenario: scenario,
              Stress: stress,
            } = dataForUse[area];
            // here the final dataKey is counterparty, credit etc
            //@ts-ignore
            const finalDataKey = areaKeyMap[area];
            finalRowObject[finalDataKey] = {
              current: {
                status: current[0][i],
                message: current[1][i],
              },
              forecast: {
                status: forecast[0][i],
                message: forecast[1][i],
              },
              scenario: {
                status: scenario[0][i],
                message: scenario[1][i],
              },
              stress: {
                message: stress[1][i],
              },
            };

            /*
             * Here we need counterparty =  {
             *   current:
             *   forecast
             *   scenario:
             *   stress - and for each of those keys we need an array of messages. We get this array of messages by splitting by br
             * }
             *
             */
          }
          dataToReturn.push(finalRowObject);
        }
        return dataToReturn;

      default:
        return [];
    }
  } catch (err) {
    return [];
  }
}

function getPositionDateFromData(aifRstData: any) {
  if (!aifRstData) return '';
  if (!aifRstData.data) return '';
  if (!aifRstData.data.length) return '';
  if (typeof aifRstData.data[0] === 'string') return '';
  if (!('selected_position_date' in aifRstData.data[0])) return '';
  return aifRstData.data[0].selected_position_date;
}

const AifRstFixed: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
  ...props
}) => {
  const prefix = positionDate
    ? 'stored_report_data_agg'
    : 'most_recently_stored_report_data_agg';
  const urlBase = 'liquidity_reverse_stress_test';
  const formattedUrl = `${prefix}/${fundId}/${urlBase}${
    positionDate ? `/${positionDate}` : ''
  }`;
  const aifRstData = useFetchData({
    keyName: `${urlBase}_${fundId}_${
      positionDate ? positionDate : formatDateForCheckingState(new Date())
    }`,
    url: formattedUrl,
    makeFetch: true,
  });
  const aifData = buildData(aifRstData?.data, TableId.AIF_RESTRICTIONS);
  const timeSeriesData = buildData(aifRstData?.data, TableId.TIME_SERIES);
  const statusChangeData = buildData(
    aifRstData?.data,
    TableId.STATUS_BREAKDOWN,
  );
  const positionDateToUse = getPositionDateFromData(aifRstData);

  return (
    <GeneralComponentErrorShield dataObjects={[aifRstData]}>
      <FixedRstTable<AifRestrictionsUnderRedemptionsData>
        positionDate={positionDateToUse}
        columns={aifRestrictionsColumns}
        data={aifData as AifRestrictionsUnderRedemptionsData[]}
        fixed={6}
        title="AIF Restrictions under Redemptions"
      />
      <FixedRstTable<TimeSeriesDataUnderRedemptionsData>
        positionDate={positionDateToUse}
        columns={timeSeriesDataColumns}
        data={timeSeriesData as TimeSeriesDataUnderRedemptionsData[]}
        fixed={6}
        title="Time Series Data under Redemptions"
      />
      <FixedRstTable<StatusBreakdownData>
        positionDate={positionDateToUse}
        columns={statusBreakdownColumns}
        data={statusChangeData as StatusBreakdownData[]}
        fixed={12}
        title="Status Breakdown"
      />
    </GeneralComponentErrorShield>
  );
};

export default AifRstFixed;
