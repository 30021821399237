import { FunctionComponent } from 'react';
import { UltraTablePreset } from '../../types/presets.types';
import { Button } from '@headlessui/react';
import { cn } from '../../../../../utilities/cn';

interface PresetSelectionBtnProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  preset: UltraTablePreset;
  selected: boolean;
}

const PresetSelectionBtn: FunctionComponent<PresetSelectionBtnProps> = ({
  preset,
  selected,
  ...props
}) => {
  return (
    <Button
      aria-describedby={preset.name}
      key={preset.id}
      className={cn(
        'mx-1 rounded-lg px-4 py-2 uppercase tracking-wide transition focus:outline-none focus:ring-2 focus:ring-offset-2',
        selected
          ? 'bg-mainblue text-white shadow-md hover:bg-[#162a4a] focus:ring-mainblue active:bg-[#0f1d33]'
          : 'bg-gray-400 text-white shadow hover:bg-gray-500 focus:ring-gray-500 active:bg-gray-600',
        props.className, // Fix the overriding of the className prop
      )}
      value={preset.id}
      {...props}
    >
      {preset.name}
    </Button>
  );
};

export default PresetSelectionBtn;
