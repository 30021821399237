import React, { ReactElement } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import {
  MarketBasedPortfolioLiquidationTime,
  buildMarketBasedTable,
  marketBasedColumns,
} from './marketBased.data';
import {
  IMaximumOneDayTableData,
  buildMaximumOneDayData,
  maximumOneDayColumns,
} from './maximum.data';

interface Props {
  inputData: DataObject;
}

function MarketBasedTable({ inputData }: Props): ReactElement {
  const { data, isFetching } = inputData;
  return (
    <>
      <GridItem card xs={12} md={6} lg={7}>
        <CustomTable<MarketBasedPortfolioLiquidationTime>
          options={{
            paging: false,
          }}
          multipleTablePdfParameters={{
            specifiedWidth: 8,
            tablesInRow: 2,
          }}
          showToolbar={true}
          title="Market Based Portfolio Liquidation Time (Days)"
          data={buildMarketBasedTable(data)}
          columns={marketBasedColumns}
          loading={isFetching}
          selectedPositionDate={data[0].selected_position_date}
        />
      </GridItem>
      <GridItem card xs={12} md={6} lg={5}>
        <CustomTable<IMaximumOneDayTableData>
          multipleTablePdfParameters={{
            specifiedWidth: 4,
            tablesInRow: 2,
          }}
          options={{
            paging: false,
            exportButton: true,
            rowStyle: {
              height: '3.12rem',
            },
          }}
          csvFields={marketBasedColumns.map((col) => col.field as string)}
          showToolbar={true}
          title="Maximum One-Day Achievable Balance"
          data={buildMaximumOneDayData(data)}
          columns={maximumOneDayColumns}
          loading={isFetching}
          selectedPositionDate={data[0].selected_position_date}
        />
      </GridItem>
    </>
  );
}

export default MarketBasedTable;
