import { FC } from 'react';
import useKeySelectStyles from './key-select.styles';
import GeneralSelect from '../../../../../../../selects/GeneralSelect';
import ButtonSelect from '../../../../../../../buttons/buttonSelect/buttonSelect.component';
import { Typography, useTheme } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
export const historicalDataKeys: string[] = [
  'net_asset_value',
  'portfolio_var',
  'absolute_portfolio_var',
  'relative_portfolio_var',
  'absolute_benchmark_var',
  'portfolio_gross_exposure',
  'portfolio_cash_gross_exposure',
  'portfolio_derivative_gross_exposure',
  'portfolio_net_exposure',
  'portfolio_commitment',
  'portfolio_cash_commitment',
  'portfolio_derivative_commitment',
  'portfolio_sum_of_notionals',
  'portfolio_asset_class_equity_commitment',
  'portfolio_asset_class_equity_gross_exposure',
  'portfolio_asset_class_equity_net_exposure',
  'portfolio_asset_class_equity_derivative_gross_exposure',
  'portfolio_asset_class_commodity_commitment',
  'portfolio_asset_class_commodity_gross_exposure',
  'portfolio_asset_class_commodity_net_exposure',
  'portfolio_asset_class_foreign_exchange_commitment',
  'portfolio_asset_class_foreign_exchange_gross_exposure',
  'portfolio_asset_class_foreign_exchange_net_exposure',
  'portfolio_asset_class_foreign_exchange_derivative_gross_exposure',
  'portfolio_asset_class_fixed_income_commitment',
  'portfolio_asset_class_fixed_income_gross_exposure',
  'portfolio_asset_class_fixed_income_net_exposure',
  'portfolio_asset_class_fixed_income_derivative_gross_exposure',
  'portfolio_bund_exposure',
  'portfolio_asset_class_cash_commitment',
  'portfolio_asset_class_cash_gross_exposure',
  'portfolio_asset_class_cash_net_exposure',
  'portfolio_asset_class_alternative_commitment',
  'portfolio_asset_class_alternative_gross_exposure',
  'portfolio_asset_class_alternative_net_exposure',
  'risk_compliance_1',
  'benchmark_var',
];

export interface KeySelectProps {
  inputKey: string | null;
  setInputKey: (chioce: string | null) => void;
  title: string;
  fourShowing: boolean;
  external?: boolean;
}
export function adjustLabel(str: string) {
  const arrFromLabel = str.split('_');
  arrFromLabel.forEach((innerLabel, index) => {
    if (innerLabel === 'var') {
      arrFromLabel[index] = 'VaR';
    } else {
      arrFromLabel[index] =
        innerLabel.charAt(0).toUpperCase() + innerLabel.slice(1);
    }
  });
  return arrFromLabel.join(' ');
}

const KeySelect: FC<KeySelectProps> = (props) => {
  const keys = historicalDataKeys;
  const classes = useKeySelectStyles({
    inputKey: props.inputKey || 'none_selected',
  });

  const theme = useTheme();

  const adjustedKeyLabels = keys.map(adjustLabel);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: props.external ? '0.62rem' : 0,
        backgroundColor: 'white',
        boxShadow: props.external ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none',
        borderRadius: 8,
      }}
    >
      {props.external ? (
        <div
          style={{
            padding: '0.25rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <VpnKey
            style={{
              color:
                props.inputKey === 'none_selected'
                  ? theme.palette.grey[400]
                  : theme.palette.primary.main,
              margin: props.external ? '0 0.50rem' : 0,
            }}
          />
          <Typography variant="subtitle2" style={{ marginRight: '0.50rem' }}>
            {props.title}:
          </Typography>
          <GeneralSelect
            labels={adjustedKeyLabels}
            values={keys}
            selected={props.inputKey || 'none_selected'}
            setSelected={props.setInputKey}
            placeholderValue="Please select a key"
            disableUnderline={true}
            truncateLabels
            customStyles={{
              formControl: classes.topLevelFormControl,
              selectRoot: classes.topLevelSelectRoot,
              pickerRoot: classes.topLevelPickerRoot,
            }}
          />
        </div>
      ) : (
        <ButtonSelect
          ariaLabel={props.title}
          selectValue={props.inputKey || 'none_selected'}
          setSelectValue={props.setInputKey}
          ButtonIcon={VpnKey}
          labels={keys}
          values={keys}
          iconButtonStyles={classes.buttonSelect}
          tooltip={
            !props.external
              ? { tooltipTitle: props.title, tooltipLocation: 'right' }
              : undefined
          }
        />
      )}
    </div>
  );
};

export default KeySelect;
