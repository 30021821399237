import { FC, useState } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import CustomTick from '../../../../charts/chartComponents/CustomTick';
import GenericBarChart from '../../../../charts/GenericBarChart';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../../selects/GeneralSelect';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { IESGPortfolioSummary } from '../types/esg-portfolio.types';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  visualisationsContainer: {
    display: 'flex',
    position: 'relative',
    // top: '2.50rem',
    // height: 500,
    width: '100%',
  },

  genericBarChartContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
    paddingTop: '0.75rem',
    paddingLeft: '0.75rem',
  },
  header: {
    position: 'relative',
    // top: '1.88rem',
  },
  tableContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '15%',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
    height: '3.12rem',
    width: '100%',
    marginBottom: '0.75rem',
  },
  selectRoot: {
    width: '100%',
  },
}));

interface SectorExposureData {
  sector: string;
  Environmental: number;
  Social: number;
  'Corporate Governance': number;
  ESG: number;
}

function prepareSectorExposureData(
  data: IESGPortfolioSummary,
  sortBy: any,
): Array<SectorExposureData> {
  if (!data) return [];
  if ('no data' in data) return [];
  const sectorExposureData: SectorExposureData[] = [];
  const sectorData = data.sector_scores;
  // Check the version number.
  const version = data.version;
  // Case for version 2

  if (version === 3) {
    // Case for verison 3
    Object.keys(sectorData).forEach((sector: any) => {
      sectorExposureData.push({
        sector: sector,
        Environmental: sectorData[sector].E_Score,
        Social: sectorData[sector].S_Score,
        'Corporate Governance': sectorData[sector].G_Score,
        ESG: sectorData[sector].ESG_Score,
      });
    });
  } else {
    sectorData.forEach((value: any) => {
      sectorExposureData.push({
        sector: value.sector_name,
        Environmental: value.scores.gross.e,
        Social: value.scores.gross.s,
        'Corporate Governance': value.scores.gross.g,
        ESG: value.scores.gross.esg,
      });
    });
  }
  sectorExposureData.sort((a: any, b: any) => (a[sortBy] < b[sortBy] ? 1 : -1));
  return sectorExposureData;
}

interface esgSectorExposureProps {
  esgData: IESGPortfolioSummary;
}

interface esgSectorExposureChartProps {
  scoreType: string;
  data: IESGPortfolioSummary;
}

function createBars(selection: string) {
  switch (selection) {
    case 'Environmental':
      return [
        { dataKey: 'Environmental', key: 'e', fill: mainColors.Pass_darker },
      ];
    case 'Social':
      return [{ dataKey: 'Social', key: 's', fill: mainColors.pumpkin }];
    case 'Corporate Governance':
      return [
        {
          dataKey: 'Corporate Governance',
          key: 'g',
          fill: mainColors.fourthPurple,
        },
      ];
    default:
      return [{ dataKey: 'ESG', key: 'ESG', fill: mainColors.mainBlue }];
  }
}

const EsgSectorExposureChart: FC<esgSectorExposureChartProps> = (props) => {
  const { data, scoreType } = props;
  const SectorData = prepareSectorExposureData(data, scoreType);

  // Set the x axis props
  const xAxis = [
    {
      dataKey: 'sector',
      label: { value: '', dy: 10 },

      tick: (
        <CustomTick
          rotationValue={-45}
          textAnchor={'end'}
          tickFormatter={(val: string) =>
            val.length > 10 ? val.substr(0, 10) + '...' : val
          }
        />
      ),
    },
  ];

  // Set the bars and colors
  const bars = createBars(scoreType);

  return (
    <GenericBarChart
      xAxes={xAxis}
      height={600}
      width={'100%'}
      bars={bars}
      data={SectorData}
      title={`${scoreType} Sector Score`}
      loading={false}
      legend={false}
      yAxisLabel={{
        angle: -90,
        position: 'left',
        value: 'Score',
        style: {
          textAnchor: 'middle',
        },
      }}
      legendWrapper={{ left: 0 }}
      legendLayout="horizontal"
      legendPositionHorizontal="center"
      legendPositionVertical="top"
      margin={{ top: 0, bottom: 150, left: 100, right: 50 }}
    />
  );
};

const ExposureBySectorPanel: FC<esgSectorExposureProps> = (props) => {
  const classes = useStyles();
  const { esgData } = props;
  const [scoreType, setScoreType] = useState('ESG');
  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setScoreType(value);
  };
  return (
    <GridItem
      card
      lg={12}
      // height="100%"
      cardStyle={{
        padding: '0.62rem',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
      }}
    >
      <div className={classes.visualisationsContainer}>
        <Typography
          variant="h2"
          style={{
            fontSize: '1.50rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
          className={classes.header}
        >
          {'ESG Sector Scores'}
        </Typography>
        <div className={classes.generalSelectContainer}>
          <GeneralSelect
            labels={['ESG', 'Environmental', 'Social', 'Corporate Governance']}
            values={['ESG', 'Environmental', 'Social', 'Corporate Governance']}
            selected={scoreType}
            setSelected={setSelected}
            placeholderValue=""
            placeholderColor={greys.grey900}
            selectTitle={'Score Type'}
            customStyles={{
              pickerRoot: classes.pickerRoot,
              selectRoot: classes.selectRoot,
            }}
          />
        </div>
      </div>
      <div style={{ marginBottom: '1.25rem' }}>
        <EsgSectorExposureChart data={esgData} scoreType={scoreType} />
      </div>
    </GridItem>
  );
};

export default ExposureBySectorPanel;
