import { FunctionComponent, ReactElement } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  IconKey,
  Page,
  Section,
} from '../../../../types/server-data/ClientConfig';
import { useSelector } from 'react-redux';
import { activePageSelector } from '../../../../redux/pages/selectors';
import { iconMap } from '../../../../images/iconMap';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  SvgIconTypeMap,
  Tooltip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { RaptorTheme } from '../../../../styling/theme';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  listItemIconRoot: {
    justifyContent: 'center',
    minWidth: 0,
    color: 'inherit',
    zIndex: 2,
  },
  iconButtonRoot: {
    borderRadius: 0,
    padding: '1.25rem',
    color: theme.palette.grey[400],
    '&:hover': {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  listItemRoot: {
    padding: 0,
    justifyContent: 'center',
    position: 'relative',
    borderBottom: 'none !important',
    '&:hover p': {
      transform: 'translate(2.81rem, -50%)',
      opacity: 1,
    },
  },
  tooltip: {
    fontSize: '0.75rem',
  },
  listItemButton: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'white',
    },
  },
  hoverText: {
    transition: 'all .3s',
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    opacity: 0,
    borderRadius: 4,
    left: '-5.62rem',
    zIndex: 1,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.3)',
    padding: '0.62rem',
    paddingLeft: '6.25rem',
    backgroundColor: 'white',
    minWidth: 'max-content',
    willChange: 'opacity',
    pointerEvents: 'none',
    fontSize: 14,
    fontWeight: 400,
    transform: 'translate(-100%, -50%)',
  },

  selectedText: {
    color: theme.palette.primary.main,
  },
}));

type CenteredListItemIconContainerProps = {
  children: ReactElement;
  isSelected: boolean;
};
type CenteredListItemProps = {
  children: ReactElement;
  page: Page;
  isSelected: boolean;
};

const CenteredListItemIconContainer: FunctionComponent<
  CenteredListItemIconContainerProps
> = ({ children, isSelected }) => {
  const classes = useStyles();

  return (
    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
      {children}
    </ListItemIcon>
  );
};

const CenteredListItem: FunctionComponent<CenteredListItemProps> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <ListItemButton
      alignItems="center"
      className={clsx(classes.listItemRoot, classes.listItemButton)}
    >
      {children}
    </ListItemButton>
  );
};

interface IListItem {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | any;
  className: string;
  title: string;
  iconButtonClassName: string;
}

const ListIcon: FunctionComponent<IListItem> = ({
  Icon,
  className,
  title,
  iconButtonClassName,
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      placement="right"
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton disableRipple classes={{ root: iconButtonClassName }}>
        <Icon style={{ fontSize: 20 }} className={className} />
      </IconButton>
    </Tooltip>
  );
};

interface ISidebarItem {
  page: Page;
  section: Section;
}

function SidebarItem({ page, section }: ISidebarItem): ReactElement {
  const currentlySelectedPage = useSelector(activePageSelector);

  const isSelected = page.pageKey === currentlySelectedPage;
  const classes = useStyles();
  return (
    <Link to={'/' + section.sectionId + '/' + page.pageKey}>
      <CenteredListItem page={page} isSelected={isSelected}>
        <CenteredListItemIconContainer isSelected={isSelected}>
          <ListIcon
            title={page.pageTitle}
            iconButtonClassName={classes.iconButtonRoot}
            Icon={iconMap(page.iconKey as IconKey)}
            className={clsx({
              [classes.selectedText]: isSelected,
            })}
          />
        </CenteredListItemIconContainer>
      </CenteredListItem>
    </Link>
  );
}

export default SidebarItem;
