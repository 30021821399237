import React from 'react';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import useFetchData from '../../../../hooks/useFetchData';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GenericLineChart from '../../../charts/GenericLineChart';
import {
  HistoricalSrriData,
  generateSrriLineChartData,
} from './SyntheticRisk.data';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../layout/GridComponents/GridItem';
import {
  percentageToNdecialPlaces,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import clsx from 'clsx';
import {
  Area,
  AreaChart,
  Brush,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ExportButton from '../../../feedback/ExportButton';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Box, Typography } from '@mui/material';

interface Props {
  data: DataObject;
}

interface HistoricalNavChartProps {
  data: any;
}

const historicalChartStyles = makeStyles<RaptorTheme>(() => ({
  toolbar: {
    padding: '0.62rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1.56rem',
    color: mainColors.mainBlue,
    fontWeight: 300,
  },
  tooltip: {
    fontSize: '0.81rem',
    border: `1px solid ${mainColors.mainBlue}`,
  },
}));

const HistoricalChart: React.FC<HistoricalNavChartProps> = ({ data }) => {
  const classes = historicalChartStyles();

  const srriNavData = data.data[0].time_series_data_v2;

  const getMinMaxVol = (srriNavData: any[]) => {
    let minVol = 1;
    let maxVol = 0;

    srriNavData.forEach((date) => {
      if (date.srri_volatility) {
        if (date.srri_volatility > maxVol) {
          maxVol = date.srri_volatility;
        }
        if (date.srri_volatility < minVol) {
          minVol = date.srri_volatility;
        }
      }
    });

    return { minVol: minVol, maxVol: maxVol };
  };

  const getDynamicRiskClassAreas = (srriNavData: any[]) => {
    const riskClassBoundaries = [
      [1, 0, 0.005],
      [2, 0.005, 0.02],
      [3, 0.02, 0.05],
      [4, 0.05, 0.1],
      [5, 0.1, 0.15],
      [6, 0.15, 0.25],
      [7, 0.25, 1],
    ];
    const minMaxVol = getMinMaxVol(srriNavData);
    const areas: any[] = [];

    const riskClasseIndexesToInclude: number[] = [];
    let i = 0;
    riskClassBoundaries.forEach((bound) => {
      if (minMaxVol.maxVol >= bound[1] && minMaxVol.minVol <= bound[2]) {
        riskClasseIndexesToInclude.push(i);
      }
      i++;
    });

    riskClasseIndexesToInclude.forEach((i) => {
      if (
        riskClassBoundaries[i][1] < minMaxVol.minVol &&
        riskClassBoundaries[i][2] > minMaxVol.maxVol
      ) {
        areas.push(
          <ReferenceArea
            ifOverflow="extendDomain"
            y1={minMaxVol.minVol - 0.005}
            y2={minMaxVol.maxVol + 0.005}
            stroke={mainColors.NA}
            strokeOpacity={0.3}
            label={`Risk Class ${riskClassBoundaries[i][0]}`}
            fill={'transparent'}
          />,
        );
      } else if (riskClassBoundaries[i][1] < minMaxVol.minVol) {
        areas.push(
          <ReferenceArea
            ifOverflow="extendDomain"
            y1={minMaxVol.minVol - 0.005}
            y2={riskClassBoundaries[i][2]}
            stroke={mainColors.NA}
            strokeOpacity={0.3}
            label={`Risk Class ${riskClassBoundaries[i][0]}`}
            fill={'transparent'}
          />,
        );
      } else if (riskClassBoundaries[i][2] > minMaxVol.maxVol) {
        areas.push(
          <ReferenceArea
            ifOverflow="extendDomain"
            y1={riskClassBoundaries[i][1]}
            y2={minMaxVol.maxVol + 0.005}
            stroke={mainColors.NA}
            strokeOpacity={0.3}
            label={`Risk Class ${riskClassBoundaries[i][0]}`}
            fill={'transparent'}
          />,
        );
      } else {
        areas.push(
          <ReferenceArea
            ifOverflow="extendDomain"
            y1={riskClassBoundaries[i][1]}
            y2={riskClassBoundaries[i][2]}
            stroke={mainColors.NA}
            strokeOpacity={0.3}
            label={`Risk Class ${riskClassBoundaries[i][0]}`}
            fill={'transparent'}
          />,
        );
      }
    });

    return areas;
  };

  const buildCsvData = (srriNavData: any[]) => {
    const datesToKeep: any[] = [];
    srriNavData.forEach((date) => {
      if (date.srri_volatility) {
        datesToKeep.push({
          date: date.date,
          nav: Number(date.nav).toFixed(4),
          srri_volatility: date.srri_volatility,
          published_srri_risk_class: date.published_srri_risk_class,
          calculated_srri_risk_class: date.calculated_srri_risk_class,
        });
      }
    });
    return datesToKeep;
  };

  return (
    <GridItem
      xs={12}
      cardStyle={{ padding: '0.62rem', paddingLeft: '0.94rem' }}
      card
    >
      <Box className={classes.toolbar}>
        <Typography className={classes.title}>
          Historical Data ( NAV vs SRRI )
        </Typography>
        <Box>
          <ExportButton
            exportData={buildCsvData(srriNavData)}
            pdfIdentifier={'id'}
            fields={[
              'date',
              'nav',
              'srri_volatility',
              'published_srri_risk_class',
              'calculated_srri_risk_class',
            ]}
            fileName={`SRRI vs NAV`}
            allowPdfExport={false}
          />
        </Box>
      </Box>
      <ResponsiveContainer width={'100%'} height={400}>
        <LineChart
          data={srriNavData}
          syncId={'navVsSrri'}
          margin={{ left: 0, top: 30, bottom: 0, right: 50 }}
        >
          <XAxis
            dataKey={'date'}
            interval={'preserveStartEnd'}
            minTickGap={100}
          />
          <YAxis
            domain={[
              (dataMin: number) => dataMin - Math.abs(dataMin * 0.01),
              (dataMax: number) => dataMax + Math.abs(dataMax * 0.01),
            ]}
            interval={'preserveStartEnd'}
            scale="linear"
            allowDataOverflow={false}
            orientation={'left'}
          />
          <Tooltip
            wrapperClassName={classes.tooltip}
            formatter={(value, name) => {
              if (name === 'nav') {
                return [Number(value).toFixed(4), 'NAV'];
              } else {
                return [value, name];
              }
            }}
          />
          <Line
            type="monotone"
            dataKey={'nav'}
            stroke={mainColors.mainBlue}
            fill={mainColors.mainBlue}
            dot={{ stroke: 'transparent', strokeWidth: 2 }}
            activeDot={{ stroke: mainColors.mainBlue, strokeWidth: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width={'100%'} height={150}>
        <AreaChart
          data={srriNavData}
          syncId={'navVsSrri'}
          margin={{ left: 0, top: 20, bottom: 20, right: 50 }}
        >
          <XAxis
            dataKey={'date'}
            interval={'preserveStartEnd'}
            minTickGap={100}
          />
          <YAxis
            domain={[
              (dataMin: number) => dataMin,
              (dataMax: number) => dataMax,
            ]}
            interval={'preserveStartEnd'}
            scale="linear"
            allowDataOverflow={false}
            orientation={'left'}
          />
          <Tooltip
            wrapperClassName={classes.tooltip}
            formatter={(value, name) => {
              if (name === 'srri_volatility') {
                return [Number(value).toFixed(4), 'SRRI'];
              } else {
                return [value, name];
              }
            }}
            active={false}
          />
          <Area
            connectNulls
            type="monotone"
            dataKey={'srri_volatility'}
            stroke={mainColors.mainBlue}
            fill={'transparent'}
            strokeWidth={2}
            dot={{ stroke: 'transparent', strokeWidth: 2 }}
            activeDot={{ stroke: mainColors.mainBlue, strokeWidth: 2 }}
          />

          {getDynamicRiskClassAreas(srriNavData)}
        </AreaChart>
      </ResponsiveContainer>
      <ResponsiveContainer width={'100%'} height={50}>
        <LineChart
          data={srriNavData}
          syncId={'navVsSrri'}
          margin={{ left: 0, top: 10, bottom: 20, right: 50 }}
        >
          <XAxis dataKey={'date'} />
          <YAxis domain={['auto', 'auto']} />
          <Brush
            height={15}
            stroke={mainColors.mainBlue_lightAlt}
            style={{ fontWeight: 100 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

const statusBoxStyles = makeStyles<RaptorTheme>(() => ({
  statusBoxContainer: {
    padding: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  title: {
    fontSize: '1.56rem',
    color: mainColors.mainBlue,
    fontWeight: 300,
  },
  levelsBox: {
    display: 'flex',
    gap: '1.25rem',
  },
  levelIndicator: {
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.62rem',
  },
  generalHeader: {
    height: '1.88rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.62rem 0.62rem 0 0',
    color: 'white',
    fontSize: '0.94rem',
    fontWeight: 500,
  },
  generalActiveHeader: {
    borderRight: `1px solid ${mainColors.NA_darker}`,
    borderTop: `1px solid ${mainColors.NA_darker}`,
    borderLeft: `1px solid ${mainColors.NA_darker}`,
  },
  passHeader: {
    backgroundColor: mainColors.Pass,
  },
  failExpectedHeader: {
    backgroundColor: mainColors.NA,
  },
  failActualHeader: {
    backgroundColor: mainColors.Fail,
  },
  indicatorBody: {
    padding: '1.25rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    border: `1px solid ${mainColors.NA_darker}`,
  },
  levelLabel: {
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  boundariesLabel: {
    fontSize: '0.75rem',
    color: mainColors.mainBlue_lighter,
  },
}));

const SrriStatusBox: React.FC<{ data: any }> = ({ data }) => {
  const classes = statusBoxStyles();

  const getIndicatorHeaderStyling = (
    key: string,
    itemLimits: string[],
    chosenLevel: string,
    weeklyVol: number,
  ) => {
    if (key === '7') {
      if (
        chosenLevel === key &&
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < 100
      ) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.passHeader,
        );
      } else if (chosenLevel === key && weeklyVol) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.failExpectedHeader,
        );
      } else if (
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < 100
      ) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.failActualHeader,
        );
      } else {
        return classes.generalHeader;
      }
    } else {
      if (
        chosenLevel === key &&
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < Number(itemLimits[1].split('%')[0])
      ) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.passHeader,
        );
      } else if (chosenLevel === key && weeklyVol) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.failExpectedHeader,
        );
      } else if (
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < Number(itemLimits[1].split('%')[0])
      ) {
        return clsx(
          classes.generalHeader,
          classes.generalActiveHeader,
          classes.failActualHeader,
        );
      } else {
        return classes.generalHeader;
      }
    }
  };

  const getIndicatorHeaderLabel = (
    key: string,
    itemLimits: string[],
    chosenLevel: string,
    weeklyVol: number,
  ) => {
    if (key === '7') {
      if (
        chosenLevel === key &&
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < 100
      ) {
        return 'PASS';
      } else if (chosenLevel === key && weeklyVol) {
        return 'PUBLISHED';
      } else if (
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < 100
      ) {
        return 'ACTUAL';
      } else {
        return '';
      }
    } else {
      if (
        chosenLevel === key &&
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < Number(itemLimits[1].split('%')[0])
      ) {
        return 'PASS';
      } else if (chosenLevel === key && weeklyVol) {
        return 'PUBLISHED';
      } else if (
        weeklyVol >= Number(itemLimits[0].split('%')[0]) &&
        weeklyVol < Number(itemLimits[1].split('%')[0])
      ) {
        return 'ACTUAL';
      } else {
        return '';
      }
    }
  };

  return (
    <GridItem xs={12} card className={classes.statusBoxContainer}>
      <Typography className={classes.title}>SRRI Status</Typography>
      <Box className={classes.levelsBox}>
        {Object.keys(data.data[0].srri_data).map((key) => {
          return (
            <Box key={key} className={classes.levelIndicator}>
              <Box
                className={getIndicatorHeaderStyling(
                  key,
                  data.data[0].srri_data[key],
                  String(data.data[0].limits[2]),
                  data.data[0].weekly_volatility,
                )}
              >
                {getIndicatorHeaderLabel(
                  key,
                  data.data[0].srri_data[key],
                  String(data.data[0].limits[2]),
                  data.data[0].weekly_volatility,
                )}
              </Box>
              <Box className={classes.indicatorBody}>
                <Typography className={classes.levelLabel}>
                  Risk Class {key}
                </Typography>
                <Typography className={classes.boundariesLabel}>
                  {`${data.data[0].srri_data[key][0]} <= SRRI < ${data.data[0].srri_data[key][1]}`}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </GridItem>
  );
};

const formatName = (el: string) => {
  const spaced = el.replace(/([a-z])([A-Z])/g, '$1 $2');
  return spaced.includes(' ')
    ? spaced.charAt(0).toUpperCase() + spaced.slice(1)
    : el.toUpperCase();
};

const HistoricalGraphics: React.FC<{ data: DataObject }> = ({ data }) => {
  const chartData = generateSrriLineChartData(data);
  const tableData = [...chartData];
  // Get the name user id
  const clientName = useSelector(clientNameSelector);
  // Create the formatter for the srri value based on the user id
  const formatter = clientName?.includes('coutts')
    ? (num: number | string) => percentageToNdecialPlaces(num, 9)
    : (num: number | string) => percentageToNdecialPlaces(num, 4);

  tableData.reverse();
  return (
    <>
      <GridItem
        xs={8}
        card
        style={{ alignSelf: 'stretch' }}
        cardStyle={{ padding: '1.25rem', height: '100%' }}
      >
        <GenericLineChart
          title="Historical SRRI - Chart"
          margin={{ top: 20, left: 0, right: 40, bottom: 20 }}
          error={data.error}
          yAxisDomain={[
            (dataMin: number) => dataMin - Math.abs(dataMin * 0.1),
            (dataMax: number) => dataMax + Math.abs(dataMax * 0.05),
          ]}
          showTitle
          width="100%"
          height={600}
          data={chartData}
          lines={[
            { dataKey: 'upperBound', color: mainColors.Fail },
            { dataKey: 'lowerBound', color: mainColors.Alert },
            { dataKey: 'srri', color: mainColors.mainBlue },
          ]}
          tooltipFormatter={(value: number, name: string) => {
            return [percentageToTwoDecimalPlaces(value), formatName(name)];
          }}
          xAxisDataKey="date"
          yAxisTickFormatter={(value: number) =>
            percentageToTwoDecimalPlaces(value)
          }
        />
      </GridItem>
      <GridItem card xs={4}>
        <CustomTable<HistoricalSrriData>
          data={tableData}
          columns={['date', 'lowerBound', 'srri', 'upperBound'].map((el) => ({
            field: el,
            title: formatName(el),
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (rowData: HistoricalSrriData) =>
              el !== 'date'
                ? formatter(rowData[el as keyof HistoricalSrriData])
                : rowData['date'],
          }))}
          options={{ paging: true, pageSize: 10, exportButton: true }}
          showToolbar
          title="Historical SRRI - Table"
          toolbarComponents={{
            titleStyle: {
              fontWeight: 300,
            },
          }}
        />
      </GridItem>
    </>
  );
};

export const SyntheticRiskComponents: React.FC<Props> = (props) => {
  // I was asked to include this hacky check
  // If there is no share class data it still returns data but with NaNs, making it unprocessable by typescript
  // Ideally this route should return a proper error code such as 204 and we can handle it properly
  return typeof props.data.data[0] === 'object' ? (
    <>
      <HistoricalChart data={props.data} />
      <SrriStatusBox data={props.data} />
      <HistoricalGraphics data={props.data} />
    </>
  ) : (
    <NoDataMessage message={'No Data Available For This Share Class'} />
  );
};

const SyntheticRisk = (props: FundInfoComponentProps) => {
  const { fundId } = props;

  const srriData = useFetchData({
    makeFetch: true,
    keyName: `${fundId}_srri_${formatDateForCheckingState(new Date())}`,
    url: `aggregate_srri/${fundId}`,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[srriData]}
      customLoadingMessages={[
        'Loading SRRI Data',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No SRRI Data Available."
    >
      <SyntheticRiskComponents data={srriData} />
    </GeneralComponentErrorShield>
  );
};
export default SyntheticRisk;
