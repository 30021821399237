import { QueryClient } from '@tanstack/react-query';

// Configure with your preferred settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient; 