import { FC, memo, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useRouteParams from '../../../../../hooks/useRouteParams';
import KidsEditorColumn from './editorTiles/KidsEditorColumn.component';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import ControlBarOptions from '../layout/ControlBarOptions.component';
import EditorPdfViewer from './viewer/EditorPdfViewer.component';
import { isEqual, debounce, DebouncedFunc } from 'lodash';
import { setAllowUserToNavigateAway } from '../../../../../redux/kids/actions';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { sanitiseEditorText } from '../utils/editor.utils';
import { useKIDSContentData } from '../services/queries';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import { greys } from '../../../../../styling/theme';

export type Tag =
  | 'text_full' // Text Full Width
  | 'text_col' // Text Column
  | 'table_style1_col' // ?
  | 'table_style2_col' // ?
  | 'table_style4' // Performance Scenarios Table
  | 'table_style5' // Costs Over Time Table
  | 'table_style6' // Composition of Costs Table
  | 'srri_chart' // SRI Chart
  | 'section_header' // Section Header
  | 'benchmark_chart' // Benchmark Chart
  | 'page_break'; // Page Break
export interface KidsData {
  formatOptions: string;
  mancoId: string;
  tag: Tag;
  content: string;
  version: string;
  editedByName: string;
  editTimestamp: string;
  editedBy: string;
  fundName: string;
  commentId: string | null;
  fieldId: string;
  publishedBy: string;
  kiidId: string;
  kiidIndex: number;
  shareClassName: string;
  shareClass: string;
  fundIdString: string;
  hasUnresolvedComment: boolean;
  documentLanguage: string;
  publishedByName: string;
  fundId: string;
  isPublished: boolean;
  templateId?: string;
  templateType?: string;
}

const ScrollableContainer: FC<{ children: React.ReactNode }> = memo(
  ({ children }) => {
    return (
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          padding: '0.62rem',
          paddingRight: '0.94rem', // Extra padding on right to account for scrollbar
        }}
      >
        {children}
      </div>
    );
  },
);

export function formatKidsData(data: any[]): KidsData[] {
  if (!data) return [];
  if (!data.length) return [];
  const kidsData: KidsData[] = [];
  data.forEach((element: any) => {
    let content = element.content;
    let templateId = undefined;
    let templateType = undefined;
    // Check if the element is a template field
    if (element.content.startsWith('{{') && element.content.endsWith('}}')) {
      try {
        const templateData = JSON.parse(
          element.content.replace('{{', '{').replace('}}', '}'),
        );
        content = templateData.text;
        templateId = templateData.id;
        templateType = templateData.type;
      } catch (err) {
        console.log(err);
      }
    }
    kidsData.push({
      formatOptions: element.format_options,
      mancoId: element.manco_id,
      tag: element.tag,
      content: sanitiseEditorText(content),
      version: element.version,
      editedByName: element.edited_by_name,
      editTimestamp: element.edit_timestamp,
      editedBy: element.edited_by,
      fundName: element.fund_name,
      commentId: element.comment_id,
      fieldId: element.field_id,
      publishedBy: element.published_by,
      kiidId: element.kiid_id,
      kiidIndex: element.kiid_index,
      shareClassName: element.share_class_name,
      shareClass: element.share_class,
      fundIdString: element.fund_id_string,
      hasUnresolvedComment: element.has_unresolved_comment,
      documentLanguage: element.document_language,
      publishedByName: element.published_by_name,
      fundId: element.fund_id,
      isPublished: element.is_published,
      templateId: templateId,
      templateType: templateType,
    });
  });
  // Order the kids data by the kiid index
  kidsData.sort((a, b) => (a.kiidIndex > b.kiidIndex ? 1 : -1));

  return kidsData;
}

const KidsEditor: FC = () => {
  const { figuresUpdated, setFiguresUpdated, backToOverviewLink } =
    useRaidrKids();

  const dispatch = useDispatch();

  // Create an object for storing the kids content data
  const [priipsKidsContent, setPriipsKidsContent] = useState<KidsData[]>([]);

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];

  const { data, isPending, error, refetch } = useKIDSContentData(
    fundId,
    isin,
    documentLanguage,
  );

  // Create an object for indictaing if the editor tile should be filtered.
  const [templateFilter, setTemplateFilter] = useState('No Filter');
  // Create an object for indicating if changes have been made and can be saved

  const originalPriipsKidsContent = useMemo(() => {
    return data ? formatKidsData(data) : [];
  }, [data]);

  // Inititally here to keep old flow working
  useEffect(() => {
    setPriipsKidsContent(formatKidsData(data));
  }, [data]);

  // This function will be called when an update is made to a template field.
  const getTemplateChangesMade = () => {
    if (
      priipsKidsContent.length === 0 ||
      originalPriipsKidsContent.length === 0
    ) {
      return [];
    }
    // Create a list for storing the fieldIds of the template fields that have been changed
    const changedTemplateFieldIds: string[] = [];
    priipsKidsContent.forEach((element: KidsData) => {
      // Check if the element is a template field
      if (element.templateId && element.templateType) {
        // Try to find the matching element in the original data using the field id
        const originalElement = originalPriipsKidsContent.find(
          (originalElement: KidsData) =>
            originalElement.fieldId === element.fieldId,
        );
        // Check if the element was found
        if (originalElement) {
          // Check if the content has changed
          if (element.content !== originalElement.content) {
            // If the content has changed then set the wereTemplateChangesMade to true
            changedTemplateFieldIds.push(element.fieldId);
          }
          // check if the formatOptions have changed
          if (element.formatOptions !== originalElement.formatOptions) {
            // If the formatOptions have changed then set the wereTemplateChangesMade to true
            changedTemplateFieldIds.push(element.fieldId);
          }
        } else {
          // If the element was not found then the template has been added
          changedTemplateFieldIds.push(element.fieldId);
        }
      }
    });
    return changedTemplateFieldIds;
  };

  // This function will be called when the user clicks the save button
  // It will check the data to ensure that it is valid before allowing the user to save to the database.
  const getCanUserSave = () => {
    if (
      priipsKidsContent[0].documentLanguage === 'Swiss English' &&
      backToOverviewLink === 'priips-kids-overview'
    ) {
      return [];
    }
    // Create variables that will be used to indicate various problems that would prevent the user from saving
    // There must be exactly one SRI chart contained in the document.
    let sriChartCount = 0;
    // There must bve exactly one performance scenario table contained in the document.
    let performanceScenarioTableCount = 0;
    // There must be exactly one costs over time table contained in the document.
    let costsOverTimeTableCount = 0;
    // There must be exactly one composition of costs table contained in the document.
    let compositionOfCostsTableCount = 0;
    // There can be at most 2 page breaks in the document (max allowed page count is 3)
    let pageBreakCount = 0;
    // Loop over each element in the priips kids content
    priipsKidsContent.forEach((element: KidsData) => {
      // Check if the template type is an SRI chart
      if (element.tag === 'srri_chart') {
        // Increment the sri chart count
        sriChartCount++;
      }
      // Check if the template type is a performance scenario table
      if (element.tag === 'table_style4') {
        // Increment the performance scenario table count
        performanceScenarioTableCount++;
      }
      // Check if the template type is a costs over time table
      if (element.tag === 'table_style5') {
        // Increment the costs over time table count
        costsOverTimeTableCount++;
      }
      // Check if the template type is a composition of costs table
      if (element.tag === 'table_style6') {
        // Increment the composition of costs table count
        compositionOfCostsTableCount++;
      }
      // Check if the template type is a page break
      if (element.tag === 'page_break') {
        // Increment the page break count
        pageBreakCount++;
      }
    });
    // Now add any error messages that are required
    const errorMessages: string[] = [];
    // Check if there are any SRI charts
    if (sriChartCount !== 1) {
      errorMessages.push(
        'There must be exactly one SRI chart in the document.',
      );
    }
    // Check if there are any performance scenario tables
    if (performanceScenarioTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Performance Scenario table in the document.',
      );
    }
    // Check if there are any costs over time tables
    if (costsOverTimeTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Costs Over Time table in the document.',
      );
    }
    // Check if there are any composition of costs tables
    if (compositionOfCostsTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Composition of Costs table in the document.',
      );
    }
    // Check if there are any page breaks
    if (pageBreakCount > 2) {
      errorMessages.push(
        'Too many page breaks added. Document can be at most 3 pages long.',
      );
    }
    // Update the error messages
    return errorMessages;
  };

  // Create a function for handling the back to overview button
  const navigate = useNavigate();
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    // Reset navigate away to be true
    dispatch(setAllowUserToNavigateAway());
    navigate(backToOverviewLink, { state: { fundId, isin } });
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (priipsKidsContent.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewBtn
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <ControlBarOptions
            fundName={`${priipsKidsContent[0].fundName} - ${isin}`}
            fundId={priipsKidsContent[0].fundId}
            shareClass={priipsKidsContent[0].shareClass}
            setTemplateFilter={setTemplateFilter}
            setFiguresUpdated={setFiguresUpdated}
            figuresUpdated={figuresUpdated}
            documentLanguage={documentLanguage}
            setPriipsKidsContent={setPriipsKidsContent}
          />,
        ]),
      );
      dispatch(toggleControlBarShowing(true));
    }

    return () => {
      // Cleanup function to reset control bar components and hide the control bar
      dispatch(updateControlBarComponents([]));
      dispatch(toggleControlBarShowing(false));
    };
  }, [priipsKidsContent]);

  const isDataEqual = useMemo(() => {
    return isEqual(priipsKidsContent, originalPriipsKidsContent);
  }, [priipsKidsContent, originalPriipsKidsContent]);

  // Handles the beforeunload event to prompt the user if they want to leave the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isDataEqual) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataEqual]);

  // Add ref to access PDF viewer methods
  const pdfViewerRef = useRef<any>(null);
  const previousContentRef = useRef<string>('');
  const debouncedRefreshRef = useRef<DebouncedFunc<() => void>>(
    debounce(() => {
      pdfViewerRef.current?.refreshPdf();
    }, 1000),
  );

  // Watch for actual content changes
  useEffect(() => {
    if (priipsKidsContent.length > 0) {
      const newContentString = JSON.stringify(
        priipsKidsContent.map((d) => d.content),
      );

      // Only schedule refresh if content actually changed
      if (newContentString !== previousContentRef.current) {
        previousContentRef.current = newContentString;
        debouncedRefreshRef.current();
      }
    }

    return () => {
      debouncedRefreshRef.current.cancel();
    };
  }, [priipsKidsContent]);

  if (isPending) {
    return <Raptor2Loading centerWrap messages={[`Loading Raidr ...`]} />;
  }

  if (error) {
    return <ErrorBoundaryMessage />;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto', // Allow both scrolls if needed
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'max(31.25rem, 33.33%) 1fr', // 31.25rem min, but prefer 1/3 width
          width: '100%',
          minWidth: '46.88rem',
          height: '100%',
          gap: '0.62rem',
          padding: '0.31rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 8.75rem)',
            border: `1px solid ${greys.grey200}`,
            backgroundColor: 'white',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            margin: 0,
          }}
        >
          <ScrollableContainer>
            <KidsEditorColumn
              filter={templateFilter}
              priipsKidsData={priipsKidsContent}
              setPriipsKidsContent={setPriipsKidsContent}
            />
          </ScrollableContainer>
        </div>
        <div>
          {priipsKidsContent.length > 0 && (
            <EditorPdfViewer
              ref={pdfViewerRef}
              priipsKidsData={priipsKidsContent}
              isSaveEnabled={!isDataEqual}
              getTemplateChangesMade={getTemplateChangesMade}
              getCanUserSave={getCanUserSave}
              resetContentAfterSave={refetch}
              figuresWereUpdated={figuresUpdated}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KidsEditor;
