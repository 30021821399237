import React from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { PEPageLinkButton } from '../../peOverview/PeOverview.component';
import CustomTable from '../../../../tables/CustomTable';
import GenericComposedChart from '../../../../charts/GenericComposedChart';
import { greys } from '../../../../../styling/theme';
import { Bar, Line, ReferenceLine } from 'recharts';
import { mainColors } from '../../../../../styling/aifTheme';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface ValueAtRiskFundSummaryProps {
  varData: DataObject;
  timeSeriesData: DataObject;
}

interface ValueAtRiskFundSummaryTableData {
  name: string;
  selectedPositionDate: string;
  nav: number;
  dailyReturn: number;
  monthlyReturn: number;
  dailyVar: number;
  monthlyPortfolioVar: number;
  assetDebtRatio: number;
  assetDebtRatioStatus: string;
  assetDebtRatioVarAdjusted: number;
  assetDebtRatioVarAdjustedStatus: string;
}

interface ValueAtRiskFundSummaryChartData {
  date: string;
  monthlyVar: number;
  dailyVar: number;
  return: number;
}

const buildTableData = (
  inputData: DataObject,
): ValueAtRiskFundSummaryTableData[] => {
  if (!inputData.data.length) return [];
  if (!('fund_data' in inputData.data[0])) return [];
  const varData = inputData.data[0].fund_data;
  return [
    {
      name: varData.gm_name,
      selectedPositionDate: varData.Date,
      nav: varData.nav,
      dailyReturn: varData.one_day_pct_return,
      monthlyReturn: varData.one_month_pct_return,
      dailyVar: varData.one_day_VaR,
      monthlyPortfolioVar: varData.monthly_VaR,
      assetDebtRatio: varData.asset_debt_ratio,
      assetDebtRatioStatus: varData.asset_debt_ratio_status,
      assetDebtRatioVarAdjusted: varData.asset_debt_ratio_VaR_adj,
      assetDebtRatioVarAdjustedStatus: varData.asset_debt_ratio_VaR_adj_status,
    },
  ];
};

const buildChartData = (
  timeSeriesData: DataObject,
): ValueAtRiskFundSummaryChartData[] => {
  if (!timeSeriesData) return [];
  if (!timeSeriesData.data.length) return [];
  const data = timeSeriesData.data[0];
  const chartData: ValueAtRiskFundSummaryChartData[] = [];

  data.forEach((value: any) => {
    chartData.push({
      date: value[0],
      monthlyVar: value[3],
      dailyVar: value[1],
      return: value[2],
    });
  });

  return chartData;
};

export const ValueAtRiskFundSummaryTableColumns: CustomColumn<ValueAtRiskFundSummaryTableData>[] =
  [
    {
      title: 'Fund Name',
      field: 'name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Selected Position Date',
      field: 'selectedPositionDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'NAV',
      field: 'nav',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) =>
        addCommasToNumbersAndRound(rowData.nav),
    },
    {
      title: 'Monthly Return',
      field: 'monthlyReturn',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.monthlyReturn),
    },
    {
      title: 'Monthly Porfolio Quantile Risk',
      field: 'monthlyPortfolioVar',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.monthlyPortfolioVar),
    },
    {
      title: 'Asset/Debt ratio',
      field: 'assetDebtRatio',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) =>
        roundNumberToTwoDecimalPlaces(rowData.assetDebtRatio),
    },
    {
      title: 'Status',
      field: 'assetDebtRatioStatus',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) => (
        <PEPageLinkButton
          status={specifyStatus(rowData.assetDebtRatioStatus)}
        />
      ),
    },
    {
      title: 'Quantile Risk Adjusted Asset Debt Ratio',
      field: 'assetDebtRatioVarAdjusted',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) =>
        roundNumberToTwoDecimalPlaces(rowData.assetDebtRatioVarAdjusted),
    },
    {
      title: 'Status',
      field: 'assetDebtRatioVarAdjustedStatus',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: ValueAtRiskFundSummaryTableData) => (
        <PEPageLinkButton
          status={specifyStatus(rowData.assetDebtRatioVarAdjustedStatus)}
        />
      ),
    },
  ];

const legendFormatter = (value?: any, entry?: any, i?: number) => {
  return (
    <span style={{ color: greys.grey700 }}>
      {value === 'dailyVar' ? 'Quantile Risk' : 'Return'}
    </span>
  );
};

const tooltipFormatter = (
  value: string | number | Array<string | number>,
  name: string,
  entry: any,
  index: number,
) => {
  return [
    (value =
      name === 'dailyVar'
        ? percentageToTwoDecimalPlacesNoHundred(entry.payload.dailyVar)
        : percentageToTwoDecimalPlacesNoHundred(entry.payload.return)),
    name === 'dailyVar' ? 'Quantile Risk' : 'Return',
  ];
};

const ValueAtRiskFundSummary: React.FC<ValueAtRiskFundSummaryProps> = ({
  varData,
  timeSeriesData,
}) => {
  const tableData = buildTableData(varData);
  const chartData = buildChartData(timeSeriesData);

  return (
    <GridItem card xs={12} style={{ padding: 8 }}>
      <GridItem xs={12}>
        <CustomTable<ValueAtRiskFundSummaryTableData>
          columns={ValueAtRiskFundSummaryTableColumns}
          showToolbar
          data={tableData}
          title={'Fund Summary'}
          options={{
            paging: false,
            search: true,
            exportButton: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} style={{ padding: '1.25rem' }}>
        <GenericComposedChart<ValueAtRiskFundSummaryChartData>
          loading={!chartData}
          data={chartData}
          title="Historical Quantile Risk"
          export
          download
          height={400}
          width={'100%'}
          margin={{ top: 50, right: 50, left: 60, bottom: 50 }}
          xAxes={[
            {
              dataKey: 'date',
              interval: 'preserveStartEnd',
              type: 'category',
              minTickGap: 70,
            },
          ]}
          yAxes={[
            {
              label: {
                value: 'Value %',
                angle: -90,
                position: 'left',
                offset: 30,
                style: {
                  fill: greys.grey700,
                  fontSize: '130%',
                  fontWeight: 400,
                  color: mainColors.mainBlue,
                },
              },
              tickFormatter: (val) =>
                percentageToTwoDecimalPlacesNoHundred(val),
            },
          ]}
          legendFormatter={legendFormatter}
          tooltipFormatter={tooltipFormatter}
          components={[
            {
              ComponentType: Bar,
              props: {
                dataKey: 'return',
                fill: mainColors.mainBlue,
              },
            },
            {
              ComponentType: Line,
              props: {
                type: 'monotone',
                dataKey: 'dailyVar',
                stroke: 'red',
                showDot: false,
              },
            },
            {
              ComponentType: ReferenceLine,
              props: {
                y: 0,
                stroke: 'red',
              },
            },
          ]}
        />
      </GridItem>
    </GridItem>
  );
};

export default ValueAtRiskFundSummary;
