import React, { FC, useEffect, useState } from 'react';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarDatePicker from '../../../../hooks/useTitleBarDatePicker';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import DailyPAndL from './charts/daily/Daily.component';
import SharePrice from './charts/sharePrice/SharePrice.component';
import ExceedanceTable from './tables/exceedanceTable/exceedanceTable.component';
import OutSampleTable from './tables/outSampleTable/outSample.component';
import GridItem from '../../../layout/GridComponents/GridItem';
import { mainColors } from '../../../../styling/theme';
import Raptor2Button from '../../../buttons/Raptor2Button';
import dayjs from 'dayjs';

const VarBacktest: FC<FundInfoComponentProps> = (props) => {
  const startDateWeekday = getWeekdayXMonthsAgo(12);
  const endDateWeekday = getMostRecentWeekday();

  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'startDate',
    titleBarTitle: 'Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
  });
  useAdditionalTitleBarDatePicker({
    displayOnOverviewPage: true,
    titleBarKey: 'endDate',
    titleBarTitle: 'End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
  });
  const activeFund = props.fundId;

  // Format the start and end dates as strings
  const fromDate = useTitleBarDatePicker('startDate') || startDateWeekday;
  const toDate = useTitleBarDatePicker('endDate') || endDateWeekday;

  // code for fetching data, makeFetch by default will rest at false until user clicks run button
  const [makeFetch, setMakeFetch] = useState(false);
  const varData = useFetchData({
    keyName: `${fromDate}_${toDate}_${activeFund}_varBacktestData`,
    url: `ucits_historical_backtest/${activeFund}/${fromDate}/${toDate}`,
    makeFetch: makeFetch,
  });

  // when user clicks run button, begin fetching data
  const sendRequest = () => {
    setMakeFetch(true);
  };

  // when start and end dates updated mid load, re-prompt user to run data fetch again for new dates
  useEffect(() => {
    setMakeFetch(false);
  }, [fromDate, toDate]);

  return (
    <>
      {!varData ? (
        <GridItem xs={12} card cardStyle={{ padding: '0.62rem' }}>
          <h2 style={{ color: mainColors.mainBlue }}>
            Please configure dates above
          </h2>
          <Raptor2Button onClick={sendRequest} color="primary">
            Run
          </Raptor2Button>
        </GridItem>
      ) : !varData.isFetching ? (
        <>
          {makeFetch ? setMakeFetch(false) : null}
          <OutSampleTable varBacktestData={varData} />
          <DailyPAndL varData={varData} />
          <ExceedanceTable varData={varData} />
          <SharePrice varData={varData} />
        </>
      ) : (
        <RaptorLoading
          centerWrap
          messages={[
            'Processing VaR data can take a little time, please hang on...',
            'only a few seconds longer...',
            '...',
          ]}
        />
      )}
    </>
  );
};
export default VarBacktest;
