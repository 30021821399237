import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  roundToNDecimalPlacesReturnNumber,
} from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';

interface StressTestBreakdownLineChartProps {
  data: DataObject;
  dataKey: string;
}

interface StressTestBreakdownLineChartData {
  date: string;
  pl: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.62rem 1.56rem 0.62rem 0.94rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.38rem',
    fontWeight: 400,
    margin: '0.62rem 0.94rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    gap: '0.12rem',
    margin: '0 1.88rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '0.62rem 0.94rem',
    borderRadius: '0.31rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '15.62rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.00rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          <div className={classes.tooltipValue}>
            P/L:{' '}
            {percentageToNdecialPlaces(
              payload.find((item: any) => item.name === 'pl').value,
              2,
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const prepareTimeSeriesData = (
  data: any,
): StressTestBreakdownLineChartData[] => {
  // Get the time series data and the nav value
  const timeSeriesData = data.data[0].time_series_data.slice(1);
  const nav = data.data[0].nav;
  // Adjust the values to be a % of nav
  const adjustedTimeSeries = timeSeriesData.map((value: any) => {
    return { date: value[0], pl: value[1] / nav };
  });
  return adjustedTimeSeries;
};

function getMaxDrawdown(data: any): number {
  if (!data.data.length) return 0;
  return data.data[0].maximum_drawdown / data.data[0].nav;
}

const StressTestBreakdownLineChart: React.FC<
  StressTestBreakdownLineChartProps
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtGraphData = prepareTimeSeriesData(props.data);
  const maxDrawdown = getMaxDrawdown(props.data);
  const title = `Historical Proxy NAV`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'stressTestBreakdownLineChart',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title: title,
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: 'center',
        dontIncrementYPosition: undefined,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonGroupContainer}>
          <ExportButton
            fields={Object.keys(builtGraphData[0])}
            exportData={builtGraphData}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-20}
            textAnchor="end"
            height={80}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Date'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem, 2);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
            domain={[
              (dataMin: number) =>
                roundToNDecimalPlacesReturnNumber(dataMin - 0.2, 2),
              (dataMax: number) =>
                roundToNDecimalPlacesReturnNumber(dataMax + 0.2, 2),
            ]}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <ReferenceLine
            y={maxDrawdown}
            stroke={mainColors.Fail}
            strokeDasharray={'5'}
          >
            <Label position={'bottom'}>
              {`Maximum Drawdown: ${percentageToNdecialPlaces(maxDrawdown, 2)}`}
            </Label>
          </ReferenceLine>
          <Line
            type="linear"
            dataKey="pl"
            stroke={mainColors.mainBlue}
            strokeWidth={2}
          />
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.00rem' }}
            formatter={(item) => {
              switch (item) {
                case 'pl':
                  return 'Profit / Loss %';
                default:
                  return item;
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default StressTestBreakdownLineChart;
