import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateFund } from '../../redux/pages/actions';
import {
  activeDateSelector,
  createActiveFundSelectorBySection,
} from '../../redux/pages/selectors';
import { clientNameSelector } from '../../redux/auth/selectors';
import { Autocomplete, TextField } from '@mui/material';
import { RaptorTheme } from '../../styling/theme';

export const useFundPickerStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: '0.22rem',
    borderRadius: 8,
  },
  inputRoot: {
    paddingLeft: '0.31rem',
    color: theme.palette.primary.main,
  },
  listbox: {
    color: theme.palette.grey[700],
  },
}));

interface Props {
  labels: string[];
  values: string[];
  currentlySelected: string;
  section: string;
  currentPage: string;
}

function FundPicker({
  currentlySelected,
  section,
  currentPage,
  ...props
}: Props) {
  const labels = section === 'esg' ? props.labels.slice(0, 22) : props.labels;
  const values = section === 'esg' ? props.values.slice(0, 22) : props.values;
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeFundSelector = createActiveFundSelectorBySection(section);
  const fundIdParam = useSelector(activeFundSelector);
  const activeDate = useSelector(activeDateSelector);

  const handleChange = (fundId: string | null | undefined) => {
    if (!fundId) {
      dispatch(updateFund(section, '', activeDate));
      navigate(`/${section}/${currentPage}`);
    } else {
      if (activeDate !== '') {
        navigate(
          `/${section}/${currentPage}?fundId=${
            fundId as string
          }&positionDate=${activeDate}`,
        );
      } else {
        navigate(`/${section}/${currentPage}?fundId=${fundId as string}`);
      }
    }
  };

  const classes = useFundPickerStyles({
    currentlySelected,
  });

  const options = labels
    .map((label, index) => ({
      label: clientName.includes('mersenne')
        ? label
        : `(${values[index]}) - ${label}`,
      value: values[index],
    }))
    .sort((a: any, b: any) =>
      a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );

  return labels.length ? (
    <Autocomplete
      id="fund_picker"
      style={{ width: '28.12rem', marginRight: '0.62rem' }}
      options={options}
      className={classes.listbox}
      onChange={(label, value) => handleChange(value?.value)}
      getOptionLabel={(option) => option?.label || ''}
      clearOnBlur={false}
      value={
        options.find((option: any) => option.value === fundIdParam?.id) || {
          label: '',
          value: '',
        }
      }
      ChipProps={{
        onClick: () => {
          handleChange('none_selected');
        },
      }}
      renderInput={(params): ReactElement => (
        <TextField
          {...params}
          variant="standard"
          InputLabelProps={{
            variant: 'standard',
          }}
          placeholder="Please select a fund"
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.inputRoot,
            },
            disableUnderline: true,
          }}
          className={classes.root}
        />
      )}
    />
  ) : null;
}

export default FundPicker;
