import { FC, useState } from 'react';
import { greys, mainColors } from '../../../../styling/theme';
import GenericLineChart from '../../../charts/GenericLineChart';
import { roundNumberToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ESGIcon from '../../../../images/esgIcons/world_icon.svg';
import { Typography, useTheme } from '@mui/material';

interface EsgVarChartProps {
  data: any;
}

function getChartColour(chartTitle: string): string {
  switch (chartTitle) {
    case 'Environmental Score Timeseries':
      return mainColors.Pass_darker;
    case 'Social Score Timeseries':
      return mainColors.pumpkin;
    case 'Corporate Governance Score Timeseries':
      return mainColors.fourthPurple;
    default:
      return mainColors.controlButtonBlue;
  }
}
function FormatLabel(value: string): string {
  switch (value) {
    case 'e_data':
      return 'Environmental Score';
    case 's_data':
      return 'Socail Score';
    case 'g_data':
      return 'Corporate Governance Score';
    default:
      return 'ESG Score';
  }
}

function buildChartData(data: any) {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const varData = data.data[0];
  const chartData = [];
  for (let i = 0; i < varData.dates.length; i++) {
    chartData.push({
      name: varData.dates[i],
      e_data: varData.e_data.time_series[i],
      s_data: varData.s_data.time_series[i],
      g_data: varData.g_data.time_series[i],
      esg_data: varData.esg_data.time_series[i],
    });
  }
  return chartData;
}

function buildChartLines(
  environmentalOn: boolean,
  socialOn: boolean,
  corporateOn: boolean,
  esgOn: boolean,
) {
  const chartLines: any[] = [];
  if (environmentalOn)
    chartLines.push({ dataKey: 'e_data', color: mainColors.Pass_darker });
  if (socialOn)
    chartLines.push({ dataKey: 's_data', color: mainColors.pumpkin });
  if (corporateOn)
    chartLines.push({ dataKey: 'g_data', color: mainColors.fourthPurple });
  if (esgOn)
    chartLines.push({
      dataKey: 'esg_data',
      color: mainColors.controlButtonBlue,
    });
  return chartLines;
}

const EsgVarChart: FC<EsgVarChartProps> = (props) => {
  const { data } = props;

  const [environmentalOn, setEnvironmentalOn] = useState<boolean>(false);
  const [socialOn, setSocialOn] = useState<boolean>(false);
  const [corporateOn, setCorporateOn] = useState<boolean>(false);
  const [esgOn, setEsgOn] = useState<boolean>(true);

  // const chartData = data.data[0];
  const theme = useTheme();
  const chartData = buildChartData(data);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginLeft: '1.25rem',
          marginTop: '0.62rem',
          marginBottom: '0.62rem',
        }}
      >
        <Typography variant="h2">
          Click on a score to add/remove from chart:{' '}
        </Typography>
        <Button
          // className={classes.button}
          style={{
            backgroundColor: environmentalOn
              ? mainColors.Pass_darker
              : greys.grey500,
            marginLeft: '0.62rem',
          }}
          variant="contained"
          onClick={() => {
            setEnvironmentalOn(!environmentalOn);
          }}
          endIcon={
            <Icon
              sx={{
                fontSize: 15,
                color: 'white',
              }}
            >
              eco
            </Icon>
          }
        >
          Environmental
        </Button>
        <Button
          // className={classes.button}
          style={{
            backgroundColor: socialOn ? mainColors.pumpkin : greys.grey500,
            marginLeft: '0.62rem',
          }}
          variant="contained"
          onClick={() => {
            setSocialOn(!socialOn);
          }}
          endIcon={
            <Icon
              sx={{
                fontSize: 15,
                color: 'white',
              }}
              onClick={() => {
                return;
              }}
            >
              group
            </Icon>
          }
        >
          Social
        </Button>
        <Button
          // className={classes.button}
          style={{
            backgroundColor: corporateOn
              ? mainColors.fourthPurple
              : greys.grey500,
            marginLeft: '0.62rem',
          }}
          variant="contained"
          onClick={() => {
            setCorporateOn(!corporateOn);
          }}
          endIcon={
            <Icon
              sx={{
                fontSize: 15,
                color: 'white',
              }}
              onClick={() => {
                return;
              }}
            >
              gavel
            </Icon>
          }
        >
          Corporate Governance
        </Button>
        <Button
          // className={classes.button}
          style={{
            backgroundColor: esgOn
              ? mainColors.controlButtonBlue
              : greys.grey500,
            marginLeft: '0.62rem',
          }}
          variant="contained"
          onClick={() => {
            setEsgOn(!esgOn);
          }}
          endIcon={
            <img
              style={{
                height: '1.12rem',
                width: '1.12rem',
                marginTop: '0.19rem',
                marginLeft: '0.19rem',
              }}
              src={ESGIcon}
            />
          }
        >
          ESG
        </Button>
      </div>
      <GenericLineChart
        error={'No Timeseries Data available'}
        title={'Scores Timeseries'}
        showTitle
        id={`esg_var_timeseries`}
        loading={false}
        showDot={false}
        // lines={[
        //     {
        //         dataKey: metric,
        //         color: getChartColour(chartTitle),
        //         //   key: 'score',
        //         // yAxisId: 'left'
        //     },
        // ]}
        lines={buildChartLines(environmentalOn, socialOn, corporateOn, esgOn)}
        //   loadingHeight={400}
        height={400}
        width={'100%'}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        yAxisLabel={{
          position: 'left',
          angle: -90,
          offset: 0,
          fontSize: '0.88rem',
          fill: theme.palette.grey[700],
          value: 'Score',
          style: {
            textAnchor: 'middle',
          },
        }}
        tooltipFormatter={(val: any, name: any) => [
          roundNumberToTwoDecimalPlaces(val as number),
          FormatLabel(name),
        ]}
        data={chartData}
      />
    </div>
  );
};

export default EsgVarChart;
