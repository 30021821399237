import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import { SearchSharp } from '@mui/icons-material';
import { Input } from '@mui/material';
import { RaptorTheme } from '../../../../styling/theme';
import clsx from 'clsx';

const useNewSearchStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    border: '1px solid lightgrey',
    minWidth: '11.25rem',
    height: '1.75rem',
    transition: 'width .2s',
    borderRadius: '0.50rem',
    paddingLeft: '0.62rem',
    '& svg': {
      pointerEvents: 'none',
      fontSize: '0.94rem',
      color: theme.palette.grey[800],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  input: {
    padding: '0.31rem',
    paddingLeft: '0.31rem',
    fontSize: '0.75rem',
  },
  focused: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.grey[100],
  },
}));

interface TableSearchProps {
  onChange: Dispatch<SetStateAction<string>>;
  className?: string;
}

const TableSearch: FunctionComponent<TableSearchProps> = ({
  onChange,
  className,
}) => {
  const debounceSearch = debounce(async (value: string) => {
    onChange(value);
  }, 200);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const newSearchStyles = useNewSearchStyles();
  return (
    <Input
      placeholder="Search..."
      disableUnderline
      classes={{
        root: clsx(newSearchStyles.root, className),
        input: newSearchStyles.input,
        focused: newSearchStyles.focused,
      }}
      startAdornment={<SearchSharp />}
      onChange={handleChange}
    />
  );
};

export default TableSearch;
