import React from 'react';
import CustomTable from '../../../tables/CustomTable';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';
import GeneralCard from '../../../general/GeneralCard';
import { YieldDataProps } from '../YieldPage';

const useStyles = makeStyles({
  container: {
    height: 'fit-content',
  },
  assetTable: {
    padding: '1.25rem',
    '& th, td': {
      padding: '0.62em',
      textAlign: 'center',
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: mainColors.lightGrey,
    },
  },
});

const columns = [
  { title: 'Maturity', field: 'matur', type: 'numeric' as const },
  { title: 'Par', field: 'par', type: 'numeric' as const },
  { title: 'Spot', field: 'spot', type: 'numeric' as const },
  { title: 'Forward', field: 'forward', type: 'numeric' as const },
];

const YieldTable: React.FC<YieldDataProps> = ({ yieldData }) => {
  const classes = useStyles();

  return (
    <GeneralCard className={classes.container}>
      <CustomTable
        showToolbar={false}
        columns={columns}
        data={yieldData}
        tableRootStyles={classes.assetTable}
        options={{
          draggable: false,
          paging: false,
        }}
      />
    </GeneralCard>
  );
};

export default React.memo(YieldTable);
