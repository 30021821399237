import makeStyles from '@mui/styles/makeStyles';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../styling/theme';
import { FC } from 'react';
import { EditorTileProps } from '../../kids/editor/editorTiles/KidsEditorColumn.component';
import KidsEditorGridItem from '../../kids/layout/KidsEditorGridItem.component';
import CustomTable from '../../../../tables/CustomTable';
import UtilityButtons from '../buttons/UtilityButtons.component';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    marginLeft: '0.62rem',
    fontSize: '0.94rem',
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '6.25rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '0.62rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
}));

interface CostsOverTimeTableData {
  scenario: string;
  year1: string;
  holdingPeriod: string;
}

const generalCostsOverTimeTableData: CostsOverTimeTableData[] = [
  {
    scenario: 'Total Costs',
    year1: '',
    holdingPeriod: '',
  },
  {
    scenario: 'Impact on return (RIY) per year',
    year1: '',
    holdingPeriod: '',
  },
];

function buildCostsOverTimeTableData(content: any[]): CostsOverTimeTableData[] {
  const tableData: CostsOverTimeTableData[] = [
    ...generalCostsOverTimeTableData,
  ];
  tableData[0].year1 = content[0][0];
  tableData[0].holdingPeriod = content[0][1];
  tableData[1].year1 = content[1][0];
  tableData[1].holdingPeriod = content[1][1];
  return tableData;
}

const columns: CustomColumn<CostsOverTimeTableData>[] = [
  {
    title: 'Investment Scenarios EUR 10,000',
    field: 'scenario',
    cellStyle: {
      width: '20%',
      border: '1px solid #1875d2',
      padding: '0.31rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      width: '20%',
      padding: '0.31rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
  {
    title: 'If you cash in after 1 year',
    field: 'year1',
    cellStyle: {
      width: '20%',
      border: '1px solid #1875d2',
      padding: '0.31rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      width: '20%',
      padding: '0.31rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
  {
    title: 'If you cash in at the end of the recommended holding period',
    field: 'holdingPeriod',
    cellStyle: {
      border: '1px solid #1875d2',
      padding: '0.31rem',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    headerStyle: {
      // border: '1px solid white',
      padding: '0.31rem',
      textAlign: 'center',
      color: 'white',
      backgroundColor: mainColors.controlButtonBlue,
    },
  },
];

const CostsOverTimeTableEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the current element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  // Build the table data
  const tableData = buildCostsOverTimeTableData(content);

  const classes = useStyles();

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <CustomTable<CostsOverTimeTableData>
            columns={columns}
            id={'performance_scenarios_table'}
            showToolbar={false}
            data={tableData}
            options={{
              paging: false,
              search: false,
              exportButton: false,
              sorting: false,
              draggable: false,
              toolbar: false,
            }}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
          disableDelete
          disableDeleteMessage="You cannot delete the costs over time table"
        />
      </div>
    </KidsEditorGridItem>
  );
};

export default CostsOverTimeTableEditorTile;
