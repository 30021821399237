import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { mainColors } from '../../../../../styling/theme';
import GenericMapChart from '../../../../charts/map/GenericMapChart';
import { roundNumberToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';

interface SubFundCumulativeBreakdownMapProps {
  data: any;
  selectedBreakdown: string | null;
}

interface SubFundCumulativeBreakdownMapData {
  name: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.38rem',
    fontWeight: 400,
    margin: '0.62rem 0.94rem',
  },
  mapContainer: {
    margin: 'auto',
    height: 600,
    width: 1250,
  },
}));

const buildChartData = (
  inputData: any,
  selectedBreakdown: string | null,
): SubFundCumulativeBreakdownMapData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const geographicalData: any[] = [];

    inputData.data[0].drop_down_breakdown.country.forEach((country: any) => {
      geographicalData.push([
        country.country_name,
        roundNumberToTwoDecimalPlaces(country.aggregate_pct_pl),
      ]);
    });

    return geographicalData;
  }
};

const SubFundCumulativeBreakdownMap: React.FC<
  SubFundCumulativeBreakdownMapProps
> = ({ data, selectedBreakdown }) => {
  const classes = useStyles();

  const mapData = buildChartData(data, selectedBreakdown);

  const valueFormatter = (val: string | number) => `${val}`;
  const labelFormatter = (val: string | number) => `${val}`;

  return (
    <GridItem xs={12} xl={6} card>
      <h2 className={classes.cardTitle}>Geographic Distribution</h2>
      <div className={classes.mapContainer}>
        <GenericMapChart
          data={mapData}
          tooltipValueFormatter={valueFormatter}
          tooltipLabelFormatter={labelFormatter}
          color={mainColors.mainBlue}
        />
      </div>
    </GridItem>
  );
};

export default SubFundCumulativeBreakdownMap;
