import React, { FC } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import {
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import GenericStatusCell from '../../../tables/GenericStatusCell';

interface CounterpartyStressTestsProps {
  counterpartyStressTestData: DataObject;
  stressTestRestrictions: DataObject;
  counterparty: string | null;
}

interface StressTestsData {
  headerRow: boolean;
  stressScenarioType: string | null;
  name: string | null;
  pL: number | null;
  pLstatus: Status | null;
  exAnteVolatility: number | null;
  exAnteVar: number | null;
  numSds: number | null;
  numVar: number | null;
  exPostVolatility: number | null;
  exPostVar: number | null;
}

export const buildStressColumns = (): CustomColumn<StressTestsData>[] => {
  return [
    {
      title: 'Stress/Scenario Type',
      field: 'stressScenarioType',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: (rowData, rowDataValues) =>
        rowDataValues.headerRow
          ? {
              padding: '1.25rem',
              fontWeight: 700,
              color: mainColors.mainBlue,
              textAlign: 'center',
            }
          : {
              padding: 0,
            },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        padding: 0,
      },
      headerStyle: {},
    },
    {
      title: 'P/L',
      field: 'pL',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfRenderType: 'StatusWithPercentage',
      renderMethod: {
        methodName: 'percentageToTwoDecimalPlaces',
        params: ['pL'],
      },
      render: (rowData) => {
        const statusCell = rowData.headerRow ? (
          ''
        ) : (
          <GenericStatusCell
            height={'3.12rem'}
            status={rowData.pLstatus!}
            innerText={percentageToTwoDecimalPlaces(rowData.pL!)}
          />
        );
        return statusCell;
      },
    },
    {
      title: 'Ex-Ante Volatility',
      field: 'exAnteVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVolatility as number),
    },
    {
      title: 'Ex-Ante VaR',
      field: 'exAnteVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVar as number),
    },
    {
      title: '#SDs',
      field: 'numSds',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numSds as number),
    },
    {
      title: '#VaR',
      field: 'numVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numVar as number),
    },
    {
      title: 'Ex-Post Volatility',
      field: 'exPostVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVolatility as number),
    },
    {
      title: 'Ex-Post VaR',
      field: 'exPostVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVar as number),
    },
  ];
};

export const sectorNameMap: { [key: string]: string } = {
  risk_sector_stress_tests: 'Risk Sector Stress',
  historical_stress_tests: 'Historical Stress',
  relative_stress_tests: 'Relative Stress',
};

export function buildStressTestData(
  counterparty: string | null,
  inputData: any[],
  inputRestrictions: any[],
) {
  if (!inputData || !inputRestrictions || !counterparty) return [];
  try {
    const mainData = inputData[0][counterparty];

    const nav = mainData.nav;
    const restrictions = inputRestrictions[0];

    let riskSectorData;
    let historicalData;
    const returnArr: StressTestsData[] = [];
    [
      'risk_sector_stress_tests',
      'historical_stress_tests',
      'relative_stress_tests',
    ].forEach((itemKey) => {
      if (itemKey in mainData && Object.keys(mainData[itemKey]).length) {
        // add header row

        returnArr.push({
          headerRow: true,
          stressScenarioType: sectorNameMap[itemKey],
          name: null,
          pL: null,
          pLstatus: null,
          exAnteVolatility: null,
          exAnteVar: null,
          numSds: null,
          numVar: null,
          exPostVolatility: null,
          exPostVar: null,
        });
        Object.keys(mainData[itemKey]).forEach((key: any) => {
          const name = key;
          const dataValuesForOtherColumns = mainData[itemKey][key];
          const pLvalue = dataValuesForOtherColumns[0] / nav;
          returnArr.push({
            headerRow: false,
            stressScenarioType: null,
            name,
            pL: pLvalue,
            pLstatus:
              pLvalue < 0 && Math.abs(pLvalue) >= restrictions[name]
                ? Status.Alert
                : Status.Pass, // come back to this
            exAnteVolatility: dataValuesForOtherColumns[1] / nav,
            exAnteVar: dataValuesForOtherColumns[2] / nav,
            numSds: dataValuesForOtherColumns[3],
            numVar: dataValuesForOtherColumns[4],
            exPostVolatility: dataValuesForOtherColumns[5] / nav,
            exPostVar: dataValuesForOtherColumns[6] / nav,
          });
        });
      }
    });

    return returnArr;
  } catch (err) {
    console.error('Problem building stress data: ', err);
  }
  return [];
}
const CounterpartyStressTestsTable: FC<CounterpartyStressTestsProps> = ({
  counterpartyStressTestData,
  stressTestRestrictions,
  counterparty,
}) => {
  const columns = buildStressColumns();
  const builtData = buildStressTestData(
    counterparty,
    counterpartyStressTestData.data,
    stressTestRestrictions.data,
  );

  return (
    <GridItem card xs={12}>
      <CustomTable<StressTestsData>
        pdfNoClearFirstRow
        options={{
          paging: false,
          exportButton: true,
        }}
        showToolbar={true}
        data={builtData}
        toolbarComponents={{
          toolbarTitle: counterparty,
        }}
        columns={columns}
        csvFields={[
          'stressScenarioType',
          'name',
          'pL',
          'pLstatus',
          'exAnteVolatility',
          'exAnteVar',
          'numSds',
          'numVar',
          'exPostVolatility',
          'exPostVar',
        ]}
      />
    </GridItem>
  );
};

export default CounterpartyStressTestsTable;
