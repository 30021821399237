import { makeStyles } from '@mui/styles';
import { greys, mainColors } from '../../../../../styling/theme';

export const useKidsEditorStyles = makeStyles(() => ({
  editorPageParent: {
    display: 'flex',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    gap: '3.12rem',
    justifyContent: 'center',
    height: '100%',
  },
  editorContainer: {
    maxWidth: '50.00rem',
    width: '100%',
    padding: '0 0.62rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.31rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '0.62rem',
    },
  },
  viewerContainer: {
    width: '100%',
  },
}));

export const useEditorTileStyles = makeStyles(() => ({
  textEditorBox: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    marginLeft: '0.62rem',
    fontSize: '0.94rem',
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '6.25rem',
  },
  outerContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    padding: '0.62rem',
  },
  editorContainer: {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    width: '100%',
    marginRight: '0.62rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
  button: {
    height: '1.88rem',
    marginRight: '0.62rem',
    width: '9.38rem',
  },
}));

export const useEditorPdfViewerStyles = makeStyles(() => ({
  viewerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 8.75rem)',
    border: `1px solid ${greys.grey200}`,
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    margin: 0,
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Changed to space-between for left/right groups
    padding: '0.47rem 0.62rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${greys.grey100}`,
    minHeight: '48px',
  },

  toolbarGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.47rem',
  },

  divider: {
    height: '24px',
    width: '1px',
    backgroundColor: greys.grey200,
    margin: '0 0.31rem',
  },

  zoomControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.16rem',
  },

  zoomButton: {
    minWidth: '28px !important',
    width: '28px',
    height: '28px',
    padding: '4px !important',
    border: `1px solid ${greys.grey200} !important`,
    '&:hover': {
      backgroundColor: greys.grey500,
    },
    '&.Mui-disabled': {
      borderColor: `${greys.grey100} !important`,
    },
  },

  zoomInput: {
    width: '64px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
    },
    '& input': {
      padding: '4px',
      textAlign: 'center',
      fontSize: '0.55rem',
    },
  },

  toolbarButton: {
    fontSize: '0.56rem',
    height: '1.88rem',
    padding: '0.12rem 0.62rem',
    color: greys.grey800,
    backgroundColor: 'white',
    borderRadius: '0.12rem',
    borderColor: greys.grey200,
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: greys.grey500,
    },
  },

  primaryButton: {
    fontSize: '0.56rem',
    height: '1.88rem',
    padding: '0.12rem 0.62rem',
    color: 'white',
    backgroundColor: mainColors.mainBlue,
    borderRadius: '0.12rem',
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&.Mui-disabled': {
      backgroundColor: greys.grey200,
      color: greys.grey400,
    },
  },

  iframeContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    backgroundColor: greys.grey200,
    padding: '1.25rem',
    position: 'relative',
  },

  documentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: greys.grey200,
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: greys.grey200,
    },
  },

  pageContainer: {
    margin: '0.62rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    width: 'fit-content',
    '& canvas': {
      display: 'block',
      backgroundColor: 'white',
    },
  },

  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '1123px',
    minWidth: '794px',
    backgroundColor: greys.grey200,
    margin: '0.62rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '1123px',
    minWidth: '794px',
    backgroundColor: 'white',
    margin: '0.62rem auto',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
}));

