import makeStyles from '@mui/styles/makeStyles';
import React, { FC, FormEvent, useEffect } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import requestClient from '../../../../../utilities/requestClient';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { roleTranslatorReverse } from '../ManageAccounts.component';
import clsx from 'clsx';
import TokenService from '../../../../../services/token-service';
import FastAPIInstance from '../../../../../services/risksystem3-public-api';

const useStyles = makeStyles(() => ({
  newUserContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '0.62rem',
    borderRadius: '0.25rem',
  },
  inviteNewUserTitle: {
    fontSize: '1.25rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  inviteNewUserDescription: {
    fontSize: '0.75rem',
    fontWeight: 400,
    margin: '0.31rem 0 0 0',
    color: mainColors.mainBlue,
  },
  inviteNewUserDescription_warning: {
    color: mainColors.Fail,
  },
  emailInput: {
    width: '18.75rem',
    height: '1.88rem',
    padding: '0.31rem 0.62rem',
    border: `1px solid ${mainColors.mainBlue}`,
    fontSize: '0.88rem',
    fontWeight: 400,
    margin: '0.62rem 0',
  },
  selectContainer: {
    width: '18.75rem',
    height: '1.88rem',
    fontSize: '0.88rem',
    fontWeight: 400,
    marginBottom: '0.62rem',
  },
  sendInviteButton: {
    all: 'unset',
    width: '5.00rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  sendInviteButton_inactive: {
    all: 'unset',
    width: '5.00rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: greys.grey300,
    color: 'white',
    userSelect: 'none',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.62rem',
    height: '1.88rem',
  },
}));

const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'Success':
      return mainColors.Pass;
    case 'Fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

const NewUser: FC = () => {
  const classes = useStyles();

  const [email, setEmail] = React.useState<string>('');
  const [role, setRole] = React.useState<string>('Individual');

  const [coolDownActive, setCoolDownActive] = React.useState<boolean>(false);
  const [seconds, setSeconds] = React.useState<number>(5);
  const [intervalId, setIntervalId] = React.useState<any>(null);
  const [authStatus, setAuthStatus] = React.useState<string>('idle');
  const [authMessage, setAuthMessage] = React.useState<string>('');

  const coolDown = () => {
    setCoolDownActive(true);
    if (seconds > 0) {
      const id = setInterval(() => setSeconds((seconds) => seconds - 1), 1000);
      setIntervalId(id);
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalId);
      setCoolDownActive(false);
      setSeconds(5);
    }
  }, [seconds]);

  const sendInvitation = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!coolDownActive) {
      setAuthStatus('Loading');
      setAuthMessage('Submitting signup request...');
      if (!email || email.length === 0) {
        setAuthStatus('Fail');
        setAuthMessage('Please enter an email address.');
        return;
      } else {
        coolDown();
        FastAPIInstance
          .post(
            '/v1/external_user_management/create_user_invitation',
            {
              user_email: email,
              user_type: roleTranslatorReverse(role),
            },
            {
              headers: {
                Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
              },
            },
          )
          .then((res) => {
            if (res.status === 200) {
              setAuthStatus('Success');
              setAuthMessage('Signup invitation sent.');
            } else {
              setAuthStatus('Fail');
              setAuthMessage(
                'Unknown Error. Please contact RiskSystem support.',
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              setAuthStatus('Fail');
              setAuthMessage(err.response.data.detail || 'Unknown Error. Please contact RiskSystem support.');
            }
          });
      }
    }
  };

  return (
    <div className={classes.newUserContainer}>
      <div className={classes.inviteNewUserTitle}>Invite New User</div>
      <div className={classes.inviteNewUserDescription}>
        An email will be sent to the new user with a link to create an account.
      </div>
      <div
        className={clsx(
          classes.inviteNewUserDescription,
          classes.inviteNewUserDescription_warning,
        )}
      >
        This new user will be given access to all features and funds. If you
        would like to create an account with restricted access, please contact
        RiskSystem support.
      </div>
      <form onSubmit={sendInvitation} autoComplete="off">
        <input
          className={classes.emailInput}
          type="email"
          id={`email-input`}
          name="email-input"
          placeholder={'Email Address'}
          autoComplete="new-email"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className={classes.selectContainer}>
          <RaptorSelect
            options={[
              // Removing the administrator option for now - DC 2024-03-27
              // 'Administrator',
              'Individual',
            ]}
            defaultOption={role}
            handleChoice={(choice: string) => {
              setRole(choice);
            }}
          />
        </div>
        <div className={classes.actionContainer}>
          {coolDownActive ? (
            <div className={classes.sendInviteButton_inactive}>{seconds}</div>
          ) : (
            <button type="submit" className={classes.sendInviteButton}>
              Send Invite
            </button>
          )}
          {authMessage && (
            <div
              style={{
                color: getAuthMessageColor(authStatus),
              }}
            >
              <h3>{authMessage}</h3>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default NewUser;
