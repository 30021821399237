import React, { FC } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { flexify } from '../../../utilities/cssMixins';

interface Props {
  noMinHeight?: boolean;
  setWidth?: number | string;
  setHeight?: number | string;
  children: React.ReactNode;
}

const DisplayAreaCenteredWrapper: FC<Props> = ({
  noMinHeight,
  setWidth,
  setHeight,
  children,
}) => {
  const { height } = useWindowSize();

  const heightWithoutTop = height - 128;
  const width = '100%';
  return (
    <div
      style={{
        height: setHeight
          ? setHeight
          : noMinHeight
            ? '4.00rem'
            : heightWithoutTop - 16,
        width: setWidth ? setWidth : width,
        ...flexify(),
      }}
    >
      {children}
    </div>
  );
};

export default DisplayAreaCenteredWrapper;
