import React, { FC } from 'react';
import { roundNumberToNDecimalPlaces } from '../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../styling/theme';
import { ColumnIdToHeaderMapType } from '../../../../feedback/ExportButton';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';

export interface VarChangesTableProps {
  data: any;
  positionDate: string;
}

interface VarChangesTableData {
  asset: string;
  position_var_data: number | string;
  position_cvar_data: number | string;
  position_dvar_data: number | string;
}

const buildColumns = (
  positionDate: string,
): CustomColumn<VarChangesTableData>[] => {
  return [
    {
      title: 'Asset',
      field: 'asset',
      width: '40%',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '1.00rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '|',
      field: 'var',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: 'VAR',
      field: 'position_var_data',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset' || rowData.position_var_data === '') {
          return rowData.position_var_data;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_var_data,
            2,
          )} %`;
        }
      },
    },
    {
      title: '|',
      field: 'cvar',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: 'C-VAR',
      field: 'position_cvar_data',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset' || rowData.position_cvar_data === '') {
          return rowData.position_cvar_data;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_cvar_data,
            2,
          )} %`;
        }
      },
    },
    {
      title: '|',
      field: 'dvar',
      width: '0.62rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: () => {
        return '|';
      },
    },
    {
      title: 'D-VAR',
      field: 'position_dvar_data',
      cellStyle: (
        data: VarChangesTableData[],
        rowData: VarChangesTableData,
      ) => {
        if (rowData.asset === 'Asset') {
          return {
            textAlign: 'center',
            fontSize: '0.88rem',
            fontWeight: 600,
            color: mainColors.mainBlue,
          };
        } else {
          return {
            textAlign: 'center',
          };
        }
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: VarChangesTableData) => {
        if (rowData.asset === 'Asset' || rowData.position_dvar_data === '') {
          return rowData.position_dvar_data;
        } else {
          return `${roundNumberToNDecimalPlaces(
            rowData.position_dvar_data,
            2,
          )} %`;
        }
      },
    },
  ];
};

const buildVarData = (data: any, positionDate: string) => {
  if (!data.data || !data.data.length) {
    return [];
  } else {
    const outputData: VarChangesTableData[] = [];
    Object.keys(data.data[0]).map((assetName: string) => {
      const asset = data.data[0][assetName];
      outputData.push({
        asset: assetName,
        position_var_data: asset.position_var_data ?? '',
        position_cvar_data: asset.position_cvar_data ?? '',
        position_dvar_data: asset.position_dvar_data ?? '',
      });
    });
    outputData.sort((a: VarChangesTableData, b: VarChangesTableData) => {
      if (a.asset === 'Aggregate') {
        return -1;
      } else if (b.asset === 'Aggregate') {
        return 1;
      } else {
        return a.asset.localeCompare(b.asset, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }
    });
    return outputData;
  }
};

const buildExportData = (data: any) => {
  const outputData: VarChangesTableData[] = [];
  data.forEach((row: VarChangesTableData) => {
    if (row.asset !== 'Asset') {
      outputData.push(row);
    }
  });
  return outputData;
};

const buildColumnFieldsMapColumns = (
  positionDate: string,
): ColumnIdToHeaderMapType => {
  return [
    {
      label: 'Asset',
      key: 'asset',
    },
    {
      label: `VAR - ${positionDate}`,
      key: 'position_var_data',
    },
    {
      label: `C-VAR - ${positionDate}`,
      key: 'position_cvar_data',
    },
    {
      label: `D-VAR - ${positionDate}`,
      key: 'position_dvar_data',
    },
  ];
};

const VarNewBreakdownTable: FC<VarChangesTableProps> = (props) => {
  const builtVarData = buildVarData(props.data, props.positionDate);
  const exportData = buildExportData(builtVarData);

  const columns = buildColumns(props.positionDate);
  const columnFieldsMap = buildColumnFieldsMapColumns(props.positionDate);

  return (
    <GridItem xs={12} card>
      <CustomTable<VarChangesTableData>
        columns={columns}
        showToolbar
        data={builtVarData}
        title={'VaR Changes'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
          draggable: false,
          rowStyle: (rowData: VarChangesTableData) => ({
            backgroundColor:
              rowData.asset === 'Aggregate' ? mainColors.lightGrey : 'white',
          }),
        }}
        fieldsMap={columnFieldsMap}
        exportData={exportData}
      />
    </GridItem>
  );
};

export default VarNewBreakdownTable;
