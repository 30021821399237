import React, { useMemo } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { activeSectionSelector } from '../../../../redux/pages/selectors';
import {
  createMainTableData,
  OverviewTableData,
  ucitsOverviewCsvFields,
  useOverviewColumns,
} from './data';

interface DeceasedTableProps {
  reportsData: DataObject;
  tableData: DataObject;
}

const DeceasedTable: React.FC<DeceasedTableProps> = (props) => {
  const clientName = useSelector(clientNameSelector);
  const section = useSelector(activeSectionSelector);

  const columns: CustomColumn<OverviewTableData>[] = useOverviewColumns(
    clientName,
    section,
  );

  const data = useMemo(
    () =>
      createMainTableData(
        props.tableData.data[0],
        props.reportsData.data,
        null, // Putting null in for sub fund risk reports for now.
        clientName,
        section,
      ),
    [props.tableData, props.reportsData],
  );
  return (
    <GridItem card xs={12}>
      <CustomTable<OverviewTableData>
        csvFields={ucitsOverviewCsvFields}
        showToolbar
        toolbarComponents={{
          toolbarTitle: 'Deceased Data',
        }}
        columns={columns}
        data={data}
        options={{
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, data.length],
          search: true,
          exportButton: true,
          rowStyle: {
            padding: '0.62rem',
          },
        }}
      />
    </GridItem>
  );
};

export default DeceasedTable;
