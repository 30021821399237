import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../styling/theme';
import DetailMap from './components/DetailMap.component';
import DetailBarChart from './components/DetailBarChart.component';
import DetailTimeSeries from './components/DetailTimeSeries.component';
import { Grid } from '@mui/material';

interface OverviewSecondLevelGraphsProps {
  fundName: string;
  positionDate: string;
  varFund: boolean;
  passValue: string;
}

export const useOverviewSecondLevelStyles = makeStyles(() => ({
  chartContainerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  map: {
    textAlign: 'left',
    margin: '0.94rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '0.94rem',
  },
  outerDiv: {
    textAlign: 'center',
    width: '80%',
    maxWidth: '37.50rem',
    height: '80%',
  },
  container: {
    position: 'relative',
    padding: '1.25rem 0',
    paddingBottom: 0,
  },
  mapTitle: {
    marginTop: 0,
    marginBottom: '1.88rem',
    color: mainColors.mainBlue,
    textAlign: 'center',
  },
}));

export type RenderDataType = {
  [index: string]: (string | number)[][];
};

const OverviewSecondLevelGraphs: React.FC<OverviewSecondLevelGraphsProps> = (
  props,
) => {
  const classes = useOverviewSecondLevelStyles();

  const overviewGraphsData = useFetchData({
    url: `uar_historical_overview_graph/${props.fundName}/${props.positionDate}`,
    keyName: `overview_graphs_${props.fundName}_${props.positionDate}`,
    makeFetch: true,
  });

  let renderData: RenderDataType = {};
  if (overviewGraphsData && overviewGraphsData.data.length) {
    renderData = overviewGraphsData.data[0];
  }

  return (
    <GeneralComponentErrorShield
      dataObjects={[overviewGraphsData]}
      setLoadingHeight={'28.12rem'}
    >
      <Grid container className={classes.container}>
        <DetailMap renderData={renderData} />
        <DetailBarChart renderData={renderData} varFund={props.varFund} />
        <DetailTimeSeries renderData={renderData} varFund={props.varFund} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default OverviewSecondLevelGraphs;
