import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import { roundNumberToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import GenericMapChart from '../../../../charts/map/GenericMapChart';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../../selects/GeneralSelect';
import { Typography } from '@mui/material';
import { IESGPortfolioSummary } from '../types/esg-portfolio.types';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  visualisationsContainer: {
    display: 'flex',
    position: 'relative',
    // top: '2.50rem',
    // height: 500,
    width: '100%',
  },

  genericBarChartContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
    paddingTop: '0.75rem',
    paddingLeft: '0.75rem',
  },
  header: {
    position: 'relative',
    // top: '1.88rem',
  },
  tableContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '15%',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '0.75rem',
    height: '3.12rem',
    width: '100%',
    marginBottom: '0.75rem',
  },
  selectRoot: {
    width: '100%',
  },
  outerDiv: {
    textAlign: 'center',
    // height: "25.00rem",
    width: '100%',
    // maxWidth: '37.50rem',
    height: '100%',
  },
}));

interface geographicalExposureData {
  country: string;
  Environmental: number;
  Social: number;
  'Corporate Governance': number;
  ESG: number;
}

function prepareGeographicalDataForMap(
  data: IESGPortfolioSummary,
  scoreType: string,
) {
  if (!data) return [];
  if ('no data' in data) return [];
  const geographicalData: any[] = [];
  let scoreKey = 'esg';
  // Get the ESG Version Number.
  const version = data.version;
  // Case for version 2.
  if (version === 3) {
    // Case for Version 3
    if (scoreType === 'Environmental') scoreKey = 'E_Score';
    else if (scoreType === 'Social') scoreKey = 'S_Score';
    else if (scoreType === 'Corporate Governance') scoreKey = 'G_Score';
    else scoreKey = 'ESG_Score';
    const countryData = data.geographical_scores;

    Object.keys(countryData).forEach((country: any) => {
      geographicalData.push([
        country,
        roundNumberToTwoDecimalPlaces(countryData[country][scoreKey]),
      ]);
    });
  } else {
    if (scoreType === 'Environmental') scoreKey = 'e';
    else if (scoreType === 'Social') scoreKey = 's';
    else if (scoreType === 'Corporate Governance') scoreKey = 'g';
    const countryData = data.geographical_scores;

    countryData.forEach((value: any) => {
      geographicalData.push([
        value.country_code,
        roundNumberToTwoDecimalPlaces(value.scores.gross[scoreKey]),
      ]);
    });
  }
  return geographicalData;
}

interface GeographicalExposureProps {
  esgData: IESGPortfolioSummary;
}

interface GeographicalExposureChartProps {
  scoreType: string;
  data: any;
  title: string;
}

function getMapColor(selection: string) {
  switch (selection) {
    case 'Environmental':
      return mainColors.Pass_darker;
    case 'Social':
      return mainColors.pumpkin;
    case 'Corporate Governance':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const EsgGeographicalExposureChart: FC<GeographicalExposureChartProps> = (
  props,
) => {
  const { scoreType, data, title } = props;
  const mapData = prepareGeographicalDataForMap(data, scoreType);
  const mapColor = getMapColor(scoreType);

  const valueFormatter = (val: string | number) => `${val}`;
  const labelFormatter = (val: string | number) => `${val}`;
  return (
    <GenericMapChart
      data={mapData}
      title={title}
      tooltipValueFormatter={valueFormatter}
      tooltipLabelFormatter={labelFormatter}
      color={mapColor}
    />
  );
};

const GeographicalExposurePanel: FC<GeographicalExposureProps> = (props) => {
  const classes = useStyles();
  const { esgData } = props;
  const [scoreType, setScoreType] = useState('ESG');
  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setScoreType(value);
  };
  return (
    <GridItem
      card
      lg={12}
      cardStyle={{
        padding: '0.62rem',
        height: '60%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        minHeight: '18.75rem',
      }}
    >
      <div>
        <div className={classes.visualisationsContainer}>
          <Typography
            variant="h2"
            style={{
              fontSize: '1.50rem',
              width: '100%',
              fontWeight: 500,
              textAlign: 'left',
            }}
            className={classes.header}
          >
            {'ESG Geographical Exposure'}
          </Typography>
          <div className={classes.generalSelectContainer}>
            <GeneralSelect
              labels={[
                'ESG',
                'Environmental',
                'Social',
                'Corporate Governance',
              ]}
              values={[
                'ESG',
                'Environmental',
                'Social',
                'Corporate Governance',
              ]}
              selected={scoreType}
              setSelected={setSelected}
              placeholderValue=""
              placeholderColor={greys.grey900}
              selectTitle={'Score Type'}
              customStyles={{
                pickerRoot: classes.pickerRoot,
                selectRoot: classes.selectRoot,
              }}
            />
          </div>
        </div>
        <div style={{ height: '50%' }}>
          <EsgGeographicalExposureChart
            data={esgData}
            scoreType={scoreType}
            title={`${scoreType} Score by Country`}
          />
        </div>
      </div>
    </GridItem>
  );
};

export default GeographicalExposurePanel;
