import makeStyles from '@mui/styles/makeStyles';
import React, { FC, FormEvent, useEffect } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { validatePassword } from '../../../../../utilities/accountDetailsValidation';
import { ClassNameMap } from '@mui/styles';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import TokenService from '../../../../../services/token-service';
import FastAPIInstance from '../../../../../services/risksystem3-public-api';

const useStyles = makeStyles(() => ({
  accountDetailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '0.62rem',
    borderRadius: '0.25rem',
  },
  accountDetailsTitle: {
    fontSize: '1.25rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
    marginBottom: '0.62rem',
  },
  accountDetailsUsername: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.31rem',
    alignItems: 'center',
    fontSize: '1.56rem',
    fontWeight: 600,
    marginBottom: '0.62rem',
    color: mainColors.accentOrange,
  },
  accountDetailsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.62rem',
  },
  accountDetailsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
  },
  accountDetailsItemTitle: {
    fontSize: '0.94rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  accountDetailsItemValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
    color: mainColors.mainBlue,
  },
  accountDetailsItemValue: {
    all: 'unset',
    fontSize: '1.00rem',
    fontWeight: 400,
    padding: '0.31rem 0.62rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '18.75rem',
  },
  accountDetailsItemValue_password: {
    all: 'unset',
    fontSize: '1.00rem',
    fontWeight: 400,
    padding: '0.31rem 2.50rem 0.31rem 0.62rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '16.88rem',
  },
  submitChangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '1.88rem',
  },
  resetPasswordButton: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  profilePicture: {
    height: '6.25rem',
    width: '6.25rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.mainBlue,
    fontSize: '1.88rem',
    fontWeight: 600,
    border: `3px solid ${mainColors.mainBlue}`,
    userSelect: 'none',
    backgroundColor: greys.grey100,
  },
  submitEditButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  submitEditButton_inactive: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    padding: '0.31rem 0.62rem',
    borderRadius: '0.25rem',
    fontWeight: 400,
    fontSize: '0.94rem',
    backgroundColor: greys.grey300,
    color: 'white',
    userSelect: 'none',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  emailInputs: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
  },
  verificationCodeInput: {
    all: 'unset',
    fontSize: '1.00rem',
    fontWeight: 400,
    padding: '0.31rem 0.62rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '3.75rem',
  },
  newPasswordGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.62rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input_valid: {
    border: `1px solid ${mainColors.Pass}`,
    backgroundColor: mainColors.Pass_veryLight,
  },
  input_not_valid: {
    border: `1px solid ${mainColors.Fail}`,
    backgroundColor: mainColors.Fail_veryLight,
  },
  inputValidationMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '0.31rem',
    '& svg': {
      fontSize: '1.00rem',
      color: greys.grey600,
    },
  },
  inputValidationMessage: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: mainColors.mainBlue,
  },
  inputValidationMessage_valid: {
    color: mainColors.Pass,
  },
  inputValidationMessage_not_valid: {
    color: mainColors.Fail,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.31rem',
  },
  tooltipTitle: {
    fontSize: '0.88rem',
    fontWeight: 600,
  },
  tooltipItem: {
    fontSize: '0.62rem',
  },
  passwordInput: {
    width: 'fit-content',
    position: 'relative',
  },
  showPasswordButton: {
    position: 'absolute',
    right: '0.62rem',
    top: '50%',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
    '& svg': {
      fontSize: '1.56rem',
      color: greys.grey600,
    },
  },
  passwordExpiredMessage: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  passwordExpiredMessage_expired: {
    color: mainColors.Fail,
  },
  passwordExpiredMessage_ok: {
    color: mainColors.Pass,
  },
}));

const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

const passwordValidationStyles = (password: string, classes: ClassNameMap) => {
  if (password.length === 0) {
    return classes.accountDetailsItemValue_password;
  } else if (password.length > 0 && validatePassword(password)) {
    return clsx(classes.accountDetailsItemValue_password, classes.input_valid);
  } else {
    return clsx(
      classes.accountDetailsItemValue_password,
      classes.input_not_valid,
    );
  }
};

const confirmPasswordValidation = (
  password: string,
  confirmPassword: string,
  classes: ClassNameMap,
) => {
  if (confirmPassword.length === 0) {
    return classes.accountDetailsItemValue_password;
  } else if (
    confirmPassword.length > 0 &&
    validatePassword(confirmPassword) &&
    password === confirmPassword
  ) {
    return clsx(classes.accountDetailsItemValue_password, classes.input_valid);
  } else {
    return clsx(
      classes.accountDetailsItemValue_password,
      classes.input_not_valid,
    );
  }
};

const AccountDetails: FC = () => {
  const classes = useStyles();

  const [userDetails, setUserDetails] = React.useState<any>(
    TokenService.getUser()
  );

  const [firstName, setFirstName] = React.useState<string>(
    userDetails?.user_first_name ?? '',
  );
  const [firstNameRequestStatus, setFirstNameRequestStatus] =
    React.useState<string>('idle');
  const [firstNameRequestMessage, setFirstNameRequestMessage] =
    React.useState<string>('');

  const [lastName, setLastName] = React.useState<string>(
    userDetails?.user_last_name ?? '',
  );
  const [lastNameRequestStatus, setLastNameRequestStatus] =
    React.useState<string>('idle');
  const [lastNameRequestMessage, setLastNameRequestMessage] =
    React.useState<string>('');

  const [email, setEmail] = React.useState<string>(
    userDetails?.user_email ?? '',
  );
  const [emailAuthKey, setEmailAuthKey] = React.useState<string>('');
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [emailRequestStatus, setEmailRequestStatus] =
    React.useState<string>('idle');
  const [emailRequestMessage, setEmailRequestMessage] =
    React.useState<string>('');

  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] =
    React.useState<string>('');
  const [passwordRequestStatus, setPasswordRequestStatus] =
    React.useState<string>('idle');
  const [passwordRequestMessage, setPasswordRequestMessage] =
    React.useState<string>('');

  const [showOldPassword, setShowOldPassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    React.useState<boolean>(false);

  const [isPasswordExpired, setIsPasswordExpired] =
    React.useState<boolean>(false);
  const [isPasswordExpiredMessage, setIsPasswordExpiredMessage] =
    React.useState<string>('');

  useEffect(() => {
    FastAPIInstance
      .get('/v1/settings/auth_pref_attrs/is_passwd_expired')
      .then((res) => {
        setIsPasswordExpired(res.data.is_passwd_expired);
        // Get the current UTC datetime
        const currentDate = new Date();

        // Convert the string date from user_model to a Date object
        const userPasswordLastUpdate = new Date(
          res.data.user_password_last_update,
        );

        // Calculate the difference in days
        const timeDiff =
          currentDate.getTime() - userPasswordLastUpdate.getTime();
        const dayDifference = Math.floor(timeDiff / (1000 * 3600 * 24));

        if (res.data.is_passwd_expired) {
          setIsPasswordExpiredMessage(
            `Your password is ${dayDifference} days old out of a recommended ${res.data.password_update_frequency_days}. Please choose a new one.`,
          );
        } else {
          setIsPasswordExpiredMessage(
            `Your password is ${dayDifference} days old out of a recommended ${res.data.password_update_frequency_days}.`,
          );
        }
      });
  }, []);

  // this function takes the user input for username and auto formats it with our desired format
  // currently this just means making all characters lowercase
  const handleEmailInputEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEmail(event.target.value.toLowerCase());
  };

  const checkIsEmailValid = (emailToCheck: string): boolean => {
    if (emailToCheck.length === 0) return false;
    if (emailToCheck.includes('@') && emailToCheck.includes('.')) {
      const emailParts = emailToCheck.split('@');
      if (emailParts.length !== 2) return false;
      if (emailParts[0].length === 0) return false;
      const emailDomainParts = emailParts[1].split('.');
      if (emailDomainParts.length !== 2) return false;
      if (emailDomainParts[0].length === 0) return false;
      if (emailDomainParts[1].length === 0) return false;
      return true;
    } else {
      return false;
    }
  };

  // this function takes the user input for verification code and auto formats it to be only numeric
  const handleVerificationCodeEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const result = event.target.value.replace(/\D/g, '');
    if (result.length > 6) return;
    setVerificationCode(result);
  };

  const handleSubmitChange = (
    e: FormEvent<HTMLFormElement>,
    type: 'first-name' | 'last-name' | 'password',
  ): void => {
    e.preventDefault();
    if (type === 'first-name') {
      setFirstNameRequestStatus('loading');
      setFirstNameRequestMessage('Submitting update request...');
      FastAPIInstance
        .put(
          `/v1/external_user_management/user_id/${userDetails?.user_id}`,
          {
            attributes: {
              user_first_name: firstName,
            },
            user_last_updating_datetime: userDetails?.user_updating_datetime,
          },
        )
        .then(() => {
          setFirstNameRequestStatus('success');
          setFirstNameRequestMessage('Updated Successfully.');
          FastAPIInstance
            .get(`/v1/external_user_management/user_id/${userDetails?.user_id}`)
            .then((res) => {
              TokenService.setUser(res.data);
              setUserDetails(res.data);
            });
          setTimeout(() => {
            setFirstNameRequestStatus('idle');
            setFirstNameRequestMessage('');
          }, 5000);
        })
        .catch((error) => {
          setFirstNameRequestStatus('fail');
          setFirstNameRequestMessage(
            error.response?.data?.detail ||
              'Unknown Error. Please contact RiskSystem support.',
          );
        });
    } else if (type === 'last-name') {
      setLastNameRequestStatus('loading');
      setLastNameRequestMessage('Submitting update request...');
      FastAPIInstance
        .put(
          `/v1/external_user_management/user_id/${userDetails?.user_id}`,
          {
            attributes: {
              user_last_name: lastName,
            },
            user_last_updating_datetime: userDetails?.user_updating_datetime,
          },
        )
        .then(() => {
          setLastNameRequestStatus('success');
          setLastNameRequestMessage('Updated Successfully.');
          FastAPIInstance
            .get(`/v1/external_user_management/user_id/${userDetails?.user_id}`)
            .then((res) => {
              TokenService.setUser(res.data);
              setUserDetails(res.data);
            });
          setTimeout(() => {
            setLastNameRequestStatus('idle');
            setLastNameRequestMessage('');
          }, 5000);
        })
        .catch((error) => {
          setLastNameRequestStatus('fail');
          setLastNameRequestMessage(
            error.response?.data?.detail ||
              'Unknown Error. Please contact RiskSystem support.',
          );
        });
    } else if (type === 'password') {
      if (
        !oldPassword ||
        oldPassword.length === 0 ||
        !newPassword ||
        newPassword.length === 0 ||
        !confirmNewPassword ||
        confirmNewPassword.length === 0
      ) {
        setPasswordRequestStatus('fail');
        setPasswordRequestMessage('Please fill out all fields.');
        return;
      } else if (newPassword !== confirmNewPassword) {
        setPasswordRequestStatus('fail');
        setPasswordRequestMessage('New passwords do not match.');
        return;
      } else if (
        !validatePassword(newPassword) ||
        !validatePassword(confirmNewPassword)
      ) {
        setPasswordRequestStatus('fail');
        setPasswordRequestMessage('New password not valid.');
        return;
      } else {
        setPasswordRequestStatus('loading');
        setPasswordRequestMessage('Submitting update request...');
        FastAPIInstance
          .put(
            `/v1/external_user_management/reset_passwd`,
            {
              user_id: userDetails?.user_id,
              user_new_password: newPassword,
              user_old_passwd: oldPassword,
            },
          )
          .then(() => {
            setPasswordRequestStatus('success');
            setPasswordRequestMessage('Updated Successfully.');
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            FastAPIInstance
              .get(`/v1/external_user_management/user_id/${userDetails?.user_id}`)
              .then((res) => {
                TokenService.setUser(res.data);
                setUserDetails(res.data);
              });
            setTimeout(() => {
              setPasswordRequestStatus('idle');
              setPasswordRequestMessage('');
            }, 5000);
          })
          .catch((error) => {
            setPasswordRequestStatus('fail');
            setPasswordRequestMessage(
              error.response?.data?.detail ||
                'Error updating password. Please contact RiskSystem support.',
            );
          });
      }
    }
  };

  const handleSendEmailVerification = (): void => {
    setEmailRequestStatus('loading');
    setEmailRequestMessage('Sending verification code...');
    FastAPIInstance
      .get(`/v1/external_user_management/check_if_user_exists/user_email/${email}`)
      .then((res) => {
        if (!res.data.is_identifier_active) {
          setEmailRequestMessage('Submitting update request...');
          const formData = new FormData();
          formData.append(
            'user_last_updating_datetime',
            userDetails?.user_updating_datetime,
          );
          formData.append('auth_key', emailAuthKey);
          formData.append('future_user_email', email);
          formData.append('email_verification_code', verificationCode);
          FastAPIInstance
            .get(
              `/v1/external_user_management/send_update_email_verification?future_user_email=${email}`
            )
            .then((res) => {
              setEmailRequestStatus('success');
              setEmailRequestMessage('Verification code sent.');
              setEmailAuthKey(res.data.auth_key);
              FastAPIInstance
                .get(`/v1/external_user_management/user_id/${userDetails?.user_id}`)
                .then((res) => {
                  TokenService.setUser(res.data);
                  setUserDetails(res.data);
                });
              setTimeout(() => {
                setEmailRequestStatus('idle');
                setEmailRequestMessage('');
              }, 5000);
            })
            .catch((error) => {
              setEmailRequestStatus('fail');
              setEmailRequestMessage(
                error.response?.data?.detail ||
                  'Unknown Error. Please contact RiskSystem support.',
              );
            });
        } else {
          setEmailRequestStatus('fail');
          setEmailRequestMessage('Email already exists.');
        }
      })
      .catch((error) => {
        setEmailRequestStatus('fail');
        setEmailRequestMessage(
          error.response?.data?.detail ||
            'Unknown Error. Please contact RiskSystem support.',
        );
      });
  };

  const handleChangeEmail = (): void => {
    setEmailRequestStatus('loading');
    setEmailRequestMessage('Checking if email is available...');
    FastAPIInstance
      .get(`/v1/external_user_management/check_if_user_exists/user_email/${email}`)
      .then((res) => {
        if (!res.data.is_identifier_active) {
          setEmailRequestMessage('Submitting update request...');
          const formData = new FormData();
          formData.append(
            'user_last_updating_datetime',
            userDetails?.user_updating_datetime,
          );
          formData.append('auth_key', emailAuthKey);
          formData.append('future_user_email', email);
          formData.append('email_verification_code', verificationCode);
          FastAPIInstance
            .put(`/v1/external_user_management/update_email_confirmation`, formData, {
              headers: {
                Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
              },
            })
            .then(() => {
              setEmailRequestStatus('success');
              setEmailRequestMessage('Updated Successfully.');
              setEmailAuthKey('');
              setVerificationCode('');
              FastAPIInstance
                .get(`/v1/external_user_management/user_id/${userDetails?.user_id}`)
                .then((res) => {
                  TokenService.setUser(res.data);
                  setUserDetails(res.data);
                  setEmailAuthKey('');
                });
              setTimeout(() => {
                setEmailRequestStatus('idle');
                setEmailRequestMessage('');
              }, 5000);
            })
            .catch((error) => {
              setEmailRequestStatus('fail');
              setEmailRequestMessage(
                error.response?.data?.detail ||
                  'Verification code is incorrect or expired. Please resend.',
              );
            });
        } else {
          setEmailRequestStatus('fail');
          setEmailRequestMessage('Email already exists.');
        }
      })
      .catch((error) => {
        setEmailRequestStatus('fail');
        setEmailRequestMessage(
          error.response?.data?.detail ||
            'Unknown Error. Please contact RiskSystem support.',
        );
      });
  };

  return (
    <div className={classes.accountDetailsContainer}>
      <div className={classes.accountDetailsTitle}>Account Details</div>
      <div className={classes.accountDetailsUsername}>
        <div>@</div>
        <div>{userDetails?.user_name ?? ''}</div>
      </div>
      {/* <div
        className={classes.accountDetailsSection}
      >
        <div
          className={classes.accountDetailsItem}
        >
          <div
            className={classes.accountDetailsItemTitle}
          >
            Profile Picture
          </div>
          <div
            className={classes.profilePicture}
          >
            T W
          </div>
        </div>
      </div> */}
      <div className={classes.accountDetailsSection}>
        <div className={classes.accountDetailsItem}>
          <div className={classes.accountDetailsItemTitle}>First Name</div>
          <form
            className={classes.accountDetailsItemValueContainer}
            onSubmit={(e: FormEvent<HTMLFormElement>) =>
              handleSubmitChange(e, 'first-name')
            }
          >
            <input
              id="first-name-input"
              type="text"
              placeholder="First Name"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={classes.accountDetailsItemValue}
            />
            <div className={classes.submitChangeContainer}>
              {firstName !== userDetails?.user_first_name ? (
                <button type="submit" className={classes.submitEditButton}>
                  Submit Change
                </button>
              ) : (
                <div className={classes.submitEditButton_inactive}>
                  Submit Change
                </div>
              )}
              {firstNameRequestStatus !== 'idle' ? (
                <div
                  style={{
                    color: getAuthMessageColor(firstNameRequestStatus),
                  }}
                >
                  <h3>{firstNameRequestMessage}</h3>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
      <div className={classes.accountDetailsSection}>
        <div className={classes.accountDetailsItem}>
          <div className={classes.accountDetailsItemTitle}>Last Name</div>
          <form
            className={classes.accountDetailsItemValueContainer}
            onSubmit={(e: FormEvent<HTMLFormElement>) =>
              handleSubmitChange(e, 'last-name')
            }
          >
            <input
              id="last-name-input"
              type="text"
              placeholder="Last Name"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classes.accountDetailsItemValue}
            />
            <div className={classes.submitChangeContainer}>
              {lastName !== userDetails?.user_last_name ? (
                <button type="submit" className={classes.submitEditButton}>
                  Submit Change
                </button>
              ) : (
                <div className={classes.submitEditButton_inactive}>
                  Submit Change
                </div>
              )}
              {lastNameRequestStatus !== 'idle' ? (
                <div
                  style={{
                    color: getAuthMessageColor(lastNameRequestStatus),
                  }}
                >
                  <h3>{lastNameRequestMessage}</h3>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
      <div className={classes.accountDetailsSection}>
        <div className={classes.accountDetailsItem}>
          <div className={classes.accountDetailsItemTitle}>Email</div>
          <div className={classes.accountDetailsItemValueContainer}>
            <div className={classes.emailInputs}>
              <input
                id="email-input"
                type="email"
                placeholder="Email"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={email}
                onChange={(e) => handleEmailInputEnforcement(e)}
                className={classes.accountDetailsItemValue}
              />
              <input
                id="verification-code-input"
                type="text"
                placeholder="Code"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={verificationCode}
                onChange={(e) => handleVerificationCodeEnforcement(e)}
                className={classes.verificationCodeInput}
              />
            </div>
            <div className={classes.submitChangeContainer}>
              {email !== userDetails?.user_email &&
              checkIsEmailValid(email) &&
              emailRequestStatus !== 'checking' &&
              emailRequestStatus !== 'taken' ? (
                <button
                  type="submit"
                  className={classes.submitEditButton}
                  onClick={() => handleSendEmailVerification()}
                >
                  {emailAuthKey ? 'Resend Code' : 'Send Code'}
                </button>
              ) : (
                <div className={classes.submitEditButton_inactive}>
                  {emailAuthKey ? 'Resend Code' : 'Send Code'}
                </div>
              )}
              {email !== userDetails?.user_email &&
              emailAuthKey &&
              verificationCode.length === 6 &&
              emailRequestStatus !== 'checking' &&
              emailRequestStatus !== 'taken' ? (
                <button
                  type="submit"
                  className={classes.submitEditButton}
                  onClick={() => handleChangeEmail()}
                >
                  Submit Change
                </button>
              ) : (
                <div className={classes.submitEditButton_inactive}>
                  Submit Change
                </div>
              )}
              {emailRequestStatus !== 'idle' ? (
                <div
                  style={{
                    color: getAuthMessageColor(emailRequestStatus),
                  }}
                >
                  <h3>{emailRequestMessage}</h3>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.accountDetailsSection}>
        <div className={classes.accountDetailsItem}>
          <div className={classes.accountDetailsItemTitle}>Password</div>
          <div
            className={
              isPasswordExpired
                ? clsx(
                    classes.passwordExpiredMessage,
                    classes.passwordExpiredMessage_expired,
                  )
                : clsx(
                    classes.passwordExpiredMessage,
                    classes.passwordExpiredMessage_ok,
                  )
            }
          >
            {isPasswordExpiredMessage}
          </div>
          <form
            className={classes.passwordContainer}
            onSubmit={(e: FormEvent<HTMLFormElement>) =>
              handleSubmitChange(e, 'password')
            }
          >
            <div className={classes.inputGroup}>
              <div className={classes.passwordInput}>
                <input
                  id="old-password-input"
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder="Old Password"
                  autoComplete="new-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className={classes.accountDetailsItemValue_password}
                />
                <div
                  className={classes.showPasswordButton}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </div>
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.newPasswordGroup}>
                <div className={classes.passwordInput}>
                  <input
                    id="new-password-input"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={passwordValidationStyles(newPassword, classes)}
                  />
                  <div
                    className={classes.showPasswordButton}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </div>
                </div>
                <div className={classes.inputValidationMessageContainer}>
                  <Tooltip
                    title={
                      <div className={classes.tooltipContainer}>
                        <div className={classes.tooltipTitle}>
                          For security, we enforce the following password rules:
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must be at least 12 characters long.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 uppercase letter.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 lowercase letter.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 number.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 special character.
                        </div>
                      </div>
                    }
                    placement="right-end"
                  >
                    <Help />
                  </Tooltip>
                  {newPassword.length > 0 ? (
                    validatePassword(newPassword) ? (
                      <div
                        className={clsx(
                          classes.inputValidationMessage,
                          classes.inputValidationMessage_valid,
                        )}
                      >
                        Password valid.
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          classes.inputValidationMessage,
                          classes.inputValidationMessage_not_valid,
                        )}
                      >
                        Password not valid.
                      </div>
                    )
                  ) : null}
                </div>
              </div>
              <div className={classes.newPasswordGroup}>
                <div className={classes.passwordInput}>
                  <input
                    id="confirm-new-password-input"
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    placeholder="Confirm New Password"
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className={confirmPasswordValidation(
                      newPassword,
                      confirmNewPassword,
                      classes,
                    )}
                  />
                  <div
                    className={classes.showPasswordButton}
                    onClick={() =>
                      setShowConfirmNewPassword(!showConfirmNewPassword)
                    }
                  >
                    {showConfirmNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </div>
                </div>
                <div className={classes.inputValidationMessageContainer}>
                  <Tooltip
                    title={
                      <div className={classes.tooltipContainer}>
                        <div className={classes.tooltipTitle}>
                          For security, we enforce the following rules for
                          passwords:
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must be at least 12 characters long.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 uppercase letter.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 lowercase letter.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 number.
                        </div>
                        <div className={classes.tooltipItem}>
                          {' '}
                          - Must contain at least 1 special character.
                        </div>
                      </div>
                    }
                    placement="right-end"
                  >
                    <Help />
                  </Tooltip>
                  {confirmNewPassword.length > 0 ? (
                    validatePassword(confirmNewPassword) ? (
                      newPassword === confirmNewPassword ? (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_valid,
                          )}
                        >
                          Passwords match.
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Passwords do not match.
                        </div>
                      )
                    ) : (
                      <div
                        className={clsx(
                          classes.inputValidationMessage,
                          classes.inputValidationMessage_not_valid,
                        )}
                      >
                        Password not valid.
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
            <div className={classes.submitChangeContainer}>
              {oldPassword.length > 0 &&
              newPassword.length > 0 &&
              confirmNewPassword.length > 0 &&
              newPassword === confirmNewPassword &&
              validatePassword(newPassword) &&
              validatePassword(confirmNewPassword) ? (
                <button type="submit" className={classes.submitEditButton}>
                  Submit Change
                </button>
              ) : (
                <div className={classes.submitEditButton_inactive}>
                  Submit Change
                </div>
              )}
              {passwordRequestStatus !== 'idle' ? (
                <div
                  style={{
                    color: getAuthMessageColor(passwordRequestStatus),
                  }}
                >
                  <h3>{passwordRequestMessage}</h3>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
