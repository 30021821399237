// historical data will always be [date, ...params]
export type HistoricalData = string[][];

export interface IHistoricalTableData {
  [key: string]: string;
}
export const generateHistoricalTableColumns = (data: any[]) => {
  if (!data.length) return [];
  const dataForUse = data[0][0];

  try {
    const columns = dataForUse.map((col: string) => ({
      field: col,
      title: col,
      cellStyle: {
        padding: '0.62rem',
      },
      headerStyle: {
        padding: '0.62rem',
      },
    }));
    return columns;
  } catch (err) {
    return err;
  }
};

export const generateHistoricalTableData = (
  data: HistoricalData[],
): IHistoricalTableData[] => {
  if (!data.length) return [];
  const returnData = data[0].map((datum: string[]) => {
    const tableRow: {
      [key: string]: string;
    } = {};
    data[0][0].forEach((param, innerIndex) => {
      tableRow[param] = datum[innerIndex];
    });
    return tableRow;
  });
  // remove header row
  returnData.shift();
  return returnData;
};

export function generateHistoricalTimeSeriesData(inputData: HistoricalData[]) {
  if (!inputData.length) return [];
  // we have to replace "Date " with "name" for the chart
  const historicalTimeSeriesData = generateHistoricalTableData(inputData).map(
    (el: IHistoricalTableData) => {
      const { Date: name, ...rest } = el;
      return { name, ...rest };
    },
  );
  return historicalTimeSeriesData;
}
