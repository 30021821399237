import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';
import clsx from 'clsx';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import ExportButton from '../../../../feedback/ExportButton';
import { AttachMoney, Percent } from '@mui/icons-material';

interface MancoExposureBarChartProps {
  data: any;
}

interface MancoExposureBarChartData {
  name: string;
  value: number;
  percent: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    marginLeft: '1.88rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.62rem 1.56rem 0.62rem 0.94rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.38rem',
    fontWeight: 400,
    margin: '0.62rem 0.94rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '0.94rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '0.62rem 0.94rem',
    borderRadius: '0.31rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '12.50rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.00rem',
    fontWeight: 600,
  },
  tooltipValueBelowZero: {
    color: mainColors.Fail,
  },
  toggleButton: {
    all: 'unset',
    transition: 'width .2s',
    borderRadius: '1.88rem',
    fontSize: 'clamp(0.62rem, 0.9vw, 0.94rem)',
    backgroundColor: greys.grey400,
    width: '2.50rem',
    height: '2.50rem',
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',
    filter: `drop-shadow(0.06rem 0.06rem 0.06rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeButton: {
    backgroundColor: mainColors.mainBlue,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>{label}</div>
          <hr />
          <div className={classes.tooltipValue}>
            {payload[0].dataKey === 'percent'
              ? percentageToNdecialPlaces(payload[0].value, 2)
              : addCommasToNumbersAndRound(payload[0].value)}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

function mapBarColors(value: string) {
  switch (value) {
    case 'Equity':
      return mainColors.mainBlue_lighter;
    case 'Fixed Income':
      return mainColors.mainGold;
    case 'Foreign Exchange':
      return mainColors.Fail_darker;
    case 'Cash':
      return mainColors.tertiaryGreen;
    case 'Alternative':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const CustomBar = (props: any) => {
  return <Rectangle {...props} fill={mapBarColors(props.name)} />;
};

function buildChartData(data: any) {
  if (!data.data.length) return [];
  if (!('asset_class_gross_exposure' in data.data[0])) return [];
  if (!('manco_nav' in data.data[0])) return [];
  const baseCurrencyExposure = data.data[0].asset_class_gross_exposure;
  const nav = data.data[0].manco_nav;
  const chartData: MancoExposureBarChartData[] = [
    {
      name: 'Equity',
      value: baseCurrencyExposure.equity,
      percent: baseCurrencyExposure.equity / nav,
    },
    {
      name: 'Fixed Income',
      value: baseCurrencyExposure.fixed_income,
      percent: baseCurrencyExposure.fixed_income / nav,
    },
    {
      name: 'Foreign Exchange',
      value: baseCurrencyExposure.foreign_exchange,
      percent: baseCurrencyExposure.foreign_exchange / nav,
    },
    {
      name: 'Cash',
      value: baseCurrencyExposure.cash,
      percent: baseCurrencyExposure.cash / nav,
    },
    {
      name: 'Alternative',
      value: baseCurrencyExposure.alternative,
      percent: baseCurrencyExposure.alternative / nav,
    },
  ];
  return chartData;
}

const MancoExposureBarChart: React.FC<MancoExposureBarChartProps> = (props) => {
  const classes = useStyles();

  const [option, setOption] = useState<string>('percent');

  const builtChartData = buildChartData(props.data);

  return (
    <GridItem
      xs={7}
      card
      cardStyle={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>Asset Class Gross Exposure</h2>
        <div className={classes.buttonContainer}>
          <button
            className={
              option === 'percent'
                ? clsx(classes.toggleButton, classes.activeButton)
                : classes.toggleButton
            }
            onClick={() => {
              setOption('percent');
            }}
          >
            <Percent />
          </button>
          <button
            onClick={() => {
              setOption('value');
            }}
            className={
              option === 'value'
                ? clsx(classes.toggleButton, classes.activeButton)
                : classes.toggleButton
            }
          >
            <AttachMoney />
          </button>
          <ExportButton
            fields={Object.keys(builtChartData[0])}
            exportData={builtChartData}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={520}>
        <BarChart
          width={500}
          height={400}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            textAnchor="middle"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Asset Class'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={90}
            tickFormatter={(tickItem) => {
              if (option === 'percent') {
                return percentageToNdecialPlaces(tickItem, 1);
              } else {
                return formatMillions(tickItem);
              }
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Exposure'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey={option} shape={CustomBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default MancoExposureBarChart;
