import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC, useState } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { getCanUserNavigateAwaySelector } from '../../../../../redux/kids/selectors';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface styleProps {
  isHovered: boolean;
  setIsHovered: Dispatch<boolean>;
  dontSetPosition?: boolean;
}

const useStyles = makeStyles<styleProps>((props) => ({
  conrolContainer: {
    display: 'flex',
  },
  toggleIcon: {
    color: props.isHovered ? mainColors.mainBlue : greys.grey700,
  },
  toggleIconMessage: {
    transform: props.isHovered ? 'translateY(5.00rem)' : 'translateY(-5.00rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface BackToOverviewBtnProps {
  backToOverview: () => void;
  dontSetPosition?: boolean;
}

const BackToOverviewBtn: FC<BackToOverviewBtnProps> = (props) => {
  const { backToOverview, dontSetPosition } = props;

  // Check if the user should be able to navigate away without some dialog showing
  const navigateAway = useSelector(getCanUserNavigateAwaySelector);

  const backToOverviewWithDialog = () => {
    const result = window.confirm(
      'Are you sure you want to navigate away from this page? Any unsaved changes will be lost.',
    );
    if (result) {
      backToOverview();
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({
    isHovered: isHovered,
    setIsHovered: setIsHovered,
    dontSetPosition: dontSetPosition,
  });

  return (
    <div
      className={classes.conrolContainer}
      style={{
        position: dontSetPosition ? 'static' : 'fixed',
        marginTop: dontSetPosition ? '0' : '-3.44rem',
        zIndex: 100,
      }}
      data-testid="back-to-overview-btn"
    >
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Back To Overview"
        placement={dontSetPosition ? 'top' : 'right'}
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <ArrowBackIcon
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            fontSize: 30,
            color: mainColors.controlButtonBlue,
            opacity: isHovered ? 1 : dontSetPosition ? 0.8 : 0.6,
            cursor: isHovered ? 'pointer' : null,
          }}
          onClick={navigateAway ? backToOverview : backToOverviewWithDialog}
        />
      </Tooltip>
    </div>
  );
};

export default BackToOverviewBtn;
