import React, { FC } from 'react';
import useSnackbar from '../../../../hooks/useSnackbar';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { formatESGNumber } from '../../../../utilities/numberFormatters';
import { BASE_URL } from '../../../../utilities/requestClient';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import FunctionalityButton from '../../ucits/functionality/functionalityComponents/functionalityButton';
import paiReportData from './data/pai_report.json';

interface PrincipleAdverseImpactReportTableProps {
  data: any;
}

export interface PrincipleAdverseImpactReportTableData {
  category: string;
  RTSIndicator: string;
  RTSMetric: string;
  kpi: string;
  IRIndicator: string;
  description: string;
  type: string;
  available: string;
  appliedOn: string;
  RTSIndicatorClassification: string;
  RTSStatus: string;
  impactYearN: number | string;
  impactYearNMinus1: number | string;
  explaination: string;
  actionsTaken: string;
}

// export function buildPrincipleAdverseImpactTableData(data: any): PrincipleAdverseImpactReportTableData[] {
//     if (!data.data.length) return [];
//     if (!('pai_page' in data.data[0])) return [];
//     const paiData = data.data[0].pai_page;
//     const tableData: PrincipleAdverseImpactReportTableData[] = [];
//     paiData.forEach((value: any) => {
//         const parsedNumber = value.name.match(/\d+/);
//         const ruleNumber = parseInt(parsedNumber);

//         tableData.push({
//             name: value.name,
//             qualitativeIndicatorScan: value.qualitative,
//             metricIndicatorScan: value.metric,
//             positions: value.concerning_positions,
//             ruleNumber: ruleNumber,
//             concerningExposure: value.concerning_exposure,
//             concerningExposureValue: value.concerning_exposure_value,
//             topic: value.topic
//         })
//     })
//     const sortedData = tableData.sort((a, b) => a.ruleNumber > b.ruleNumber ? 1 : -1)

//     return sortedData;
// }

const detailColumns: CustomColumn<PrincipleAdverseImpactReportTableData>[] = [
  {
    title: 'Category',
    field: 'category',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'RTS Indicator',
    field: 'RTSIndicator',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'RTS Metric',
    field: 'RTSMetric',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'kpi',
    field: 'kpi',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  // {
  //     title: 'IR Indicator',
  //     field: 'IRIndicator',
  //     cellStyle: { textAlign: 'center' },
  //     headerStyle: { textAlign: 'center' },
  // },
  {
    title: 'description',
    field: 'description',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '15%',
  },
  {
    title: 'type',
    field: 'type',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  // {
  //     title: 'available',
  //     field: 'available',
  //     cellStyle: { textAlign: 'center' },
  //     headerStyle: { textAlign: 'center' },
  // },
  {
    title: 'applied On',
    field: 'appliedOn',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'RTS Indicator Classification',
    field: 'RTSIndicatorClassification',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  // {
  //     title: 'RTS Status',
  //     field: 'RTSStatus',
  //     cellStyle: { textAlign: 'center' },
  //     headerStyle: { textAlign: 'center' },
  // },
  {
    title: 'impact (2021)',
    field: 'impactYearN',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactReportTableData) =>
      rowData.impactYearN === 'N/a' || rowData.impactYearN === ''
        ? rowData.impactYearN
        : formatESGNumber(rowData.impactYearN),
  },
  {
    title: 'impact (2020)',
    field: 'impactYearNMinus1',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactReportTableData) =>
      rowData.impactYearNMinus1 === 'N/a' || rowData.impactYearNMinus1 === ''
        ? rowData.impactYearNMinus1
        : formatESGNumber(rowData.impactYearNMinus1),
  },
  {
    title: 'explaination',
    field: 'explaination',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'actions Taken',
    field: 'actionsTaken',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '15%',
  },
];
const pdfColumns: CustomColumn<PrincipleAdverseImpactReportTableData>[] = [
  {
    title: 'Adverse Sustainability Indicator',
    field: 'category',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Indicator Name',
    field: 'RTSIndicator',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Metric',
    field: 'RTSMetric',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Impact (2021)',
    field: 'impactYearN',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactReportTableData) =>
      rowData.impactYearN === 'N/a' || rowData.impactYearN === ''
        ? rowData.impactYearN
        : formatESGNumber(rowData.impactYearN),
  },
  {
    title: 'Impact (2020)',
    field: 'impactYearNMinus1',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PrincipleAdverseImpactReportTableData) =>
      rowData.impactYearNMinus1 === 'N/a' || rowData.impactYearNMinus1 === ''
        ? rowData.impactYearNMinus1
        : formatESGNumber(rowData.impactYearNMinus1),
  },
  {
    title: 'Eplaination',
    field: 'explaination',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Actions Taken',
    field: 'actionsTaken',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '15%',
  },
];
async function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
    return true;
  } else {
    return false;
  }
}
// const PrincipleAdverseImpactReportTable: FC<PrincipleAdverseImpactTableProps> = (props) => {
const PrincipleAdverseImpactReportTable: FC = () => {
  // const { data } = props;
  // const tableData = buildPrincipleAdverseImpactTableData(data);
  // const timSeriesData = buildOverviewTimeseriesData(data);
  const url = `${BASE_URL}get_pai_report`;
  const { showAsyncSnackbar } = useSnackbar();
  async function handleRequest() {
    await showAsyncSnackbar({
      startMessage: 'Generating PAI Report',
      successMessage: 'Report Done!',
      failureMessage: 'Failed to generate report',
      promiseToResolve: openInNewTab(url),
    });
    // await requestClient.get(url);
  }
  return (
    <GridItem card xs={12} style={{ padding: 8 }}>
      <div style={{ marginLeft: '0.62rem', marginTop: '0.62rem' }}>
        <FunctionalityButton handler={handleRequest} title="Generate Report" />
      </div>
      <CustomTable<PrincipleAdverseImpactReportTableData>
        columns={detailColumns}
        showToolbar
        data={paiReportData}
        title={'Principal Adverse Impact'}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
        id={'pai_report'}
        pdfColumns={pdfColumns}
      />
    </GridItem>
  );
};
export default PrincipleAdverseImpactReportTable;
