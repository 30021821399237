import { FC } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { mainColors } from '../../../../styling/theme';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GenericLineChart from '../../../charts/GenericLineChart';
import GeneralCard from '../../../general/GeneralCard';
import { Typography } from '@mui/material';

export interface HistoricalPerformanceChartProps {
  dataForRender: DataObject;
}

function buildHistroicalPerformanceData(data: any) {
  if (!data) return [];
  if (!data.length) return [];
  if (!('returns_data' in data[0])) return [];
  const returnsData = data[0].returns_data;
  const headers = returnsData[0];
  const numIndices = headers.length - 1;
  const chartData: any[] = [];

  // Loop through each value (without the header).
  returnsData.slice(1).forEach((value: any) => {
    const date = value[0];
    const currentDateData: any = {
      date,
    };
    // Loop through the rest of the indices
    for (let i = 1; i <= numIndices; i++) {
      currentDateData[`index${i}`] = value[i];
    }
    // Add to the overall data
    chartData.push(currentDateData);
  });

  return chartData;
}

function getIndexNames(data: any): string[] {
  if (!data) return [];
  if (!data.length) return [];
  if (!('returns_data' in data[0])) return [];
  const returnsData = data[0].returns_data;
  const headers = returnsData[0];
  return headers.slice(1);
}

const HistoricalPerformanceChart: FC<HistoricalPerformanceChartProps> = (
  props,
) => {
  const { data } = props.dataForRender;
  const chartData = buildHistroicalPerformanceData(data);
  const numIndices = chartData.length
    ? Object.keys(chartData[0]).length - 1
    : 0;
  const indexNames = getIndexNames(data);
  // Use as a circular array in case where we have more lines than colors
  const colors = [
    mainColors.mainBlue_lighter,
    mainColors.Pass,
    mainColors.Fail,
    mainColors.Alert,
    mainColors.fourthPurple,
    mainColors.pineGreen,
  ];
  const lines = Array.from(Array(numIndices + 1).keys())
    .slice(1)
    .map((item: any) => {
      const n = colors.length;
      return {
        dataKey: `index${item}`,
        color: colors[(((item - 1) % n) + n) % n],
      };
    });
  const { width, height } = useWindowSize();
  return (
    <GeneralCard cardStyle={{ marginBottom: '1.00rem' }}>
      <Typography
        variant="h2"
        style={{
          marginLeft: '1.25rem',
          marginTop: '0.62rem',
          fontSize: '1.25rem',
          fontWeight: 400,
        }}
      >
        Historical Performance Data
      </Typography>
      <GenericLineChart
        title="Historical Performance Data"
        data={chartData}
        export
        id="historical_performance_data"
        xAxisDataKey="date"
        yAxisTickFormatter={(value: number) =>
          percentageToTwoDecimalPlacesNoHundred(value)
        }
        tooltipFormatter={(value: number, name: string) => {
          return [
            percentageToTwoDecimalPlacesNoHundred(value),
            indexNames[parseInt(name.replace('index', '')) - 1],
          ];
        }}
        yAxisLabel={{ value: 'Return (%)', angle: -90, position: 'insideLeft' }}
        yAxisWidth={80}
        lines={lines}
        width={'100%'}
        height={height / 2 - 100}
        showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 20,
          right: 50,
        }}
        error={'No Historical Performance Data Available'}
        pdfDontmoveToNewPage
        pdfSpecificYPosition={25}
        pdfSpecificXPosition={5}
      />
    </GeneralCard>
  );
};

export default HistoricalPerformanceChart;
