import { ReactElement } from 'react';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import { Fund } from '../../../../../types/server-data/ClientConfig';
import {
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import InTableTimeSeries from '../../../../charts/InTableTimeSeries';
import NoTimeSeries from '../../../../charts/NoTimeSeries';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import HistoricalTable from '../../../ucits/law/lawComponents/HistoricalTable';
import SecondLevelTable from './second-level-table/second-level-table.component';
import RaptorStatusBox from '../../../../feedback/RaptorStatusBox.component';
import { specifyStatus } from '../../../../../utilities/generalMappings';
import NotesComp from '../../../sharedComponents/notes/Notes';

export interface IProspectusRestrictionsFirstLevelData {
  number: number;
  name: string;
  status: Status;
  value: number;
  limit: number;
  timeSeries: any; // TODO what is the appropriate type here?
}

export interface IProspectusRestrictionsHistoricalData {
  [key: string]: any;
  number: number;
  rule: string;
}
export const csvProspectusRestrictionsFirstLevel = [
  'number',
  'name',
  'status',
  'value',
  'limit',
];

export const firstLevelColumns = (
  fundId: string,
  fundName: string,
  positionDate: string,
): CustomColumn<IProspectusRestrictionsFirstLevelData>[] => [
  { title: '#', field: 'number', width: '0.62rem' },
  { title: 'Name', field: 'name' },
  {
    title: 'Value',
    field: 'value',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: { textAlign: 'center' },
  },

  {
    title: 'Limit',
    field: 'limit',

    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: IProspectusRestrictionsFirstLevelData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
    width: '8%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: `Time Series`,
    field: 'time_series',

    render: (rowData: IProspectusRestrictionsFirstLevelData) => {
      if (rowData.timeSeries) {
        const hist = rowData.timeSeries;
        return (
          <InTableTimeSeries
            id={fundId + rowData.number}
            export
            lines={[
              {
                dataKey: 'limit',
                color: mainColors.Fail_darker,
              },
              {
                dataKey: 'value',
                color: mainColors.mainBlue,
              },
            ]}
            error={null}
            yAxisTickFormatter={(val: any) => toTwoDecimalPlaces(val)}
            title={''}
            yAxisDomain={[
              (dataMin: number) => {
                if (dataMin < 0) {
                  return dataMin * 1.1;
                } else if (dataMin > 0) {
                  return dataMin * 0.1;
                } else {
                  return 0;
                }
              },
              (dataMax: number) => Math.round(dataMax * 1.1 * 100) / 100,
            ]}
            formatter={(val: number) => {
              return percentageToTwoDecimalPlacesNoHundred(val);
            }}
            data={hist
              .filter((item: any) => item[0] !== 'Date')
              .map(formatTimeSeriesForChart)}
          />
        );
      } else {
        return <NoTimeSeries />;
      }
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      padding: '0.62rem 0',
      textAlign: 'center',
      width: '50%',
    },
  },
  {
    title: 'Notes',
    field: 'notes',
    width: '3.12rem',
    render: (rowData: IProspectusRestrictionsFirstLevelData) => (
      <NotesComp
        positionDate={positionDate}
        fundId={fundId}
        fundName={`${fundName}`}
        subject="ucits_prospectus-restrictions"
        topic={`rule_${rowData.number}`}
      />
    ),
    cellStyle: {
      padding: '1.00rem',
      textAlign: 'center',
      paddingRight: '1.50rem',
      paddingLeft: '0.50rem',
    },
    headerStyle: {
      textAlign: 'center',
      padding: 'auto 1.00rem',
      paddingRight: '1.50rem',
      paddingLeft: '0.50rem',
    },
  },
];
function formatTimeSeriesForChart(item: any) {
  const [date, value, limit] = item;
  return { date, value, limit };
}
export function useBuildFirstLevelProspectusRestrictionsData(
  data: any,
): IProspectusRestrictionsFirstLevelData[] {
  if (data.length) {
    const returnData: IProspectusRestrictionsFirstLevelData[] = [];
    data[0].limit_status.forEach((row: any, index: number) => {
      if (index > 0) {
        returnData.push({
          number: index,
          name: row[0],
          value: row[3],
          limit: row[2],
          status: row[1],
          timeSeries: row[5] && row[5].length > 2 ? row[5] : null,
        });
      }
    });

    return returnData;
  } else {
    return [];
  }
}
interface IProspectusRestrictionsFirstLevelTableProps {
  activeFund: string;
  prospectusRestrictionsData: DataObject;
  activeFundName: string;
  fundDetails: Fund;
}

function FirstLevelTable({
  prospectusRestrictionsData,
  activeFund,
  activeFundName,
  fundDetails,
}: IProspectusRestrictionsFirstLevelTableProps): ReactElement {
  const currentOrHistorical = useTitleBarSelect('dataDisplay');

  const historical = currentOrHistorical === 'historical';
  const tableData = useBuildFirstLevelProspectusRestrictionsData(
    prospectusRestrictionsData.data,
  );

  return historical ? (
    <HistoricalTable
      fetchUrl={'aggregate_monthly_prospectus_review_d'}
      activeFund={activeFund}
      reduxKey="prospectus-restrictions"
    />
  ) : (
    <GeneralComponentErrorShield dataObjects={[prospectusRestrictionsData]}>
      <GridItem xs={12} card>
        <CustomTable<IProspectusRestrictionsFirstLevelData>
          selectedPositionDate={
            prospectusRestrictionsData.data[0].selected_position_date
          }
          columns={firstLevelColumns(
            activeFund,
            activeFundName,
            !prospectusRestrictionsData.isFetching
              ? prospectusRestrictionsData.data[0].selected_position_date
              : 'no_date',
          )}
          csvFields={csvProspectusRestrictionsFirstLevel}
          showToolbar={true}
          data={tableData}
          options={{
            exportButton: true,
            search: true,
            paging: false,
            raptorExport: true,
          }}
          toolbarComponents={{
            toolbarTitle: fundDetails?.name,
          }}
          onRowClick={(event: any, rowData, togglePanel: any) => {
            return togglePanel!();
          }}
          detailPanel={[
            {
              render: (rowData) => {
                return (
                  <SecondLevelTable
                    selectedPositionDate={
                      prospectusRestrictionsData.data[0].selected_position_date
                    }
                    rowData={rowData as IProspectusRestrictionsFirstLevelData}
                    prospectusRestrictionsData={prospectusRestrictionsData}
                    fundDetails={fundDetails}
                  />
                );
              },
            },
          ]}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
}

export default FirstLevelTable;
