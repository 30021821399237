import {
  FloatingFocusManager,
  FloatingList,
  autoUpdate,
  flip,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { greys, mainColors } from '../../styling/theme';
import { Theme } from '@mui/material';

type StyleProps = {
  maxWidth?: string;
};
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '1.88rem',
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '18.75rem'),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    overflow: 'ellipsis',
    color: mainColors.mainBlue,
    // color: 'red',
    borderRadius: '0.12rem',
    fontSize: '1.00rem',
    fontWeight: 600,
    letterSpacing: '0.06rem',
    border: `1px solid ${mainColors.mainBlue}`,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.controlButtonBlue_lighter,
    },
    padding: '0.50rem 0.62rem',
    backgroundColor: greys.grey100,
    '&:hover, &:focus': {
      backgroundColor: greys.grey200,
    },
  },
  listbox: {
    display: 'flex',
    minWidth: '18.75rem',
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '18.75rem'),
    flexDirection: 'column',
    color: mainColors.mainBlue,
    border: `2px solid ${mainColors.mainBlue}`,
    maxHeight: '12.50rem',
    overflowY: 'scroll',
    zIndex: 100,
    '&::-webkit-scrollbar': {
      width: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue_lighter,
      borderRadius: '0.62rem',
    },
  },
  item: {
    fontSize: '0.94rem',
    backgroundColor: 'white',
    padding: '0.53rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
}));

interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (choice: any) => void;
}

const SelectContext = React.createContext<SelectContextValue>(
  {} as SelectContextValue,
);

interface SelectProps<T extends string | number | object>
  extends RaptorSelectProps<T> {
  children: any;
}

const Select = <T extends string | number | object>({
  options,
  defaultOption,
  onSelect,
  getDisplayValue,
  children,
  maxWidth,
}: SelectProps<T>) => {
  const classes = useStyles({ maxWidth });

  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(
    defaultOption ? options.indexOf(defaultOption) : null,
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(
    defaultOption ? options.indexOf(defaultOption) : null,
  );
  const [selectedLabel, setSelectedLabel] = React.useState<T | null>(
    defaultOption ?? null,
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const elementsRef = React.useRef<Array<HTMLElement | null>>([]);
  // Transform `T` into `string` for `labelsRef`
  const labelsRef = React.useRef<Array<string | null>>([]);

  const handleSelect = React.useCallback((choice: any) => {
    const index = options.indexOf(choice);
    setSelectedIndex(index);
    setIsOpen(false);
    if (index !== null) {
      setSelectedLabel(choice);
      onSelect(choice);
    }
  }, []);

  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index);
    } else {
      handleSelect(index);
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNav, typeahead, click, dismiss, role],
  );

  const selectContext = React.useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  return (
    <>
      <div
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
        className={classes.container}
      >
        {getDisplayValue(selectedLabel) ?? 'Select...'}
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={classes.listbox}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
};

interface OptionProps {
  option: string | number | object;
  label: string;
}

const Option: React.FC<OptionProps> = ({ label, option }) => {
  const classes = useStyles({});

  const { activeIndex, selectedIndex, getItemProps, handleSelect } =
    React.useContext(SelectContext);

  const { ref, index } = useListItem();

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <div
      ref={ref}
      className={classes.item}
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      style={{
        background: isActive ? mainColors.hoverOverVeryFaintBlue : '',
        fontWeight: isSelected ? 'bold' : '',
      }}
      {...getItemProps({
        onClick: () => handleSelect(option),
      })}
    >
      {label}
    </div>
  );
};

interface RaptorSelectProps<T extends string | number | object> {
  options: T[];
  defaultOption?: T;
  onSelect: (choice: T) => void;
  getDisplayValue: (item: T | null) => string;
  maxWidth?: string;
}

const RaptorSelectObject = <T extends string | number | object>(
  props: RaptorSelectProps<T>,
) => {
  return (
    <Select {...props}>
      {props.options.map((option, index) => (
        <Option
          key={index}
          option={option}
          label={props.getDisplayValue(option)}
        />
      ))}
    </Select>
  );
};

export default RaptorSelectObject;
