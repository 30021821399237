import * as React from 'react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../../../../utilities/requestClient';

import { useSelector } from 'react-redux';
import { createSpecificFundNameAndIdSelectorByFundType } from '../../../../../redux/pages/selectors';
import FilterNumber from './FilterNumber.component';
import FilterString from './FilterString.component';
import { cloneDeep } from 'lodash';
import { Clear } from '@mui/icons-material';
import { fuzzyFilter } from '../../../../tables/ultraTable/utils/ultratable.utils';
import { customSortingFn } from '../../../../tables/ultraTable/utils/ultratable.utils';

type Position = {
  position_id: string;
  gm_name: string;
  gm_name_long: string;
  fund_name: string;
  fund_id: string;
  expiry_date: string;
  mr_position: number;
  asset_subclass_name: string;
  instrument_id: number;
  instrument_type_id: number;
  adepa_derivative_hedge: stateType;
};

const buildData = (originalData: any) => {
  const builtData: Position[] = [];
  Object.entries(originalData).forEach((fund: any) => {
    fund[1].forEach((position: any) => {
      const fundName = useSelector(
        createSpecificFundNameAndIdSelectorByFundType('', fund[0], ''),
      )?.name;
      builtData.push({
        position_id: position.position_id,
        gm_name: position.gm_name,
        gm_name_long: position.gm_name_long,
        fund_name: fundName ? fundName : '',
        fund_id: fund[0],
        expiry_date: position.expiry_date ? position.expiry_date : '',
        mr_position: position.mr_position,
        asset_subclass_name: position.asset_subclass_name
          ? position.asset_subclass_name
          : '',
        instrument_id: position.instrument_id,
        instrument_type_id: position.instrument_type_id,
        adepa_derivative_hedge: position.adepa_derivative_hedge,
      });
    });
  });
  return builtData;
};

const columns: ColumnDef<Position>[] = [
  {
    id: 'gm_name',
    accessorKey: 'gm_name',
    header: () => 'GM Name',
  },
  {
    id: 'asset_subclass_name',
    accessorKey: 'asset_subclass_name',
    header: () => 'Asset Subclass',
  },
  {
    id: 'expiry_date',
    accessorKey: 'expiry_date',
    header: () => 'Expiry Date',
  },
  {
    id: 'mr_position',
    accessorKey: 'mr_position',
    header: () => 'Position Size',
  },
  {
    id: 'fund_name',
    accessorKey: 'fund_name',
    header: () => 'Fund Name',
  },
  {
    id: 'fund_id',
    accessorKey: 'fund_id',
    header: () => 'Fund ID',
  },
  {
    id: 'adepa_derivative_hedge',
    accessorKey: 'adepa_derivative_hedge',
    header: () => 'Is Hedge?',
  },
];

// styling for the table
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.88rem 1.88rem 0 1.88rem',
  },
  header: {
    ...theme.typography.h1,
    marginBottom: '1.88rem',
  },
  toolbarOptions: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  tableContainer: {
    // maxHeight: '43.75rem',
    overflow: 'auto',
    marginBottom: '1.25rem',
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
    paddingBottom: '0.62rem',
    borderCollapse: 'collapse',
    minWidth: '100%',
  },
  tableHead: {
    position: 'sticky',
  },
  tableBody: {
    paddingBottom: '1.25rem',
  },
  row: {
    display: 'flex',
    borderTop: `1px solid ${mainColors.lightGrey}`,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  hedgedRow: {
    backgroundColor: mainColors.veryFaintBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
  aggregateRow: {
    backgroundColor: mainColors.lightGrey,
  },
  headerRow: {
    display: 'flex',
    borderBottom: '2px solid grey',
  },
  cell: {
    ...theme.typography.h3,
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '0.81rem',
    width: '100%',
    minWidth: '3.12rem',
    minHeight: '3.12rem',
    padding: '0.31rem 1.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
  },
  columnHead: {
    width: '100%',
    minWidth: '3.12rem',
    minHeight: '6.88rem',
    height: 'clamp(6.88rem, calc(11.25rem - 4vw), 8.75rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.94rem',
    padding: '0.62rem',
    marginBottom: '0.62rem',
  },
  sortIcon: {
    cursor: 'pointer',
  },
  columnTitle: {
    fontWeight: 600,
    border: 'none',
    background: 'none',
    marginTop: '0.62rem',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '1.25rem',
    color: mainColors.mainBlue,
  },
  resetButton: {
    transition: 'width .2s',
    borderRadius: '0.50rem',
    padding: '0.5 1.88rem',
    margin: '0 0.62rem',
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  toggleAllRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.62rem',
    flexWrap: 'wrap',
  },
  toggleAllButton: {
    padding: '0.31rem 0.62rem',
    width: '4.38rem',
    backgroundColor: mainColors.mainBlue_lighter,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '0.31rem',
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue_lighter,
    },
  },
  dialogTitle: {
    fontSize: '1.25rem',
    fontWeight: 800,
    padding: '1.25rem 1.25rem 0 1.25rem',
  },
  dialogContent: {
    padding: '0 1.25rem',
  },
  dialogText: {
    fontSize: '1.00rem',
    fontWeight: 500,
  },
  dialogFunds: {
    maxHeight: '18.75rem',
    overflowY: 'scroll',
    padding: '0 1.25rem',
    '&::-webkit-scrollbar': {
      width: '0.38rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.12rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '0.62rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  fundBox: {
    display: 'flex',
    alignItems: 'center',
  },
  fundBox_id: {
    padding: '0.31rem 0.62rem',
    marginRight: '0.62rem',
    // color: 'white',
    backgroundColor: mainColors.lightGrey,
    borderRadius: '0.31rem',
  },
  fundBox_name: {
    fontWeight: 500,
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.62rem',
  },
  snackbar: {
    marginLeft: '3.12rem',
  },
  snackbarStack: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.62rem',
  },
  snackbarItem: {
    padding: '1.25rem',
    borderRadius: '0.12rem',
    color: 'white',
    fontSize: '1.12rem',
    fontWeight: 500,
    width: '21.88rem',
    cursor: 'pointer',
  },
  snackbarSuccess: {
    backgroundColor: mainColors.Pass,
  },
  snackbarFail: {
    backgroundColor: mainColors.Fail,
  },
  snackbarToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  snackbarBody: {
    fontSize: '0.81rem',
  },
}));

type TableProps<TData> = {
  originalData: any;
};

// types
type stateType = 'yes' | 'no' | 'loading' | 'error';
type toggleStateType = { [key: string]: stateType };
type sortingTypes = 'Ascending' | 'Descending' | '';
type SortingStateType = { [key: string]: sortingTypes };
type FilterStateType = { [key: string]: string[] };
type MinMaxStateType = { [key: string]: (number | string)[] };

const PotentialHedgePositionsTable = <T extends object>({
  originalData,
}: TableProps<Position>): ReactElement => {
  const classes = useStyles();

  const [builtData] = useState<Position[]>(buildData(originalData));
  const [data, setData] = useState<Position[]>(builtData);
  const [toggleStates, setToggleStates] = useState<toggleStateType>(() => {
    const newObj: toggleStateType = {};
    builtData.map((position) => {
      if (position.adepa_derivative_hedge) {
        newObj[position.position_id] =
          position.adepa_derivative_hedge as stateType;
      }
    });
    return newObj;
  });

  const [unsortedData, setUnsortedData] = useState<Position[]>(data);
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('');

  // setup filter states
  const [filterState, setFilterState] = useState<FilterStateType>({});
  const [minMax, setMinMax] = useState<MinMaxStateType>({});

  // setup sorting state
  const originalSortingState: SortingStateType = {};
  columns.forEach((col) => (originalSortingState[col.id as string] = ''));
  const [sortingState, setSortingState] =
    useState<SortingStateType>(originalSortingState);

  const [stateCounter, setStateCounter] = useState<number>(0);
  const [que, setQue] = useState<{ id: string; value: stateType }[]>([]);
  const [snackBarQue, setSnackBarQue] = useState<
    { id: string; status: 'success' | 'fail'; position: any }[]
  >([]);

  const handleDerivateToggle = (
    id: string,
    fundId: string,
    checked: boolean,
  ) => {
    axios
      .get(
        BASE_URL +
          'update_edit_specific_position_data_adepa_hedge_key/' +
          fundId +
          '/' +
          id +
          (checked ? '/yes' : '/no'),
        { withCredentials: true },
      )
      .then((response) => {
        if (response.status === 200) {
          const oldQue = que;
          oldQue.push({ id: id, value: checked ? 'yes' : 'no' });
          setQue(oldQue);
          setStateCounter((prevState) => prevState + 1);
        } else {
          const oldQue = que;
          oldQue.push({ id: id, value: 'error' });
          setQue(oldQue);
          setStateCounter((prevState) => prevState + 1);
        }
        const relevantPosition = data.find(
          (position) => position.position_id === id,
        );
        const queId = Date.now().toString();
        const oldSnackBarQue = snackBarQue;
        oldSnackBarQue.push({
          id: queId,
          status: response.status === 200 ? 'success' : 'fail',
          position: relevantPosition,
        });
        setSnackBarQue(oldSnackBarQue);
        setTimeout(() => {
          handleRemoveItemFromSnackbarQue(queId);
        }, 30000);
      });
  };

  useEffect(() => {
    const oldState = cloneDeep(toggleStates);
    if (que.length > 0) {
      const next = que.shift();
      oldState[next?.id ?? ''] = next?.value ?? 'loading';
      setToggleStates(oldState);
    }
  }, [stateCounter]);

  useEffect(() => {
    if (snackBarQue.length > 5) {
      setSnackBarQue((q) => [...q.slice(1)]);
    }
  }, [JSON.stringify(snackBarQue)]);

  const handleRemoveItemFromSnackbarQue = (id: string) => {
    setSnackBarQue((q) => [...q.filter((item) => item.id !== id)]);
  };

  const table = useReactTable<Position>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    sortingFns: {
      customSortingFn: customSortingFn,
    },
  });

  // function to get type of data in column
  const getTypeOfColumn = (columnId: string) => {
    if (builtData.length) {
      return typeof builtData[0][columnId as keyof Position];
    } else {
      return null;
    }
  };

  // function to get all unique values from a column
  const getColumnUniqueVals = (columnId: string) => {
    const uniqueVals: string[] = [];
    Array.from(builtData).forEach((row: any) => {
      if (!uniqueVals.includes(row[columnId])) {
        uniqueVals.push(row[columnId]);
      }
    });
    if (typeof uniqueVals[0] === 'number') {
      return [];
    } else {
      return uniqueVals.sort();
    }
  };

  // handler for when a colum is sorted
  // sorting a column first cancels any other sorts
  const handleSortChanged = (columnId: string) => {
    const order: sortingTypes[] = ['', 'Ascending', 'Descending'];
    const sortType = sortingState[columnId];
    const index = order.indexOf(sortType);
    if (columnId === currentSortedColumn) {
      if (index === 2) {
        const newState = sortingState;
        newState[columnId] = order[0];
        setSortingState(newState);
      } else {
        const newState = sortingState;
        newState[columnId] = order[index + 1];
        setSortingState(newState);
      }
    } else {
      setCurrentSortedColumn(columnId);
      const newState = sortingState;
      Object.keys(newState).forEach((key) => (newState[key] = ''));
      newState[columnId] = 'Ascending';
      setSortingState(newState);
    }
    if (sortingState[columnId] === 'Ascending') {
      setData(
        Array.from(unsortedData).sort((first: any, second: any) => {
          let a = first[columnId];
          let b = second[columnId];
          if (getTypeOfColumn(columnId) === 'string') {
            a = a.toLowerCase();
            b = b.toLowerCase();
          }
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }),
      );
    } else if (sortingState[columnId] === 'Descending') {
      setData(
        Array.from(unsortedData).sort((first: any, second: any) => {
          let a = first[columnId];
          let b = second[columnId];
          if (getTypeOfColumn(columnId) === 'string') {
            a = a.toLowerCase();
            b = b.toLowerCase();
          }
          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        }),
      );
    } else if (columnId === currentSortedColumn) {
      setData(unsortedData);
    }
  };

  // update filters based on changes made in the popovers
  const handleFiltersChanged = (colId: string, newFilters: string[]) => {
    if (newFilters.length) {
      setFilterState((prev) => ({ ...prev, [colId]: newFilters }));
    } else {
      setFilterState((prev) => ({
        ...prev,
        [colId]: getColumnUniqueVals(colId),
      }));
    }
  };

  // update filters based on min max changes made in the popovers
  const handleMinMaxChanged = (
    colId: string,
    min: number | '' | '-',
    max: number | '' | '-',
  ) => {
    const temp = [min, max];
    setMinMax((prev) => ({ ...prev, [colId]: temp }));
  };

  // custom filtering based on choices picked in the popovers
  useEffect(() => {
    const filteredData: Position[] = [];
    Array.from(builtData).forEach((row: any) => {
      let rowOK = true;
      Object.keys(filterState).forEach((column) => {
        if (!filterState[column].includes(row[column])) {
          rowOK = false;
        }
      });
      Object.keys(minMax).forEach((column) => {
        if (minMax[column][0] !== '' && row[column] < minMax[column][0]) {
          rowOK = false;
        } else if (
          minMax[column][1] !== '' &&
          row[column] > minMax[column][1]
        ) {
          rowOK = false;
        }
      });
      if (rowOK) filteredData.push(row);
    });
    setUnsortedData(filteredData);
    setData(filteredData);
  }, [filterState, minMax]);

  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const handleTableReset = () => {
    setData(builtData);
    setFilterState({});
    setMinMax({});
    setResetFilters(!resetFilters);
    setSortingState(originalSortingState);
  };

  const [fundsInFilter, setFundsInFilter] = useState<{
    [index: string]: string;
  }>({});
  const [positionsInFilter, setPositionsInFilter] = useState<Position[]>([]);
  const [toggleAllState, setToggleAllState] = useState<'yes' | 'no' | ''>('');

  const [toggleAllOpen, setToggleAllOpen] = useState<boolean>(false);

  const handleToggleAll = (value: 'yes' | 'no') => {
    setToggleAllOpen(true);
    setToggleAllState(value);
  };

  const closeToggleAll = () => {
    setToggleAllOpen(false);
  };

  useEffect(() => {
    const newFunds: { [index: string]: string } = {};
    const newPositions: Position[] = [];
    data.forEach((row) => {
      newPositions.push(row);
      if (row.fund_id in newFunds === false) {
        newFunds[row.fund_id] = row.fund_name;
      }
    });
    setFundsInFilter(newFunds);
    setPositionsInFilter(newPositions);
  }, [data]);

  const confirmToggleAll = () => {
    const oldToggleStates = cloneDeep(toggleStates);
    positionsInFilter.forEach((position) => {
      if (
        toggleAllState === 'yes' &&
        position.adepa_derivative_hedge === 'no'
      ) {
        oldToggleStates[position.position_id] = 'loading';
        handleDerivateToggle(position.position_id, position.fund_id, true);
      } else if (
        toggleAllState === 'no' &&
        position.adepa_derivative_hedge === 'yes'
      ) {
        oldToggleStates[position.position_id] = 'loading';
        handleDerivateToggle(position.position_id, position.fund_id, false);
      }
    });
    setToggleStates(oldToggleStates);
    setToggleAllState('');
    setToggleAllOpen(false);
  };

  useEffect(() => {
    const newData: Position[] = [];
    data.forEach((position) => {
      const newPosition: Position = position;
      newPosition['adepa_derivative_hedge'] =
        toggleStates[position.position_id];
      newData.push(newPosition);
    });
    setData(newData);
  }, [toggleStates]);

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.header}>Potential Hedge Positions</h2>
        <div className={classes.toolbarOptions}>
          <Button
            aria-describedby={'reset'}
            variant="text"
            disableElevation
            onClick={handleTableReset}
            className={classes.resetButton}
          >
            Reset Filters
          </Button>
          <Button
            aria-describedby={'refresh'}
            variant="text"
            disableElevation
            onClick={() => window.location.reload()}
            className={classes.resetButton}
          >
            Refresh Page
          </Button>
        </div>
      </div>
      <GridItem xs={12} className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.headerRow}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={classes.columnHead}>
                    <div className={classes.columnTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </div>
                    <div>
                      {getTypeOfColumn(header.id) === 'number' ? (
                        <FilterNumber
                          items={getColumnUniqueVals(header.column.id)}
                          columnID={header.column.id}
                          minMaxState={minMax}
                          onMinMaxChange={handleMinMaxChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                          resetFilters={resetFilters}
                        />
                      ) : (
                        <FilterString
                          items={getColumnUniqueVals(header.column.id)}
                          columnID={header.column.id}
                          filterState={filterState}
                          onFilterChange={handleFiltersChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                          resetFilters={resetFilters}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={classes.tableBody}>
            <tr key={'toggle-all'} className={classes.row}>
              {table
                .getRowModel()
                .rows[0]?.getVisibleCells()
                .map((cell) => {
                  if (cell.id.includes('adepa_derivative_hedge')) {
                    return (
                      <td className={classes.cell} key={cell.id}>
                        <div>
                          <div className={classes.toggleAllRow}>
                            <button
                              className={classes.toggleAllButton}
                              onClick={() => handleToggleAll('no')}
                            >
                              All No
                            </button>
                            <button
                              className={classes.toggleAllButton}
                              onClick={() => handleToggleAll('yes')}
                            >
                              All Yes
                            </button>
                          </div>
                          <Dialog
                            open={toggleAllOpen}
                            onClose={closeToggleAll}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <h2 className={classes.dialogTitle}>
                              Are you sure?
                            </h2>
                            <div className={classes.dialogContent}>
                              <p className={classes.dialogText}>
                                You are about to set{' '}
                                <b>{positionsInFilter.length}</b> positions to{' '}
                                <b>
                                  {toggleAllState === 'yes'
                                    ? 'be hedges'
                                    : 'not be hedges'}
                                </b>{' '}
                                in the following funds:
                              </p>
                            </div>
                            <div>
                              <div
                                className={classes.dialogFunds}
                                key={'funds'}
                              >
                                {Object.keys(fundsInFilter).map((fundId) => (
                                  <div
                                    className={clsx(
                                      classes.dialogText,
                                      classes.fundBox,
                                    )}
                                    key={fundId}
                                  >
                                    <p className={classes.fundBox_id}>
                                      ({fundId}) - {fundsInFilter[fundId]}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <DialogActions>
                              <Button onClick={closeToggleAll} autoFocus>
                                Cancel
                              </Button>
                              <Button onClick={confirmToggleAll}>
                                Toggle All
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </td>
                    );
                  } else {
                    return <td className={classes.cell} key={cell.id}></td>;
                  }
                })}
            </tr>
          </tbody>
          <tbody className={classes.tableBody}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={
                  row
                    .getAllCells()
                    .filter((cell) =>
                      cell.id.includes('adepa_derivative_hedge'),
                    )[0]
                    .getValue() === 'yes'
                    ? clsx(classes.row, classes.hedgedRow)
                    : classes.row
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return cell.id.includes('adepa_derivative_hedge') ? (
                    <td
                      key={cell.id}
                      className={clsx(classes.cell, classes.toggleColumn)}
                    >
                      {cell.getValue() === 'yes' || cell.getValue() === 'no' ? (
                        <div className={classes.switch}>
                          <Typography>No</Typography>
                          <Switch
                            checked={
                              toggleStates[row.original.position_id] === 'yes'
                            }
                            onChange={() => {
                              setToggleStates({
                                ...toggleStates,
                                [row.original.position_id]: 'loading',
                              });
                              handleDerivateToggle(
                                row.original.position_id,
                                row.original.fund_id,
                                toggleStates[row.original.position_id] === 'no',
                              );
                            }}
                          />
                          <Typography>Yes</Typography>
                        </div>
                      ) : cell.getValue() === 'loading' ? (
                        <div className={classes.progressContainer}>
                          <CircularProgress size={20} />
                          <div>Updating...</div>
                        </div>
                      ) : (
                        <div>Error. Please refresh page.</div>
                      )}
                    </td>
                  ) : (
                    <td key={cell.id} className={classes.cell}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </GridItem>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={true}
        key={'bottom' + 'right'}
        className={classes.snackbar}
      >
        <Stack
          spacing={2}
          sx={{ maxWidth: 400 }}
          className={classes.snackbarStack}
        >
          {snackBarQue.map((snackbarItem) => (
            <div
              className={clsx(
                classes.snackbarItem,
                snackbarItem.status === 'success'
                  ? classes.snackbarSuccess
                  : classes.snackbarFail,
              )}
              onClick={() => handleRemoveItemFromSnackbarQue(snackbarItem.id)}
            >
              <div className={classes.snackbarToolbar}>
                <div>{snackbarItem.position.gm_name}</div>
                <Clear />
              </div>
              <div className={classes.snackbarBody}>
                {snackbarItem.status === 'success'
                  ? 'Successfully modified position'
                  : 'An error occured while trying to modify this position'}
              </div>
            </div>
          ))}
        </Stack>
      </Snackbar>
    </GridItem>
  );
};

export default PotentialHedgePositionsTable;
