import { Table } from '@tanstack/react-table';
import { normaliseFalsyValues } from '../../../../utilities/helpers/forms/formUtils';
import { checkFormatValue } from './ultratable.utils';

export function isCellValueEqual(initialValue: any, targetValue: any): boolean {
  const normalisedInitialValue = normaliseFalsyValues(
    checkFormatValue(initialValue),
  );
  const normalisedTargetValue = normaliseFalsyValues(targetValue);

  // Type check this as all e.target.value are strings
  return String(normalisedInitialValue) === String(normalisedTargetValue);
}

// Updates the TanStack table state and sets the updated state of the cell if the value has changed

// BUG: -> when validation is added to cell - this function not triggered so UI does not update - setUpdated
export const conditionallyUpdateCellMeta = (
  e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  initialCellValue: any,
  rowIndex: number,
  columnId: string,
  table: Table<any>,
  setIsUpdated: (isUpdated: boolean) => void,
) => {
  const targetValue = e.target.value;

  // Check if the value has changed
  const valuesEqual = isCellValueEqual(initialCellValue, targetValue);

  const resetOldVal = valuesEqual;

  // TODO: This logic is confusing and should be reworked as a later stage.
  // Conditionally update the table data: ->
  // - If `resetOld` (values are equal), update the cell with the new value directly.
  // - If values differ, track both the old and updated values for change detection.
  //

  table.options.meta?.updateData(rowIndex, columnId, targetValue, resetOldVal);

  setIsUpdated(!valuesEqual);
};

export const conditionallyUpdateCellMetaWithValue = (
  targetValue: any,
  initialCellValue: any,
  rowIndex: number,
  columnId: string,
  table: Table<any>,
  setIsUpdated: (isUpdated: boolean) => void,
) => {
  // Check if the value has changed
  const valuesEqual = isCellValueEqual(initialCellValue, targetValue);

  const resetOldVal = valuesEqual;

  // TODO: This logic is confusing and should be reworked as a later stage.
  // Conditionally update the table data: ->
  // - If `resetOld` (values are equal), update the cell with the new value directly.
  // - If values differ, track both the old and updated values for change detection.
  //

  table.options.meta?.updateData(rowIndex, columnId, targetValue, resetOldVal);

  setIsUpdated(!valuesEqual);
};

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): T {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  } as T;
}
