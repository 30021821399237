import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  roundToNDecimalPlacesReturnNumber,
} from '../../../../../utilities/numberFormatters';
import clsx from 'clsx';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import ExportButton from '../../../../feedback/ExportButton';
import { countryCodeToName } from '../../../../../utilities/generalMappings';

interface MancoCountryExposureBarChartProps {
  data: any;
}

interface MancoCountryExposureBarChartData {
  name: string;
  netExposure: number;
  grossExposure: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.62rem 1.56rem 0.62rem 0.94rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.38rem',
    fontWeight: 400,
    margin: '0.62rem 0.94rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '0.94rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '0.62rem 0.94rem',
    borderRadius: '0.31rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '12.50rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.00rem',
    fontWeight: 600,
  },
  tooltipValueBelowZero: {
    color: mainColors.Fail,
  },
  toggleButton: {
    all: 'unset',
    transition: 'width .2s',
    borderRadius: '0.50rem',
    padding: '0.12rem 1.25rem',
    fontSize: 'clamp(0.62rem, 0.9vw, 0.94rem)',
    backgroundColor: greys.grey400,
    height: '1.88rem',
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    filter: `drop-shadow(0.06rem 0.06rem 0.06rem ${greys.grey400})`,
  },
  toggleButtonBlue: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  toggleButtonGreen: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.Pass, 0.5),
    },
  },
  activeButtonBlue: {
    backgroundColor: mainColors.mainBlue,
  },
  activeButtonGreen: {
    backgroundColor: mainColors.Pass,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>{countryCodeToName(label)}</div>
          <hr />
          <div className={classes.tooltipValue}>
            {percentageToNdecialPlaces(payload[0].value / 100, 2)}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const CustomBar = (props: any) => {
  let fill = props.fill;

  if (props.tooltipPayload[0].dataKey === 'grossExposure') {
    fill = mainColors.mainBlue;
  } else {
    fill = mainColors.Pass;
  }

  return <Rectangle {...props} fill={fill} />;
};

function buildChartData(data: any) {
  if (!data.data.length) return [];
  if (!('country_code_exposure' in data.data[0])) return [];
  const countryCodeExposure = data.data[0].country_code_exposure;
  const nav = data.data[0].manco_nav;
  const chartData: MancoCountryExposureBarChartData[] = [];
  for (const key of Object.keys(countryCodeExposure)) {
    chartData.push({
      name: key,
      netExposure: countryCodeExposure[key][0],
      grossExposure: countryCodeExposure[key][1],
    });
  }
  return chartData;
}

const MancoCountryExposureBarChart: React.FC<
  MancoCountryExposureBarChartProps
> = (props) => {
  const classes = useStyles();

  const [netGrossExposureOption, setNetGrossExposureOption] =
    useState<string>('grossExposure');

  const builtChartData = buildChartData(props.data);

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>
          {`${
            netGrossExposureOption === 'grossExposure' ? 'Gross' : 'Net'
          } Country Exposure`}
        </h2>
        <div className={classes.buttonContainer}>
          <button
            className={
              netGrossExposureOption === 'grossExposure'
                ? clsx(
                    classes.toggleButton,
                    classes.toggleButtonBlue,
                    classes.activeButtonBlue,
                  )
                : clsx(classes.toggleButton, classes.toggleButtonBlue)
            }
            onClick={() => {
              setNetGrossExposureOption('grossExposure');
            }}
          >
            Gross Exposure
          </button>
          <button
            onClick={() => {
              setNetGrossExposureOption('netExposure');
            }}
            className={
              netGrossExposureOption === 'netExposure'
                ? clsx(
                    classes.toggleButton,
                    classes.toggleButtonGreen,
                    classes.activeButtonGreen,
                  )
                : clsx(classes.toggleButton, classes.toggleButtonGreen)
            }
          >
            Net Exposure
          </button>
          <ExportButton
            fields={Object.keys(builtChartData[0])}
            exportData={builtChartData}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={400}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '125%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Country'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '0.81rem',
            }}
            tickLine={true}
            domain={[
              (dataMin: number) =>
                roundToNDecimalPlacesReturnNumber(dataMin - 2, 0),
              (dataMax: number) =>
                roundToNDecimalPlacesReturnNumber(dataMax + 2, 0),
            ]}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Exposure %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey={netGrossExposureOption} shape={CustomBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default MancoCountryExposureBarChart;
