import makeStyles from '@mui/styles/makeStyles';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../../styling/theme';
import { FC, useState } from 'react';

import CustomTable from '../../../../../tables/CustomTable';
import UtilityButtons from '../../../components/buttons/UtilityButtons.component';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { KidsData } from '../KidsEditor';
import KidsEditorGridItem from '../../layout/KidsEditorGridItem.component';
import { EditorTileProps } from './KidsEditorColumn.component';
import { useRaidrKids } from '../../context/RaidrKidsContext';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    marginLeft: '0.62rem',
    fontSize: '0.94rem',
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '6.25rem',
  },
  outerContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    padding: '0.62rem',
  },
  editorContainer: {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    width: '100%',
    marginRight: '0.62rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
  button: {
    height: '1.88rem',
    marginRight: '0.62rem',
    width: '9.38rem',
  },
}));

interface PastPerformanceChartData {
  year: EditorState;
  fund: EditorState;
  benchmark: EditorState;
  index: number;
}

function buildPastPerformanceChartData(
  content: any[],
): PastPerformanceChartData[] {
  const tableData: PastPerformanceChartData[] = [];

  for (let i = 0; i < content.length; i++) {
    // Create an editor state object for each column
    const yearBlocks = convertFromHTML(content[i][0]);
    const yearContentState = ContentState.createFromBlockArray(
      yearBlocks.contentBlocks,
    );
    const yearInitialState = EditorState.createWithContent(yearContentState);

    const fundBlocks = convertFromHTML(content[i][1].toString());
    const fundContentState = ContentState.createFromBlockArray(
      fundBlocks.contentBlocks,
    );
    const fundInitialState = EditorState.createWithContent(fundContentState);

    const benchmarkBlocks = convertFromHTML(content[i][2].toString());
    const benchmarkContentState = ContentState.createFromBlockArray(
      benchmarkBlocks.contentBlocks,
    );
    const benchmarkInitialState = EditorState.createWithContent(
      benchmarkContentState,
    );
    tableData.push({
      year: yearInitialState,
      fund: fundInitialState,
      benchmark: benchmarkInitialState,
      index: i,
    });
  }
  return tableData;
}

function buildColumns(
  handleChange: (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'year' | 'benchmark' | 'fund',
  ) => void,
): CustomColumn<PastPerformanceChartData>[] {
  const columns: CustomColumn<PastPerformanceChartData>[] = [
    {
      title: 'Year',
      field: 'year',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.31rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.31rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: PastPerformanceChartData) => (
        <Editor
          editorState={rowData.year}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index, 'year');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Fund',
      field: 'fund',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.31rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.31rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: PastPerformanceChartData) => (
        <Editor
          editorState={rowData.fund}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index, 'fund');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Benchmark',
      field: 'benchmark',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.31rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        padding: '0.31rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: PastPerformanceChartData) => (
        <Editor
          editorState={rowData.benchmark}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index, 'benchmark');
          }}
          stripPastedStyles={true}
        />
      ),
    },
  ];

  return columns;
}

const PastPerformanceChartEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  moveField,
  deleteField,
  setPriipsKidsContent,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the current element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  const [tableData, setTableData] = useState<PastPerformanceChartData[]>(
    buildPastPerformanceChartData(content),
  );
  // Create an object for storing the state of each cell in the table.
  const [editorState, setEditorState] =
    useState<PastPerformanceChartData[]>(tableData);

  const classes = useStyles();

  function addRemoveRow(action: 'add' | 'remove') {
    if (action === 'add') {
      const newTableData = [...tableData];
      newTableData.push({
        year: EditorState.createEmpty(),
        benchmark: EditorState.createEmpty(),
        fund: EditorState.createEmpty(),
        index: newTableData.length,
      });
      setTableData(newTableData);
      setEditorState(newTableData);

      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.push(['', 0, 0]);
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    } else {
      const newTableData = [...tableData];
      newTableData.pop();
      setTableData(newTableData);
      setEditorState(newTableData);
      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.pop();
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    }
  }

  const handleTextChange = (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'year' | 'benchmark' | 'fund',
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = editorState;
    // Now update the cell that was changed.
    currentEditorState[rowIndex][column] = updatedEditorState;
    // Update the overall table state.
    setEditorState(currentEditorState);
    // Update the table data
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex][column] = updatedEditorState;
    setTableData(updatedTableData);

    // Create an array of the raw data
    const updatedArray: any[] = [];
    tableData.forEach((row) => {
      const rowData = [];
      rowData.push(row.year.getCurrentContent().getPlainText());
      rowData.push(row.fund.getCurrentContent().getPlainText());
      rowData.push(row.benchmark.getCurrentContent().getPlainText());
      updatedArray.push(rowData);
    });
    // Update the overall data
    setPriipsKidsContent((allContent: KidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = JSON.stringify(updatedArray);
      return newContent;
    });
  };

  const tableColumns = buildColumns(handleTextChange);

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.outerContainer}>
        <div className={classes.editorContainer}>
          <div className={classes.textEditorBox}>
            <CustomTable<PastPerformanceChartData>
              columns={tableColumns}
              id={'past_performance_table'}
              showToolbar={false}
              data={tableData}
              options={{
                paging: false,
                search: false,
                exportButton: false,
                sorting: false,
                draggable: false,
                toolbar: false,
              }}
            />
          </div>
          <UtilityButtons
            moveField={moveField}
            index={index}
            deleteField={deleteField}
            fieldId={fieldId}
            disableDelete
            disableDeleteMessage="You cannot delete the past performance table"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '0.62rem',
          }}
        >
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('add')}
            endIcon={<AddCircleIcon style={{ color: 'white' }} />}
            disabled={tableData.length == 10}
          >
            <div style={{ color: 'white' }}>Add Row</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('remove')}
            endIcon={<RemoveCircleIcon style={{ color: 'white' }} />}
            disabled={tableData.length == 1}
          >
            <div style={{ color: 'white' }}>Remove Row</div>
          </Button>
        </div>
      </div>
    </KidsEditorGridItem>
  );
};

export default PastPerformanceChartEditorTile;
