import { FunctionComponent, InputHTMLAttributes } from 'react';
import { cn } from '../../../utilities/cn';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

interface SearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  searchHandler: (input: string) => void;
  debounceTime?: number;
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
  debounceTime,
  searchHandler,
  ...props
}) => {
  const debounceSearch = debounce((value: string) => {
    searchHandler(value);
  }, debounceTime);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceTime) {
      debounceSearch(event.target.value);
    } else {
      searchHandler(event.target.value);
    }
  };

  return (
    <div className="relative">
      <input
        {...props}
        onChange={handleChange}
        className={cn(
          'w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pl-10 leading-tight text-gray-800 transition-colors hover:border-gray-400 focus:border-blue-600 focus:outline-none focus:ring-blue-600',
          props.className,
        )}
        type="text"
        placeholder="Search..."
        id="search-input"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        aria-autocomplete="both"
      />

      <div className="absolute inset-y-0 left-2 flex items-center">
        <SearchIcon className="text-2xl text-gray-400" />
      </div>
    </div>
  );
};

export default SearchBar;
