import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys } from '../../../../../styling/theme';
import { Grid } from '@mui/material';

interface KidsEditorGridItemProps {
  xs: any;
}

const useStyles = makeStyles(() => ({
  gridItem: {
    marginBottom: '0.62rem',
    color: 'black',
  },
  generalCardRoot: {
    background: greys.grey900,
    borderRadius: 2,
    overflow: 'hidden',
    width: '100%',
    marginBottom: '0.62rem',
  },
}));

function KidsEditorGridItem(
  props: React.PropsWithChildren<KidsEditorGridItemProps>,
): ReactElement {
  const classes = useStyles();

  return (
    <Grid item>
      <div className={classes.generalCardRoot}>{props.children}</div>
    </Grid>
  );
}

export default KidsEditorGridItem;
