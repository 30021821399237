import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { FC } from 'react';
import client from '../../../../../utilities/requestClient';
import useSnackbar from '../../../../../hooks/useSnackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button';
import { mainColors } from '../../../../../styling/theme';
import { toggleControlBarShowing } from '../../../../../redux/ui/controlbar/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface RevertVersionNumberDialogBoxProps {
  setDialogIsShowing: (value: boolean) => void;
  fundId: string;
  shareClass: string;
  documentLanguage: string;
  versionNumber: string;
  backToOverviewLink: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RevertVersionNumberDialogBox: FC<RevertVersionNumberDialogBoxProps> = (
  props,
) => {
  const {
    setDialogIsShowing,
    fundId,
    shareClass,
    documentLanguage,
    versionNumber,
  } = props;
  const requestClient = client();

  // variable for changing the text after the data has been reverted
  const [dataHasBeenReverted, setDataHasBeenReverted] =
    React.useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    navigate(props.backToOverviewLink);
  };

  const { showAsyncSnackbar } = useSnackbar();
  // Define a function for reverting versions
  async function handleRevertVersion() {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('fund_id', fundId);
    formData.append('share_class', shareClass);
    formData.append('document_langauge', documentLanguage);
    formData.append('version', versionNumber);
    // Monitor if an error occured to prevent the content from being reset.
    let errorOccured = false;
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Reverting to previous version.',
      successMessage: 'Document Reverted.',
      failureMessage: 'An Error occured while reverting.',
      promiseToResolve: requestClient
        .post('revert_kid_to_previous_version', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          alert(
            'There was an error while reverting versions. A report has been sent to Risksystem.',
          );
          errorOccured = true;
          throw error;
        })
        .then(() => {
          if (!errorOccured) {
            setDataHasBeenReverted(true);
          }
        }),
    });
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      {dataHasBeenReverted ? (
        <>
          <DialogTitle id="revert-header-text" textAlign="center">
            Document has been reverted.
          </DialogTitle>
          <DialogActions>
            <Button onClick={backToOverview}>Back to overview</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="revert-header-text" textAlign="center">
            Revert to a previous version of the document?
          </DialogTitle>
          <DialogContentText
            id="revert-subheader-text"
            textAlign="center"
            sx={{
              fontSize: '0.75rem',
            }}
          >
            Clicking confirm will revert the latest version of this document to
            be the version shown on this page.
          </DialogContentText>
          <DialogContentText
            id="revert-wairning-text"
            textAlign="center"
            sx={{
              color: mainColors.Fail,
              fontSize: '0.75rem',
              fontWeight: 600,
            }}
          >
            Warning: Reverting this document will delete all versions later than
            version {versionNumber}. This action cannot be undone.
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setDialogIsShowing(false)}>Cancel</Button>
            <Button onClick={handleRevertVersion}>Confirm</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default RevertVersionNumberDialogBox;
