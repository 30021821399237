import makeStyles from '@mui/styles/makeStyles';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../../styling/theme';
import { FC, useState } from 'react';
import CustomTable from '../../../../../tables/CustomTable';
import UtilityButtons from '../../../components/buttons/UtilityButtons.component';
import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { KidsData } from '../KidsEditor';
import KidsEditorGridItem from '../../layout/KidsEditorGridItem.component';
import { EditorTileProps } from './KidsEditorColumn.component';
import { useRaidrKids } from '../../context/RaidrKidsContext';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    marginTop: '1.25rem',

    marginBottom: '1.25rem',
    marginLeft: '0.62rem',
    fontSize: '0.94rem',
    boxSizing: 'border-box',
    padding: '0.62rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '6.25rem',
  },
  outerContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    padding: '0.62rem',
  },
  editorContainer: {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    width: '100%',
    marginRight: '0.62rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '0.62rem',
    gap: '1.25rem',
  },
  button: {
    height: '1.88rem',
    // marginTop: '0.12rem',
    marginRight: '0.62rem',
    width: '9.38rem',
  },
}));

interface ChargesTableData {
  chargeType:
    | 'Entry Charge'
    | 'Exit Charge'
    | 'Ongoing Charge'
    | 'Performance Fee';
  value: EditorState;
}

function buildChargesTableData(content: any[]): ChargesTableData[] {
  const entryChargeBlocks = convertFromHTML(content[0]);
  const entryChargeState = ContentState.createFromBlockArray(
    entryChargeBlocks.contentBlocks,
  );
  const entryChargeInitialState =
    EditorState.createWithContent(entryChargeState);

  const exitChargeBlocks = convertFromHTML(content[1]);
  const exitChargeState = ContentState.createFromBlockArray(
    exitChargeBlocks.contentBlocks,
  );
  const exitChargeInitialState = EditorState.createWithContent(exitChargeState);

  const ongoingChargeBlocks = convertFromHTML(content[2]);
  const ongoingChargeState = ContentState.createFromBlockArray(
    ongoingChargeBlocks.contentBlocks,
  );
  const ongoingChargeInitialState =
    EditorState.createWithContent(ongoingChargeState);

  const performanceFeeBlocks = convertFromHTML(content[3]);
  const performanceFeeState = ContentState.createFromBlockArray(
    performanceFeeBlocks.contentBlocks,
  );
  const performanceFeeInitialState =
    EditorState.createWithContent(performanceFeeState);

  const tableData: ChargesTableData[] = [
    {
      chargeType: 'Entry Charge',
      value: entryChargeInitialState,
    },
    {
      chargeType: 'Exit Charge',
      value: exitChargeInitialState,
    },
    {
      chargeType: 'Ongoing Charge',
      value: ongoingChargeInitialState,
    },
    {
      chargeType: 'Performance Fee',
      value: performanceFeeInitialState,
    },
  ];
  return tableData;
}

function buildColumns(
  handleChange: (
    updatedEditorState: EditorState,
    row: 'Entry Charge' | 'Exit Charge' | 'Ongoing Charge' | 'Performance Fee',
  ) => void,
): CustomColumn<ChargesTableData>[] {
  const columns: CustomColumn<ChargesTableData>[] = [
    {
      title: 'Charge Type',
      field: 'chargeType',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.31rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.31rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
    },
    {
      title: 'Value',
      field: 'value',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.31rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.31rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: ChargesTableData) => (
        <Editor
          editorState={rowData.value}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.chargeType);
          }}
          stripPastedStyles={true}
        />
      ),
    },
  ];

  return columns;
}

const ChargesTableEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  moveField,
  deleteField,
  setPriipsKidsContent,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the current element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object
  const content = JSON.parse(priipsKidsElement.content);
  const [tableData, setTableData] = useState<ChargesTableData[]>(
    buildChargesTableData(content),
  );
  // Create an object for storing the state of each cell in the table.
  const [editorState, setEditorState] = useState<ChargesTableData[]>(tableData);

  const classes = useStyles();

  const handleTextChange = (
    updatedEditorState: EditorState,
    rowKey:
      | 'Entry Charge'
      | 'Exit Charge'
      | 'Ongoing Charge'
      | 'Performance Fee',
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = editorState;
    // Decide on the index by matching the charge type.
    const rowIndex = currentEditorState.findIndex(
      (r) => r.chargeType === rowKey,
    );
    // Now update the cell that was changed.
    currentEditorState[rowIndex].value = updatedEditorState;
    // Update the overall table state.
    setEditorState(currentEditorState);
    // Update the table data
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex].value = updatedEditorState;
    setTableData(updatedTableData);

    // Create an array of the raw data
    const updatedArray: any[] = [];
    tableData.forEach((row) => {
      updatedArray.push(stateToHTML(row.value.getCurrentContent()));
    });
    // Update the overall data
    setPriipsKidsContent((allContent: KidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = JSON.stringify(updatedArray);
      return newContent;
    });
  };

  const tableColumns = buildColumns(handleTextChange);

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.outerContainer}>
        <div className={classes.editorContainer}>
          <div className={classes.textEditorBox}>
            <CustomTable<ChargesTableData>
              columns={tableColumns}
              id={'charges_table'}
              showToolbar={false}
              data={tableData}
              options={{
                paging: false,
                search: false,
                exportButton: false,
                sorting: false,
                draggable: false,
                toolbar: false,
              }}
            />
          </div>
          <UtilityButtons
            moveField={moveField}
            index={index}
            deleteField={deleteField}
            fieldId={fieldId}
            disableDelete
            disableDeleteMessage="You cannot delete the charges table"
          />
        </div>
      </div>
    </KidsEditorGridItem>
  );
};

export default ChargesTableEditorTile;
