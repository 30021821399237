import React, { ReactElement } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import makeStyles from '@mui/styles/makeStyles';
import RaptorMultiSelect from '../../../selects/RaptorMultiSelect.component';
import RaptorDatePicker from '../../../selects/RaptorDatePicker.component';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createSectionFundsSelector,
} from '../../../../redux/pages/selectors';
import { Switch } from '@mui/material';
import { greys, mainColors } from '../../../../styling/theme';
import { BASE_URL } from '../../../../utilities/requestClient';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { getAllRelevantWeekdays } from '../../../../utilities/dateUtilities';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginLeft: '1.25rem',
    marginTop: '0.62rem',
    marginBottom: '1.25rem',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  cardTitle: {
    color: 'black',
    fontSize: '1.25rem',
    fontWeight: '500',
    margin: 0,
  },
  dataPointSelect: {
    marginTop: '0.62rem',
    marginBottom: '0.62rem',
  },
  positionDatesContainer: {
    marginTop: '0.62rem',
    marginBottom: '0.62rem',
    display: 'flex',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    width: 'fit-content',
    justifyContent: 'space-around',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '0 0 1.88rem 0',
  },
  switchLabel: {
    fontSize: '0.94rem',
    fontWeight: 400,
    marginRight: '0.62rem',
  },
  sendInviteButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.62rem',
    padding: '0.31rem 1.25rem',
    borderRadius: '0.25rem',
    fontWeight: 500,
    fontSize: '1.25rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  sendInviteButton_inactive: {
    backgroundColor: greys.grey300,
    '&:hover': {
      backgroundColor: greys.grey300,
    },
    '&:active': {
      backgroundColor: greys.grey300,
    },
  },
}));

interface FileExportProps {
  availableDates: string[];
  riskMetrics: DataObject;
}

function formatRiskMetrics(riskMetrics: DataObject): any[] {
  if (!riskMetrics) return [];
  if (!('data' in riskMetrics)) return [];
  if (!riskMetrics.data) return [];
  if (!riskMetrics.data.length) return [];
  const metricsData = riskMetrics.data[0];
  const keys = Object.keys(metricsData);
  // sort the keys alphabetically
  keys.sort((a, b) =>
    metricsData[a].raptor_name.localeCompare(metricsData[b].raptor_name),
  );
  return keys.map((key) => ({
    value: key,
    label: metricsData[key].raptor_name,
  }));
}

export default function FileExport({
  availableDates,
  riskMetrics,
}: FileExportProps): ReactElement {
  const classes = useStyles();

  const historicalDataKeys = formatRiskMetrics(riskMetrics);

  // Create an array for storing the selected data points
  const [selectedDataPoints, setSelectedDataPoints] = React.useState<any[]>([]);
  // Create an object for storing start date
  const weekdays = getAllRelevantWeekdays();
  const [startDate, setStartDate] = React.useState<string>(
    availableDates.length > 1 ? availableDates[1] : weekdays[1],
  );
  // Object for storing end date
  const [endDate, setEndDate] = React.useState<string>(
    availableDates.length ? availableDates[0] : weekdays[0],
  );

  // Get the available funds
  const section = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createSectionFundsSelector(section || 'ucits'),
  );
  const fundIds = fundDetails != null ? fundDetails.map(({ id }) => id) : [];
  const fundNames =
    fundDetails != null ? fundDetails.map(({ name }) => name) : [];
  // Combine the fund ids and names into an array of objects
  const funds = fundIds.map((id, index) => ({
    value: id,
    label: fundNames[index],
  }));

  const [selectedFunds, setSelectedFunds] = React.useState<any[]>([]);

  // Object for indicating if one data point per sheet is selected (false means that oine fund per sheet is selected)
  const [oneDataPointPerSheet, setOneDataPointPerSheet] =
    React.useState<boolean>(false);

  function exportData() {
    // Create a form object that will be used for posting the request
    const mapForm = document.createElement('form');
    mapForm.target = '_blank';
    mapForm.method = 'POST';
    // On form submit send the request to the correct endpoint
    mapForm.action = BASE_URL + 'generate_historical_data_file';
    // add the fund names
    const fund_names = selectedFunds.map((fund) => fund.value);
    const fundNamesInput = document.createElement('input');
    fundNamesInput.type = 'text';
    fundNamesInput.name = 'fund_names';
    fundNamesInput.value = JSON.stringify(fund_names);
    // Add to the form
    mapForm.appendChild(fundNamesInput);

    // add the data points
    const data_points = selectedDataPoints.map(
      (data_point) => data_point.value,
    );
    const dataPointsInput = document.createElement('input');
    dataPointsInput.type = 'text';
    dataPointsInput.name = 'key_list';
    dataPointsInput.value = JSON.stringify(data_points);
    // Add to the form
    mapForm.appendChild(dataPointsInput);

    // add the start date
    const startDateInput = document.createElement('input');
    startDateInput.type = 'text';
    startDateInput.name = 'start_date';
    startDateInput.value = startDate;
    // Add to the form
    mapForm.appendChild(startDateInput);

    // add the end date
    const endDateInput = document.createElement('input');
    endDateInput.type = 'text';
    endDateInput.name = 'end_date';
    endDateInput.value = endDate;
    // Add to the form
    mapForm.appendChild(endDateInput);

    // add the one data point per sheet
    const outputFormatInput = document.createElement('input');
    outputFormatInput.type = 'text';
    outputFormatInput.name = 'output_format';
    outputFormatInput.value = oneDataPointPerSheet ? 'metric' : 'fund';
    // Add to the form
    mapForm.appendChild(outputFormatInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  return (
    <>
      <GridItem card xs={12}>
        <div className={classes.container}>
          <h6 className={classes.cardTitle}>
            What data points do you want to export?
          </h6>
          <div className={classes.dataPointSelect}>
            <RaptorMultiSelect
              items={historicalDataKeys}
              label="Data Points..."
              placeholder="Data Points..."
              selectAllLabel="Select all..."
              onChange={setSelectedDataPoints}
              noOptionsText="No options"
            />
          </div>
        </div>
      </GridItem>
      <GridItem card xs={12}>
        <div className={classes.container}>
          <h6 className={classes.cardTitle}>For what Time Period?</h6>
          <div className={classes.positionDatesContainer}>
            <RaptorDatePicker
              title={'Start Date'}
              handler={(val) => {
                setStartDate(dayjs(val).format('YYYY-MM-DD'));
              }}
              datesToInclude={availableDates}
              selectedDate={dayjs(startDate)}
              tiedToActiveDate={false}
            />
            <RaptorDatePicker
              title={'End Date'}
              handler={(val) => {
                setEndDate(dayjs(val).format('YYYY-MM-DD'));
              }}
              datesToInclude={availableDates}
              selectedDate={dayjs(endDate)}
              tiedToActiveDate={false}
            />
          </div>
        </div>
      </GridItem>
      <GridItem card xs={12}>
        <div className={classes.container}>
          <h6 className={classes.cardTitle}>
            What funds do you want to export data for?
          </h6>
          <div className={classes.dataPointSelect}>
            <RaptorMultiSelect
              items={funds}
              // getOptionDisabled={getOptionDisabled}
              label="Funds..."
              placeholder="Funds..."
              selectAllLabel="Select all..."
              onChange={setSelectedFunds}
              noOptionsText="No options"
            />
          </div>
        </div>
      </GridItem>
      <GridItem card xs={12}>
        <div className={classes.container}>
          <h6 className={classes.cardTitle}>
            How would you like to split the data?
          </h6>
          <div className={classes.dataPointSelect}>
            <div className={classes.switch}>
              <div className={classes.switchLabel}>One fund per sheet</div>
              <Switch
                checked={oneDataPointPerSheet}
                onChange={() => setOneDataPointPerSheet(!oneDataPointPerSheet)}
              />
              <div className={classes.switchLabel}>
                One data point per sheet
              </div>
            </div>
            {!selectedDataPoints.length || !selectedFunds.length ? (
              <div
                className={clsx(
                  classes.sendInviteButton,
                  classes.sendInviteButton_inactive,
                )}
              >
                Please make a selection above
              </div>
            ) : (
              <button className={classes.sendInviteButton} onClick={exportData}>
                Export Data
              </button>
            )}
          </div>
        </div>
      </GridItem>
    </>
  );
}
