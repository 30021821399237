import { FC, useState } from 'react';
import { Cell } from 'recharts';
import { greys, mainColors } from '../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToNdecialPlaces,
} from '../../../../utilities/numberFormatters';
import PercentValueToggleButton from '../../../buttons/PercentValueToggleButton.component';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { Typography } from '@mui/material';

interface MancoCounterpartyBaseCurrencyExposureChartProps {
  data: any;
}

function buildChartData(data: any) {
  if (!data.data.length) return [];
  if (!('asset_class_bc_exposure' in data.data[0])) return [];
  if (!('nav' in data.data[0])) return [];
  const baseCurrencyExposure = data.data[0].asset_class_bc_exposure;
  const nav = data.data[0].nav;
  const chartData: any[] = [
    {
      name: 'Equity',
      value: baseCurrencyExposure.equity,
      percent: baseCurrencyExposure.equity / nav,
    },
    {
      name: 'Fixed Income',
      value: baseCurrencyExposure.fixed_income,
      percent: baseCurrencyExposure.fixed_income / nav,
    },
    {
      name: 'Foreign Exchange',
      value: baseCurrencyExposure.foreign_exchange,
      percent: baseCurrencyExposure.foreign_exchange / nav,
    },
    {
      name: 'Cash',
      value: baseCurrencyExposure.cash,
      percent: baseCurrencyExposure.cash / nav,
    },
    {
      name: 'Alternative',
      value: baseCurrencyExposure.alternative,
      percent: baseCurrencyExposure.alternative / nav,
    },
  ];
  return chartData;
}

function mapBarColors(value: string) {
  switch (value) {
    case 'Equity':
      return mainColors.mainBlue_lighter;
    case 'Fixed Income':
      return mainColors.mainGold;
    case 'Foreign Exchange':
      return mainColors.Fail_darker;
    case 'Cash':
      return mainColors.tertiaryGreen;
    case 'Alternative':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const MancoCounterpartyBaseCurrencyExposureChart: FC<
  MancoCounterpartyBaseCurrencyExposureChartProps
> = (props) => {
  const [percentValueOption, setPercentValueOption] = useState<string>('value');

  const chartData = buildChartData(props.data);
  const bars = [
    {
      dataKey: percentValueOption === 'percent' ? 'percent' : 'value',
      fill: mainColors.mainBlue,
      key: percentValueOption === 'percent' ? 'percent' : 'value',
      children: chartData.map((value, index) => (
        <Cell key={`cell-${index}`} fill={mapBarColors(value.name)} />
      )),
    },
  ];
  return (
    <GridItem card xs={9} cardStyle={{ height: '100%' }}>
      {chartData.length ? (
        <>
          <div style={{ marginLeft: '1.25rem', marginTop: '1.25rem' }}>
            <PercentValueToggleButton
              percentValueOption={percentValueOption}
              updatePercentValueOption={setPercentValueOption}
            />
          </div>
          <GenericBarChart
            title={'Asset Class Base Currency Exposure'}
            id="manco_counterparty_asset_class_exposure_chart"
            exportButton
            loading={props.data.isFetching}
            bars={bars}
            loadingHeight={400}
            height={400}
            margin={{ top: 0, bottom: 24, left: 24, right: 24 }}
            width={'100%'}
            legend={false}
            yAxisLabel={{
              position: 'left',
              angle: -90,
              offset: 5,
              fontSize: '0.88rem',
              fill: greys.grey700,
              value: 'Exposure',
              style: {
                textAnchor: 'middle',
              },
            }}
            yAxisFormatter={(val) =>
              percentValueOption === 'percent'
                ? percentageToNdecialPlaces(val, 7)
                : formatMillions(val)
            }
            tooltipFormatter={(value: any) =>
              percentValueOption === 'percent'
                ? percentageToNdecialPlaces(value, 7)
                : addCommasToNumbersAndRound(value)
            }
            xAxes={[
              {
                label: {
                  value: 'Asset Class',
                  offset: 10,
                  style: {
                    fill: greys.grey700,
                    fontSize: '0.88rem',
                  },
                  position: 'bottom',
                },
                dataKey: 'name',
              },
            ]}
            data={chartData}
          />
        </>
      ) : (
        <DisplayAreaCenteredWrapper noMinHeight={true}>
          <Typography variant="h1">{'No data Available.'}</Typography>
        </DisplayAreaCenteredWrapper>
      )}
    </GridItem>
  );
};

export default MancoCounterpartyBaseCurrencyExposureChart;
