import { FC } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { percentageToNdecialPlacesNoHundred } from '../../../../utilities/numberFormatters';
import GenericStatusCell from '../../../tables/GenericStatusCell';
import { Status } from '../../../../types/redux/data/dataTypes';
import CashFlowStressTestDetailPanel from './CashflowStressTestsDetailPanel.component';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';

interface CashFlowStressTestsTableProps {
  data: any;
}

function buildTableData(data: any) {
  if (!data || !data.data || !data.data.length || !data.data[0]) return [];
  let tableData: CashFlowStressTestsTableData[] = [];
  const tableDataObject = data.data[0];
  // Create an object for storing the data by type
  const dataByType: any = {};
  tableDataObject.forEach((item: any) => {
    // Build the timeseries data.
    const timeSeriesData: any[] = [];
    for (let i = 0; i < item.discounted_free_cash_flow_ts.length; i++) {
      timeSeriesData.push({
        year: i,
        'Discounted Free Cash Flow': item.discounted_free_cash_flow_ts[i],
        EBIT: item.ebit_ts[i],
        'Free Cash Flow': item.free_cash_flow_ts[i],
      });
    }
    if (!(item.type in dataByType)) {
      dataByType[item.type] = [
        {
          stressTestType: item.type,
          stressTestName: '',
          pAndL: 0,
          exAntePAndL: 0,
          exPostPAndL: 0,
          status: '',
          timeseries: [],
        },
      ];
    }
    // Append to the list
    dataByType[item.type].push({
      stressTestType: '',
      stressTestName: item.name,
      pAndL: item.change_in_pl,
      exAntePAndL: item.ex_ante_pl,
      exPostPAndL: item.ex_post_pl,
      status: item.status,
      timeSeriesData,
    });
  });

  Object.keys(dataByType).forEach((key) => {
    tableData = tableData.concat(dataByType[key]);
  });
  return tableData;
}

interface CashFlowStressTestsTableData {
  stressTestType: string;
  stressTestName: string;
  pAndL: number;
  exAntePAndL: number;
  exPostPAndL: number;
  status: Status;
  timeSeriesData: any[];
}

const columns: CustomColumn<CashFlowStressTestsTableData>[] = [
  {
    title: 'Stress Test Type',
    field: 'stressTestType',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
  },
  {
    title: 'Stress Test Name',
    field: 'stressTestName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
  },
  {
    title: 'P/L',
    field: 'pAndL',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
    },
    headerStyle: {
      textAlign: 'center',
    },
    pdfRenderType: 'StatusWithPercentage',
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['pAndL'],
    },
    render: (rowData) =>
      rowData.stressTestType === '' ? (
        <GenericStatusCell
          height={'3.12rem'}
          status={rowData.status}
          innerText={percentageToNdecialPlacesNoHundred(rowData.pAndL, 2)}
        />
      ) : null,
  },
  {
    title: 'Ex-Ante P/L',
    field: 'exAntePAndL',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) =>
      rowData.stressTestType === '' ? rowData.exAntePAndL : null,
  },
  {
    title: 'Ex-Post P/L',
    field: 'exPostPAndL',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) =>
      rowData.stressTestType === '' ? rowData.exPostPAndL : null,
  },
];

const CashFlowStressTestsTable: FC<CashFlowStressTestsTableProps> = (props) => {
  // const theme = useTheme();
  const { data } = props;

  const tableData = buildTableData(data);
  return (
    <GridItem
      xs={12}
      card
      style={{
        padding: 8,
      }}
    >
      <CustomTable<CashFlowStressTestsTableData>
        pdfNoClearFirstRow
        options={{
          paging: false,
          exportButton: true,
        }}
        title={'Cash Flow Stress Tests'}
        showToolbar={true}
        data={tableData}
        //   toolbarComponents={{
        //     toolbarTitle: fundDetails.name,
        //   }}
        columns={columns}
        //   csvFields={[
        //     'stressScenarioType',
        //     'name',
        //     'pL',
        //     'pLstatus',
        //     'exAnteVolatility',
        //     'exAnteVar',
        //     'numSds',
        //     'numVar',
        //     'exPostVolatility',
        //     'exPostVar',
        //   ]}
        detailPanel={[
          (rowData) => ({
            disabled: rowData.stressTestType !== '',
            icon: () => (rowData.stressTestType !== '' ? null : <UnfoldMore />),
            openIcon: UnfoldLess,
            render: (rowData: CashFlowStressTestsTableData) => {
              return (
                <CashFlowStressTestDetailPanel
                  timeseriesData={rowData.timeSeriesData}
                />
              );
            },
          }),
        ]}
      />
    </GridItem>
  );
};

export default CashFlowStressTestsTable;
